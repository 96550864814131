/*CORE*/
import React, { ReactNode } from 'react'
/*LIBS*/
import { Route, Redirect, RouteProps } from 'react-router-dom'
import { connect, ConnectedProps } from 'react-redux'
/*SELECTORS*/
import { isProfileInitialLoad, profileRoleSelector } from '../store/profile/selectors'
import { isAuthenticated } from '../store/auth/selectors'
/*CONSTANTS*/
import { ROLE_NAMES } from 'utils/constants'
/*TYPES*/
import { RootState } from '../types'

// A wrapper for <Route> that redirects to the home
// screen if user is not admin

interface Props extends ConnectedProps<typeof connector>, RouteProps {
  children: ReactNode
}

const AdminRoute = ({ children, isAuthenticated, userRole, isInitLoad, ...rest }: Props) => {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!isAuthenticated) {
          return <Redirect to={{ pathname: '/login', state: { from: location } }} />
        } else if (!isInitLoad && userRole !== ROLE_NAMES.ADMIN) {
          return <Redirect to={{ pathname: '/', state: { from: location } }} />
        } else {
          return children
        }
      }}
    />
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    isAuthenticated: isAuthenticated(state),
    userRole: profileRoleSelector(state),
    isInitLoad: isProfileInitialLoad(state)
  }
}

const connector = connect(mapStateToProps)

export default connector(AdminRoute)
