/*CORE*/
import axios from 'axios'
import React, { useState } from 'react'
/*LIBS*/
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js'
/*CONSTANTS*/
import { API_BILLING_INTENT } from 'utils/constants'
/*STYLES*/
import styles from './CardSetupForm.module.scss'

import { Button, Input } from 'antd'

const BASE_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#E84060',
      iconColor: '#E84060',
    },
  },
}

interface Props {
  onAddNewPaymentCardSuccess: (cardData: { card_id: string }) => void
  onAddNewPaymentCardError: (errorMessage: string) => void
}

const CardSetupForm = ({ onAddNewPaymentCardSuccess, onAddNewPaymentCardError }: Props) => {

  const [ isLoading, setIsLoading ] = useState(false)
  const [ name, setName ] = useState('')

  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return
    }

    setIsLoading(true)

    try {
      const response = await axios.get(API_BILLING_INTENT)
      if (response.status === 200 && response.data.client_secret) {
        const result = await stripe.confirmCardSetup(response.data.client_secret, {
          payment_method: {
            card: elements.getElement(CardNumberElement)!,
            billing_details: {
              name,
            },
          }
        })

        if (result.error || !result?.setupIntent?.payment_method) {
          // Display result.error.message
          onAddNewPaymentCardError(result?.error?.message || '')
        } else {
          // Send result.setupIntent.payment_method to server to save the
          // card to a Customer
          onAddNewPaymentCardSuccess({
            card_id: result.setupIntent.payment_method
          })
        }
      }
    } catch (error) {
      onAddNewPaymentCardError(error?.message || '')
    }
  }

  const handleNameChange = (e: any) => {
    setName(e.target.value)
  }

  return (
    <form onSubmit={handleSubmit} className={styles['new-payment-card-form']}>
      <div className={styles['new-payment-card-section']}>
        <div className={styles['new-payment-card-section__row']}>
          <div className={styles['new-payment-card-section__field']}>
            <label htmlFor="number">Card number</label>
            <CardNumberElement
              id="number"
              options={BASE_OPTIONS}
            />
          </div>
        </div>
        <div className={styles['new-payment-card-section__row']}>
          <div className={styles['new-payment-card-section__field']}>
            <label htmlFor="expiry">Valid Thru</label>
            <CardExpiryElement
              id="expiry"
              options={BASE_OPTIONS}
            />
          </div>
          <div className={styles['new-payment-card-section__field']}>
            <label htmlFor="cvc">CVC</label>
            <CardCvcElement
              id="cvc"
              options={BASE_OPTIONS}
            />
          </div>
        </div>
        <div className={styles['new-payment-card-section__row']}>
          <div className={styles['new-payment-card-section__field']}>
            <label htmlFor="name">Owner name</label>
            <Input
              placeholder="John Doe"
              size="large"
              type="text"
              onChange={handleNameChange}
            />
          </div>
        </div>
      </div>
      <Button
        type="primary"
        size="large"
        htmlType="submit"
        loading={isLoading}
        disabled={!stripe}
      >{isLoading ? 'Adding' : 'Add a card'}</Button>
    </form>
  )
}

export default CardSetupForm
