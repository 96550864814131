import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { AxiosResponse } from 'axios'
import { useClipboard } from 'use-clipboard-copy'
import { Button, Form, Input, message } from 'antd'
import { getTwoFactorAuthToken as getTwoFactorAuthTokenAction } from 'store/auth/actions'
import { ReactComponent as CopyIcon } from 'assets/img/copy.svg'
import { isAuthenticated } from 'store/auth/selectors'
import { generateQR } from 'utils/utils'
import { RootState } from 'types'
import styles from './InitialSetup.module.scss'


interface Props {
  isAuthenticated: boolean
  getTwoFactorAuthToken: () => Promise<AxiosResponse<string>>
  handleContinue: () => void
}

const InitialSetup = ({ isAuthenticated, getTwoFactorAuthToken, handleContinue }: Props) => {
  const [useQrCode, setUseQrCode] = useState(true)
  const [qrImage, setQrImage] = useState('')
  const [secretCode, setSecretCode] = useState('')

  const clipboard = useClipboard({
    onSuccess() {
      message.success('Copied successfully')
    },
    onError() {
      message.error('Failed to copy text')
    }
  })

  useEffect(() => {
    isAuthenticated && generateQRImage()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  const generateQRImage = () => {
    getTwoFactorAuthToken().then(async (resp) => {
      const urlParams = new URLSearchParams(resp.data.split('?')[1])
      setSecretCode(urlParams.get('secret') || '')

      const imageUrl = await generateQR(resp.data)
      setQrImage(imageUrl)
    }).catch(() => {
      console.error('Could not get 2fa token')
    })
  }

  const handleCopyCode = () => {
    clipboard.copy(secretCode)
  }

  return (
    <>
      <header>
        <h1>Two-step authentication</h1>
        <p>
          Download the free <a href='https://support.google.com/accounts/answer/1066447?hl=en' target='_blank' rel='noopener noreferrer'>
          Google Authenticator</a> app, add a new account, then {useQrCode ? 'scan this barcode' : 'enter this key'} to set up your account.
        </p>
      </header>
      {useQrCode ? (
        <div className={styles['main-content']}>
          {qrImage && <img width={160} src={qrImage} alt='qrcode' />}
          <Button type='link' onClick={() => setUseQrCode(false)}>Enter code manually</Button>
        </div>
      ) : (
        <Form layout='vertical'>
          <Form.Item label='Key' colon={false} >
            <Input
              readOnly
              value={secretCode}
              suffix={<CopyIcon className='copy-icon' onClick={handleCopyCode} />}
            />
          </Form.Item>
          <Button type='link' className='btn-link' onClick={() => setUseQrCode(true)}>Scan barcode instead</Button>
        </Form>
      )}
      <div>
        <Button type='primary' block onClick={handleContinue}>
          Continue
        </Button>
      </div>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    isAuthenticated: isAuthenticated(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators({
      getTwoFactorAuthToken: getTwoFactorAuthTokenAction,
    }, dispatch),
  }
}
const connector = connect(mapStateToProps, mapDispatchToProps)

// @ts-ignore
export default connector(InitialSetup)
