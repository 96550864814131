import React, { useState } from 'react'
import { Input } from 'antd'
import { SketchPicker } from 'react-color'
import styles from './ColorPicker.module.scss'

interface Props {
  label: string,
  value: string,
  onChange: (value: string) => void,
  readOnly: boolean
}

const ColorPicker = ({ label, value, onChange, readOnly }: Props) => {
  const [showPicker, setShowPicker] = useState(false)
  const [color, setColor] = useState(value)

  const handleChange = (color: any) => {
    setColor(color.hex)
    onChange(color.hex)
  }

  const handleInputChange = (value: string) => {
    setColor(value)
    if (value.length === 7) {
      onChange(value)
    }
  }

  return (
    <div className={styles['color-picker']}>
      <div className={styles['color-picker__l']}>
        <div className={styles['color-picker__label']}>{label}</div>
      </div>
      <div className={styles['color-picker__r']}>
        <div
          className={styles['color-picker__trigger']}
          style={{ backgroundColor: color, cursor: readOnly ? 'auto' : 'pointer' }}
          onClick={() => {
            if (!readOnly) {
              setShowPicker(true)
            }
          }}
        />
        <Input
          value={color}
          onChange={e => handleInputChange(e.target.value)}
          maxLength={7}
          readOnly={readOnly}
        />
        {showPicker && (
          <div className={styles['color-picker__popover']}>
            <div
              className={styles['color-picker__cover']}
              onClick={() => setShowPicker(false)}
            />
            <SketchPicker
              color={color}
              onChange={handleChange}
            />
          </div>
        )}
      </div>
    </div>
  )
}

ColorPicker.defaultProps = {
  value: '',
  onChange: () => {},
  readOnly: false
}

export default ColorPicker
