/*CORE*/
import React from 'react'
/*LIBS*/
import { Redirect, Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { connect, ConnectedProps } from 'react-redux'
import { Button, Tabs } from 'antd'
/*COMPONENTS*/
import SinglePageLayout from '../../components/SinglePageLayout/SinglePageLayout'
import ShowroomCardLoader from '../ShowroomCardLoader/ShowroomCardLoader'
import DetailsSkeletonLoader from './Loaders/DetailsSkeletonLoader'
import AccessControl from '../../../common/AccessControl'
import Documents from './components/Documents/Documents'
import ShowroomCard from '../ShowroomCard/ShowroomCard'
import Overview from './components/Overview/Overview'
/*UTILS*/
import { goBack } from 'utils/utils'
/*SELECTORS*/
import { integratedMiniappIdsSelector, integrationsSelector } from 'store/integrations/selectors'
import { appCategoriesSelector, showroomMiniAppByIdSelector } from 'store/showroom/selectors'
import { isMiniAppsLoading, miniAppIdsSelector } from 'store/miniApps/selectors'
import { superAppsSelector } from 'store/superApps/selectors'
/*TYPES*/
import { Integration, IShowroomMiniApp, ISuperApp, RootState } from 'types'
/*CONSTANTS*/
import { IntegrationStatuses, Permissions } from 'utils/constants'
/*STYLES*/
import styles from './AppDetails.module.scss'

interface Props extends ConnectedProps<typeof connector> {
}

const { TabPane } = Tabs

const AppDetails = (
  {
    integratedMiniappIds,
    clientMiniAppIds,
    isMiniAppsLoading,
    integrations,
    superapps,
    getMiniappById
  }: Props
) => {
  const history = useHistory()
  const match = useRouteMatch()
  let { appId } = useParams()

  const getActiveTab = () => {
    // TODO: Handle it with routing architecture
    const p = history.location.pathname
    return p.split('/')[3]
  }

  const handleTabChange = (activeTab: string) => {
    history.replace(`${match.url}/${activeTab}`)
  }

  const handleClose = () => {
    goBack(history, '/showroom')
  }

  const app: IShowroomMiniApp | undefined = getMiniappById(+appId!)

  const isClientMiniApp = clientMiniAppIds.includes(app?.id)
  const isIntegratedMiniApp = integratedMiniappIds.includes(app?.id)

  const activeIntegrations =
    integrations.filter((item: Integration) => item.miniapp.id === app?.id && item.status !== IntegrationStatuses.Rejected)

  const integratedSuperApps = activeIntegrations.map((item: Integration) => item.hostapp.id)
  const hasSuperAppsToIntegrate = superapps.some((superapp: ISuperApp) => !integratedSuperApps.includes(superapp.id))

  const showIntegrateButton = !isIntegratedMiniApp && hasSuperAppsToIntegrate

  const handleIntegrate = () => {
    if (!isClientMiniApp) {
      history.push('/showroom/integrate', {
        app
      })
    }
  }

  const ApplyIntegrateBtn = () =>
    <AccessControl permission={Permissions.CAN_EDIT}>
      {(!isClientMiniApp && showIntegrateButton) && (
        <div className={styles['integrate-btn']}>
          <div className={styles['integrate-btn__btn']}>
            <Button type="primary" block onClick={handleIntegrate}>Apply to integrate</Button>
          </div>
        </div>
      )}
    </AccessControl>

  return (
    <SinglePageLayout onGoBack={handleClose}>
      <section className={styles['app-details']}>
        <aside>
          {
            !app || isMiniAppsLoading ?
              <ShowroomCardLoader />
              :
              <>
                <ShowroomCard hover={false} miniApp={app} />
                <ApplyIntegrateBtn />
              </>
          }
        </aside>
        <main>
          <Tabs
            defaultActiveKey={getActiveTab()}
            onChange={handleTabChange}
            className={styles['app-details__tabs']}
          >
            <TabPane tab="Overview" key="overview" />
            <TabPane tab="Documents" key="documents" />
          </Tabs>

          {
            !app ?
              <DetailsSkeletonLoader />
              :
              <Switch>
                <Route path={`${match.url}/overview`}>
                  <Overview app={app} />
                </Route>
                <Route path={`${match.url}/documents`}>
                  <Documents app={app} />
                </Route>
                <Route path="*">
                  <Redirect to={{ pathname: `${match.url}/overview` }} />
                </Route>
              </Switch>
          }
        </main>
      </section>
    </SinglePageLayout>
  )
}

const mapStateToProps = (state: RootState) => {

  return {
    categories: appCategoriesSelector(state),
    integratedMiniappIds: integratedMiniappIdsSelector(state),
    clientMiniAppIds: miniAppIdsSelector(state),
    isMiniAppsLoading: isMiniAppsLoading(state),
    superapps: superAppsSelector(state),
    integrations: integrationsSelector(state),
    getMiniappById: (miniappId: number) => showroomMiniAppByIdSelector(miniappId, state),
  }
}

const connector = connect(mapStateToProps, {})

export default connector(AppDetails)
