import React, { useState, useEffect } from 'react'
/*LIBS*/
import moment from 'moment'
import { AxiosResponse } from 'axios'
import { connect } from 'react-redux'
import { Typography, message, Tabs } from 'antd'
import { Store } from 'rc-field-form/lib/interface'
import { bindActionCreators, Dispatch } from 'redux'
import { RangeValue } from 'rc-picker/lib/interface'
import { useHistory, useParams, useRouteMatch, Switch, Route, Redirect } from 'react-router-dom'
/*ACTIONS*/
import {
  getSuperApp as getSuperAppAction,
  updateSuperApp as updateSuperAppAction
} from 'store/superApps/actions'
import { getAppMetricsByRangeDate as getAppMetricsByRangeDateAction } from 'store/analytics/actions'
/*COMPONENTS*/
import SinglePageLayout from '../components/SinglePageLayout/SinglePageLayout'
import SuperAppForm from './components/SuperAppForm/SuperAppForm'
import AppSkeleton from '../components/Skeletons/AppSkeleton'
import AppAnalytics from '../Analytics/AppAnalytics/AppAnalytics'
import SuperAppBilling from './SuperAppBilling'
import SuperAppPayouts from './SuperAppPayouts'
import Badge from '../components/Badge/Badge'
/*UTILS*/
import { goBack, normalizeMetrics } from 'utils/utils'
/*SELECTORS*/
import { isProfileInitialLoad, profileRoleSelector } from 'store/profile/selectors'
import { superAppPlansLoadedSelector, superAppPlansSelector } from 'store/billing/selectors'
/*CONSTANTS*/
import { ROLE_NAMES } from 'utils/constants'
/*TYPES*/
import { IFormData, ISuperApp, RootState } from 'types'
import { IMetrics } from 'types/Metrics'
import { IAppPlan, PaymentType } from 'types/Billing'
import SuperAppLogs from './components/SuperAppLogs/SuperAppLogs';

const { Title } = Typography
const { TabPane } = Tabs

enum SuperAppDetailsTabs {
  Overview = 'overview',
  Analytics = 'analytics',
  Billing = 'billing',
  Payouts = 'payouts',
  Logs = 'logs',
}

interface IStateProps {
  superAppPlans: IAppPlan[]
  isAdmin: boolean
  isProfileLoaded: boolean
  superAppPlansLoaded: boolean
}

interface IDispatchProps {
  getSuperApp: (appId: string) => Promise<AxiosResponse<ISuperApp>>
  updateSuperApp: (appId: string, formData: IFormData) => { superApp: ISuperApp }
  getAppMetricsByRangeDate:
    (appId: number | string, [ fromDate, toDate ]: [ moment.Moment, moment.Moment ], isMiniApp: boolean) => Promise<AxiosResponse<IMetrics[]>>
}

type Props = IStateProps & IDispatchProps

const SuperAppDetails = (
  {
    getSuperApp,
    getAppMetricsByRangeDate,
    updateSuperApp,
    superAppPlans,
    isAdmin,
    isProfileLoaded,
    superAppPlansLoaded,
  }: Props
) => {
  const [ activeTabKey, setActiveTabKey ] = useState<string>(SuperAppDetailsTabs.Overview)
  const [ isLoadingMetrics, setIsLoadingMetrics ] = useState(false)
  const [ app, setApp ] = useState<ISuperApp | null>(null)
  const [ metrics, setMetrics ] = useState<IMetrics[]>([])
  const [ isLoading, setIsLoading ] = useState(false)
  const match = useRouteMatch()
  const history = useHistory()
  let { appId } = useParams()

  useEffect(() => {
    setActiveTabKey(getActiveTab())
    if (appId) {
      loadSuperApp(appId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ appId ])

  const loadSuperApp = async (appId: string) => {
    try {
      const response = await getSuperApp(appId)
      setApp(response.data)
    } catch (error) {
      message.error('Something went wrong while loading host app')
    }
  }

  const handleClose = () => {
    goBack(history, '/host-apps')
  }

  const handleUpdate = async (data: Store) => {
    setIsLoading(true)
    try {
      if (data.upload instanceof Blob) {
        const formData = new FormData()
        formData.append('logo', data.upload, 'superapp-logo.png')
        await updateSuperApp(appId!, formData)
      }

      const updateAction = await updateSuperApp(appId!, data)
      if (updateAction.superApp) {
        setApp(updateAction.superApp)
      }
      message.success('Successfully saved')
      setIsLoading(false)
    } catch (error) {
      message.error('Something went wrong while updating host app')
      setIsLoading(false)
    }
  }

  const onChangeAnalyticsDate = async (values: RangeValue<moment.Moment>) => {
    if (values?.[0] && values?.[1]) {
      try {
        setIsLoadingMetrics(true)
        const range: [ moment.Moment, moment.Moment ] = [ values[0], values[1] ]

        const metricsResponse = await getAppMetricsByRangeDate(app!.id, range, false)
        setMetrics(normalizeMetrics(metricsResponse.data))
        setIsLoadingMetrics(false)

      } catch (e) {
        setIsLoadingMetrics(false)
        message.error('Something went wrong while loading analytics')
      }
    }
  }

  let badge = null
  if (app) {
    badge = <Badge
      status={app.is_active ? 'success' : 'warning'}
      label={app.is_active ? 'Active' : 'Awaiting approval'}
    />
  }

  const handleTabChange = (activeTab: string) => {
    setActiveTabKey(activeTab)
    history.replace(`${match.url}/${activeTab}`)
  }

  const getActiveTab = () => {
    // TODO: Handle it with routing architecture
    const p = history.location.pathname
    return p.split('/')[3]
  }

  let hasBillingPlans = false
  let hasPayoutPlans = false
  superAppPlans.forEach((plan) => {
    if (plan.hostapp.id === +appId!) {
      hasBillingPlans = hasBillingPlans || plan.billing_type === PaymentType.Billing
      hasPayoutPlans = hasPayoutPlans || plan.billing_type === PaymentType.Payout
    }
  })
  const renderBilling = isAdmin && hasBillingPlans
  const renderPayouts = isAdmin && hasPayoutPlans

  return (
    <SinglePageLayout onGoBack={handleClose} className="super-app-details">
      {app && <Title>{app.name} {badge}</Title>}
      <Tabs activeKey={activeTabKey} onChange={handleTabChange}>
        <TabPane tab="Overview" key={SuperAppDetailsTabs.Overview} />
        <TabPane tab="Analytics" key={SuperAppDetailsTabs.Analytics} />
        {renderBilling && (
          <TabPane tab="Billing" key={SuperAppDetailsTabs.Billing} />
        )}
        {renderPayouts && (
          <TabPane tab="Payouts" key={SuperAppDetailsTabs.Payouts} />
        )}
        <TabPane tab="Logs" key={SuperAppDetailsTabs.Logs} />
      </Tabs>
      {
        (app && isProfileLoaded && superAppPlansLoaded) ? (
          <Switch>
            <Route path={`${match.url}/${SuperAppDetailsTabs.Overview}`}>
              <SuperAppForm app={app} isSaving={isLoading} onSubmit={handleUpdate} />
            </Route>
            <Route path={`${match.url}/${SuperAppDetailsTabs.Analytics}`}>
              <AppAnalytics onChangeDate={onChangeAnalyticsDate} metrics={metrics} isLoading={isLoadingMetrics} />
            </Route>
            {renderBilling && (
              <Route path={`${match.url}/${SuperAppDetailsTabs.Billing}`}>
                <SuperAppBilling appId={appId} />
              </Route>
            )}
            {renderPayouts && (
              <Route path={`${match.url}/${SuperAppDetailsTabs.Payouts}`}>
                <SuperAppPayouts appId={appId} />
              </Route>
            )}
            <Route path={`${match.url}/${SuperAppDetailsTabs.Logs}`}>
              <SuperAppLogs appId={appId as string} />
            </Route>
            <Route path="*">
              <Redirect to={{ pathname: `${match.url}/${SuperAppDetailsTabs.Overview}` }} />
            </Route>
          </Switch>
        ) : (
          <AppSkeleton />
        )
      }
    </SinglePageLayout>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    isAdmin: profileRoleSelector(state) === ROLE_NAMES.ADMIN,
    isProfileLoaded: !isProfileInitialLoad(state),
    superAppPlans: superAppPlansSelector(state),
    superAppPlansLoaded: superAppPlansLoadedSelector(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators({
      getSuperApp: getSuperAppAction,
      updateSuperApp: updateSuperAppAction,
      getAppMetricsByRangeDate: getAppMetricsByRangeDateAction,
    }, dispatch),
  }
}

// @ts-ignore
export default connect<IStateProps, IDispatchProps, {}>(mapStateToProps, mapDispatchToProps)(SuperAppDetails)
