/*LIBS*/
import { createSelector } from 'reselect'
/*TYPES*/
import { RootState } from 'types'

// Partnerships selectors
const partnershipsStateSelector = (state: RootState) => state.partnerships

export const partnershipsSelector = createSelector(
  partnershipsStateSelector,
  partnershipsState => partnershipsState.partnerships
)

export const isPartnershipsLoading = createSelector(
  partnershipsStateSelector,
  partnershipsState => partnershipsState.isLoading
)

export const uiSelector = createSelector(
  partnershipsStateSelector,
  partnershipsState => partnershipsState.ui
)