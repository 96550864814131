/*TYPES*/
import {
  LOAD_PARTNERSHIPS, PartnershipsActionTypes
} from './types'
/*ACTIONS*/
import { LOAD_PARTNERSHIPS_ERROR, LOAD_PARTNERSHIPS_REQUEST, UPDATE_UI } from './actions'

export function partnerships(state = {
  partnerships: [],
  isLoading: false,
  ui: {
    csvModal: false,
    sentEmail: false,
  }
}, action: PartnershipsActionTypes) {
  switch (action.type) {
    case LOAD_PARTNERSHIPS_REQUEST: {
      return {
        ...state,
        isLoading: true
      }
    }
    case LOAD_PARTNERSHIPS_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    }
    case LOAD_PARTNERSHIPS: {
      return {
        ...state,
        isLoading: false,
        partnerships: action.partnerships
      }
    }
    case UPDATE_UI: {
      return {
        ...state,
        ui: {
          ...state.ui,
          ...action.payload
        }
      }
    }
    default:
      return state
  }
}
