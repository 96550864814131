import { createSelector } from 'reselect'
import { AppType } from 'utils/constants'

const payoutsStateSelector = state => state.payouts

export const bankAccountsSelector = createSelector(
  payoutsStateSelector,
  payoutsState => payoutsState.bankAccounts
)

export const primaryBankAccountSelector = createSelector(
  payoutsStateSelector,
  payoutsState =>
    payoutsState.bankAccounts.find(bankAccount => bankAccount.is_primary)
)

export const miniAppPayoutsSelector = (miniAppId, state) => {
  return createSelector(
    payoutsStateSelector,
    payoutsState => payoutsState.miniAppPayouts
      .filter(payout => payout.miniapp_plan.miniapp.id === miniAppId)
      .map(preparePayout)
  )(state)
}

export const superAppPayoutsSelector = (superAppId, state) => {
  return createSelector(
    payoutsStateSelector,
    payoutsState => payoutsState.hostAppPayouts
      .filter(payout => payout.hostapp_plan.hostapp.id === superAppId)
      .map(preparePayout)
  )(state)
}

export const allPayoutsSelector = (state) => {
  return createSelector(
    payoutsStateSelector,
    payoutsState => [...payoutsState.miniAppPayouts, ...payoutsState.hostAppPayouts]
      .map(preparePayout)
  )(state)
}

export const currenciesSelector = createSelector(
  payoutsStateSelector,
  payoutsState => payoutsState.currencies
)

/*
* preparePayouts function equates host app and miniapps payouts to a common interface for payouts table
* */
export const preparePayout = (payout) => {
  const newPayout = {...payout}
  if (newPayout.miniapp_plan) {
    newPayout['app_plan'] = {...payout.miniapp_plan}
    newPayout['paidTo'] = AppType.Miniapp
    delete newPayout['miniapp_plan']
  } else {
    newPayout['app_plan'] = {...payout.hostapp_plan}
    newPayout['paidTo'] = AppType.Hostapp
    delete newPayout['hostapp_plan']
  }
  return {...newPayout}
}
