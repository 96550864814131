/*CORE*/
import React, { useState } from 'react'
/*LIBS*/
import { connect, ConnectedProps } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { message, Button } from 'antd'
/*ACTIONS*/
import {
  addNewMiniapp as addNewMiniappAction,
  loadMiniApps as loadMiniAppsAction
} from 'store/miniApps/actions'
/*COMPONENTS*/
import SinglePageLayout from '../../components/SinglePageLayout/SinglePageLayout'
import MiniAppBasicInfo from '../components/MiniAppBasicInfo/MiniAppBasicInfo'
import ResultPane from '../../components/Result/Result'
/*UTILS*/
import { goBack } from 'utils/utils'


interface Props extends ConnectedProps<typeof connector> {}

export const CreateMiniApp = ({ addNewMiniapp, loadMiniApps }: Props) => {
  const [miniappId, setMiniappId] = useState<null | number>(null)
  const history = useHistory()

  const handleClose = () => {
    goBack(history, '/miniapps')
  }

  const goToMiniappDetails = () => {
    history.replace(`/miniapps/${miniappId}/overview`)
  }

  const handleSaveAndSubmit = async ({ name }: { name: string }) => {
    try {
      const formData = new FormData()
      formData.append('name', name)

      const response = await addNewMiniapp(formData)
      setMiniappId(response.data.id)
      await loadMiniApps()
    } catch (error) {
      console.log(error)
      message.error('Something went wrong. Please try again.')
    }
  }

  return (
    <SinglePageLayout
      contentPadding="large"
      onClose={handleClose}
      isFullHeight
    >
      {!miniappId ? (
        <MiniAppBasicInfo onSubmit={data => handleSaveAndSubmit(data)}/>
      ) : (
        <ResultPane
          title="You have added your miniapp"
          text="Continue to dashboard to finish the setup process"
        >
          <Button
            type="primary"
            size="large"
            block
            style={{ width: '340px' }}
            onClick={goToMiniappDetails}
          >
            Continue
          </Button>
        </ResultPane>
      )}
    </SinglePageLayout>
  )
}

const connector = connect(null, {
  addNewMiniapp: addNewMiniappAction,
  loadMiniApps: loadMiniAppsAction
})
export default connector(CreateMiniApp)
