/*CORE*/
import React, { useState } from 'react'
/*LIBS*/
import { connect, ConnectedProps } from 'react-redux'
/*ASSETS*/
import { ReactComponent as ArrowDown } from 'assets/img/arrow-down.svg'
/*SELECTORS*/
import {
  miniAppCountriesNamesByCodes as miniAppCountriesNamesByCodesSelector,
  miniappCountries as miniappCountriesSelector,
} from 'store/miniApps/selectors'
/*COMPONENTS*/
import CountriesModal from '../../components/CountriesModal/CountriesModal'
/*TYPES*/
import { RootState } from 'types'
/*STYLES*/
import styles from './ShowroomCountriesFilter.module.scss'

interface Props extends ConnectedProps<typeof connector> {
  onChange: (checkedCountries: string[]) => void
  checkedCountries: string[]
}

const ShowroomCountriesFilter = ({ onChange, checkedCountries, miniAppCountriesNamesByCodes, miniappCountries }: Props) => {

  const [ isOpenCountriesModal, setIsOpenCountriesModal ] = useState(false)

  const onChangeCountries = (countries: string[]) => {
    onChange(countries)
    toggleModal()
  }

  const toggleModal = () => setIsOpenCountriesModal(!isOpenCountriesModal)

  const getFilterLabel = (): string => {
    if (!checkedCountries?.length) {
      return 'Worldwide'
    }

    switch (checkedCountries.length) {
      case miniappCountries.length:
        return 'Worldwide'
      case 1:
        return miniAppCountriesNamesByCodes(checkedCountries)[0]
      default:
        return checkedCountries.length + ' countries'
    }
  }

  return (
    <>
      <div className={styles['countries-filter']} onClick={toggleModal}>
      <span>
        {
          getFilterLabel()
        }
      </span>
        <ArrowDown className={styles['arrow-down']} />
      </div>

      <CountriesModal
        counter
        closeModal={toggleModal}
        title={'Countries Filter'}
        onChange={onChangeCountries}
        visible={isOpenCountriesModal}
        checkedCountries={checkedCountries}
        description={'Choose the countries where mininapps should be available in.'}
      />
    </>
  )

}

const mapStateToProps = (state: RootState) => {
  return {
    miniappCountries: miniappCountriesSelector(state),
    miniAppCountriesNamesByCodes: (countryCodes: string[]) => miniAppCountriesNamesByCodesSelector(countryCodes, state)
  }
}

const connector = connect(mapStateToProps)

export default connector(ShowroomCountriesFilter)
