import React from 'react'
import { message } from 'antd'
import { useHistory } from 'react-router-dom'
import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { addNewBankAccount as addNewBankAccountAction } from 'store/payouts/actions'
import { currenciesSelector } from 'store/payouts/selectors'
import BankAccountForm from '../BankAccountForm/BankAccountForm'
import { RootState } from 'types'


interface Props extends ConnectedProps<typeof connector> {}

const AddBankAccount = ({ addNewBankAccount, currencies }: Props) => {
  const history = useHistory()

  const handleSave = (values: FormData) => {
    addNewBankAccount(values)
    message.success('Successfully saved')
    history.push('/payouts/details')
  }

  return (
    <BankAccountForm onSave={handleSave} currencies={currencies} />
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    currencies: currenciesSelector(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators({
      addNewBankAccount: addNewBankAccountAction
    }, dispatch),
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(AddBankAccount)
