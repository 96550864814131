/*CORE*/
import React from 'react'
/*CONSTANTS*/
import { PaymentMethodType } from 'utils/constants'
/*TYPE*/
import { IPaymentCard } from 'types/Billing'
import { IBankAccount } from 'types/Payouts'


const WireTransferMethod = ({ className, paymentMethodDetails }: { className: string, paymentMethodDetails: IBankAccount }) => (
  <>
    <div className={className}>{paymentMethodDetails.account_holder_name}</div>
    <div>Wire Transfer</div>
  </>
)

const PaymentCardMethod = ({ className, paymentMethodDetails }: { className: string, paymentMethodDetails: IPaymentCard }) => (
  <>{paymentMethodDetails.name || paymentMethodDetails.brand || paymentMethodDetails.last4
    ? <>
      <div className={className}>{paymentMethodDetails.name}</div>
      <div style={{textTransform: 'capitalize'}}>
        {paymentMethodDetails.brand} **** {paymentMethodDetails.last4}
      </div>
    </>
    : <div className={className}>Card was deleted.</div>
  }
  </>
)

interface Props {
  paymentMethod?: PaymentMethodType
  paymentMethodDetails: IPaymentCard | IBankAccount | null
  className: string
}

const PaymentMethod = ({ paymentMethod, paymentMethodDetails, className }: Props) => {
  if (!paymentMethodDetails) {
    return null
  }

  switch (paymentMethod) {
    case PaymentMethodType.WireTransfer:
      return <WireTransferMethod paymentMethodDetails={paymentMethodDetails as IBankAccount} className={className} />
    case PaymentMethodType.CreditCard:
      return <PaymentCardMethod paymentMethodDetails={paymentMethodDetails as IPaymentCard} className={className} />
    default:
      return null
  }
}

export default PaymentMethod
