import Cookies from 'js-cookie'

import { SET_AUTH } from './actions'

export function auth(state={
  isAuthenticated: Boolean(Cookies.get('token'))
}, action) {
  switch (action.type) {
    case SET_AUTH:
      return {
        ...state,
        isAuthenticated: action.isAuthenticated
      }
    default:
      return state
  }
}
