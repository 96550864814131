import React from 'react';
import { isNumber } from "lodash";

export interface FlexProps extends React.HTMLAttributes<HTMLElement> {
  vertical?: boolean;
  wrap?: React.CSSProperties["flexWrap"];
  justify?: React.CSSProperties["justifyContent"];
  align?: React.CSSProperties["alignItems"];
  flex?: React.CSSProperties["flex"];
  gap?: React.CSSProperties["gap"];
  children: React.ReactNode;
}

export const Flex = ({
  vertical = true,
  wrap,
  justify,
  align,
  flex,
  gap,
  children,
  ...others
}: FlexProps) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: vertical ? "column" : "row",
        flexWrap: wrap,
        justifyContent: justify,
        alignItems: align,
        flex,
        gap: isNumber(gap) ? `${gap}px` : gap,
      }}
      {...others}
    >
      {children}
    </div>
  );
};
