import {
  LOAD_USER_PROFILE,
  UPDATE_PROFILE
} from './actions'

export function profile(state={
  isInitialLoad: true,
  profile: {}
}, action) {
  switch (action.type) {
    case LOAD_USER_PROFILE:
      return {
        ...state,
        isInitialLoad: false,
        profile: action.profile
      }
    case UPDATE_PROFILE:
      return {
        ...state,
        profile: action.profile
      }
    default:
      return state
  }
}
