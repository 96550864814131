/*CORE*/
import React, { useEffect } from 'react'
/*LIBS*/
import { connect, ConnectedProps } from 'react-redux'
/*CONSTANTS*/
import { NESTED_ROUTES_PORTAL_TARGET_ID } from './utils/constants'
/*TYPES*/
import { RootState } from 'types'
/*COMPONENTS*/
import RootRouter from './RootRouter'
/*SELECTORS*/
import { isAuthenticated as isAuthenticatedSelector } from './store/auth/selectors'
/*ACTIONS*/
import {
  loadMiniApps as loadMiniAppsAction,
  loadCountries as loadCountriesAction,
} from './store/miniApps/actions'
import {
  loadPaymentCards as loadPaymentCardsAction,
  loadMiniAppSubPlans as loadMiniAppSubPlansAction,
  loadMiniAppInvoices as loadMiniAppInvoicesAction,
  loadSuperAppInvoices as loadSuperAppInvoicesAction,
  loadSuperAppSubPlans as loadSuperAppSubPlansAction,
  loadAppboxoBankDetails as loadAppboxoBankDetailsAction,
} from './store/billing/actions'
import {
  loadBankAccounts as loadBankAccountsAction,
  loadMiniAppPayouts as loadMiniAppPayoutsAction,
  loadSuperAppPayouts as loadSuperAppPayoutsAction,
  loadCurrencies as loadCurrenciesAction
} from './store/payouts/actions'
import {
  loadRoles as loadRolesAction,
  loadMembers as loadMembersAction,
  loadInvites as loadInvitesAction
} from './store/team/actions'
import { loadSuperApps as loadSuperAppsAction } from './store/superApps/actions'
import { loadUserProfile as loadUserProfileAction } from './store/profile/actions'
import { loadAppCategories as loadAppCategoriesAction } from './store/showroom/actions'
import { loadIntegrations as loadIntegrationsAction } from './store/integrations/actions'
import { loadPartnerships as loadPartnershipsAction } from './store/partnerships/actions'
import { loadShowroomMiniApps as loadShowroomMiniAppsAction } from './store/showroom/actions'

interface Props extends ConnectedProps<typeof connector> {}

const App = (
  {
    isAuthenticated, loadUserProfile,
    loadAppCategories, loadMiniApps, loadSuperApps,
    loadPaymentCards, loadMiniAppSubPlans, loadMiniAppInvoices,
    loadRoles, loadMembers, loadInvites, loadIntegrations,
    loadShowroomMiniApps, loadSuperAppSubPlans, loadSuperAppInvoices,
    loadBankAccounts, loadMiniAppPayouts, loadSuperAppPayouts, loadPartnerships,
    loadCurrencies, loadAppboxoBankDetails, loadMiniappCountries
  }: Props
) => {

  useEffect(() => {
    async function loadInitialData() {
      if (isAuthenticated) {
        try {
          await loadUserProfile()
          loadAppCategories()
          loadMiniApps()
          loadSuperApps()
          loadPaymentCards()
          loadBankAccounts()
          loadMiniAppSubPlans()
          loadMiniAppInvoices()
          loadSuperAppSubPlans()
          loadRoles()
          loadMembers()
          loadInvites()
          loadIntegrations()
          loadShowroomMiniApps()
          loadSuperAppInvoices()
          loadMiniAppPayouts()
          loadSuperAppPayouts()
          loadCurrencies()
          loadPartnerships()
          loadAppboxoBankDetails()
          loadMiniappCountries()
        } catch (error) {
          console.log(error)
        }
      }
    }

    loadInitialData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isAuthenticated ])

  return (
    <>
      <div id={NESTED_ROUTES_PORTAL_TARGET_ID} />
      <RootRouter />
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    isAuthenticated: isAuthenticatedSelector(state)
  }
}

const connector = connect(
  mapStateToProps,
  {
    loadUserProfile: loadUserProfileAction,
    loadAppCategories: loadAppCategoriesAction,
    loadMiniApps: loadMiniAppsAction,
    loadSuperApps: loadSuperAppsAction,
    loadPaymentCards: loadPaymentCardsAction,
    loadBankAccounts: loadBankAccountsAction,
    loadMiniAppSubPlans: loadMiniAppSubPlansAction,
    loadMiniAppInvoices: loadMiniAppInvoicesAction,
    loadSuperAppInvoices: loadSuperAppInvoicesAction,
    loadSuperAppSubPlans: loadSuperAppSubPlansAction,
    loadRoles: loadRolesAction,
    loadMembers: loadMembersAction,
    loadInvites: loadInvitesAction,
    loadIntegrations: loadIntegrationsAction,
    loadShowroomMiniApps: loadShowroomMiniAppsAction,
    loadMiniAppPayouts: loadMiniAppPayoutsAction,
    loadSuperAppPayouts: loadSuperAppPayoutsAction,
    loadCurrencies: loadCurrenciesAction,
    loadPartnerships: loadPartnershipsAction,
    loadAppboxoBankDetails: loadAppboxoBankDetailsAction,
    loadMiniappCountries: loadCountriesAction,

  }
)

export default connector(App)
