import { createSelector } from 'reselect'
import { RootState } from '../../types'

const organizationStoreSelector = (state: RootState) => state.organization

export const organizationSelector = createSelector(
  organizationStoreSelector,
  organization => organization.organization
)

export const countriesSelector = createSelector(
  organizationStoreSelector,
  organization => organization.countries
)
