/*CORE*/
import React from 'react'
/*LIBS*/
import { Form, Input, Button } from 'antd'
import { FieldData } from 'rc-field-form/lib/interface'
import { Link } from 'react-router-dom'
/*INTERFACES*/
import { FormData } from '../../../Auth/components/InvitationForm/InvitationForm'
/*UTILS*/
import { hasErrors } from 'utils/utils'
/*STYLES*/
import styles from './VerificationCodeForm.module.scss'


interface Props {
  onSubmit: (values: FormData) => void
  isLoading: boolean
  errors: string[]
}

const VerificationCodeForm = ({ onSubmit, errors, isLoading }: Props) => {
  const [ form ] = Form.useForm()
  const [ submitDisabled, setSubmitDisabled ] = React.useState(false)

  const detectErrors = (changedFields: FieldData[], allFields: FieldData[]) => {
    setSubmitDisabled(hasErrors(allFields))
  }

  return (
    <Form
      form={form}
      layout='vertical'
      onFieldsChange={detectErrors}
      onFinish={onSubmit}
      className={styles['verification-code-form']}
    >
      <div className={styles['verification-code-form__content']}>
        <Form.Item
          label='Verification code'
          name='code'
          colon={false}
          required={false}
          rules={[
            { required: true, message: 'Please enter your verification code' },
            { min: 6, message: 'Please enter full verification code' }
          ]}
        >
          <Input
            maxLength={6}
            placeholder='******'
            size='large'
          />
        </Form.Item>
        {Boolean(errors.length) && <div className={styles['login-form__errors']}>
          {errors.map(error => (
            <span key={error}>{error}</span>
          ))}
        </div>}
      </div>
      <div className={styles['verification-code-form__footer']}>
        <Button
          loading={isLoading}
          type='primary'
          htmlType='submit'
          size='large'
          block
          disabled={submitDisabled}
        >
          {isLoading ? 'Logging' : 'Continue'}
        </Button>
        <div className={styles['other-auth-option']}>
          <span>Authenticator app is unavailable?</span>
          <Link to='/disable-2fa'>Disable</Link>
        </div>
      </div>
    </Form>
  )
}

export default VerificationCodeForm
