/*CORE*/
import React, { useRef, useState } from 'react'
/*LIBS*/
import { Carousel } from 'antd'
import classnames from 'classnames'
/*ASSETS*/
import { ReactComponent as ArrowRight } from 'assets/img/arrow-right.svg'
import { ReactComponent as ArrowLeft } from 'assets/img/arrow-left.svg'
/*COMPONENTS*/
import LoadingPlaceholder
  from '../../../../../MiniApps/components/MiniAppForm/ScreenshotsUploader/ImgPlaceholders/LoadingPlaceholder/LoadingPlaceholder'
import ScreenshotPreviewModal from '../../../../../components/ScreenshotPreviewModal/ScreenshotPreviewModal'
import ImageWithPlaceholder from '../../../../../components/ImageWithPlaceholder/ImageWithPlaceholder'
/*CONSTANTS*/
import { BASE_URL } from 'utils/constants'
/*TYPES*/
import { IMiniAppScreenshot } from 'types'
/*STYLES*/
import styles from './ScreenshotsCarousel.module.scss'

// Carousel steps
// 1 is equal to one displayed screenshot
// Float numbers are needed to display part of the screenshot
const VISIBLE_PORTRAIT_SCREENS_COUNT = 3.5 as const
const VISIBLE_LANDSCAPE_SCREENS_COUNT = 2 as const
const PORTRAIT_CAROUSEL_STEP = 0.85 as const

const NextArrow = ({ className, onClick }: { className?: string, onClick: () => void }) => (
  <div onClick={onClick} className={classnames(styles['arrow'], className)}>
    <ArrowRight />
  </div>
)

const PrevArrow = ({ className, onClick }: { className?: string, onClick: () => void }) => (
  <div onClick={onClick} className={classnames(styles['arrow'], className)}>
    <ArrowLeft />
  </div>
)

interface Props {
  className?: string
  isLandscape: boolean
  screens: IMiniAppScreenshot[]
}

const ScreenshotsCarousel = ({ screens, className, isLandscape }: Props) => {
  const [ screenshotToOpenId, setScreenshotToOpenId ] = useState<number | null>(null)
  const [ prevIsAllowed, setPrevIsAllowed ] = useState(false)
  const [ nextIsAllowed, setNextIsAllowed ] = useState(true)

  const carouselRef = useRef<Carousel | null>(null)

  const getPortraitLastStep = (screensLength: number) => {
    // Adjusting the scroll position according to design
    // If we have 4 screenshots, then according to the design
    // need to scroll the slider 1.675 steps
    const lastStepCoef = 1.675

    // If we have 5 screenshots, then according to the design
    // need to scroll the slider 2 steps
    const firstStepCoef = 2

    return screensLength > VISIBLE_PORTRAIT_SCREENS_COUNT + 1 ? PORTRAIT_CAROUSEL_STEP * lastStepCoef :
      screensLength > VISIBLE_PORTRAIT_SCREENS_COUNT ? PORTRAIT_CAROUSEL_STEP / firstStepCoef : 0
  }

  const getLandscapeLastStep = (screensLength: number) =>
    screensLength > VISIBLE_LANDSCAPE_SCREENS_COUNT ? screensLength - VISIBLE_LANDSCAPE_SCREENS_COUNT : screensLength

  const getLastStep = (screensLength: number, isLandscape: boolean) =>
    isLandscape ? getLandscapeLastStep(screensLength) : getPortraitLastStep(screensLength)

  const onNext = (screensLength: number, isLandscape: boolean) => () => {
    const carouselInstance = carouselRef?.current
    if (!carouselInstance) {
      return
    }

    isLandscape ? carouselInstance.next() : carouselInstance.goTo(getLastStep(screensLength, isLandscape))
  }

  function onPrev() {
    carouselRef?.current?.prev()
  }

  function beforeChangeCarousel(from: number, to: number) {

    const firstScreenStep = 0

    if (to === getLastStep(screens.length, isLandscape)) {
      setNextIsAllowed(false)
    } else if (!nextIsAllowed) {
      setNextIsAllowed(true)
    }

    if (to === firstScreenStep) {
      setPrevIsAllowed(false)
    } else if (!prevIsAllowed) {
      setPrevIsAllowed(true)
    }
  }

  function openPreviewModal(screenArrayIdx: number) {
    setScreenshotToOpenId(screenArrayIdx)
  }

  function closePreviewModal() {
    setScreenshotToOpenId(null)
  }

  const onPreviewScreenshot = (id: number) => () => {
    const candidateIdx = screens.findIndex(screen => screen.id === id)
    candidateIdx > -1 && openPreviewModal(candidateIdx)
  }

  const visibleScreensCount = isLandscape ? VISIBLE_LANDSCAPE_SCREENS_COUNT : VISIBLE_PORTRAIT_SCREENS_COUNT

  return (
    <section className={classnames(styles['details-carousel'], isLandscape && styles['details-carousel__landscape'], className)}>
      {

        <NextArrow
          onClick={onNext(screens.length, isLandscape)}
          className={
            classnames(
              styles['next-arrow'],
              nextIsAllowed && screens.length > visibleScreensCount && styles['next-arrow__visible']
            )
          }
        />
      }
      {
        <PrevArrow onClick={onPrev} className={
          classnames(
            styles['prev-arrow'],
            prevIsAllowed && styles['prev-arrow__visible']
          )
        } />
      }
      <Carousel
        beforeChange={beforeChangeCarousel}
        slidesToShow={visibleScreensCount}
        slidesToScroll={1}
        ref={carouselRef}
        infinite={false}
        dots={false}
      >
        {
          // TODO(Vlad): Fix src attribute when backend is ready
          screens.map(({ id, url }: IMiniAppScreenshot) =>
            <div key={id} className={styles['carousel-item']} onClick={onPreviewScreenshot(id)}>
              <ImageWithPlaceholder
                src={BASE_URL + url}
                className={styles['img']}
                loadingPlaceholder={<LoadingPlaceholder />}
              />
            </div>
          )
        }
      </Carousel>
      <div className={
        classnames(
          styles['blurred-edge'],
          styles['blurred-edge__right'],
          nextIsAllowed && styles['blurred-edge__right--active'],
          isLandscape && styles['blurred-edge__right--active-landscape'],
        )} />
      <div className={
        classnames(
          styles['blurred-edge'],
          styles['blurred-edge__left'],
          prevIsAllowed && !isLandscape && styles['blurred-edge__left--active'],
        )} />
      <ScreenshotPreviewModal
        screenshotToOpenId={screenshotToOpenId}
        onCancel={closePreviewModal}
        isLandscape={isLandscape}
        screens={screens}
      />
    </section>
  )
}

export default ScreenshotsCarousel
