import React from 'react'
import classnames from 'classnames'

import { ReactComponent as Cross } from 'assets/img/cross.svg'
import { ReactComponent as Arrow } from 'assets/img/arrow.svg'
import Footer from '../Footer/Footer'

import './SinglePageLayout.scss'

interface Props {
  onGoBack?: (() => void) | null
  onClose?: () => void
  children?: React.ReactNode | React.ReactNodeArray
  contentPadding?: string
  isFullHeight?: boolean
  className?: string
  noControls?: boolean
}

const SinglePageLayout = ({
  onGoBack,
  onClose,
  isFullHeight,
  children,
  contentPadding = '',
  className = '',
  noControls
}: Props) => {
  return (
    <div
      className={classnames(
        'single-page-layout',
        {
          'is-full-height': isFullHeight,
        },
        className
      )}
    >
      {!noControls &&
        <>
          {onGoBack && (
            <div className="single-page-layout__back" onClick={onGoBack}>
              <Arrow />
            </div>
          )}
          {onClose && (
            <div className="single-page-layout__close" onClick={onClose}>
              <Cross />
            </div>
          )}
        </>
      }
      <div
        className={classnames('single-page-layout__content', {
          'padding-lg': contentPadding === 'large',
        })}
      >
        {children}
      </div>
      <Footer />
    </div>
  )
}

export default SinglePageLayout
