import axios from 'axios'
import { API_INVITATIONS, API_LOAD_ROLES, API_TEAM } from 'utils/constants'
import { Dispatch } from 'types/common'
import { IRole } from 'types/Team'


export const LOAD_ROLES_REQUEST = 'LOAD_ROLES_REQUEST'
export const LOAD_ROLES = 'LOAD_ROLES'
export const LOAD_ROLES_ERROR = 'LOAD_ROLES_ERROR'

export const LOAD_MEMBERS_REQUEST = 'LOAD_MEMBERS_REQUEST'
export const LOAD_MEMBERS = 'LOAD_MEMBERS'
export const LOAD_MEMBERS_ERROR = 'LOAD_MEMBERS_ERROR'

export const LOAD_INVITES_REQUEST = 'LOAD_INVITES_REQUEST'
export const LOAD_INVITES = 'LOAD_INVITES'
export const LOAD_INVITES_ERROR = 'LOAD_INVITES_ERROR'

export const CHANGE_ROLE_REQUEST = 'CHANGE_ROLE_REQUEST'
export const CHANGE_ROLE = 'CHANGE_ROLE'
export const CHANGE_ROLE_ERROR = 'CHANGE_ROLE_ERROR'

export const DEACTIVATE_ACCOUNT_REQUEST = 'DEACTIVATE_ACCOUNT_REQUEST'
export const DEACTIVATE_ACCOUNT = 'DEACTIVATE_ACCOUNT'
export const DEACTIVATE_ACCOUNT_ERROR = 'DEACTIVATE_ACCOUNT_ERROR'

export const ACTIVATE_ACCOUNT_REQUEST = 'ACTIVATE_ACCOUNT_REQUEST'
export const ACTIVATE_ACCOUNT = 'ACTIVATE_ACCOUNT'
export const ACTIVATE_ACCOUNT_ERROR = 'ACTIVATE_ACCOUNT_ERROR'

export const SEND_INVITE_REQUEST = 'SEND_INVITE_REQUEST'
export const SEND_INVITE = 'SEND_INVITE'
export const SEND_INVITE_ERROR = 'SEND_INVITE_ERROR'

export const RESEND_INVITE_REQUEST = 'RESEND_INVITE_REQUEST'
export const RESEND_INVITE = 'RESEND_INVITE'
export const RESEND_INVITE_ERROR = 'RESEND_INVITE_ERROR'

export const REMOVE_INVITE_REQUEST = 'REMOVE_INVITE_REQUEST'
export const REMOVE_INVITE = 'REMOVE_INVITE'
export const REMOVE_INVITE_ERROR = 'REMOVE_INVITE_ERROR'

export interface CreateInviteDTO {
  email: string
  role: IRole
}

export interface ChangeRoleDTO {
  id: number
  email: string
  role: IRole
}

export interface ManageMemberDTO {
  id: number
  email: string
}

export function loadRoles() {
  return function (dispatch: Dispatch) {
    dispatch({ type: LOAD_ROLES_REQUEST })

    return axios.get(API_LOAD_ROLES)
      .then((res) => {
        dispatch({ type: LOAD_ROLES, payload: res.data })
      })
      .catch(e => {
        dispatch({ type: LOAD_ROLES_ERROR })
        throw e
      })
  }
}

export function loadMembers() {
  return function (dispatch: Dispatch) {
    dispatch({ type: LOAD_MEMBERS_REQUEST })

    return axios.get(API_TEAM)
      .then((res) => {
        dispatch({ type: LOAD_MEMBERS, payload: res.data })
      })
      .catch(e => {
        dispatch({ type: LOAD_MEMBERS_ERROR })
        throw e
      })
  }
}

export function loadInvites() {
  return function (dispatch: Dispatch) {
    dispatch({ type: LOAD_INVITES_REQUEST })

    return axios.get(API_INVITATIONS)
      .then((res) => {
        dispatch({ type: LOAD_INVITES, payload: res.data })
      })
      .catch(e => {
        dispatch({ type: LOAD_INVITES_ERROR })
        throw e
      })
  }
}

export function changeRole(formData: ChangeRoleDTO) {
  return function (dispatch: Dispatch) {
    dispatch({ type: CHANGE_ROLE_REQUEST, payload: formData.email })

    return axios.patch(API_TEAM + `${formData.id}/`, {
      role: formData.role.id
    })
      .then(res => {
        dispatch({ type: CHANGE_ROLE, payload: { email: formData.email, role: formData.role } })
      })
      .catch(e => {
        dispatch({ type: CHANGE_ROLE_ERROR, payload: formData.email })
        throw e
      })
  }
}

export function deactivateAccount(formData: ManageMemberDTO) {
  return function (dispatch: Dispatch) {
    dispatch({ type: DEACTIVATE_ACCOUNT_REQUEST, payload: formData.email })

    return axios.patch(API_TEAM + `${formData.id}/`, {status: false})
      .then(res => {
        dispatch({ type: DEACTIVATE_ACCOUNT, payload: { email: formData.email, status: res.data.status } })
      })
      .catch(e => {
        dispatch({ type: DEACTIVATE_ACCOUNT_ERROR, payload: formData.email })
        throw e
      })
  }
}

export function activateAccount(formData: ManageMemberDTO) {
  return function (dispatch: Dispatch) {
    dispatch({ type: ACTIVATE_ACCOUNT_REQUEST, payload: formData.email })

    return axios.patch(API_TEAM + `${formData.id}/`, {status: true})
      .then(res => {
        dispatch({ type: ACTIVATE_ACCOUNT, payload: { email: formData.email, status: res.data.status } })
      })
      .catch(e => {
        dispatch({ type: ACTIVATE_ACCOUNT_ERROR, payload: formData.email })
        throw e
      })
  }
}

export function sendInvite(formData: CreateInviteDTO) {
  return function (dispatch: Dispatch) {
    dispatch({ type: SEND_INVITE_REQUEST })

    return axios.post(API_INVITATIONS, { email: formData.email, role: formData.role.id })
      .then(res => {
        dispatch({ type: SEND_INVITE })
      })
      .catch(e => {
        dispatch({ type: SEND_INVITE_ERROR })
        return Promise.reject(e)
      })
  }
}

export function removeInvite(formData: ManageMemberDTO) {
  return function (dispatch: Dispatch) {
    dispatch({type: REMOVE_INVITE_REQUEST, payload: formData.email})

    return axios.delete(API_INVITATIONS + `${formData.id}/`)
      .then(() => {
        dispatch({type: REMOVE_INVITE, payload: formData.email})
      })
      .catch(e => {
        dispatch({type: REMOVE_INVITE_ERROR, payload: formData.email})
        throw e
      })
  }
}

export function resendInvite(formData: ManageMemberDTO) {
  return function (dispatch: Dispatch) {
    dispatch({type: RESEND_INVITE_REQUEST, payload: formData.email})

    return axios.post(API_INVITATIONS + `${formData.id}/resend/`)
      .then(() => {
        dispatch({type: RESEND_INVITE, payload: formData.email})
      })
      .catch(e => {
        dispatch({type: RESEND_INVITE_ERROR, payload: formData.email})
        throw e
      })
  }
}
