/*CORE*/
import React, { useEffect } from 'react'
/*LIBS*/
import { Link } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
/*ACTIONS*/
import { loadOrganization as loadOrganizationAction } from 'store/organization/actions'
/*SELECTORS*/
import { primaryBankAccountSelector } from 'store/payouts/selectors'
import { organizationSelector } from 'store/organization/selectors'
/*COMPONENTS*/
import MoneyWrapper from '../../../common/MoneyWrapper'
import CurrencyDataTooltip from '../../Payment/CurrencyDataTooltip/CurrencyDataTooltip'
/*CONSTANTS*/
import { DEFAULT_CURRENCY } from 'utils/constants'
/*TYPES*/
import { IAppPayout, IBankAccount } from 'types/Payouts'
import { IAppPlan } from 'types/Billing'
import { RootState } from 'types'
/*STYLES*/
import styles from './TotalPayouts.module.scss'


const PayoutDestination = ({ payout }: { payout: IAppPayout }) => {
  return payout.destination ? (
    <div>
      <div className={styles['account']}>{payout.destination.bank_name}</div>
      <div>Account Number: {payout.destination.account_number}</div>
    </div>
  ) : null
}

const PrimaryAccount = ({ primaryAccount }: { primaryAccount: IBankAccount }) => {
  return primaryAccount ? (
    <>
      <div>
        <div className={styles['account']}>{primaryAccount.bank_name}</div>
        <div>Account Number: {primaryAccount.account_number}</div>
      </div>
      <Link to='/payouts/details' style={{ paddingLeft: 30 }}>Change</Link>
    </>
  ) : (
    <div>
      <div className={styles['account']}>No account</div>
      <Link to='/bank-account/new'>+ Add payment method</Link>
    </div>
  )
}

interface Props extends ConnectedProps<typeof connector> {
  appPlans: IAppPlan[]
  payout?: IAppPayout
}

const TotalPayouts = ({ appPlans, primaryAccount, payout, loadOrganization, organization }: Props) => {
  const total = appPlans.reduce((total, plan) => total + Number(plan.team_currency_amount), 0)
  const exchangeRateDate = appPlans.length > 0 ? appPlans[0].actual_currency_exchange_date : ''
  const dueToPay = appPlans.some((plan) => !plan.paid_date)
  const currency = payout ? payout.currency : (organization?.default_currency || DEFAULT_CURRENCY)

  useEffect(() => {
    loadOrganization()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles['total-payment']}>
      <div>
        <CurrencyDataTooltip
          currency={currency}
          exchangeRateDate={exchangeRateDate}
          hidden={!exchangeRateDate}
        >
          <MoneyWrapper amount={total} currency={currency} className={styles['amount']} />
        </CurrencyDataTooltip>
        <div>Estimated total for {dueToPay ? 'current' : ''} payout period</div>
      </div>
      <div className={styles['bank-account']}>
        {payout
          ? <PayoutDestination payout={payout} />
          : <PrimaryAccount primaryAccount={primaryAccount} />
        }
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    primaryAccount: primaryBankAccountSelector(state),
    organization: organizationSelector(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators({
      loadOrganization: loadOrganizationAction,
    }, dispatch)
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(TotalPayouts)
