/*CORE*/
import React from 'react'
/*LIBS*/
import { Button, Form, Input, message } from 'antd'
import { useClipboard } from 'use-clipboard-copy'
/*ASSETS*/
import { ReactComponent as CopyIcon } from 'assets/img/copy.svg'
/*UTILS*/
import { downloadFile } from 'utils/utils'

type Props = {
  emergencyCode: string
}

const EmergencyBackupCode = ({ emergencyCode }: Props) => {
  const clipboard = useClipboard({
    onSuccess() {
      message.success('Copied successfully')
    },
    onError() {
      message.error('Failed to copy text')
    }
  })

  const handleCopyEmergencyBackupCode = () => {
    clipboard.copy(emergencyCode)
  }

  const handleDownload = () => {
    downloadFile('emergency_code', 'txt', emergencyCode)
  }

  return (
    <Form layout='vertical' style={{ textAlign: 'left' }}>
      <Form.Item
        label='Emergency backup code'
        colon={false}
      >
        <Input
          readOnly
          value={emergencyCode}
          suffix={<CopyIcon className='copy-icon' onClick={handleCopyEmergencyBackupCode} />}
        />
      </Form.Item>
      <Button type='link' className='btn-link' onClick={handleDownload}>Download as TXT</Button>
    </Form>
  )
}

export default EmergencyBackupCode
