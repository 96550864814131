/*CORE*/
import React from 'react'
/*LIBS*/
import { Typography } from 'antd'
/*COMPONENTS*/
import { WithEmpty } from '../../../../components/WithEmpty/WithEmpty'
/*CONSTANTS*/
import { PartnershipStatuses } from '../../../enums'
/*STYLES*/
import styles from './Overview.module.scss'

const { Title } = Typography

interface Props {
  content: string
  status: PartnershipStatuses
}

const FURTHER_STEPS = [
  {
    title: 'Applying for a miniapp integration',
    description: 'View the Commercial Model and Summary of Terms of the chosen miniapp profile page. Please confirm that you agree before continuing to integration.',
  },
  {
    title: 'Preparing for integration',
    description: 'Super App tech team reviews tech documentation and commences integration with Boxo SDK.',
  },
  {
    title: 'Launch',
    description: 'Super App conducts testing and launches miniapp.',
  },
] as const

export const Overview = ({ content, status }: Props) => {

  const FurtherStep = ({ title, description, index }: { title: string, description: string, index: number }) =>
    <div className={styles['further-step']}>
      <div className={styles['count']}>0{index}.</div>
      <div className={styles['content']}>
        <p className={styles['step-title']}>{title}</p>
        <p className={styles['step-description']}>{description}</p>
      </div>
    </div>

  return (
    <div className={styles['overview']}>
      {
        status === PartnershipStatuses.Pending ?
          <div className={styles['further-steps']}>
            <Title className={styles['title']} level={3}>Next steps</Title>
            {
              FURTHER_STEPS
                .map(({ title, description }, i) =>
                  <FurtherStep key={title} title={title} description={description} index={i + 1} />
                )
            }
          </div>
          :
          <div className={styles['content-wrapper']}>
            <Title className={styles['title']} level={3}>Commercial Model</Title>
            <WithEmpty>
              {
                !!content && <p className={styles['content']}>{content}</p>
              }
            </WithEmpty>
          </div>
      }
    </div>
  )
}
