/*CORE*/
import React, { useEffect, useState } from 'react'
/*LIBS*/
import { Carousel } from 'antd'
import classnames from 'classnames'
import Modal from 'antd/lib/modal/Modal'
/*ASSETS*/
import { ReactComponent as ArrowLeft } from 'assets/img/arrow-left.svg'
import { ReactComponent as ArrowRight } from 'assets/img/arrow-right.svg'
/*COMPONENTS*/
import LoadingPlaceholder
  from '../../MiniApps/components/MiniAppForm/ScreenshotsUploader/ImgPlaceholders/LoadingPlaceholder/LoadingPlaceholder'
import ImageWithPlaceholder from '../ImageWithPlaceholder/ImageWithPlaceholder'
/*TYPES*/
import { IMiniAppScreenshot, MiniAppOrientations } from 'types'
/*UTILS*/
import { BASE_URL, ORIENTATION_SIZES } from 'utils/constants'
/*STYLES*/
import styles from './ScreenshotPreviewModal.module.scss'

interface Props {
  isLandscape: boolean
  onCancel: () => void
  screens: IMiniAppScreenshot[]
  screenshotToOpenId: number | null
}

export default function ScreenshotPreviewModal({ screenshotToOpenId, onCancel, screens, isLandscape }: Props) {
  const [ carouselRef, setCarouselRef ] = useState<Carousel | null>(null)
  const [ prevIsAllowed, setPrevIsAllowed ] = useState(false)
  const [ nextIsAllowed, setNextIsAllowed ] = useState(true)
  const [ isOpen, setIsOpen ] = useState(false)

  useEffect(() => {
    setIsOpen(screenshotToOpenId !== null)
    !isOpen && setCarouselRef(null)
    carouselRef && screenshotToOpenId !== null && goTo(screenshotToOpenId)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ screenshotToOpenId, carouselRef ])

  const onSetCarouselRef = (carousel: Carousel) => {
    setCarouselRef(carousel)
  }

  function onNext() {
    carouselRef?.next()
  }

  function onPrev() {
    carouselRef?.prev()
  }

  function goTo(idx: number) {
    carouselRef?.goTo(idx, true)
  }

  function beforeChangeCarousel(from: number, to: number) {

    const firstScreenId = 0
    const lastScreenId = screens.length - 1

    if (to === lastScreenId) {
      setNextIsAllowed(false)
    } else if (!nextIsAllowed) {
      setNextIsAllowed(true)
    }

    if (to === firstScreenId) {
      setPrevIsAllowed(false)
    } else if (!prevIsAllowed) {
      setPrevIsAllowed(true)
    }
  }

  const NextArrow = ({ className, onClick }: { className?: string, onClick: () => void }) => (
    <div onClick={onClick} className={classnames(styles['arrow'], className)}>
      <ArrowRight />
    </div>
  )

  const PrevArrow = ({ className, onClick }: { className?: string, onClick: () => void }) => (
    <div onClick={onClick} className={classnames(styles['arrow'], className)}>
      <ArrowLeft />
    </div>
  )

  const modalWidth = isLandscape ?
    ORIENTATION_SIZES.LARGE[MiniAppOrientations.Landscape].width :
    ORIENTATION_SIZES.LARGE[MiniAppOrientations.Portrait].width

  const bordersWidth = 2

  return (
    <Modal
      centered
      footer={null}
      visible={isOpen}
      closable={false}
      onCancel={onCancel}
      bodyStyle={{ padding: 0 }}
      width={modalWidth + bordersWidth}
      className={classnames(styles['preview-modal'], isLandscape && styles['preview-modal__landscape'])}
    >
      {
        nextIsAllowed && screens.length > 1 &&
        <NextArrow onClick={onNext} className={styles['next-arrow']} />
      }
      {
        prevIsAllowed &&
        <PrevArrow onClick={onPrev} className={styles['prev-arrow']} />
      }
      <Carousel
        beforeChange={beforeChangeCarousel}
        ref={onSetCarouselRef}
        dots={false}
        fade
      >
        {
          screens.map((screen: IMiniAppScreenshot, i: number) =>
            <div key={i} className={styles['carousel-item']}>
              <ImageWithPlaceholder
                className={styles['img']}
                src={BASE_URL + screen.url}
                loadingPlaceholder={<LoadingPlaceholder spinnerScale={0.4} />}
              />
            </div>
          )
        }
      </Carousel>
    </Modal>
  )
}
