/*CORE*/
import React from 'react'
/*COMPONENTS*/
import MoneyWrapper from '../../../../common/MoneyWrapper'
import CurrencyDataTooltip from '../../../Payment/CurrencyDataTooltip/CurrencyDataTooltip'
/*UTILS*/
import { formatAverageSession } from '../../AppAnalytics/AppAnalytics'
import { formatAmount } from 'utils/common'
/*TYPES*/
import { IMetrics } from 'types'
/*STYLES*/
import styles from './AnalyticsOverallTable.module.scss'

type Props = {
  metrics: IMetrics
}

const AnalyticsOverallTable = ({ metrics }: Props) => {
  return (
    <table className={styles['analytics-overall-table']}>
      <thead>
      <tr>
        <th>Description</th>
        <th>Unique users</th>
        <th>Launches</th>
        <th>Conversions</th>
        <th>Average session</th>
        <th>Sales / GMV</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>Overall metrics</td>
        <td>{formatAmount(metrics.unique_user_count)}</td>
        <td>{formatAmount(metrics.total_launches)}</td>
        <td>{formatAmount(metrics.total_conversion)}</td>
        <td>{formatAverageSession(metrics.average_session)}</td>
        <td>
          <CurrencyDataTooltip
            currency={metrics.currency}
            exchangeRateDate={metrics.actual_currency_exchange_date}
            hidden={metrics.multi_currency_sales.length === 0}
          >
            <MoneyWrapper amount={metrics.total_sales} currency={metrics.currency}/>
          </CurrencyDataTooltip>
        </td>
      </tr>
      </tbody>
    </table>
  )
}

export default AnalyticsOverallTable
