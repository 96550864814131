/*CORE*/
import React from 'react'
/*LIBS*/
import { Form, Input, Button } from 'antd'
import { FieldData } from 'rc-field-form/lib/interface'
import { Link } from 'react-router-dom'
/*INTERFACES*/
import { FormData } from '../InvitationForm/InvitationForm'
/*UTILS*/
import { hasErrors } from 'utils/utils'
/*STYLES*/
import styles from './LoginForm.module.scss'

interface Props {
  onSubmit: (values: FormData) => void
  isLoading: boolean
  errors: string[]
}

const LoginForm = ({ onSubmit, errors, isLoading }: Props) => {
  const [ form ] = Form.useForm()
  const [ submitDisabled, setSubmitDisabled ] = React.useState(false)

  const detectErrors = (changedFields: FieldData[], allFields: FieldData[]) => {
    setSubmitDisabled(hasErrors(allFields))
  }

  return (
    <Form
      form={form}
      onFieldsChange={detectErrors}
      className={styles['login-form']}
      onFinish={onSubmit}
    >
      <div className={styles['login-form__content']}>
        <Form.Item
          name='email'
          rules={[
            {
              type: 'email',
              message: 'The input is not valid email',
            },
            {
              required: true,
              message: 'Please input your email'
            }
          ]}
        >
          <Input
            placeholder='Email'
            size='large'
            type='text'
          />
        </Form.Item>
        <Form.Item
          name='password'
          rules={[ { required: true, message: 'Please input your password' } ]}
        >
          <Input.Password
            type="password"
            placeholder="Password"
            size="large"
          />
        </Form.Item>
        {Boolean(errors.length) && <div className={styles['login-form__errors']}>
          {errors.map(error => (
            <span key={error}>{error}</span>
          ))}
        </div>}
        <div className={styles['login-form__forget']}>
          <Link to='/recover'>Forgot Password?</Link>
        </div>
      </div>
      <div className={styles['login-form__footer']}>
        <Button
          loading={isLoading}
          type="primary"
          htmlType="submit"
          size="large"
          block
          disabled={submitDisabled}
        >
          {isLoading ? 'Logging' : 'Login'}
        </Button>
      </div>
    </Form>
  )
}

export default LoginForm
