import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import { connect, ConnectedProps } from 'react-redux'
import { List, Skeleton, Typography, Upload, message, Button, Popconfirm } from 'antd'
import { FileTextOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons'

import {
  getMiniappDocuments as getMiniappDocumentsAction,
  deleteMiniappDocument as deleteMiniappDocumentAction
} from 'store/miniApps/actions'
import { API_USER_APPS, Permissions } from 'utils/constants'
import { IFile } from 'types'
import AccessControl from '../../../../common/AccessControl'

import './Documents.scss'

interface Props extends ConnectedProps<typeof connector> {}

const { Title } = Typography

export const Documents = ({ getMiniappDocuments, deleteMiniappDocument, appId }: Props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [uploadingDocument, setUploadingDocument] = useState(false)
  const [files, setFiles] = useState<IFile[]>([])
  const [fileName, setFileName] = useState('document')
  const documentUrl = API_USER_APPS + appId + '/documents/'

  // Upload component's props
  const uploadProps = {
    name: 'document',
    data: {
      name: fileName
    },
    showUploadList: false,
    accept: 'application/pdf',
    action: documentUrl,
    headers: {
      authorization: 'Token ' + Cookies.get('token')
    },
    beforeUpload(file: File) {
      const isValid = file.size / 1024 / 1024 < 30
      if (!isValid) {
        message.error('Document must be smaller than 30MB')
        return false
      }

      setFileName(file.name)
      setUploadingDocument(true)
      return true
    },
    onChange(info: any) {
      if (info.file.status === 'done') {
        setUploadingDocument(false)
        message.success(`${info.file.name} file uploaded successfully`)

        // Append newly created documents to existing list
        setFiles([
          ...files,
          info.file.response
        ])
      } else if (info.file.status === 'error') {
        setUploadingDocument(false)
        message.error(`${info.file.name} file upload failed.`)
      }
    },
  }

  useEffect(() => {
    if (appId) {
      loadDocuments(appId)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appId])

  const loadDocuments = async (appId: string) => {
    try {
      // Get miniapp documents
      const documents = await getMiniappDocuments(appId)
      setFiles(documents.data)

      setIsLoading(false)
    } catch (error) {
      message.error('Something went wrong while loading miniapp')
      setIsLoading(false)
    }
  }

  const deleteDocument = async (documentId: number) => {
    try {
      await deleteMiniappDocument(appId, documentId)

      // Update documents list
      setFiles(files.filter(file => file.id !== documentId))
    } catch (error) {
      message.error('Something went wrong. Please try again.')
    }
  }

  return (
    <div className="miniapp-documents">
      <div className="miniapp-documents__header">
        <Title level={3}>Documents list</Title>
        <AccessControl permission={Permissions.CAN_EDIT}>
          <Upload {...uploadProps}>
            <Button
              type="primary"
              size="small"
              loading={uploadingDocument}
              disabled={uploadingDocument}
            >
              <UploadOutlined /> Click to Upload
            </Button>
          </Upload>
        </AccessControl>
      </div>
      {isLoading ? (
        <Skeleton
          active
        />
      ) : (
        <List
          size="large"
          dataSource={files}
          renderItem={item => (
            <List.Item
              actions={[
                <AccessControl permission={Permissions.CAN_EDIT}>
                  <Popconfirm
                    title={`Are you sure you want to delete ${item.name}?`}
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => deleteDocument(item.id)}
                  >
                    <DeleteOutlined />
                  </Popconfirm>
                </AccessControl>
              ]}
            >
              <div className="miniapp-documents__item">
                <FileTextOutlined />
                <a href={item.document} target="_blank" rel="noopener noreferrer">{item.name}</a>
              </div>
            </List.Item>
          )}
        />
      )}
    </div>
  )
}


const connector = connect((state, ownProps: { appId: string }) => ({ appId: ownProps.appId}), {
  getMiniappDocuments: getMiniappDocumentsAction,
  deleteMiniappDocument: deleteMiniappDocumentAction
})

export default connector(Documents)
