import React from 'react'
import { message, Modal } from 'antd'
import RoleForm from './RoleForm'
import { FormData } from './RoleForm'
import { IRole } from 'types/Team'
import { CreateInviteDTO } from 'store/team/actions'
import './InvitationModal.scss'

interface Props {
  closeModal: (state: boolean) => void
  roles: IRole[]
  handleSubmit: (values: CreateInviteDTO) => Promise<void>
  loadInvites: () => Promise<void>
  opened?: boolean
}

const InvitationModal = ({ opened, closeModal, roles, handleSubmit, loadInvites }: Props) => {
  const [formSubmitting, setFormSubmitting] = React.useState(false)
  const [errors, setErrors] = React.useState<string[]>([])

  const handleFormSubmit = (values: FormData) => {
    setFormSubmitting(true)
    setErrors([])

    handleSubmit({
      email: values.email,
      role: roles.find(role => role.id === values.role) as IRole

    }).then(() => {
      message.success(`Invitation has been sent to ${values.email}`)
      setFormSubmitting(false)

      loadInvites().finally(() => {
        closeModal(false)
      })

    }).catch((error) => {
      if (error.response.data && typeof error.response.data === 'object') {
        const errorLabels = Object.keys(error.response.data)
          .map(errorName => `${error.response.data[errorName]}`)
        setErrors(errorLabels)
      } else {
        setErrors(['Unexpected error happened. Try again later.'])
      }
      setFormSubmitting(false)

    })
  }

  return (
    <Modal
      className="invitation-modal"
      visible={opened}
      title="Invite team member"
      footer={false}
      onCancel={() => closeModal(false)}
    >
      <RoleForm
        roles={roles}
        onSubmit={handleFormSubmit}
        isLoading={formSubmitting}
        onCancel={() => closeModal(false)}
        errors={errors}
      />
    </Modal>
  )
}

export default InvitationModal
