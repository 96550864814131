import React from 'react'
import Empty from 'antd/lib/empty'
import classnames from 'classnames'
import './WithEmpty.scss'

interface Props {
  className?: string
}

export const WithEmpty: React.FC<Props> = ({ children, className }) => (
  <>
    {children || (
      <Empty
        className={classnames('with-empty', className)}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
      />
     )}
  </>
)
