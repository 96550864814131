/*CORE*/
import React, { useState } from 'react'
/*LIBS*/
import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { useHistory } from 'react-router-dom'
import { Button, Typography } from 'antd'
/*ACTIONS*/
import {
  setPaymentStateToError as setPaymentStateToErrorAction,
  setPaymentStateToInitial as setPaymentStateToInitialAction
} from 'store/billing/actions'
/*COMPONENTS*/
import PaymentInfoMultiCurrency from '../../Payment/PaymentInfoMultiCurrency/PaymentInfoMultiCurrency'
import SinglePageLayout from '../../components/SinglePageLayout/SinglePageLayout'
import AppsPaymentTable from '../../Payment/AppsPaymentTable/AppsPaymentTable'
import AppSkeleton from '../../components/Skeletons/AppSkeleton'
import Badge from '../../components/Badge/Badge'
import TotalBilling from '../TotalBilling/TotalBilling'
import PayManually from '../PayManually/PayManually'
/*UTILS*/
import { goBack } from 'utils/utils'
import { PAYMENT_STATUS } from 'utils/billing'
import { DEFAULT_CURRENCY } from 'utils/constants'
/*SELECTORS*/
import { paymentStatusSelector } from 'store/billing/selectors'
/*TYPES*/
import { IAppInvoice, IAppPlan, InvoiceStatus } from 'types/Billing'
import { RootState } from 'types'
/*STYLES*/
import styles from '../../Payment/PaymentInfo.module.scss'

const { Title } = Typography

interface Props extends ConnectedProps<typeof connector> {
  invoice?: IAppInvoice
  downloadInvoice: (invoice: IAppInvoice, format: string) => void,
  payInvoice: (cardData: { card_id: number }, invoiceId: number) => void
  payForInvoiceWithAnotherCard: (cardData: { card_id: string }, invoiceId: number) => void
  updateInvoices: () => void
  appPlan?: IAppPlan
}

const BillingInfo = ({ invoice, appPlan, downloadInvoice, payInvoice, payForInvoiceWithAnotherCard, updateInvoices,
                       setPaymentStateToInitial, setPaymentStateToError, paymentStatus }: Props) => {
  const [isPayManuallyModalOpen, setIsPayManuallyModalOpen] = useState(false)
  const history = useHistory()

  const handleGoBack = () => {
    goBack(history, '/billing/billing-history')
  }

  const handlePayAnotherCardError = (error: string) => {
    setPaymentStateToError(error)
  }

  const handlePayAnotherCard = (card_id: string) => {
    invoice?.id && payForInvoiceWithAnotherCard({ card_id }, invoice.id)
  }

  const handlePayCollectedCard = (card_id: number) => {
    invoice?.id && payInvoice({ card_id }, invoice.id)
  }

  const handleTryAgain = () => {
    setPaymentStateToInitial()
  }

  const onOKPayManually = () => {
    setIsPayManuallyModalOpen(false)
    updateInvoices()
  }

  const onCancelPayManually = () => {
    setIsPayManuallyModalOpen(false)
  }

  if (!invoice) {
    return (
      <SinglePageLayout onGoBack={handleGoBack}>
        <AppSkeleton />
      </SinglePageLayout>
    )
  }

  return (
    <SinglePageLayout className={styles['billing-info']} onGoBack={handleGoBack}>
      <div className={styles['header']}>
        <div className={styles['details']}>
          <div>
            <Title>Payment information</Title>
            <span>{invoice.invoice_number}</span>
          </div>
          <Badge
            status={PAYMENT_STATUS[invoice.status]?.status}
            label={PAYMENT_STATUS[invoice.status]?.label}
            className={styles['badge']}
          />
        </div>
        <div className={styles['actions']}>
          {invoice.status === InvoiceStatus.Failed && (
            <>
              <Button type='link' onClick={() => setIsPayManuallyModalOpen(true)}>Pay manually</Button>
              <span>{' | '}</span>
            </>
          )}
          <Button type='link' onClick={() => downloadInvoice(invoice, 'pdf')}>Export PDF</Button>
          <span>{' | '}</span>
          <Button type='link' onClick={() => downloadInvoice(invoice, 'csv')}>Export CSV</Button>
        </div>
      </div>
      <TotalBilling appPlans={appPlan ? [appPlan] : []} invoice={invoice} />
      <AppsPaymentTable appPlans={appPlan ? [appPlan] : []} isFailed={invoice.status === InvoiceStatus.Failed} unClickable />
      <PaymentInfoMultiCurrency appPlans={appPlan ? [appPlan] : []} />
      <PayManually
        onOk={onOKPayManually}
        isOpenModal={isPayManuallyModalOpen}
        status={paymentStatus.status}
        onCancel={onCancelPayManually}
        handleTryAgain={handleTryAgain}
        errorMessage={paymentStatus?.error || ''}
        handlePayAnotherCard={handlePayAnotherCard}
        handlePayCollectedCard={handlePayCollectedCard}
        handlePayAnotherCardError={handlePayAnotherCardError}
        amount={parseFloat(invoice.amount || '0').toFixed(2)}
        refCode={invoice.invoice_number}
        currency={invoice.currency || DEFAULT_CURRENCY}
      />
    </SinglePageLayout>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    paymentStatus: paymentStatusSelector(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators({
      setPaymentStateToError: setPaymentStateToErrorAction,
      setPaymentStateToInitial: setPaymentStateToInitialAction,
    }, dispatch),
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(BillingInfo)
