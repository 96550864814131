import React, { ReactElement } from 'react'

import { ReactComponent as Visa } from '../../../../assets/img/visa.svg'
import { ReactComponent as Mastercard } from '../../../../assets/img/mastercard.svg'
import { ReactComponent as Amex } from '../../../../assets/img/amex.svg'
import { ReactComponent as Diners } from '../../../../assets/img/diners.svg'
import { ReactComponent as Discover } from '../../../../assets/img/discover.svg'
import { ReactComponent as Jcb } from '../../../../assets/img/jcb.svg'
import { ReactComponent as Unionpay } from '../../../../assets/img/unionpay.svg'
import { ReactComponent as DefaultCard } from '../../../../assets/img/default-card.svg'

const CARDS: { [key:string]: ReactElement } = {
  visa: <Visa />,
  mastercard: <Mastercard />,
  amex: <Amex />,
  diners: <Diners />,
  discover: <Discover />,
  jcb: <Jcb />,
  unionpay: <Unionpay />
}


const PaymentCardIcon = ({ type }: { type: string }) => {
  return CARDS[type] || <DefaultCard />
}

export default PaymentCardIcon
