import React, { FC } from 'react';
import { useGetMiniAppLogs } from '../../../../../queries/logs';
import LogsTable from '../../../../common/LogsTable';

interface Props {
  appId: string
}

const MiniAppLogs: FC<Props> = ({appId}) => {
  const {data, isLoading} = useGetMiniAppLogs(appId)

  return <LogsTable data={data?.filter(log => +log.response_status >= 400) || []} loading={isLoading} />
}

export default MiniAppLogs;
