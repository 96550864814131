import { ReducerAction, Integration } from 'types'
import {
    LOAD_INTEGRATIONS,
    NEW_INTEGRATION
} from './actions'

interface State {
  integrations: Integration[]
}

export function integrations(state={
   integrations: []
}, action: ReducerAction) {
  switch (action.type) {
    case LOAD_INTEGRATIONS:
      return {
        integrations: action.payload
      }
    case NEW_INTEGRATION:
      return {
        integrations: [
          ...state.integrations,
          action.payload
        ]
      }
    default:
      return state
  }
}
