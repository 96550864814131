/*CORE*/
import React from 'react'
/*LIBS*/
import classnames from 'classnames'
/*STYLES*/
import styles from './Spinner.module.scss'

interface Props {
  scale?: number
  color?: string
  className?: string
}

const Spinner = ({ className, scale = 0.25, color = '#000' }: Props) =>
  <div className={classnames(styles['wrapper'], className)} style={{ transform: `scale(${scale})` }}>
    <div className={styles['spinner']}>
      <div><span style={{ background: color }} /></div>
      <div><span style={{ background: color }} /></div>
      <div><span style={{ background: color }} /></div>
      <div><span style={{ background: color }} /></div>
      <div><span style={{ background: color }} /></div>
      <div><span style={{ background: color }} /></div>
      <div><span style={{ background: color }} /></div>
      <div><span style={{ background: color }} /></div>
      <div><span style={{ background: color }} /></div>
    </div>
  </div>

export default Spinner
