/*CORE*/
import React, { useEffect, useState } from 'react'
/*LIBS*/
import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { FieldData } from 'rc-field-form/lib/interface'
import { Button, Form, Input, message, Typography } from 'antd'
import { useHistory } from 'react-router-dom'
/*ACTIONS*/
import { disableTwoFactorAuth as disableTwoFactorAuthAction, } from 'store/auth/actions'
import { switch2FA as switch2FAAction } from 'store/profile/actions'
/*SELECTORS*/
import { profileSelector } from 'store/profile/selectors'
/*COMPONENTS*/
import SinglePageLayout from '../../Dashboard/components/SinglePageLayout/SinglePageLayout'
/*UTILS*/
import { goBack, hasErrors } from 'utils/utils'
/*TYPES*/
import { IFormData, RootState } from 'types'
/*STYLES*/
import styles from './TurnOffTwoFactorAuth.module.scss'

const { Title } = Typography

interface Props extends ConnectedProps<typeof connector> {}

const TurnOffTwoFactorAuth = ({ profile, disableTwoFactorAuth, switch2FA }: Props) => {
  const [submitDisabled, setSubmitDisabled] = React.useState(true)
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const history = useHistory()

  useEffect(() => {
    form.setFieldsValue({
      email: profile.email
    })
  }, [form, profile])

  const handleGoBack = () => {
    goBack(history, '/account')
  }

  const handleFieldsChange = (changedFields: FieldData[], fields: FieldData[]) => {
    setSubmitDisabled(hasErrors(fields))
  }

  const handleSubmit = (formData: IFormData) => {
    setLoading(true)
    disableTwoFactorAuth(formData as { email: string, password: string }).then(() => {
      message.success('Google Authenticator was successfully disabled')
      switch2FA(false)
      history.push('/account')
    }).catch(() => {
      message.error('Something went wrong while trying to turn off two factor authorization')
    }).finally(() => {
      setLoading(false)
    })
  }

  return (
    <SinglePageLayout
      onGoBack={handleGoBack}
      className={styles['turn-off-2fa-wrapper']}
    >
      <Form
        form={form}
        className={styles['turn-off-2fa-form']}
        layout='vertical'
        onFinish={handleSubmit}
        onFieldsChange={handleFieldsChange}
        initialValues={{
          email: profile.email
        }}
      >
        <header>
          <Title>Disable two-step authentication</Title>
          <p>Additional authentication required. To continue, please enter your password.</p>
        </header>
        <div>
          <Form.Item
            name='email'
            label='Email'
          >
            <Input placeholder='Email' size='large' readOnly />
          </Form.Item>
          <Form.Item
            name='password'
            rules={[ { required: true, message: 'Please input your password' } ]}
          >
            <Input.Password
              type='password'
              placeholder='Password'
              size='large'
            />
          </Form.Item>
        </div>
        <div>
          <Button
            type='primary'
            htmlType='submit'
            size='large'
            block
            loading={loading}
            disabled={submitDisabled}
          >
            {loading ? 'Disabling' : 'Continue'}
          </Button>
        </div>
      </Form>
    </SinglePageLayout>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    profile: profileSelector(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators<any, any>({
      disableTwoFactorAuth: disableTwoFactorAuthAction,
      switch2FA: switch2FAAction
    }, dispatch)
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(TurnOffTwoFactorAuth)
