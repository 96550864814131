import {
  LOAD_SUPER_APPS,
  LOAD_SUPER_APPS_REQUEST,
  LOAD_SUPER_APPS_ERROR,
  UPDATE_SUPER_APP
} from './actions'


export function superApps(state={
  isInitialLoad: true,
  isLoading: false,
  superApps: []
}, action) {
  switch (action.type) {
    case LOAD_SUPER_APPS_REQUEST: {
      return {
        ...state,
        isLoading: true
      }
    }
    case LOAD_SUPER_APPS_ERROR: {
      return {
        ...state,
        isLoading: false
      }
    }
    case LOAD_SUPER_APPS:
      return {
        ...state,
        isInitialLoad: false,
        isLoading: false,
        superApps: action.superApps
      }
    case UPDATE_SUPER_APP: {
      const superApps = state.superApps.map(app => {
        if (app.id === action.superApp.id) {
          return {
            ...app,
            ...action.superApp
          }
        }
        return app
      })
      return {
        ...state,
        superApps
      }
    }
    default:
      return state
  }
}
