/*CORE*/
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
/*LIBS*/
import { RangeValue } from 'rc-picker/lib/interface'
import { Button, DatePicker, message, Typography } from 'antd'
import classnames from 'classnames'
import moment from 'moment'
/*ASSETS*/
import { ReactComponent as ArrowLeft } from 'assets/img/arrow-right.svg'
import logoPlaceholder from 'assets/img/no-data-placeholers/logo_placeholder.png'
/*COMPONENTS*/
import AppSkeleton from '../../components/Skeletons/AppSkeleton'
import MoneyWrapper from '../../../common/MoneyWrapper'
import CurrencyDataTooltip from '../../Payment/CurrencyDataTooltip/CurrencyDataTooltip'
/*UTILS*/
import { formatAmount } from 'utils/common'
/*TYPES*/
import { IMetrics, IMiniApp } from 'types'
import { ICurrency } from 'types/Payouts'
/*STYLES*/
import styles from './AppAnalytics.module.scss'
import { apiExportUserConsent } from 'queries/analytics'
import ExportCSVModal from '../components/ExportCSVModal/ExportCSVModal'

const { Title } = Typography
const { RangePicker } = DatePicker

interface TableRowProps {
  title: string
  launches: number | null
  conversion: number | null
  sales: number
  logo: string
  averageSession: number
  currency: ICurrency
  uniqueUsersCount: number
  hasMultiCurrency: boolean
  currencyExchangeDate: string
  integration_id?: number
  dateRange?: RangeValue<moment.Moment>
}

interface TotalMetrics {
  launches: number
  sales: number
  conversion: number
  averageSession: number
  currency: ICurrency
  uniqueUsersCount: number
  hasMultiCurrency: boolean
  currencyExchangeDate: string
}

export const formatAverageSession = (seconds: number) => {
  const hours = Math.floor(seconds / 3600)
  seconds %= 3600
  const minutes = Math.floor(seconds / 60)
  seconds = Math.floor(seconds % 60)

  let result = []
  if (hours > 0) {
    result.push(`${hours}h`)
  }
  if (minutes > 0) {
    result.push(`${minutes}m`)
  }
  if (seconds || result.length === 0) {
    result.push(`${seconds}s`)
  }

  return result.join(' ')
}

const TableRow = ({
  title,
  launches,
  conversion,
  sales,
  averageSession,
  uniqueUsersCount,
  currency,
  logo,
  hasMultiCurrency,
  currencyExchangeDate,
  integration_id,
  dateRange,
}: TableRowProps) => {
  const history = useHistory()

  const handleSelectPartnershipAnalytics = () => {
    if (integration_id) {
      let link = `/partnership-analytics/${integration_id}`
      if (dateRange?.[0] && dateRange?.[1]) {
        link += `?startDate=${dateRange[0].toISOString()}&endDate=${dateRange[1].toISOString()}`
      }
      history.push(link)
    }
  }

  return (
    <div
      className={classnames(
        styles['miniapp-metrics__row'],
        integration_id && styles['miniapp-metrics__integration']
      )}
      onClick={handleSelectPartnershipAnalytics}
    >
      {title && (
        <div
          className={classnames(styles['title'], logo && styles['has-logo'])}
        >
          {logo && <img src={logo} alt="logo" />}
          <Title level={4}>{title}</Title>
        </div>
      )}
      <table>
        <thead>
          <tr>
            <th>Unique users</th>
            <th>Launches</th>
            <th>Conversions</th>
            <th>Average session</th>
            <th>Sales / GMV</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{formatAmount(uniqueUsersCount)}</td>
            <td>{formatAmount(launches || 0)}</td>
            <td>{formatAmount(conversion || 0)}</td>
            <td>{formatAverageSession(averageSession)}</td>
            <td>
              <CurrencyDataTooltip
                currency={currency}
                exchangeRateDate={currencyExchangeDate}
                hidden={!hasMultiCurrency}
              >
                <MoneyWrapper amount={sales} currency={currency} />
              </CurrencyDataTooltip>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

const MetricsPerApp = ({
  data,
  dateRange,
}: {
  data: IMetrics
  dateRange: RangeValue<moment.Moment>
}) => {
  return (
    <TableRow
      title={data.app.name}
      launches={data.total_launches}
      sales={data.total_sales}
      conversion={data.total_conversion}
      averageSession={data.average_session}
      uniqueUsersCount={data.unique_user_count}
      currency={data.currency}
      logo={data.app.logo || logoPlaceholder}
      hasMultiCurrency={data.multi_currency_sales.length > 0}
      currencyExchangeDate={data.actual_currency_exchange_date}
      integration_id={data.integration_id}
      dateRange={dateRange}
    />
  )
}

const OverallMetrics = ({ data }: { data: IMetrics[] }) => {
  const totalData = data.reduce(
    (total: TotalMetrics, item: IMetrics) => {
      if (item.total_launches) {
        total.launches += item.total_launches
      }
      if (item.total_sales) {
        total.sales += item.total_sales
      }
      if (item.total_conversion) {
        total.conversion += item.total_conversion
      }
      if (item.unique_user_count) {
        total.uniqueUsersCount += item.unique_user_count
      }
      if (item.average_session) {
        total.averageSession += item.average_session
      }
      if (item.currency) {
        total.currency = item.currency
      }
      if (item.multi_currency_sales) {
        total.hasMultiCurrency =
          item.multi_currency_sales.length > 0 || total.hasMultiCurrency
      }
      if (item.actual_currency_exchange_date) {
        total.currencyExchangeDate = item.actual_currency_exchange_date
      }
      return total
    },
    {
      launches: 0,
      sales: 0,
      conversion: 0,
      uniqueUsersCount: 0,
      averageSession: 0,
      currency: {} as ICurrency,
      hasMultiCurrency: false,
      currencyExchangeDate: '',
    }
  )
  if (data.length > 0) {
    totalData.averageSession /= data.length
  }

  return (
    <TableRow
      logo={''}
      sales={totalData.sales}
      title="All partnerships"
      launches={totalData.launches}
      conversion={totalData.conversion}
      uniqueUsersCount={totalData.uniqueUsersCount}
      averageSession={totalData.averageSession}
      currency={totalData.currency}
      hasMultiCurrency={totalData.hasMultiCurrency}
      currencyExchangeDate={totalData.currencyExchangeDate}
    />
  )
}

interface Props {
  isLoading: boolean
  metrics: IMetrics[]
  onChangeDate: (values: RangeValue<moment.Moment>) => void
  miniApp?: IMiniApp | null
  ui?: {
    csvModal: boolean
    sentEmail: boolean
  }
  setUI?: React.Dispatch<
    React.SetStateAction<{
      sentEmail: boolean
      csvModal: boolean
    }>
  >
}

const dateFormat = 'YYYY-MM-DD'

const AppAnalytics = ({
  metrics,
  onChangeDate,
  isLoading,
  miniApp,
  ui,
  setUI,
}: Props) => {
  const [dateRange, setDateRange] = useState<RangeValue<moment.Moment>>([
    moment().startOf('month'),
    moment().endOf('month'),
  ])

  useEffect(() => {
    onChangeDate(dateRange)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange?.[0], dateRange?.[1]])

  const onChangeDatePicker = (values: RangeValue<moment.Moment>) => {
    setDateRange(values)
  }

  async function exportConsent({ email }: { email: string }) {
    try {
      if (miniApp?.id) {
        await apiExportUserConsent(
          miniApp.id,
          email,
          dateRange?.[0]?.format(dateFormat) ?? '',
          dateRange?.[1]?.format(dateFormat) ?? ''
        )
      }
      setUI?.({ csvModal: false, sentEmail: true })
    } catch (e) {
      message.error('Sorry. Something went wrong.')
    }
  }

  return (
    <>
      <div className={styles['date-picker-wrapper']}>
        <RangePicker
          size="large"
          bordered={false}
          suffixIcon={null}
          value={dateRange}
          allowClear={false}
          format={'DD/MM/YYYY'}
          onChange={onChangeDatePicker}
          className={styles['date-picker']}
          separator={<ArrowLeft className={styles['separator']} />}
        />
        {miniApp && (
          <Button
            size="large"
            type="link"
            onClick={() => setUI?.({ csvModal: true, sentEmail: false })}
          >
            Export User Consent
          </Button>
        )}
      </div>
      {isLoading || !metrics ? (
        <AppSkeleton />
      ) : (
        <div className={styles['miniapp-metrics']}>
          <OverallMetrics data={metrics} />
          <hr />
          {metrics.map((data: IMetrics) => (
            <MetricsPerApp
              key={data.app.name}
              dateRange={dateRange}
              data={data}
            />
          ))}
        </div>
      )}
      <ExportCSVModal
        visible={ui?.csvModal}
        width="750px"
        description="User consent statuses will be emailed to you in CSV format."
        handleClose={() => setUI?.({ csvModal: false, sentEmail: false })}
        handleSubmit={exportConsent}
      />
    </>
  )
}

export default AppAnalytics
