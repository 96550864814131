/*CORE*/
import React from 'react'
/*LIBS*/
import { connect, ConnectedProps } from 'react-redux'
/*COMPONENTS*/
import AppsPaymentTable from '../Payment/AppsPaymentTable/AppsPaymentTable'
import TotalPayouts from '../Payouts/TotalPayouts/TotalPayouts'
import PayoutHistory from '../Payouts/PayoutHistory/PayoutHistory'
/*SELECTORS*/
import { miniAppPayoutsSelector } from 'store/payouts/selectors'
import { miniAppPayoutsPlansSelector } from 'store/billing/selectors'
/*TYPES*/
import { RootState } from 'types'


interface Props extends ConnectedProps<typeof connector> {}

const MiniAppPayout = ({ miniAppPayouts, miniAppPlans }: Props) => {
  return (
    <>
      <TotalPayouts appPlans={miniAppPlans} />
      <AppsPaymentTable appPlans={miniAppPlans} title='Month-to-date' />
      <PayoutHistory payouts={miniAppPayouts} />
    </>
  )
}

const mapStateToProps = (state: RootState, { appId }: { appId: string }) => {
  return {
    miniAppPayouts: miniAppPayoutsSelector(+appId!, state),
    miniAppPlans: miniAppPayoutsPlansSelector(+appId!, state)
  }
}

const connector = connect(mapStateToProps)

export default connector(MiniAppPayout)
