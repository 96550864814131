/*CORE*/
import React from 'react'
/*LIBS*/
import moment from 'moment'
import { Typography } from 'antd'
import { useHistory } from 'react-router-dom'
/*ASSETS*/
import logoPlaceholder from 'assets/img/no-data-placeholers/logo_placeholder.png'
/*COMPONENTS*/
import CurrencyDataTooltip from '../CurrencyDataTooltip/CurrencyDataTooltip'
import { WithEmpty } from '../../components/WithEmpty/WithEmpty'
import MoneyWrapper from '../../../common/MoneyWrapper'
/*CONSTANTS*/
import { AppPlanType, FULL_DATE_FORMAT } from 'utils/constants'
/*UTILS*/
import { formatAmount } from 'utils/common'
/*TYPES*/
import { IAppPlan } from 'types/Billing'
/*STYLES*/
import styles from './AppsPaymentTable.module.scss'


const { Title } = Typography

interface Props {
  appPlans: IAppPlan[]
  title?: string
  isFailed: boolean
  unClickable?: boolean
}

const AppsPaymentTable = ({ appPlans, title, isFailed, unClickable }: Props) => {
  const history = useHistory()

  const handleGoToAppPlanDetails = ({ id, app_type }: IAppPlan) => {
    if (id) {
      history.push(`/app-plan-details?appPlanId=${id}&appType=${app_type}`)
    }
  }

  if (!appPlans.length) {
    return (
      <div>
        {title && <Title level={3}>{title}</Title>}
        <WithEmpty />
      </div>
    )
  }

  return (
    <div>
      {title && <Title level={3}>{title}</Title>}
      <table className={styles['payment-table']}>
        <thead>
        <tr>
          <th>Hostapp</th>
          <th>Miniapp</th>
          <th>Description</th>
          <th>Amount</th>
        </tr>
        </thead>
        <tbody>
        {appPlans.map((plan, i) => (
          <tr
            key={i}
            className={unClickable ? '' : styles['clickable']}
            onClick={unClickable ? undefined : () => handleGoToAppPlanDetails(plan)}
          >
            <td>
              <div className={styles['app-info']} title={plan.hostapp.name}>
                <img src={plan.hostapp.logo || logoPlaceholder} alt='hostapp logo' />
                <div>{plan.hostapp.name}</div>
              </div>
            </td>
            <td>
              <div className={styles['app-info']} title={plan.miniapp.name}>
                <img src={plan.miniapp.logo || logoPlaceholder} alt='miniapp logo' />
                <div>{plan.miniapp.name}</div>
              </div>
            </td>
            <td>
              <div className={styles['details']}>
                {plan.plan_type === AppPlanType.Launches ? (
                  <>
                    {formatAmount(plan.launches_count)} launches
                  </>
                ) : (
                  <>
                    <CurrencyDataTooltip
                      currency={plan.currency}
                      exchangeRateDate={plan.actual_currency_exchange_date}
                      hidden={!plan.actual_currency_exchange_date}
                    >
                      <MoneyWrapper
                        currency={plan.currency}
                        amount={plan.transactions_amount}
                      />
                    </CurrencyDataTooltip> in transactions
                  </>
                )}
              </div>
              <div>
                {moment(plan.date_billing_start).format(FULL_DATE_FORMAT)}
                {' - '}
                {moment(plan.date_billing_end).format(FULL_DATE_FORMAT)}
              </div>
            </td>
            <td>
              <CurrencyDataTooltip
                currency={plan.currency}
                exchangeRateDate={plan.actual_currency_exchange_date}
                hidden={!plan.actual_currency_exchange_date}
              >
                <MoneyWrapper
                  amount={plan.amount}
                  currency={plan.currency}
                  className={styles['amount']}
                />
              </CurrencyDataTooltip>
              <div>
                {
                  plan.paid_date
                    ? `${isFailed ? 'Attempted on' : 'Paid on'} ${moment(plan.paid_date).format(FULL_DATE_FORMAT)}`
                    : `Due on ${moment(plan.due_date).format(FULL_DATE_FORMAT)}`
                }
              </div>
            </td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  )
}

AppsPaymentTable.defaultProps = {
  isFailed: false
}

export default AppsPaymentTable
