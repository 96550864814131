import React  from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { useHistory } from 'react-router-dom'
import { Button, Dropdown, Menu, message, Modal } from 'antd'
import { EllipsisOutlined } from '@ant-design/icons/lib'

import {
  deleteBankAccount as deleteBankAccountAction,
  updateBankAccount as updateBankAccountAction
} from 'store/payouts/actions'
import { bankAccountsSelector } from 'store/payouts/selectors'
import AccessControl from '../../../common/AccessControl'
import { Permissions } from 'utils/constants'
import { IBankAccount } from 'types/Payouts'
import { RootState } from 'types'

import styles from './PayoutMethods.module.scss'


const { confirm } = Modal

interface Props extends ConnectedProps<typeof connector> {}

const PayoutMethods = ({ bankAccounts, updateBankAccount, deleteBankAccount }: Props) => {
  const history = useHistory()

  const handleAddNewBankAccount = () => {
    history.push('/bank-account/new')
  }

  const handleEdit = (bankAccountId: number) => {
    history.push(`/bank-account/${bankAccountId}`)
  }

  const handleMakePrimary = (bankAccount: IBankAccount) => {
    updateBankAccount(bankAccount.id, {
      is_primary: true
    })
  }

  const handleDelete = (bankAccount: IBankAccount) => {
    confirm({
      className: styles['confirmation-modal'],
      title: 'Delete the bank account?',
      content: `Are you sure you want to delete bank account ${bankAccount.account_number}?`,
      okText: 'Delete',
      okButtonProps: {
        type: 'primary',
      },
      cancelText: 'Cancel',
      cancelButtonProps: {
        type: 'default',
      },
      width: 520,
      centered: true,
      onOk: async () => {
        try {
          await deleteBankAccount(bankAccount.id)
          message.success('Successfully deleted')
        } catch (error) {
          console.log(error)
          message.error('Sorry. Something went wrong.')
        }
      }
    })
  }

  return (
    <div className={styles['payment-methods']}>
      <div className={styles['header']}>
        <div>Choose your default destination bank account from your current saved banks accounts</div>
        <AccessControl permission={Permissions.CAN_MANAGE}>
          <Button type='link' onClick={handleAddNewBankAccount}>+ Add new</Button>
        </AccessControl>
      </div>
      <table>
        <thead>
        <tr>
          <th>Account</th>
          <th>Bank info</th>
          <th />
        </tr>
        </thead>
        <tbody>
        {bankAccounts.map((bankAccount: IBankAccount) => (
          <tr key={bankAccount.id}>
            <td>
              <div>
                <div className={styles['highlight']}>
                  {bankAccount.bank_name}
                  {bankAccount.is_primary && <span className={styles['default']}>default</span>}
                </div>
                <div>Account No: {bankAccount.account_number}</div>
              </div>
            </td>
            <td>
              <div className={styles['highlight']}>{bankAccount.swift_bic}</div>
              <div>{bankAccount.bank_address}</div>
            </td>
            <td>
              <AccessControl permission={Permissions.CAN_MANAGE}>
                <Dropdown
                  placement='bottomRight'
                  overlay={(
                    <Menu>
                      {!bankAccount.is_primary && (
                        <Menu.Item key={1} onClick={() => handleMakePrimary(bankAccount)}>
                          Make default
                        </Menu.Item>
                      )}
                      <Menu.Item key={2} onClick={() => handleEdit(bankAccount.id)}>
                        Edit
                      </Menu.Item>
                      <Menu.Item key={3} onClick={() => handleDelete(bankAccount)}>
                        Delete
                      </Menu.Item>
                    </Menu>
                  )}
                  trigger={['click']}
                >
                  <EllipsisOutlined/>
                </Dropdown>
              </AccessControl>
            </td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    bankAccounts: bankAccountsSelector(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators({
      deleteBankAccount: deleteBankAccountAction,
      updateBankAccount: updateBankAccountAction
    }, dispatch)
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(PayoutMethods)

