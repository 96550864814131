/*CORE*/
import React, { useEffect, useState } from 'react'
/*LIBS*/
import { bindActionCreators, Dispatch } from 'redux'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { message } from 'antd'
/*ACTIONS*/
import {
  getSuperAppInvoice as getSuperAppInvoiceAction,
  downloadSuperappInvoice as downloadSuperappInvoiceAction,
  paySuperAppInvoice as paySuperAppInvoiceAction,
  paySuperAppInvoiceWithAnotherCard as paySuperAppInvoiceWithAnotherCardAction,
  loadSuperAppInvoices as loadSuperAppInvoicesAction
} from 'store/billing/actions'
/*COMPONENTS*/
import BillingInfo from '../Billing/BillingHistory/BillingInfo'
/*TYPES*/
import { convertToAppPlan, IAppInvoice, IAppPlan } from 'types/Billing'

interface Props {
  getSuperAppInvoice: (invoiceId: number) => Promise<IAppInvoice>
  downloadSuperappInvoice: (invoice: IAppInvoice, format: string) => void
  paySuperAppInvoice: (cardData: { card_id: number }, invoiceId: number) => void
  paySuperAppInvoiceWithAnotherCard: (cardData: { card_id: string }, invoiceId: number) => void
  updateSuperAppInvoices: () => void
}

const SuperAppBillingInfo = ({ getSuperAppInvoice, downloadSuperappInvoice, paySuperAppInvoice, paySuperAppInvoiceWithAnotherCard, updateSuperAppInvoices }: Props) => {
  const [invoice, setInvoice] = useState(undefined as IAppInvoice | undefined)
  const [superappPlan, setSuperappPlan] = useState(undefined as IAppPlan | undefined)
  const { invoiceId } = useParams()

  useEffect(() => {
    loadAppPlan()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceId])

  const loadAppPlan = async () => {
    try {
      const superappInvoice: IAppInvoice = await getSuperAppInvoice(+invoiceId!)
      setInvoice(superappInvoice)

      const appPlan: IAppPlan = convertToAppPlan(superappInvoice)
      setSuperappPlan(appPlan)
    } catch (error) {
      message.error('Something went wrong while loading host app plan')
    }
  }

  const updateInvoices = () => {
    loadAppPlan()
    updateSuperAppInvoices()
  }

  return <BillingInfo
    invoice={invoice}
    appPlan={superappPlan}
    downloadInvoice={downloadSuperappInvoice}
    payInvoice={paySuperAppInvoice}
    payForInvoiceWithAnotherCard={paySuperAppInvoiceWithAnotherCard}
    updateInvoices={updateInvoices}
  />
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators({
      getSuperAppInvoice: getSuperAppInvoiceAction,
      downloadSuperappInvoice: downloadSuperappInvoiceAction,
      paySuperAppInvoice: paySuperAppInvoiceAction,
      paySuperAppInvoiceWithAnotherCard: paySuperAppInvoiceWithAnotherCardAction,
      updateSuperAppInvoices: loadSuperAppInvoicesAction
    }, dispatch)
  }
}

// @ts-ignore
export default connect(null, mapDispatchToProps)(SuperAppBillingInfo)
