/*CORE*/
import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
/*LIBS*/
import { Button, message } from 'antd'
import { AxiosError, AxiosResponse } from 'axios'
import { bindActionCreators, Dispatch } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
/*COMPONENTS*/
import IntegrationForm from './IntegrationForm/IntegrationForm'
import ResultPane from '../../components/Result/Result'
import AppSkeleton from '../../components/Skeletons/AppSkeleton'
import SinglePageLayout from '../../components/SinglePageLayout/SinglePageLayout'
/*SELECTORS*/
import { integrationsSelector } from 'store/integrations/selectors'
import { superAppsSelector } from 'store/superApps/selectors'
/*ACTIONS*/
import { loadPartnerships as loadPartnershipsAction } from 'store/partnerships/actions'
import {
  createIntegration as createIntegrationAction,
  loadIntegrations as loadIntegrationsAction
} from 'store/integrations/actions'
/*TYPES*/
import { RootState, IFormData, Integration, ISuperApp } from 'types'
/*UTILS*/
import { goBack } from 'utils/utils'
/*STYLES*/
import styles from './AppIntegration.module.scss'

interface Props extends ConnectedProps<typeof connector> {}

const AppIntegration = ({ integrations, superapps, createIntegration, loadPartnerships, loadIntegrations }: Props) => {
  const [integrationId, setIntegrationId] = useState<null | number>(null)
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const routeState = useLocation().state as {app: any}
  const miniApp = routeState ? routeState.app : null

  if (!miniApp) {
    history.push('/showroom')
  }

  const handleGoBack = () => {
    goBack(history, '/showroom')
  }

  const goToIntegrationDetails = () => {
    history.replace(`/partnerships/details/${integrationId}/overview`)
  }

  const handleSubmit = async (data: IFormData) => {
    setIsLoading(true)
    createIntegration({
      hostapp: data.hostapp,
      note: data.note,
      miniapp: miniApp.id
    }).then((resp: AxiosResponse<Integration>) => {
      loadIntegrations()
      loadPartnerships()
      setIntegrationId(resp.data.id)
    }).catch((error: AxiosError) => {
      message.error(error.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const integrationRequestedHostappIds = integrations
    .filter((item: Integration) => item.miniapp.id === miniApp.id)
    .map((item: Integration) => item.hostapp.id)

  if (!miniApp) {
    return <AppSkeleton />
  }

  return (
    <SinglePageLayout
      isFullHeight
      onClose={handleGoBack}
      className={styles['app-integration']}
    >
      {!integrationId ? (
        <IntegrationForm
          miniAppName={miniApp.name}
          superapps={superapps.filter((superapp: ISuperApp) => !integrationRequestedHostappIds.includes(superapp.id))}
          onSubmit={handleSubmit}
          loading={isLoading}
        />
      ) : (
        <ResultPane
          title='Your request to integrate has been sent to the miniapp.'
          text='In the meantime set up your app for full integration.'
        >
          <Button
            block
            size='large'
            type='primary'
            onClick={goToIntegrationDetails}
            className={styles['app-integration__close-btn']}
          >
            Continue
          </Button>
        </ResultPane>
      )}
    </SinglePageLayout>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    integrations: integrationsSelector(state),
    superapps: superAppsSelector(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators<any, any>({
      loadPartnerships: loadPartnershipsAction,
      createIntegration: createIntegrationAction,
      loadIntegrations: loadIntegrationsAction
    }, dispatch)
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(AppIntegration)
