import React from 'react'
import { Modal, message } from 'antd'
import RoleForm from './RoleForm'
import { FormData } from './RoleForm'
import { ChangeRoleDTO } from 'store/team/actions'
import { IRole, ITeamMember } from 'types/Team'
import './InvitationModal.scss'

interface Props {
  closeModal: () => void
  roles: IRole[]
  member: ITeamMember
  handleSubmit: (values: ChangeRoleDTO) => Promise<void>
}

const ChangeRoleModal = ({ closeModal, roles, member, handleSubmit }: Props) => {
  const [formSubmitting, setFormSubmitting] = React.useState(false)
  const [errors, setErrors] = React.useState<string[]>([])

  const handleFormSubmit = (values: FormData) => {
    setFormSubmitting(true)
    const role = roles.find(role => role.id === values.role) as IRole
    handleSubmit({
      id: member.id,
      email: member.user.email,
      role

    }).then(() => {
      message.success(`${member.user.name}'s role has been changed to ${role.name}.`)
      setFormSubmitting(false)
      closeModal()

    }).catch(error => {
      if (error.response.data) {
        const errorLabels = Object.keys(error.response.data)
          .map(errorName => `${error.response.data[errorName]}`)
        setErrors(errorLabels)
      } else {
        setErrors(['Unexpected error happened. Try again later.'])
      }
      setFormSubmitting(false)
    })
  }

  return (
    <Modal
      className='invitation-modal'
      visible={true}
      title={`Change role for ${member.user.name}`}
      footer={false}
      destroyOnClose
      onCancel={closeModal}
    >
      <RoleForm
        roles={roles}
        onSubmit={handleFormSubmit}
        submitText={['Changing', 'Change role']}
        isLoading={formSubmitting}
        onCancel={closeModal}
        initialValues={{
          role: member.role
        }}
        errors={errors}
      />
    </Modal>
  )
}

export default ChangeRoleModal
