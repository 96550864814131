import Axios from "axios";
import { API_CONSENT_EXPORT } from "utils/constants";

export function apiExportUserConsent(
  miniapp: number,
  email: string,
  start_date: string,
  end_date: string
) {
  return Axios.post(API_CONSENT_EXPORT, null, {
    data: { miniapp, email, start_date, end_date },
  });
}
