import { IAppPayout, IBankAccount, ICurrency } from './Payouts'
import { AppPlanType, AppType, PaymentMethodType } from '../utils/constants'

export enum InvoiceStatus {
  DueToPay = 'due_to_pay',
  Failed = 'failed',
  Paid = 'paid',
}

export enum PaymentType {
  Billing = 'billing',
  Payout = 'payout',
}

export interface IRequestCommercialModel {
  email: string
  miniapp: number
}

export interface IPaymentCard {
  id: number
  last4: number
  brand: string
  card_id: string
  is_primary: boolean
  name: string
  exp_date: string
  created_date: string
}

export interface IMiniAppPlan {
  id: number
  hostapp: IHostApp
  miniapp: IMiniApp
  date_billing_end: Date
  date_billing_start: Date
  due_date: Date
  paid_date: Date | null
  launches_count: number
  amount: string
  currency: ICurrency
  type: PaymentType
  app_type: AppType
  multi_currency_sales: PlanSalesData[]
  plan_type: AppPlanType
  actual_currency_exchange_date: string
  transactions_amount: number
}

export interface IHostAppPlan {
  id: number
  hostapp: IHostApp
  miniapp: IMiniApp
  date_billing_end: Date
  date_billing_start: Date
  due_date: Date
  paid_date: Date | null
  launches_count: number
  amount: string
  currency: ICurrency
  billing_type: PaymentType
  app_type: AppType
  multi_currency_sales: PlanSalesData[]
  plan_type: AppPlanType
  actual_currency_exchange_date: string
  transactions_amount: number
}

export interface IHostAppInvoice {
  id: number
  amount: string
  invoice_number: string
  status: InvoiceStatus
  from_date: Date
  to_date: Date
  due_date: Date
  paid_date: Date | null
  created_date: Date
  hostapp_plan: IShortAppPlan
  launches_count: number
  payment_method: PaymentMethodType
  payment_method_details: IPaymentCard | IBankAccount
  currency: ICurrency
  multi_currency_sales: InvoiceSalesData[]
  actual_currency_exchange_date: string
  transactions_amount: number
}

export interface IAppOptions {
  id: number
  description: string
  name: AppPlanType
}

interface IMiniApp {
  id: number,
  name: string
  logo: string
}

export interface IHostApp {
  id: number
  name: string
  logo: string
}

export interface IAppPlan {
  id?: number
  rate: string
  hostapp: IHostApp
  miniapp: IMiniApp
  date_billing_end: Date
  date_billing_start: Date
  due_date: Date
  paid_date: Date | null
  launches_count: number
  amount: string
  team_currency_amount: string
  currency: ICurrency
  billing_type: PaymentType
  app_type: AppType
  multi_currency_sales: PlanSalesData[]
  plan_type: AppPlanType
  actual_currency_exchange_date: string
  transactions_amount: number
}

export interface IMiniAppInvoice {
  id: number
  amount: string
  invoice_number: string
  status: InvoiceStatus
  from_date: Date
  to_date: Date
  due_date: Date
  paid_date: Date | null
  created_date: Date
  miniapp_plan: IShortAppPlan
  launches_count: number
  payment_method: PaymentMethodType
  payment_method_details: IPaymentCard | IBankAccount
  currency: ICurrency
  multi_currency_sales: InvoiceSalesData[]
  actual_currency_exchange_date: string
  transactions_amount: number
}

export interface IShortAppPlan {
  plan: IAppOptions
  miniapp: IMiniApp
  hostapp: IHostApp
  rate: string
}

export interface InvoiceSalesData {
  amount: string
  commission: string
  commission_rate: string
  currency: ICurrency
  exchange_rate: string
  sales: string
}

export interface PlanSalesData {
  sales: string
  amount: number | string
  commission: number | string
  commission_rate: number | string
  currency_code: string
  currency_name: string
  exchanged_amount: string
  exchange_rate: string
}

export interface IAppInvoice {
  id: number
  amount: string
  invoice_number: string
  status: InvoiceStatus
  from_date: Date
  to_date: Date
  due_date: Date
  paid_date: Date | null
  created_date: Date
  app_plan: IShortAppPlan
  launches_count: number
  payment_method: PaymentMethodType
  payment_method_details: IPaymentCard | IBankAccount
  invoiced: AppType
  currency: ICurrency
  multi_currency_sales: InvoiceSalesData[]
  actual_currency_exchange_date: string
  transactions_amount: number
}

export const convertToAppPlan = (item: IAppInvoice | IAppPayout): IAppPlan => {
  return {
    ...item.app_plan,
    launches_count: item.launches_count,
    date_billing_start: item.from_date,
    date_billing_end: item.to_date,
    amount: item.amount,
    team_currency_amount: item.amount,
    due_date: item.due_date,
    paid_date: item.paid_date,
    currency: item.currency,
    billing_type: (item as IAppInvoice).invoice_number !== undefined ? PaymentType.Billing : PaymentType.Payout,
    multi_currency_sales: item.multi_currency_sales.map((invoiceSalesData) => ({
      sales: invoiceSalesData.sales,
      amount: invoiceSalesData.amount,
      commission: invoiceSalesData.commission,
      commission_rate: invoiceSalesData.commission_rate,
      currency_code: invoiceSalesData.currency.code,
      currency_name: invoiceSalesData.currency.name,
      exchange_rate: invoiceSalesData.exchange_rate,
      exchanged_amount: ''
    })),
    app_type: (item as IAppInvoice).invoiced !== undefined ? (item as IAppInvoice).invoiced : (item as IAppPayout).paidTo,
    plan_type: item.app_plan.plan.name,
    actual_currency_exchange_date: item.actual_currency_exchange_date,
    rate: item.app_plan.rate,
    transactions_amount: item.transactions_amount
  }
}
