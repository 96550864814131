/*CORE*/
import React from 'react'
/*LIBS*/
import { connect, ConnectedProps } from 'react-redux'
/*COMPONENTS*/
import AppsPaymentTable from '../Payment/AppsPaymentTable/AppsPaymentTable'
import TotalPayouts from '../Payouts/TotalPayouts/TotalPayouts'
import PayoutHistory from '../Payouts/PayoutHistory/PayoutHistory'
/*SELECTORS*/
import { superAppPayoutsSelector } from 'store/payouts/selectors'
import { superAppPayoutsPlansSelector } from 'store/billing/selectors'
/*TYPES*/
import { RootState } from 'types'


interface Props extends ConnectedProps<typeof connector> {
  appId?: string
}

const SuperAppPayouts = ({ superAppPayouts, superAppPayoutPlans }: Props) => {
  return (
    <>
      <TotalPayouts appPlans={superAppPayoutPlans} />
      <AppsPaymentTable appPlans={superAppPayoutPlans} title='Month-to-date' />
      <PayoutHistory payouts={superAppPayouts} />
    </>
  )
}


const mapStateToProps = (state: RootState, ownProps: { appId?: string }) => {
  return {
    superAppPayouts: superAppPayoutsSelector(+ownProps.appId!, state),
    superAppPayoutPlans: superAppPayoutsPlansSelector(+ownProps.appId!, state)
  }
}

const connector = connect(mapStateToProps)

export default connector(SuperAppPayouts)
