/*CORE*/
import React from 'react'
/*LIBS*/
import { Form, Input, Button } from 'antd'
/*UTILS*/
import { hasErrors } from 'utils/utils'
/*TYPES*/
import { FieldData } from 'rc-field-form/lib/interface'
/*STYLES*/
import styles from  './RecoverForm.module.scss'

interface Props {
  onSubmit: (values: { [key: string]: any }) => void
  isLoading: boolean
  errors: string[]
}

const RecoverForm = ({ onSubmit, errors, isLoading }: Props) => {
  const [ form ] = Form.useForm()
  const [ submitDisabled, setSubmitDisabled ] = React.useState(true)

  const handleSubmit = (values: { [key: string]: any }) => {
    onSubmit(values)
  }

  const detectErrors = (changedFields: FieldData[], allFields: FieldData[]) => {
    setSubmitDisabled(hasErrors(allFields))
  }

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      onFieldsChange={detectErrors}
      className={styles['recover-form']}
    >
      <div className={styles['recover-form__content']}>
        <Form.Item
          name='email'
          rules={[ {
            type: 'email',
            message: 'The input is not valid email',
          }, {
            required: true,
            message: 'Please input your email'
          } ]}
        >
          <Input
            placeholder="Email"
            size="large"
            type="text"
          />
        </Form.Item>
        {Boolean(errors.length) && <div className={styles['recover-form__errors']}>
          {errors.map(error => (
            <span key={error}>{error}</span>
          ))}
        </div>}
      </div>
      <div className={styles['recover-form__footer']}>
        <Button
          loading={isLoading}
          type="primary"
          htmlType="submit"
          size="large"
          block
          disabled={submitDisabled}
        >
          {isLoading ? 'Sending' : 'Continue'}
        </Button>
      </div>
    </Form>
  )
}

export default RecoverForm
