/*CORE*/
import React, { useState } from 'react'
/*LIBS*/
import { Button, message, Modal } from 'antd'
import { connect, ConnectedProps } from 'react-redux'
/*ACTIONS*/
import { resendConfirmation as resendConfirmationAction } from 'store/auth/actions'
/*STORE*/
import styles from './ActivateAccountModal.module.scss'

interface Props extends ConnectedProps<typeof connector> {
  isOpen: boolean
  email: string
}

const ActivateAccountModal = ({ isOpen, email, resendConfirmation }: Props) => {
  const [loading, setLoading] = useState(false)
  const [resent, setResent] = useState(false)
  const handleResetConfirmation = () => {
    setLoading(true)
    resendConfirmation(email.toLowerCase()).then(() => {
      message.success('Activation link resent')
      setResent(true)
    }).catch(() => {
      message.error('Something went wrong while resending activation link')
    }).finally(() => {
      setLoading(false)
    })
  }
  return (
    <Modal
      className={styles['activate-account-modal']}
      visible={isOpen}
      title='Activate your account'
      closable={false}
      centered={true}
      width={480}
      footer={
        <div>
          <span className={styles['help-text']}>Haven’t received?</span>
          <Button
            onClick={handleResetConfirmation}
            loading={loading}
            type='link'
            className='btn-link'
            disabled={resent}
          >
            {resent ? 'Activation link resent' : 'Resend activation link'}
          </Button>
        </div>
      }
    >
      <div>
        Please verify your email with the link we sent to {email}.
      </div>
    </Modal>
  )
}

const connector = connect(null, {
  resendConfirmation: resendConfirmationAction
})

export default connector(ActivateAccountModal)
