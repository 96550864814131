/*CORE*/
import React from 'react'
/*LIBS*/
import { List, Typography } from 'antd'
import { FileTextOutlined } from '@ant-design/icons'
/*TYPES*/
import { IShowroomMiniApp } from 'types'
/*STYLES*/
import styles from './Documents.module.scss'

const { Title } = Typography

interface Props {
  app: IShowroomMiniApp
}

const Documents = ({ app }: Props) => (
  <>
    <Title level={3}>Documents</Title>
    <List
      size='large'
      dataSource={app.documents}
      className={styles['documents-list']}
      renderItem={item => (
        <List.Item>
          <div className={styles['document']}>
            <FileTextOutlined />
            <a href={item.document} target='_blank' rel='noopener noreferrer'>{item.name}</a>
          </div>
        </List.Item>
      )}
    />
  </>
)

export default Documents
