/*CORE*/
import React, { useEffect, useState } from 'react'
/*LIBS*/
import { bindActionCreators, Dispatch } from 'redux'
import { useHistory } from 'react-router-dom'
import { Button, Form, Input, message, Typography } from 'antd'
import { FieldData } from 'rc-field-form/lib/interface'
import { connect, ConnectedProps } from 'react-redux'
import { AxiosError, AxiosResponse } from 'axios'
/*ACTIONS*/
import {
  regenerateTwoFactorBackupCode as regenerateTwoFactorBackupCodeAction
} from 'store/auth/actions'
/*SELECTORS*/
import { profileSelector } from 'store/profile/selectors'
/*COMPONENTS*/
import SinglePageLayout from '../../Dashboard/components/SinglePageLayout/SinglePageLayout'
import RegenerateSuccess from './RegenerateSuccess/RegenerateSuccess'
/*UTILS*/
import { goBack, hasErrors } from 'utils/utils'
/*TYPES*/
import { IFormData, RootState } from 'types'
/*STYLES*/
import styles from '../RegenerateBackupCode/RegenerateBackupCode.module.scss'

const { Title } = Typography

interface Props extends ConnectedProps<typeof connector> {}

const RegenerateBackupCode = ({ profile, regenerateTwoFactorBackupCode }: Props) => {
  const [submitDisabled, setSubmitDisabled] = React.useState(true)
  const [loading, setLoading] = useState(false)
  const [emergencyCode, setEmergencyCode] = useState('')
  const [succeeded, setSucceeded] = useState(false)
  const [form] = Form.useForm()
  const history = useHistory()

  useEffect(() => {
    form.setFieldsValue({
      email: profile.email
    })
  }, [form, profile])

  const handleGoBack = () => {
    goBack(history, '/account')
  }

  const handleFieldsChange = (changedFields: FieldData[], fields: FieldData[]) => {
    setSubmitDisabled(hasErrors(fields))
  }

  const handleSubmit = (formData: IFormData) => {
    setLoading(true)
    regenerateTwoFactorBackupCode(formData as { email: string, password: string })
    .then((resp: AxiosResponse<{ emergency_code: string }>) => {
      setEmergencyCode(resp.data.emergency_code)
      setSucceeded(true)
    }).catch((error: AxiosError) => {
      message.error(error.response?.data || 'Could not regenerate two factor authorization emergency code')
    }).finally(() => {
      setLoading(false)
    })
  }

  return (
    <SinglePageLayout
      onGoBack={!succeeded ? handleGoBack : null}
      onClose={succeeded ? handleGoBack : undefined}
      className={styles['regenerate-backup-wrapper']}
    >
      {succeeded ? (
        <RegenerateSuccess
          emergencyCode={emergencyCode}
          className={styles['regenerate-backup-form']}
        />
      ) : (
        <Form
          form={form}
          className={styles['regenerate-backup-form']}
          layout='vertical'
          onFinish={handleSubmit}
          onFieldsChange={handleFieldsChange}
          initialValues={{
            email: profile.email
          }}
        >
          <header>
            <Title>Generate new backup code</Title>
            <p>
              A backup code allows you to bypass two step authentication if you lose your device.
              If you've lost your backup code, you can generate a new one.
            </p>
          </header>
          <div>
            <Form.Item
              name='email'
              label='Email'
            >
              <Input placeholder='Email' size='large' readOnly />
            </Form.Item>
            <Form.Item
              name='password'
              rules={[ { required: true, message: 'Please input your password' } ]}
            >
              <Input.Password
                type='password'
                placeholder='Password'
                size='large'
              />
            </Form.Item>
          </div>
          <div>
            <Button
              type='primary'
              htmlType='submit'
              size='large'
              block
              loading={loading}
              disabled={submitDisabled}
            >
              {loading ? 'Generating' : 'Continue'}
            </Button>
          </div>
        </Form>
      )}
    </SinglePageLayout>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    profile: profileSelector(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators<any, any>({
      regenerateTwoFactorBackupCode: regenerateTwoFactorBackupCodeAction,
    }, dispatch)
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(RegenerateBackupCode)
