/*CORE*/
import React, { useEffect, useState } from 'react'
/*LIBS*/
import { cloneDeep } from 'lodash'
import classnames from 'classnames'
import { connect, ConnectedProps } from 'react-redux'
import { Button, Checkbox, Collapse, Modal } from 'antd'
/*SELECTORS*/
import {
  miniAppCountriesNamesByCodes as miniAppCountriesNamesByCodesSelector,
  miniAppCountriesNamesByContinent as miniAppCountriesByContinentSelector
} from 'store/miniApps/selectors'
/*ASSETS*/
import { ReactComponent as Arrow } from 'assets/img/arrow-right.svg'
/*TYPES*/
import { ICountriesByContinent, RootState } from 'types'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
/*STYLES*/
import styles from './CountriesModal.module.scss'

const countries = require('@saadixl/countries')

const { Panel } = Collapse

const CheckboxGroup = Checkbox.Group

interface Props extends ConnectedProps<typeof connector> {
  onChange: (checkedCountries: string[]) => void
  checkedCountries?: string[]
  closeModal: () => void
  description: string
  visible: boolean
  counter?: boolean
  title: string
}

const CountriesModal = (
  {
    title,
    visible,
    onChange,
    closeModal,
    description,
    counter = false,
    checkedCountries,
    miniAppCountriesByContinent,
    miniAppCountriesNamesByCodes,
  }: Props) => {

  const [ countriesByContinentCheckedList, setCountriesByContinentCheckedList ] = useState<ICountriesByContinent[]>([])
  const [ indeterminateContinentsList, setIndeterminateContinentsList ] = useState<string[]>([])
  const [ checkedContinentsList, setCheckedContinentsList ] = useState<string[]>([])

  useEffect(() => {
    !!checkedCountries?.length && setInitialData(checkedCountries)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ miniAppCountriesByContinent, visible ])

  function setInitialData(checkedCountries: string[]) {
    const checkedCountriesListByContinent = checkedCountries.reduce((acc: ICountriesByContinent[], curr: string) => {
      const getNormalizedContinentName = (continentName: string) => continentName === 'Ocenia' ? 'Oceania' : continentName
      const continent = acc.find(item => item.name === getNormalizedContinentName(countries(curr).continentName))
      if (continent) {
        continent.countries = [ ...continent.countries, curr ]
        return acc
      } else {
        return [
          ...acc,
          {
            name: getNormalizedContinentName(countries(curr)?.continentName),
            countries: [ curr ],
          }
        ]
      }
    }, [])

    let indeterminateContinentsList: string[] = []

    const checkedContinentsList =
      checkedCountriesListByContinent
        .filter(continent => {
            const continentInList: ICountriesByContinent =
              miniAppCountriesByContinent.find((countriesByContinent: ICountriesByContinent) => countriesByContinent.name === continent.name)

            if (continent?.countries?.length !== continentInList?.countries?.length) {
              indeterminateContinentsList.push(continent.name)
            }

            return continent?.countries?.length === continentInList?.countries?.length
          }
        ).map(continent => continent.name)

    setIndeterminateContinentsList(indeterminateContinentsList)
    setCheckedContinentsList(checkedContinentsList || [])
    setCountriesByContinentCheckedList(checkedCountriesListByContinent)
  }

  const checkedCountriesNames = getCheckedNames()

  const onDone = () => {
    onChange(checkedCountriesNames)
  }

  function getCheckedNames() {
    return countriesByContinentCheckedList.reduce((acc: string[], curr) => [ ...acc, ...curr.countries ], [])
  }

  const onChangeChecked = (continent: ICountriesByContinent) => (checkedList: CheckboxValueType[]) => {

    let newCountriesCheckedList: ICountriesByContinent[]

    if (!!checkedList?.length) {
      const candidate = countriesByContinentCheckedList.find(item => item.name === continent.name)
      newCountriesCheckedList = candidate ?
        cloneDeep(countriesByContinentCheckedList).map(checkedCountry => {
          if (checkedCountry.name === continent.name) {
            checkedCountry.countries = checkedList as string[]
          }
          return checkedCountry
        })
        :
        [ ...countriesByContinentCheckedList, { name: continent.name, countries: checkedList as string[] } ]
    } else {
      newCountriesCheckedList = countriesByContinentCheckedList.filter(item => item.name !== continent.name)
    }

    const modifiedIndeterminateList =
      !!checkedList?.length && checkedList?.length < continent.countries?.length ?
        [ ...indeterminateContinentsList, continent.name ] :
        indeterminateContinentsList.filter(indeterminateItem => indeterminateItem !== continent.name)

    setCheckedContinentsList(
      continent.countries.length === checkedList.length ?
        [ ...checkedContinentsList, continent.name ] :
        checkedContinentsList.filter(item => item !== continent.name)
    )

    setCountriesByContinentCheckedList(newCountriesCheckedList)

    setIndeterminateContinentsList(modifiedIndeterminateList)
  }

  const onCheckAllCountriesInContinent = (continentName: string) => (e: any) => {
    const checkedContinent = countriesByContinentCheckedList.find(item => item.name === continentName)
    const continent =
      miniAppCountriesByContinent.find((continent: ICountriesByContinent) => continent.name === continentName).countries

    const newCountriesCheckedList =
      checkedContinent ?
        countriesByContinentCheckedList.map(checkedCountry => {
          if (checkedCountry.name === continentName) {
            checkedCountry.countries = continent
          }
          return checkedCountry
        })
        :
        [ ...countriesByContinentCheckedList, { name: continentName, countries: continent } ]

    const newIndeterminateContinentsList =
      indeterminateContinentsList.filter(indeterminateContinent => indeterminateContinent !== continentName)

    const checkedList = e.target.checked ?
      newCountriesCheckedList :
      newCountriesCheckedList.filter(continent => continent.name !== continentName)

    setIndeterminateContinentsList(newIndeterminateContinentsList)

    setCountriesByContinentCheckedList(checkedList)

    setCheckedContinentsList(e.target.checked ?
      [ ...checkedContinentsList, continentName ] :
      checkedContinentsList.filter(item => item !== continentName))
  }

  const onChangeWorldwide = ({ target: { checked } }: { target: { checked: boolean } }) => {
    setIndeterminateContinentsList([])
    if (checked) {
      setCheckedContinentsList(miniAppCountriesByContinent.map((item: ICountriesByContinent) => item.name))
      setCountriesByContinentCheckedList(miniAppCountriesByContinent)
    } else {
      setCheckedContinentsList([])
      setCountriesByContinentCheckedList([])
    }
  }

  const getContinentCountryCheckedList = (continentName: string) => {
    const candidate = countriesByContinentCheckedList.find(continent => continent.name === continentName)
    return candidate?.countries || []
  }

  const getCheckedAllCountriesInContinent = (continentName: string) => {
    const checkedContinent = countriesByContinentCheckedList.find(continent => continent.name === continentName)
    const continent = miniAppCountriesByContinent.find((continent: ICountriesByContinent) => continent.name === continentName)
    return checkedContinent?.countries.length === continent.countries.length
  }

  const getCountriesOptions = (countriesCodes: string[]) =>
    miniAppCountriesNamesByCodes(countriesCodes).map((countryName: string, i) => (
      {
        label: countryName,
        value: countriesCodes[i],
      }
    ))

  const ExpandArrow = ({ isActive }: { isActive?: boolean }) =>
    <div className={classnames(styles['expand-arrow'], isActive && styles['active'])}>
      <Arrow />
    </div>

  const ModalHeader = ({ continentName }: { continentName: string }) =>
    <div className={styles['modal-header']} onClick={e => e.stopPropagation()}>
      <Checkbox
        className={styles['checkbox']}
        onClick={e => e.stopPropagation()}
        onChange={onCheckAllCountriesInContinent(continentName)}
        checked={getCheckedAllCountriesInContinent(continentName)}
        indeterminate={indeterminateContinentsList.includes(continentName)}
      >
        <h3>{continentName}</h3>
      </Checkbox>
    </div>

  const getBtnText = () => {
    const count = checkedCountriesNames?.length
    return counter ? 'Apply ' + (!!count ? count : '') : 'Done'
  }


  return (
    <Modal
      className={styles['country-modal']}
      onCancel={closeModal}
      closeIcon={<div />}
      visible={visible}
      width={'840px'}
      destroyOnClose
      footer={false}
    >
      <div className={styles['country-modal__content-wrapper']}>
        <header className={styles['header']}>
          <h1>{title || ''}</h1>
          <p>
            {description || ''}
          </p>
        </header>

        <main>
          <div className={styles['scroll-wrapper']}>
            <div className={styles['worldwide-collapse-item']}>
              <Checkbox
                onChange={onChangeWorldwide}
                className={styles['checkbox']}
                indeterminate={!!checkedContinentsList.length && checkedContinentsList.length !== miniAppCountriesByContinent.length}
                checked={!!miniAppCountriesByContinent?.length && miniAppCountriesByContinent.length === checkedContinentsList.length}
              >
                <h3>Worldwide</h3>
              </Checkbox>
            </div>
            <Collapse
              className={styles['collapse']}
              expandIconPosition={'right'}
              expandIcon={ExpandArrow}
            >
              {
                !!miniAppCountriesByContinent?.length &&
                miniAppCountriesByContinent.map((continent: ICountriesByContinent) =>
                  <Panel key={continent.name} header={<ModalHeader continentName={continent.name} />} className={styles['panel']}>
                    <CheckboxGroup
                      onChange={onChangeChecked(continent)}
                      className={styles['panel__checkbox-group']}
                      options={getCountriesOptions(continent.countries)}
                      value={getContinentCountryCheckedList(continent.name)}
                    />
                    <div className={styles['divider']} />
                  </Panel>
                )
              }
            </Collapse>
          </div>
        </main>

        <footer>
          <Button
            size="middle"
            type="primary"
            onClick={onDone}
            disabled={!checkedCountriesNames?.length}
          >
            {
              getBtnText()
            }
          </Button>
          <Button
            size="middle"
            type="default"
            htmlType="button"
            onClick={closeModal}
            className={'btn-outline'}
          >
            Cancel
          </Button>
        </footer>
      </div>

    </Modal>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    miniAppCountriesByContinent: miniAppCountriesByContinentSelector(state),
    miniAppCountriesNamesByCodes: (countryCodes: string[]) => miniAppCountriesNamesByCodesSelector(countryCodes, state)
  }
}

const connector = connect(mapStateToProps)

export default connector(CountriesModal)
