/*CORE*/
import React from 'react'
/*LIBS*/
import { Typography } from 'antd'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
/*SELECTORS*/
import { isMiniAppsLoading, miniAppsSelector } from 'store/miniApps/selectors'
import { isSuperAppsLoading, superAppsSelector } from 'store/superApps/selectors'
/*COMPONENTS*/
import AppCard from '../../components/AppCard/AppCard'
import AccessControl from '../../../common/AccessControl'
import InfoCard from '../../components/InfoCard/InfoCard'
import AppSkeleton from '../../components/Skeletons/AppSkeleton'
/*CONSTANTS*/
import { DOCS_URL, Permissions } from 'utils/constants'
/*TYPES*/
import { IMiniApp, ISuperApp, RootState } from 'types'


const { Title } = Typography

interface IStateToProps {
  miniApps: IMiniApp[]
  isMiniAppsLoading: boolean
  superApps: ISuperApp[]
  isSuperAppsLoading: boolean
}

interface Props extends IStateToProps {
}

// TODO: remove component after summary of last block is implemented
const Home = ({ miniApps, superApps, isMiniAppsLoading, isSuperAppsLoading }: Props) => {
  const history = useHistory()

  const goToShowroom = () => {
    history.push('/showroom')
  }

  const addNewMiniApp = () => {
    history.push('/miniapps/new')
  }

  const goToDocs = () => {
    window.location.assign(DOCS_URL)
  }

  const openApp = (app: IMiniApp | ISuperApp, isSuperApp = false) => {
    if (isSuperApp) {
      history.push(`/host-apps/${app.id}/overview`)
    } else {
      history.push(`/miniapps/${app.id}/overview`)
    }
  }

  return (
    <div style={{ marginTop: 40 }}>
      {
        isMiniAppsLoading ? <AppSkeleton /> :
          Boolean(miniApps.length) && (<div style={{ marginBottom: 40 }}>
            <Title>My miniapps</Title>
            <div className="cards-row">
              {miniApps.map(app => (
                <AppCard
                  key={app.id}
                  imgUrl={app.logo}
                  name={app.name}
                  category={app.category?.name}
                  onClick={() => openApp(app)}
                />
              ))}
            </div>
          </div>)
      }
      {
        isSuperAppsLoading ? <AppSkeleton /> :
          Boolean(superApps.length) && (<div style={{ marginBottom: 40 }}>
            <Title>My host apps</Title>
            <div className="cards-row">
              {superApps.map(app => (
                <AppCard
                  key={app.id}
                  imgUrl={app.logo}
                  name={app.name}
                  onClick={() => openApp(app, true)}
                />
              ))}
            </div>
          </div>)
      }
      <div className='cards-row'>
        <InfoCard
          title='Boxo Showroom'
          content={<p>Explore apps that can be <br /> embedded in your app.</p>}
          onClick={goToShowroom}
        />
        <InfoCard
          title='Integrate Boxo'
          content={<p>Install miniapp into your app using our SDK for iOS and Android.</p>}
          onClick={goToDocs}
        />
        <AccessControl permission={Permissions.CAN_EDIT}>
          <InfoCard
            title='Add Miniapp'
            content={<p>Explore Boxo Documentation to create Boxo Miniapp</p>}
            onClick={addNewMiniApp}
          />
        </AccessControl>
      </div>
    </div>
  )
}


const mapStateToProps = (state: RootState) => {
  return {
    miniApps: miniAppsSelector(state),
    isMiniAppsLoading: isMiniAppsLoading(state),
    superApps: superAppsSelector(state),
    isSuperAppsLoading: isSuperAppsLoading(state)
  }
}

export default connect(mapStateToProps)(Home)
