/*CORE*/
import React, { useEffect, useState } from 'react'
/*LIBS*/
import { bindActionCreators, Dispatch } from 'redux'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { message } from 'antd'
/*COMPONENTS*/
import PayoutInfo from '../Payouts/PayoutHistory/PayoutInfo'
/*ACTIONS*/
import {
  getSuperAppPayout as getSuperAppPayoutAction,
  downloadSuperappPayout as downloadSuperappPayoutAction
} from 'store/payouts/actions'
/*TYPES*/
import { convertToAppPlan, IAppPlan } from 'types/Billing'
import { IAppPayout } from 'types/Payouts'


interface Props {
  getSuperAppPayout: (payoutId: number) => Promise<IAppPayout>
  downloadSuperappPayout: (payout: IAppPayout, format: string) => void
}

const SuperappPayoutInfo = ({ getSuperAppPayout, downloadSuperappPayout }: Props) => {
  const [payout, setPayout] = useState(undefined as IAppPayout | undefined)
  const [superappPlans, setSuperappPlans] = useState(undefined as IAppPlan | undefined)
  const { payoutId } = useParams()

  useEffect(() => {
    loadAppPlan()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payoutId])

  const loadAppPlan = async () => {
    try {
      const superappPayout: IAppPayout = await getSuperAppPayout(+payoutId!)
      setPayout(superappPayout)

      const appPlan: IAppPlan = convertToAppPlan(superappPayout)
      setSuperappPlans(appPlan)
    } catch (error) {
      message.error('Something went wrong while loading host app plan')
    }
  }

  return <PayoutInfo payout={payout} downloadPayout={downloadSuperappPayout} appPlan={superappPlans} />
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators({
      getSuperAppPayout: getSuperAppPayoutAction,
      downloadSuperappPayout: downloadSuperappPayoutAction
    }, dispatch)
  }
}

// @ts-ignore
export default connect(null, mapDispatchToProps)(SuperappPayoutInfo)
