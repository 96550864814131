/*CORE*/
import React, { ReactNode } from 'react'
/*LIBS*/
import { Card } from 'antd'
/*STYLES*/
import styles from './InfoCard.module.scss'

interface Props {
  title: string
  content: ReactNode
  onClick: () => void
}

const InfoCard = ({ title, content, onClick }: Props) => {
  return (
    <Card
      title={title}
      className={styles['info-card']}
      onClick={onClick}
    >
      {content}
    </Card>
  )
}

export default InfoCard
