import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { IPartnershipDetails } from "types/Partnerships";
import { API_INTEGRATIONS } from "utils/constants";

type PartialPartnershipDetails = Partial<IPartnershipDetails>

export function updatePartnership(partnershipId: number, data: PartialPartnershipDetails) {
  return axios.patch(API_INTEGRATIONS + partnershipId + '/', data)
}

export function useMutatePartnership() {
  return useMutation({
    mutationFn: (data: PartialPartnershipDetails & { id: number }) => updatePartnership(data.id, data),
  })
}
