/*CORE*/
import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
/*SELECTORS*/
import { miniAppInvoiceSelector, miniAppPlanSelector, superAppInvoiceSelector, superAppPlanSelector } from 'store/billing/selectors'
/*ACTIONS*/
import { loadMiniAppInvoices as loadMiniAppInvoicesAction, loadSuperAppInvoices as loadSuperAppInvoicesAction } from 'store/billing/actions'
/*PAGES*/
import TotalBilling from 'pages/Dashboard/Billing/TotalBilling/TotalBilling'
import BillingHistory from 'pages/Dashboard/Billing/BillingHistory/BillingHistory'
import AppsPaymentTable from 'pages/Dashboard/Payment/AppsPaymentTable/AppsPaymentTable'
/*TYPES*/
import { RootState } from 'types'
import { IAppInvoice, IAppPlan } from 'types/Billing'
import { IPartnershipDetails } from 'types/Partnerships'

interface Props extends ConnectedProps<typeof connector> {
  partnership: IPartnershipDetails
}

const Billing = ({ invoices, plans, updateInvoices, partnership }: Props) => {
  return (
    <>
      <TotalBilling appPlans={plans} />
      <AppsPaymentTable appPlans={plans} title='Month-to-date' />
      <BillingHistory invoices={invoices} updateInvoices={updateInvoices(partnership.is_my_integration)} />
    </>
  )
}

const mapStateToProps = (state: RootState, { partnership }: { partnership: IPartnershipDetails }) => {

  const invoices =
    partnership.is_my_integration ?
      miniAppInvoiceSelector(partnership.miniapp.id, state)
        .filter((invoice: IAppInvoice) => invoice.app_plan.hostapp.id === partnership.hostapp.id)
      :
      superAppInvoiceSelector(partnership.hostapp.id, state)
        .filter((invoice: IAppInvoice) => invoice.app_plan.miniapp.id === partnership.miniapp.id)

  const plans = partnership.is_my_integration ?
    miniAppPlanSelector(partnership.miniapp.id, state).filter((plan: IAppPlan) => plan.hostapp.id === partnership.hostapp.id)
    :
    superAppPlanSelector(partnership.hostapp.id, state).filter((plan: IAppPlan) => plan.miniapp.id === partnership.miniapp.id)

  return {
    invoices,
    plans,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators({
      updateInvoices: (isMiniApp: boolean) => isMiniApp ? loadMiniAppInvoicesAction : loadSuperAppInvoicesAction,
    }, dispatch)
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(Billing)
