import axios from 'axios'
import Cookies from 'js-cookie'

import { API_USER_PROFILE, API_CHANGE_USER_PASSWORD, COOKIE_DOMAIN, BOXO_COOKIE_DOMAIN, DEBUG } from 'utils/constants'
import { SET_AUTH } from '../auth/actions'

export const LOAD_USER_PROFILE = 'LOAD_USER_PROFILE'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'

export function loadUserProfile () {
  return function(dispatch, getState) {
    return axios.get(API_USER_PROFILE)
      .then(response => {
        return dispatch({
          type: LOAD_USER_PROFILE,
          profile: response.data
        })
      })
      .catch(() => {
        Cookies.remove('token', {
          domain: DEBUG ? '' : COOKIE_DOMAIN
        })
        Cookies.remove('token', {
          domain: DEBUG ? '' : BOXO_COOKIE_DOMAIN
        })
        return dispatch({
          type: SET_AUTH,
          isAuthenticated: false
        })
      })
  }
}

export function updateProfile (formData) {
  return function(dispatch, getState) {
    return axios.patch(API_USER_PROFILE, formData).then(response => {
      return dispatch({
        type: UPDATE_PROFILE,
        profile: response.data
      })
    })
  }
}

export function changePassword (formData) {
  return function(dispatch, getState) {
    return axios.patch(API_CHANGE_USER_PASSWORD, formData);
  }
}

export function switch2FA(is_enabled_2fa) {
  return function(dispatch, getState) {
    return dispatch({
      type: UPDATE_PROFILE,
      profile: {
        ...getState().profile.profile,
        is_enabled_2fa
      }
    })
  }
}
