import { message } from 'antd'
import Cookies from 'js-cookie'
import { isNumber } from 'lodash'
import QRCode from 'qrcode'
import { ASC, COOKIE_DOMAIN, BOXO_COOKIE_DOMAIN, DEBUG, START_WELCOME_GUIDE_KEY } from './constants'

export function hasErrors(fieldsError) {
  return fieldsError.some(field => field.errors && field.errors.length > 0)
}

export function normalizeFields(fieldsArray) {
  return fieldsArray.reduce((acc, item) => {
    acc[item.name[0]] = item
    return acc
  }, {})
}

export function normFile(e) {
  if (Array.isArray(e)) {
    return e
  }
  return e && e.fileList
}

export function validEmail(email) {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

// Validate uploaded file
export const beforeUpload = (file, setImageUrlCallback) => {
  const isPng = file.type === 'image/png'
  if (!isPng) {
    message.error('You can only upload PNG file')
    return false
  }

  getBase64(file, setImageUrlCallback)
  file.status = 'done' // mark image as ready to be cropped
  return false
}

// Limit filelist size to one
export const handleChange = (info, callback) => {
  if (info.fileList.length > 1) {
    info.fileList.splice(0, info.fileList.length - 1)
  }

  callback(info)
}

/*
  Use default history.goBack() method if possible
  To support scrolling-restoration
 */
export const goBack = (history, previousPath = '/') => {
  if (history.length > 2) {
    history.goBack()
  } else {
    history.push(previousPath)
  }
}

export const storeToken = (token, first_login) => {
  const myDate = new Date()
  myDate.setMonth(myDate.getMonth() + 12)

  Cookies.set('token', token, {
    expires: myDate,
    domain: DEBUG ? '' : COOKIE_DOMAIN,
    secure: !DEBUG
  })
  Cookies.set('token', token, {
    expires: myDate,
    domain: DEBUG ? '' : BOXO_COOKIE_DOMAIN,
    secure: !DEBUG
  })

  if (first_login) {
    localStorage.setItem(START_WELCOME_GUIDE_KEY, 'true')
  }
}

export const normalizeMetrics = (metricsData) => {
  return metricsData.map(item => {
    const metric = {
      created_date: item.created_date,
      total_conversion: item.total_conversion,
      total_launches: item.total_launches,
      total_sales: item.total_sales,
      average_session: item.average_session,
      currency: item.currency,
      unique_user_count: item.unique_user_count,
      actual_currency_exchange_date: item.actual_currency_exchange_date,
      multi_currency_sales: item.multi_currency_sales,
      integration_id: item.integration_id,
      app: {
        name: ''
      }
    }
    if (isNumber(item.miniapp)) {
      metric.app = {
        id: item.hostapp.id,
        logo: item.hostapp.logo,
        name: item.hostapp.name
      }
    } else if (isNumber(item.hostapp)) {
      metric.app = {
        id: item.miniapp.id,
        logo: item.miniapp.logo,
        name: item.miniapp.name
      }
    }
    return metric
  })
}


export const downloadFile = (name, format, content) => {
  const url = window.URL.createObjectURL(new Blob([ content ]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `${name}.${format}`)
  document.body.appendChild(link)
  link.click()
}


export const sortBy = (fieldName, order = ASC) => {
  return (a, b) => {
    if (a[fieldName] < b[fieldName]) {
      return order === ASC ? -1 : 1
    }
    if (a[fieldName] > b[fieldName]) {
      return order === ASC ? 1 : -1
    }
    return 0
  }
}

export const withEllipsis = (text, lettersCount) => text.slice(0, lettersCount) + (text[lettersCount] ? '...' : '')

export const generateQR = async (text) => {
  try {
    const imageUrl = await QRCode.toDataURL(text)
    return imageUrl
  } catch (err) {
    console.error(err)
    return ''
  }
}
