/*CORE*/
import React from 'react'
/*COMPONENTS*/
import Spinner from '../../../../../../components/Spinner/Spinner'
/*STYLES*/
import styles from './LoadingPlaceholder.module.scss'

interface Props {
  spinnerScale?: number
}

const LoadingPlaceholder = ({ spinnerScale }: Props) =>
  <div className={styles['loading-placeholder']}>
    <Spinner scale={spinnerScale} />
  </div>

export default LoadingPlaceholder
