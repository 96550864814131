/*CORE*/
import React from 'react'
/*LIBS*/
import { Tooltip } from 'antd'
import classnames from 'classnames'
import { connect, ConnectedProps } from 'react-redux'
/*ASSETS*/
import logoPlaceholder from 'assets/img/no-data-placeholers/logo_placeholder.png'
/*COMPONENTS*/
import InfoTooltip from '../../../common/InfoTooltip'
/*UTILS*/
import { withEllipsis } from 'utils/utils'
/*CONSTANTS*/
import { COUNTRY_FLAGS_URL } from 'utils/constants'
/*SELECTORS*/
import {
  miniappCountries as miniappCountriesSelector,
  miniAppCountriesNamesByCodes as miniAppCountriesNamesByCodesSelector
} from 'store/miniApps/selectors'
/*ASSETS*/
import { ReactComponent as WorldIcon } from 'assets/img/world.svg'
import { ReactComponent as QuestionIcon } from 'assets/img/question.svg'
/*TYPES*/
import { ICountry, IShowroomMiniApp, RootState } from 'types'
/*STYLES*/
import styles from './ShowroomCard.module.scss'

const AVAILABLE_COUNTRIES_COUNT = 4 as const

interface Props extends ConnectedProps<typeof connector> {
  miniApp: IShowroomMiniApp
  hover?: boolean
}

const ShowroomCard = ({ miniApp, miniappCountries, miniAppCountriesNamesByCodes, hover = true }: Props) => {

  const getCountryTooltipTitle = (country: string) =>
    miniappCountries.find((miniappCountry: ICountry) => miniappCountry.value === country)?.display_name || ''

  const counterTooltipTitle = () =>
    <div onClick={e => e.stopPropagation()}>
      {
        miniAppCountriesNamesByCodes(miniApp.countries.slice(4)).join(', ')
      }
    </div>

  const splicedMiniapps = [ ...miniApp.countries ].splice(0, AVAILABLE_COUNTRIES_COUNT).filter(Boolean)

  return (
    <div className={classnames(styles['card'], hover && styles['hover'])}>
      <div className={styles['logo']}>
        <img src={miniApp.logo || logoPlaceholder} alt='app-logo' />
      </div>

      <div className={styles['content']}>
        <h2>{miniApp.name}</h2>
        <p className={styles['category']}>{miniApp.category.name}</p>
        <p className={styles['description']}>{withEllipsis(miniApp.short_description, 60)}</p>
      </div>

      <div className={styles['divider']} />

      <div className={styles['countries']}>

        {
          miniApp.countries?.length === miniappCountries?.length ?
            <Tooltip
              trigger='hover'
              placement='bottom'
              align={{ offset: [ 0, 5 ] }}
              overlayClassName={styles['country-tooltip']}
              title='Worldwide'
            >
              <div className={styles['countries__worldwide']}>
                <WorldIcon className={styles['icon']} /> <span>Worldwide</span>
              </div>
            </Tooltip>
            :
            <>
              {
                splicedMiniapps
                  .map(country =>
                    <Tooltip
                      key={country}
                      trigger='hover'
                      placement='bottom'
                      destroyTooltipOnHide={true}
                      align={{ offset: [ 0, 5 ] }}
                      title={getCountryTooltipTitle(country)}
                      overlayClassName={styles['country-tooltip']}
                    >
                      <div className={styles['countries__flag']}>
                        <img src={COUNTRY_FLAGS_URL + country.toLowerCase() + '.svg'} alt="flag" />
                      </div>
                    </Tooltip>
                  )
              }
              {
                miniApp.countries.length > 4 &&
                <InfoTooltip
                  placement={'topRight'}
                  title={counterTooltipTitle}
                  destroyTooltipOnHide={true}
                  overlayClassName={styles['tooltip']}
                  align={{ offset: [ 2, 0 ], targetOffset: [ -3, 0 ] }}
                >
                  <div className={styles['countries__counter']}>
                    {
                      '+' + (miniApp.countries.length - 4)
                    }
                  </div>
                </InfoTooltip>
              }
              {
                !splicedMiniapps.length &&
                <div className={styles['countries__empty']}>
                  <QuestionIcon className={styles['icon']} />
                  <span>No data on countries availability</span>
                </div>
              }
            </>
        }

      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {

  return {
    miniappCountries: miniappCountriesSelector(state),
    miniAppCountriesNamesByCodes: (countryCodes: string[]) => miniAppCountriesNamesByCodesSelector(countryCodes, state),
  }
}

const connector = connect(mapStateToProps)

export default connector(ShowroomCard)
