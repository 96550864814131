/*CORE*/
import React from 'react'
/*LIBS*/
import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
/*COMPONENTS*/
import AppsPaymentTable from '../Payment/AppsPaymentTable/AppsPaymentTable'
import TotalBilling from '../Billing/TotalBilling/TotalBilling'
import BillingHistory from '../Billing/BillingHistory/BillingHistory'
/*SELECTORS*/
import { superAppBillingPlansSelector, superAppInvoiceSelector } from 'store/billing/selectors'
/*ACTIONS*/
import { loadSuperAppInvoices as loadSuperAppInvoicesAction } from 'store/billing/actions'
/*TYPES*/
import { RootState } from 'types'


interface Props extends ConnectedProps<typeof connector> {
  appId?: string
}

const SuperAppBilling = ({ superAppInvoices, superAppPlans, updateInvoices }: Props) => {
  return (
    <>
      <TotalBilling appPlans={superAppPlans} />
      <AppsPaymentTable appPlans={superAppPlans} title='Month-to-date' />
      <BillingHistory invoices={superAppInvoices} updateInvoices={updateInvoices} />
    </>
  )
}


const mapStateToProps = (state: RootState, { appId }: { appId?: string }) => {
  return {
    superAppInvoices: superAppInvoiceSelector(+appId!, state),
    superAppPlans: superAppBillingPlansSelector(+appId!, state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators({
      updateInvoices: loadSuperAppInvoicesAction
    }, dispatch)
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(SuperAppBilling)
