import axios from 'axios'

import {
  API_INTEGRATIONS
} from 'utils/constants'
import { Dispatch, GetState, IntegrationFormData } from 'types'


export const LOAD_INTEGRATIONS = 'LOAD_INTEGRATIONS'
export const NEW_INTEGRATION = 'NEW_INTEGRATION'

export function loadIntegrations() {
  return function (dispatch: Dispatch, getState: GetState) {
    return axios.get(API_INTEGRATIONS)
      .then(response => {
        return dispatch({
          type: LOAD_INTEGRATIONS,
          payload: response.data
        })
      })
  }
}

export function createIntegration(data: IntegrationFormData) {
  return function (dispatch: Dispatch, getState: GetState) {
    return axios.post(API_INTEGRATIONS, data).then(response => {
      return response
    })
  }
}
