/*CORE*/
import React from 'react'
import { Route } from 'react-router-dom'
/*TYPES*/
import { PartnershipStatuses } from '../enums'
/*COMPONENTS*/
import Billing from './components/Billing/Billing'
import Payouts from './components/Payouts/Payouts'
import PartnershipAnalytics from '../../Analytics/PartnershipAnalytics/PartnershipAnalytics'
import { Overview } from './components/Overview/Overview'
import { PartnerDetails } from './components/PartnerDetails/PartnerDetails'
import PartnerIntegration from './components/PartnerIntegration/PartnerIntegration'
/*LIBS*/
import { Tabs } from 'antd'
/*TYPES*/
import { IPartnershipDetails } from 'types/Partnerships'

const { TabPane } = Tabs

export enum PartnershipTabs {
  Overview = 'overview',
  Integration = 'integration',
  PartnerDetails = 'partner-details',
  Analytics = 'analytics',
  Billing = 'billing',
  Payouts = 'payouts',
}

export const tabPanesStrategy = [
  {
    key: PartnershipTabs.Overview,
    pane: (status: PartnershipStatuses) => <TabPane tab="Overview" key={PartnershipTabs.Overview} />,
    route: (partnership: IPartnershipDetails, baseUrl: string) =>
      <Route
        key={PartnershipTabs.Overview}
        path={`${baseUrl}/${PartnershipTabs.Overview}`}
      >
        <Overview
          status={partnership.status}
          content={partnership.is_my_integration ? partnership.miniapp_commercial_model : partnership.hostapp_commercial_model}
        />
      </Route>,
  },
  {
    key: PartnershipTabs.Integration,
    pane: (status: PartnershipStatuses) => status === PartnershipStatuses.Accepted
      ? <TabPane tab="Integration" key={PartnershipTabs.Integration} />
      : null,
    route: (partnership: IPartnershipDetails, baseUrl: string) =>
      partnership.status === PartnershipStatuses.Accepted ? (
        <Route
          key={PartnershipTabs.Integration}
          path={`${baseUrl}/${PartnershipTabs.Integration}`}
        >
          <PartnerIntegration partnership={partnership} />
        </Route>
      ) : null
  },
  {
    key: PartnershipTabs.PartnerDetails,
    pane: (status: PartnershipStatuses) => <TabPane tab="Partner details" key={PartnershipTabs.PartnerDetails} />,
    route: (partnership: IPartnershipDetails, baseUrl: string) =>
      <Route
        key={PartnershipTabs.PartnerDetails}
        path={`${baseUrl}/${PartnershipTabs.PartnerDetails}`}
      >
        <PartnerDetails partnership={partnership.is_my_integration ? partnership.miniapp.details : partnership.hostapp.details} />
      </Route>,
  },
  {
    key: PartnershipTabs.Analytics,
    pane: (status: PartnershipStatuses) => status === PartnershipStatuses.Accepted ?
      <TabPane tab="Analytics" key={PartnershipTabs.Analytics} /> : null,
    route: (partnership: IPartnershipDetails, baseUrl: string) =>
      partnership.status === PartnershipStatuses.Accepted ?
        <Route
          key={PartnershipTabs.Analytics}
          path={`${baseUrl}/${PartnershipTabs.Analytics}`}
        >
          <PartnershipAnalytics
            partnership={partnership}
          />
        </Route> : null,
  },
  {
    key: PartnershipTabs.Billing,
    pane: (status: PartnershipStatuses) => status === PartnershipStatuses.Accepted ?
      <TabPane tab="Billing" key={PartnershipTabs.Billing} /> : null,
    route: (partnership: IPartnershipDetails, baseUrl: string) =>
      partnership.status === PartnershipStatuses.Accepted ?
        <Route
          key={PartnershipTabs.Billing}
          path={`${baseUrl}/${PartnershipTabs.Billing}`}
        >
          <Billing partnership={partnership} />
        </Route> : null,
  },
  {
    key: PartnershipTabs.Payouts,
    pane: (status: PartnershipStatuses) => status === PartnershipStatuses.Accepted ?
      <TabPane tab="Payouts" key={PartnershipTabs.Payouts} /> : null,
    route: (partnership: IPartnershipDetails, baseUrl: string) =>
      partnership.status === PartnershipStatuses.Accepted ?
        <Route
          key={PartnershipTabs.Payouts}
          path={`${baseUrl}/${PartnershipTabs.Payouts}`}
        >
          <Payouts partnership={partnership} />
        </Route> : null,
  },
]
