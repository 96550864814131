/*CORE*/
import React from 'react'
/*STYLES*/
import styles from './NoDataPlaceholder.module.scss'

interface Props {
  height: string
  text: string
  img: string
}

const NoDataPlaceholder = ({ text, img, height }: Props) =>
  <div className={styles['nodata-placeholder']}>
    <img src={img} alt="" style={{height}} />
    <p>{text}</p>
  </div>

export default NoDataPlaceholder
