import React from 'react'
import { Modal, Typography, message } from 'antd'
import { ITeamMember } from 'types/Team'
import { ChangeRoleDTO } from 'store/team/actions'
import './DeactivationModal.scss'

interface Props {
  closeModal: () => void
  member: ITeamMember
  handleSubmit: (values: ChangeRoleDTO) => Promise<void>
}

const { Text } = Typography

const ActivationModal = ({ closeModal, member, handleSubmit }: Props) => {
  const [errors, setErrors] = React.useState<string[]>([])

  const handleModalSubmit = () => {
    handleSubmit({
      id: member.id,
      email: member.user.email,
      role: member.role
    }).then(() => {
      message.success(`${member.user.name} was activated successfully.`)
      closeModal()
    }).catch(error => {
      if (error.response.data) {
        const errorLabels = Object.keys(error.response.data)
          .map(errorName => `${error.response.data[errorName]}`)
        setErrors(errorLabels)
      } else {
        setErrors(['Unexpected error happened. Try again later.'])
      }
    })
  }

  return (
    <Modal
      className='deactivation-modal'
      title={`Activation`}
      okText={'Yes'}
      onOk={handleModalSubmit}
      onCancel={closeModal}
      destroyOnClose
      visible={true}
    >
      <Text className="deactivation-modal__title">Are you sure you want to activate <b>{member.user.name}</b>?</Text>
      {
        errors && errors.length > 0 &&
        <div className="role-form__errors">
          {errors.map((error, idx) => (
            <div className="role-form__error" key={idx}>{error}</div>
          ))}
        </div>
      }
    </Modal>
  )
}

export default ActivationModal
