import React from "react";

import "./MainPaneLayout.scss";

import { Typography } from "antd";
import InfoTooltip from "pages/common/InfoTooltip";

const { Title } = Typography;

interface Props {
  title: string;
  description?: string;
  descriptionNode?: React.ReactNode;
  children?: React.ReactNode | React.ReactNodeArray;
  tooltip?: string;
}

const MainPaneLayout = ({
  title,
  description,
  descriptionNode,
  children,
  tooltip,
}: Props) => {
  return (
    <div className="main-pane-layout">
      <div className="header-wrapper">
        <Title>{title}</Title>
        {tooltip && (
          <InfoTooltip title={tooltip} className="tooltip" placement="bottom" />
        )}
      </div>
      {descriptionNode || <p>{description}</p>}
      <div className="main-pane-layout__body">{children}</div>
    </div>
  );
};

export default MainPaneLayout;
