import { ReactComponent as Logo } from 'assets/img/logo.svg';
import React from 'react';
import etisalatLogo from 'assets/img/etisalat-logo.png';
import adibLogo from 'assets/img/adib-logo.png';
import { useLogo } from 'hooks/useLogo';

export const config: any = {
  etisalat: {
    logo: etisalatLogo,
    title: 'e&',
  },
  adib: {
    logo: adibLogo,
    title: 'ADIB',
  },
}

export function DashboardLogo() {
  const logo = useLogo();

  return logo ? <img src={config[logo]?.logo} alt="" /> : <Logo />;
}
