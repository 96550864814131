import React from 'react'
import classname from 'classnames'
import { StarFilled } from '@ant-design/icons'

import PaymentCardIcon from '../PaymentCardIcon/PaymentCardIcon'
import { ReactComponent as PlusIcon } from '../../../../assets/img/plus.svg'

import './PaymentCard.scss'

interface NewPaymentCardProps {
  onClick?: () => void
  large?: boolean
}

interface PaymentCardProps {
  type: string
  lastDigits: number | string
  large?: boolean
  primary?: boolean
  onClick?: () => void
}

export const NewPaymentCard = ({ large, onClick }: NewPaymentCardProps) => {
  return (
    <div
      className={classname('payment-card payment-card--new', {
        'payment-card--large': large
      })}
      onClick={onClick}
    >
      <div className="payment-card__icon">
        <PlusIcon />
      </div>
      <div className="payment-card__number">New card</div>
    </div>
  )
}

export const PaymentCard = ({ type, lastDigits, large, primary, onClick }: PaymentCardProps) => {
  return (
    <div className={classname('payment-card', {
      'payment-card--large': large
    })} onClick={onClick}>
      <div className="payment-card__icon">
        <PaymentCardIcon
          type={type}
        />
      </div>
      <div className="payment-card__number">
        {large && '**** **** '}**** {lastDigits}
        {primary && (
          <div className={classname(large ? '' : 'star-small')}>
            <StarFilled
              style={{ color: '#2EB8DA' }}
            />
          </div>
        )}
      </div>
    </div>
  )
}
