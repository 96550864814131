/*CORE*/
import React, { useEffect, useState } from 'react'
/*LIBS*/
import { message } from 'antd'
import { connect, ConnectedProps } from 'react-redux'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
/*ACTIONS*/
import {
  signup as signupAction,
  validateOrganizationInvitationToken as validateOrganizationInvitationTokenAction,
  validateUserActivation as validateUserActivationAction
} from 'store/auth/actions'
/*COMPONENTS*/
import ActivateAccountModal from './components/ActivateAccountModal/ActivateAccountModal'
import SignupForm from './components/SignupForm/SingupForm'
import AuthLayout from './components/AuthLayout/AuthLayout'
/*TYPES*/
import { FormData } from './components/SignupForm/SingupForm'

interface Props extends ConnectedProps<typeof connector> {}

const Signup = ({ signup, validateOrganizationInvitationToken, validateUserActivation }: Props) => {
  const [formSubmitting, setFormSubmitting] = useState(false)
  const [isActivateModalOpen, setIsActivateModalOpen] = useState(false)
  const [userEmail, setUserEmail] = useState('')
  const [errors, setErrors] = useState<string[]>([])
  const history = useHistory()
  const { invitation_code, uidb64, token } = queryString.parse(history.location.search)

  useEffect(() => {
    if (uidb64 && token) {
      validateUserActivation(uidb64, token).then(() => {
        message.success('Your account is successfully activated')
      }).catch(() => {
        message.error('Account activation failed')
      })
      history.replace('/login')
    } else if (invitation_code) {
      setFormSubmitting(true)
      validateOrganizationInvitationToken(invitation_code).catch((err) => {
        message.error(
          err.response?.data.key
          ? err.response.data.key[0]
          : 'Something went wrong. Please try again later.'
        )
        setFormSubmitting(false)

        history.replace('/login')
      }).finally(() => {
        setFormSubmitting(false)
      })
    } else {
      message.error('Signup is available only with invitation')
      history.replace('/login')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, history.location.search])

  const onFormSubmit = (formData: FormData) => {
    setFormSubmitting(true)
    signup({
        ...formData,
        invitation_code
    }).then(() => {
      setFormSubmitting(false)
      setUserEmail(formData.email as string)
      setIsActivateModalOpen(true)
      message.success('Verification email was sent successfully')
    }).catch((error) => {
      if (error.response.data && error.response.status !== 404) {
        const errorLabels = Object.keys(error.response.data)
        .map(errorName => error.response.data[errorName])
        setErrors(errorLabels)
      } else {
        setErrors([error.response.statusText])
      }
      setFormSubmitting(false)
    })
  }

  return (
    <AuthLayout
      title='Join Boxo'
      subtitle='Quick and free sign-up. Only takes a minute.'
    >
      <SignupForm
        onSubmit={onFormSubmit}
        isLoading={formSubmitting}
        errors={errors}
      />
      <ActivateAccountModal isOpen={isActivateModalOpen} email={userEmail} />
    </AuthLayout>
  )
}

const connector = connect(null, {
  signup: signupAction,
  validateOrganizationInvitationToken: validateOrganizationInvitationTokenAction,
  validateUserActivation: validateUserActivationAction
})

export default connector(Signup)
