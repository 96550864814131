import { useSelector } from 'react-redux'
import { profileSelector } from 'store/profile/selectors'
import { Permissions, ROLE_NAMES } from 'utils/constants'


type UserPermissions = {
  [key in Permissions]: boolean
}

function useUserPermission(): UserPermissions {
  const profile = useSelector(profileSelector)

  return {
    [Permissions.IS_STAFF]: profile.is_staff,
    [Permissions.CAN_MANAGE]: profile.role === ROLE_NAMES.ADMIN,
    [Permissions.CAN_EDIT]: profile.role === ROLE_NAMES.ADMIN || profile.role === ROLE_NAMES.DEV
  }
}

export default useUserPermission
