/*CORE*/
import React, { useEffect, useState } from 'react'
/*LIBS*/
import { Button, Dropdown, Menu, message, Modal } from 'antd'
import { EllipsisOutlined } from '@ant-design/icons/lib'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { useHistory } from 'react-router-dom'
import { AxiosResponse } from 'axios'
import moment from 'moment'
/*SELECTORS*/
import { appboxoBankDetailsSelector, paymentCardSelector } from 'store/billing/selectors'
import { organizationSelector } from 'store/organization/selectors'
/*ACTIONS*/
import {
  updatePaymentCard as updatePaymentCardAction,
  deletePaymentCard as deletePaymentCardAction
} from 'store/billing/actions'
import {
  loadOrganization as loadOrganizationAction,
  updateOrganization as updateOrganizationAction
} from 'store/organization/actions'
/*COMPONENTS*/
import WireTransferModal from '../WireTransferModal/WireTransferModal'
import AccessControl from '../../../common/AccessControl'
/*CONSTANTS*/
import { FULL_DATE_FORMAT, PaymentMethodType, Permissions } from 'utils/constants'
/*TYPES*/
import { IPaymentCard } from 'types/Billing'
import { IBankAccount } from 'types/Payouts'
import { IOrganization, RootState } from 'types'
/*STYLES*/
import './PaymentMethods.scss'

const { confirm } = Modal

type IDispatchProps = {
  updatePaymentCard: (id: number, cardData: Partial<IPaymentCard>) => Promise<AxiosResponse<IPaymentCard>>
  deletePaymentCard: (id: number) => void
  loadOrganization: () => void
  updateOrganization: (organizationData: Partial<IOrganization>) => void
}

type IStateProps = {
  paymentCards: IPaymentCard[]
  appboxoBankDetails: IBankAccount
  organization: IOrganization
}

interface Props extends IStateProps, IDispatchProps {
}

const PaymentMethods = ({ paymentCards, updatePaymentCard, deletePaymentCard, appboxoBankDetails, organization,
                          loadOrganization, updateOrganization }: Props) => {
  const [isWireTransferModalOpen, setIsWireTransferModalOpen] = useState(false)
  const history = useHistory()

  useEffect(() => {
    loadOrganization()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAddNewCard = () => {
    history.push('/payment-card/new')
  }

  const handleMakeCardPrimary = (card: IPaymentCard) => {
    updatePaymentCard(card.id, {
      is_primary: true
    })
  }

  const handleMakeWireTransferPrimary = () => {
    updateOrganization({ payment_method: PaymentMethodType.WireTransfer })
  }

  const handleDeleteCard = (card: IPaymentCard) => {
    confirm({
      className: 'confirmation-modal',
      title: 'Delete the card?',
      content: `Are you sure you want to delete card **** **** **** ${card.last4}?`,
      okText: 'Delete',
      okButtonProps: {
        type: 'primary',
      },
      cancelText: 'Cancel',
      cancelButtonProps: {
        type: 'default',
      },
      width: 520,
      centered: true,
      onOk: async () => {
        try {
          await deletePaymentCard(card.id)
          message.success('Successfully deleted')
        } catch (error) {
          console.log(error)
          message.error('Sorry. Something went wrong.')
        }
      }
    })
  }

  return (
    <div className='payment-methods'>
      <div className='header'>
        <div>Choose your default payment method</div>
        <AccessControl permission={Permissions.CAN_MANAGE}>
          <Button type='link' onClick={handleAddNewCard}>+ Add new</Button>
        </AccessControl>
      </div>
      <table>
        <thead>
          <tr>
            <th>Account</th>
            <th>Debit / Credit card</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {paymentCards.map((card: IPaymentCard) => (
            <tr key={card.id}>
              <td>
                <div>
                  <div className='highlight'>
                    {card.name}
                    {(organization.payment_method === PaymentMethodType.CreditCard && card.is_primary) && (
                      <span className='default'>default</span>
                    )}
                  </div>
                  <div style={{ textTransform: 'capitalize' }}>{card.brand} **** {card.last4}</div>
                </div>
              </td>
              <td>
                <div className='highlight'>Card</div>
                <div>Valid Thru {card.exp_date}  •  Added on {moment(card.created_date).format(FULL_DATE_FORMAT)}</div>
              </td>
              <td>
                {(!card.is_primary || organization.payment_method === PaymentMethodType.WireTransfer) && (
                  <AccessControl permission={Permissions.CAN_MANAGE}>
                    <Dropdown
                      placement="bottomRight"
                      overlay={(
                        <Menu>
                          <Menu.Item key={0} onClick={() => handleMakeCardPrimary(card)}>
                            Make default
                          </Menu.Item>
                          <Menu.Item key={1} onClick={() => handleDeleteCard(card)}>
                            Delete
                          </Menu.Item>
                        </Menu>
                      )}
                      trigger={['click']}
                    >
                      <EllipsisOutlined/>
                    </Dropdown>
                  </AccessControl>
                )}
              </td>
            </tr>
          ))}
          {appboxoBankDetails && (
            <tr key='Wire transfer'>
              <td>
                <div>
                  <div className='highlight'>
                    {appboxoBankDetails.account_holder_name}
                    {organization.payment_method === PaymentMethodType.WireTransfer && (
                      <span className='default'>default</span>
                    )}
                  </div>
                  <div>Account No: {appboxoBankDetails.account_number}</div>
                </div>
              </td>
              <td>
                <div className='highlight'>Wire transfer</div>
                <Button type='link' onClick={() => setIsWireTransferModalOpen(true)} className='btn-link'>
                  Show details
                </Button>
              </td>
              <td>
                {organization.payment_method !== PaymentMethodType.WireTransfer && (
                  <AccessControl permission={Permissions.CAN_MANAGE}>
                    <Dropdown
                      placement='bottomRight'
                      overlay={(
                        <Menu>
                          <Menu.Item onClick={handleMakeWireTransferPrimary}>
                            Make default
                          </Menu.Item>
                        </Menu>
                      )}
                      trigger={['click']}
                    >
                      <EllipsisOutlined/>
                    </Dropdown>
                  </AccessControl>
                )}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <WireTransferModal isOpen={isWireTransferModalOpen} onClose={() => setIsWireTransferModalOpen(false)} />
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  organization: organizationSelector(state),
  paymentCards: paymentCardSelector(state),
  appboxoBankDetails: appboxoBankDetailsSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators<any, any>({
      updatePaymentCard: updatePaymentCardAction,
      deletePaymentCard: deletePaymentCardAction,
      loadOrganization: loadOrganizationAction,
      updateOrganization: updateOrganizationAction,
    }, dispatch),
  }
}

export default connect<IStateProps, IDispatchProps>(mapStateToProps, mapDispatchToProps)(PaymentMethods)
