/*CORE*/
import React, { useEffect, useState } from 'react'
/*LIBS*/
import { bindActionCreators, Dispatch } from 'redux'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { message } from 'antd'
/*ACTIONS*/
import {
  getMiniAppInvoice as getMiniAppInvoiceAction,
  downloadMiniappInvoice as downloadMiniappInvoiceAction,
  payMiniAppInvoice as payMiniAppInvoiceAction,
  payMiniAppInvoiceWithAnotherCard as payMiniAppInvoiceWithAnotherCardAction,
  loadMiniAppInvoices as loadMiniAppInvoicesAction
} from 'store/billing/actions'
/*COMPONENTS*/
import BillingInfo from '../Billing/BillingHistory/BillingInfo'
/*TYPES*/
import { convertToAppPlan, IAppInvoice, IAppPlan } from 'types/Billing'

interface Props {
  getMiniAppInvoice: (invoiceId: number) => Promise<IAppInvoice>
  downloadMiniappInvoice: (invoice: IAppInvoice, format: string) => void
  payMiniAppInvoice: (cardData: { card_id: number }, invoiceId: number) => void
  payMiniAppInvoiceWithAnotherCard: (cardData: { card_id: string }, invoiceId: number) => void
  updateMiniAppInvoices: () => void
}

const MiniAppBillingInfo = ({ getMiniAppInvoice, downloadMiniappInvoice, payMiniAppInvoice, payMiniAppInvoiceWithAnotherCard, updateMiniAppInvoices }: Props) => {
  const [invoice, setInvoice] = useState(undefined as IAppInvoice | undefined)
  const [miniappPlan, setMiniappPlan] = useState(undefined as IAppPlan | undefined)
  const { invoiceId } = useParams()

  useEffect(() => {
    loadAppPlan()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceId])

  const loadAppPlan = async () => {
    try {
      const miniappInvoice: IAppInvoice = await getMiniAppInvoice(+invoiceId!)
      setInvoice(miniappInvoice)

      const appPlan: IAppPlan = convertToAppPlan(miniappInvoice)
      setMiniappPlan(appPlan)
    } catch (error) {
      message.error('Something went wrong while loading miniapp plan')
    }
  }

  const updateInvoices = () => {
    loadAppPlan()
    updateMiniAppInvoices()
  }

  return <BillingInfo
    invoice={invoice}
    appPlan={miniappPlan}
    downloadInvoice={downloadMiniappInvoice}
    payInvoice={payMiniAppInvoice}
    payForInvoiceWithAnotherCard={payMiniAppInvoiceWithAnotherCard}
    updateInvoices={updateInvoices}
  />
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators({
      getMiniAppInvoice: getMiniAppInvoiceAction,
      downloadMiniappInvoice: downloadMiniappInvoiceAction,
      payMiniAppInvoice: payMiniAppInvoiceAction,
      payMiniAppInvoiceWithAnotherCard: payMiniAppInvoiceWithAnotherCardAction,
      updateMiniAppInvoices: loadMiniAppInvoicesAction
    }, dispatch)
  }
}

// @ts-ignore
export default connect(null, mapDispatchToProps)(MiniAppBillingInfo)
