import React from 'react'
import { Col, Row, Skeleton } from 'antd'

import './AppSkeleton.scss'

const AppSkeleton = () => {
  return (
    <Row gutter={16} className="skeleton">
      <Col span={12}>
        <Skeleton
          paragraph={{rows: 2}}
          active
        />
      </Col>
      <Col span={12}>
        <Skeleton
          paragraph={{rows: 2}}
          active
        />
      </Col>
    </Row>
  )
}

export default AppSkeleton
