import React, { useState } from "react"
import { Button, Form, Input, Modal } from "antd"
import { ModalProps } from "antd/lib/modal"

import styles from "./ExportCSVModal.module.scss"

interface FormValue {
  email: string
}

interface Props extends ModalProps {
  handleClose: () => void
  handleSubmit: (data: FormValue) => Promise<void>
  description: string
}

const ExportCSVModal = ({
  handleSubmit,
  handleClose,
  description,
  ...modalProps
}: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [form] = Form.useForm()

  const onSubmit = async (data: any) => {
    try {
      setIsLoading(true)
      await handleSubmit(data as FormValue)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal {...modalProps} closeIcon={<div />} destroyOnClose footer={false}>
      <Form
        form={form}
        onFinish={onSubmit}
        layout='horizontal'
        initialValues={{ email: "" }}
      >
        <div className={styles["modal-content"]}>
          <div>
            <div className={styles["title"]}>Export CSV</div>
            <div className={styles["desc"]}>
              {description}
            </div>
          </div>

          <div>
            <Form.Item
              name='email'
              rules={[
                {
                  type: "email",
                  message: "The input is not valid email",
                },
                {
                  required: true,
                  message: "Please input your email",
                },
              ]}
              label='Email'
            >
              <Input size='middle' />
            </Form.Item>
          </div>
          <div className={styles["footer"]}>
            <Button
              size='middle'
              type='primary'
              htmlType='submit'
              loading={isLoading}
            >
              Export
            </Button>
            <Button
              size='middle'
              type='default'
              htmlType='button'
              onClick={handleClose}
              className={"btn-outline"}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  )
}

export default ExportCSVModal
