/*CORE*/
import React from 'react'
/*LIBS*/
import { Divider, Skeleton } from 'antd'
import classnames from 'classnames'
/*STYLES*/
import styles from './ShowroomCardLoader.module.scss'

const ShowroomCardLoader = ({ className }: { className?: string }) =>
  <div className={classnames(styles['skeleton'], className)}>
    <div style={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
      <Skeleton.Avatar active size={160} shape={'square'} style={{ marginTop: 60 }} />
    </div>
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Skeleton.Input style={{ width: 100, height: 24, marginBottom: 24 }} active size={'small'} />
      <Skeleton.Input style={{ width: 280, height: 24, marginBottom: 24 }} active size={'small'} />
      <Skeleton.Input style={{ width: 200, height: 24 }} active size={'small'} />
    </div>
    <Divider className={styles['divider']} />
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Skeleton.Input style={{ width: 100, height: 24 }} active size={'small'} />
    </div>
  </div>

export default ShowroomCardLoader
