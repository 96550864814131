/*TYPES*/
import { ICountriesByContinent, ICountry, IMiniApp, RootState } from '../../types'
/*LIBS*/
import { createSelector } from 'reselect'

const countries = require('@saadixl/countries')

// MiniApp selectors
const miniAppsStateSelector = (state: RootState) => state.miniApps

export const isMiniAppsInitialLoad = createSelector(
  miniAppsStateSelector,
  miniAppsState => miniAppsState.isInitialLoad
)

export const isMiniAppsLoading = createSelector(
  miniAppsStateSelector,
  miniAppsState => miniAppsState.isLoading
)

export const miniAppsSelector = createSelector(
  miniAppsStateSelector,
  miniAppsState => miniAppsState.miniApps
)

export const miniAppIdsSelector = createSelector(
  miniAppsStateSelector,
  miniAppsState => miniAppsState.miniApps.map((item: IMiniApp) => item.id)
)

export const miniAppCountriesNamesByContinent = createSelector(
  miniAppsStateSelector,
  miniAppsState => miniAppsState?.countries?.reduce((acc: ICountriesByContinent[], curr: ICountry) => {
    const getNormalizedContinentName = (continentName: string) => continentName === 'Ocenia' ? 'Oceania' : continentName
    const continent = acc.find(item => item.name === getNormalizedContinentName(countries(curr.value).continentName))
    if (continent) {
      continent.countries = [ ...continent.countries, curr.value ]
      return acc
    } else {
      return [
        ...acc,
        {
          name: getNormalizedContinentName(countries(curr.value).continentName),
          countries: [ curr.value ],
        }
      ]
    }
  }, [])
)

export const miniappCountries = createSelector(
  miniAppsStateSelector,
  miniAppsState => miniAppsState.countries
)

export const miniAppCountriesNamesByCodes = (countriesCodes: string[], state: RootState): string[] => {
  return createSelector(
    miniAppsStateSelector,
    miniAppsState => miniAppsState?.countries
      .filter((country: ICountry) => countriesCodes.find(code => code === country.value))
      .map((country: ICountry) => country.display_name)
  )(state)
}
