import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useHistory, useParams } from 'react-router-dom'

import { Typography, Button, Popconfirm, Modal, message } from 'antd'
import { StarFilled, DeleteOutlined, StarOutlined } from '@ant-design/icons'

import {
  loadPaymentCards as loadPaymentCardsAction,
  updatePaymentCard as updatePaymentCardAction,
  deletePaymentCard as deletePaymentCardAction
} from 'store/billing/actions'
import { paymentCardSelector } from 'store/billing/selectors'

import SinglePageLayout from '../../components/SinglePageLayout/SinglePageLayout'
import SpinnerOverlay from '../../components/SpinnerOverlay/SpinnerOverlay'
import { PaymentCard } from '../../components/PaymentCard/PaymentCard'
import useUserPermission from 'hooks/useUserPermission'
import AccessControl from '../../../common/AccessControl'
import { Permissions } from 'utils/constants'

import './PaymentCardDetails.scss'

const {Title} = Typography
const {confirm} = Modal

const PaymentCardDetails = ({paymentCards, updatePaymentCard, deletePaymentCard}) => {
  const history = useHistory()
  let {cardId} = useParams()
  const user = useUserPermission()

  // TODO(Chyngyz): Move this logic into external HOC component
  useEffect(() => {
    setTimeout(() => {
      if (!paymentCards.length) {
        history.goBack()
      }
    }, 3000)
    // eslint-disable-next-line
  }, [paymentCards])

  const card = paymentCards.find(cardItem => cardItem.id === parseInt(cardId))

  const handleClose = () => {
    history.goBack()
  }

  const handleMakePrimary = () => {
    if (user.CAN_MANAGE) {
      updatePaymentCard(card.id, {
        is_primary: true
      })
    }
  }

  const handleDeleteCard = () => {
    confirm({
      className: 'confirmation-modal',
      title: 'Delete the card?',
      content: `Are you sure you want to delete card **** **** **** ${card.last4}?`,
      okText: 'Delete',
      okButtonProps: {
        type: 'primary',
      },
      cancelText: 'Cancel',
      cancelButtonProps: {
        type: 'default',
      },
      width: 520,
      centered: true,
      onOk: async () => {
        try {
          await deletePaymentCard(card.id)
          handleClose()
        } catch (error) {
          console.log(error)
          message.error('Sorry. Something went wrong.')
        }
      }
    })
  }

  return card ? (
    <SinglePageLayout
      onGoBack={handleClose}
      className="payment-card-details"
    >
      <Title>Card details</Title>
      <div className="payment-card-details__actions">
        {card.is_primary ? (
          <Button type="link">
            <StarFilled /> Primary card
          </Button>
        ) : (
          <Popconfirm
            placement="bottomLeft"
            title="Are you sure you want make this card primary?"
            onConfirm={handleMakePrimary}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="link"
            >
              <StarOutlined /> Make primary card
            </Button>
          </Popconfirm>
        )}
        <AccessControl permission={Permissions.CAN_MANAGE}>
          <Button
            type="link"
            onClick={handleDeleteCard}
          >
            <DeleteOutlined /> Delete card
          </Button>
        </AccessControl>
      </div>
      <div className="payment-card-details__card">
        <PaymentCard
          type={card.brand}
          lastDigits={card.last4}
          large
        />
      </div>
    </SinglePageLayout>
  ) : (
    <SpinnerOverlay />
  )
}

const mapStateToProps = (state) => {
  return {
    paymentCards: paymentCardSelector(state)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators({
      loadPaymentCards: loadPaymentCardsAction,
      updatePaymentCard: updatePaymentCardAction,
      deletePaymentCard: deletePaymentCardAction
    }, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentCardDetails)
