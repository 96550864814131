/*CORE*/
import React, { useEffect } from 'react'
/*LIBS*/
import { message } from 'antd'
import { AxiosResponse } from 'axios'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { connect, ConnectedProps } from 'react-redux'
/*ACTIONS*/
import {
  validateInvitationToken as validateInvitationTokenAction,
  signup as signupByInvitationAction,
  setAuth as setAuthAction
} from 'store/auth/actions'
/*COMPONENTS*/
import SpinnerOverlay from 'pages/Dashboard/components/SpinnerOverlay/SpinnerOverlay'
import InvitationForm, { FormData } from './components/InvitationForm/InvitationForm'
import AuthLayout from './components/AuthLayout/AuthLayout'
/*UTILS*/
import { storeToken } from 'utils/utils'


interface QueryParams {
  email: string
  inviter: string
  is_joined: boolean
  invite_key?: string
  organization: string
}

interface Props extends ConnectedProps<typeof connector> {}

const Join = ({ signupByInvitation, validateInvitationToken, setAuth }: Props) => {
  const [formSubmitting, setFormSubmitting] = React.useState(false)
  const [formLoading, setFormLoading] = React.useState(false)
  const [queryParams, setQueryParams] = React.useState<QueryParams>({
    email: '',
    inviter: 'User',
    organization: '',
    is_joined: false,
  })
  const [errors, setErrors] = React.useState<string[]>([])
  const history = useHistory()

  const onFormSubmit = (formData: FormData) => {
    setFormSubmitting(true)
    signupByInvitation({
      ...formData,
      invite_key: queryParams.invite_key
    }).then((resp) => {
      setFormSubmitting(false)
      message.success('Account was created successfully')
      storeToken(resp.data.token, true)
      setAuth(true)
      history.push('/')
    }).catch((error) => {
      if (error.response.data && error.response.status !== 404) {
        const errorLabels = Object.keys(error.response.data)
          .map(errorName => error.response.data[errorName])
        setErrors(errorLabels)
      } else {
        setErrors([error.response.statusText])
      }
      setFormSubmitting(false)
    })
  }

  useEffect(() => {
    setFormLoading(true)
    const { invite_key } = queryString.parse(history.location.search)
    if (!invite_key) {
      message.error('Signup is available only with invitation')
      history.replace('/login')
      return
    }
    validateInvitationToken(invite_key)
      .then((res: AxiosResponse<QueryParams>) => {

        if (res.data.is_joined) {
          message.success('Invitation successfully accepted')
          history.replace('/login')
        }

        setQueryParams({
          ...res.data,
          invite_key: invite_key as string
        })
        setFormLoading(false)
      })
      .catch((err) => {
        message.error(
          err.response?.data.key
          ? err.response.data.key[0]
          : 'Something went wrong. Please try again later.'
        )
        history.replace('/login')
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, history.location.search])

  return !formLoading ? (
    <AuthLayout
      title='You’ve got an invite'
      subtitle={`You’ve been invited to join ${queryParams.organization} on Boxo.`}
    >
      <InvitationForm
        onSubmit={onFormSubmit}
        isLoading={formSubmitting}
        initialValues={{ email: queryParams.email }}
        errors={errors}
      />
    </AuthLayout>
  ) : (
    <SpinnerOverlay />
  )
}

const connector = connect(null, {
  signupByInvitation: signupByInvitationAction,
  validateInvitationToken: validateInvitationTokenAction,
  setAuth: setAuthAction
})

export default connector(Join)
