import React, { Children, cloneElement, isValidElement, ReactElement } from 'react'
import useUserPermission from 'hooks/useUserPermission'
import { Permissions } from 'utils/constants'


interface Props {
  children: ReactElement
  permission: keyof typeof Permissions
}

const AccessControl = ({ children, permission, ...props }: Props) => {
  const user = useUserPermission()
  const childrenWithProps = Children.map(children, child => {
    // Checking isValidElement is the safe way and avoids a TS error too.
    if (isValidElement(child)) {
      return cloneElement(child, props)
    }

    return child
  })

  return user[permission]
    ? <>{childrenWithProps}</>
    : null
}

export default AccessControl
