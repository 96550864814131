/*CORE*/
import React, { ReactNode } from 'react'
/*LIBS*/
import { Tooltip } from 'antd'
import { TooltipProps } from 'antd/lib/tooltip'
/*ASSETS*/
import { ReactComponent as InfoIcon } from 'assets/img/info.svg'

const InfoTooltip = ({children, ...props}: TooltipProps & {children?: ReactNode}) => (
  <Tooltip {...props}>
    {
      children || <InfoIcon />
    }
  </Tooltip>
)

InfoTooltip.defaultProps = {
  placement: 'top'
}

export default InfoTooltip
