/*CORE*/
import React from 'react'
/*LIBS*/
import { connect, ConnectedProps } from 'react-redux'
/*COMPONENTS*/
import TotalBilling from '../TotalBilling/TotalBilling'
import AppsPaymentTable from '../../Payment/AppsPaymentTable/AppsPaymentTable'
import NoDataPlaceholder from '../../components/NoDataPlaceholder/NoDataPlaceholder'
/*ASSETS*/
import placeholder from 'assets/img/no-data-placeholers/placeholder_month-to-date.png'
/*SELECTORS*/
import { miniAppsBillingPlansSelector,  superAppsBillingPlansSelector } from 'store/billing/selectors'
/*TYPES*/
import { RootState } from 'types'


interface Props extends ConnectedProps<typeof connector> {
}

const BillingTable = ({ miniAppPlans, superAppPlans }: Props) => {
  return (
    <>
      <TotalBilling appPlans={[ ...superAppPlans, ...miniAppPlans ]} />
      {
        !!miniAppPlans.length || !!superAppPlans.length ?
          <>
            <AppsPaymentTable appPlans={superAppPlans} title='My hostapps' />
            <AppsPaymentTable appPlans={miniAppPlans} title='My miniapps' />
          </>
          :
          <NoDataPlaceholder
            height={'265px'}
            img={placeholder}
            text='Month-to-date totals from all of your partnerships will be collected here.'
          />
      }
    </>
  )
}


const mapStateToProps = (state: RootState) => {
  return {
    miniAppPlans: miniAppsBillingPlansSelector(state),
    superAppPlans: superAppsBillingPlansSelector(state)
  }
}

const connector = connect(mapStateToProps)

export default connector(BillingTable)
