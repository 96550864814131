import axios from 'axios'

import {
  API_BILLING_CARDS,
  API_BILLING_MINIAPP_INVOICES,
  API_BILLING_MINIAPP_SUB_PLANS,
  API_BILLING_SUPERAPP_INVOICES,
  API_BILLING_SUPERAPP_SUB_PLANS,
  API_BILLING_APPBOXO_BANK_ACCOUNT,
} from 'utils/constants'
import { prepareInvoice } from './selectors'
import { downloadFile } from 'utils/utils'

export const LOAD_PAYMENT_CARDS = 'LOAD_PAYMENT_CARDS'
export const LOAD_MINIAPP_SUB_PLANS = 'LOAD_MINIAPP_SUB_PLANS'
export const LOAD_SUPERAPP_SUB_PLANS = 'LOAD_SUPERAPP_SUB_PLANS'
export const UPDATE_PAYMENT_CARD = 'UPDATE_PAYMENT_CARD'
export const DELETE_PAYMENT_CARD = 'DELETE_PAYMENT_CARD'
export const ADD_PAYMENT_CARD = 'ADD_PAYMENT_CARD'
export const LOAD_MINI_APP_INVOICES = 'LOAD_MINI_APP_INVOICES'
export const LOAD_SUPER_APP_INVOICES = 'LOAD_SUPER_APP_INVOICES'
export const LOAD_APPBOXO_BANK_DETAILS = 'LOAD_APPBOXO_BANK_DETAILS'

export const PAYMENT_IN_PROGRESS = 'PAYMENT_IN_PROGRESS'
export const PAYMENT_ERROR = 'PAYMENT_ERROR'
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS'
export const PAYMENT_INITIAL = 'PAYMENT_INITIAL'

export const ADD_CARD_ERROR = 'ADD_CARD_ERROR'
export const ADD_CARD_INITIAL = 'ADD_CARD_INITIAL'
export const ADD_CARD_IN_PROGRESS = 'ADD_CARD_IN_PROGRESS'

export function loadPaymentCards() {
  return function (dispatch, getState) {
    return axios.get(API_BILLING_CARDS)
      .then(response => {
        return dispatch({
          type: LOAD_PAYMENT_CARDS,
          payload: response.data
        })
      })
  }
}

export function loadAppboxoBankDetails() {
  return function (dispatch) {
    return axios.get(API_BILLING_APPBOXO_BANK_ACCOUNT)
      .then(response => {
        return dispatch({
          type: LOAD_APPBOXO_BANK_DETAILS,
          payload: response.data
        })
      })
  }
}

export function getAppSubPlan(id, isMiniappPlan) {
  return function() {
    const url = `${isMiniappPlan ? API_BILLING_MINIAPP_SUB_PLANS : API_BILLING_SUPERAPP_SUB_PLANS}${id}/`
    return axios.get(url)
  }
}

export function loadMiniAppSubPlans() {
  return function (dispatch, getState) {
    return axios.get(API_BILLING_MINIAPP_SUB_PLANS)
      .then(response => {
        return dispatch({
          type: LOAD_MINIAPP_SUB_PLANS,
          payload: response.data
        })
      })
  }
}

export function getMiniAppInvoice(invoiceId) {
  return function () {
    return axios.get(API_BILLING_MINIAPP_INVOICES, { params: { id: invoiceId } })
      .then(response => prepareInvoice(response.data[0]))
  }
}

export function getSuperAppInvoice(invoiceId) {
  return function () {
    return axios.get(API_BILLING_SUPERAPP_INVOICES, { params: { id: invoiceId } })
      .then(response => prepareInvoice(response.data[0]))
  }
}

export function loadSuperAppSubPlans() {
  return function (dispatch, getState) {
    return axios.get(API_BILLING_SUPERAPP_SUB_PLANS)
      .then(response => {
        return dispatch({
          type: LOAD_SUPERAPP_SUB_PLANS,
          payload: response.data
        })
      })
  }
}

export function updatePaymentCard(cardId, formData) {
  return function (dispatch, getState) {
    return axios.patch(API_BILLING_CARDS + cardId + '/', formData).then(response => {
      return dispatch({
        type: UPDATE_PAYMENT_CARD,
        payload: response.data
      })
    })
  }
}

export function deletePaymentCard(cardId) {
  return function (dispatch, getState) {
    return axios.delete(API_BILLING_CARDS + cardId + '/').then(() => {
      return dispatch({
        type: DELETE_PAYMENT_CARD,
        payload: {
          cardId
        }
      })
    })
  }
}

export function addNewPaymentCard(cardData) {
  return function (dispatch, getState) {
    setAddNewCardStateToLoading()(dispatch)
    return axios.post(API_BILLING_CARDS, cardData)
      .then(response => {
        return dispatch({
          type: ADD_PAYMENT_CARD,
          payload: response.data
        })
      })
      .catch(err => setAddNewCardStateToError(err.message)(dispatch))
  }
}

export function loadMiniAppInvoices() {
  return function (dispatch) {
    return axios.get(API_BILLING_MINIAPP_INVOICES)
      .then(response => {
        return dispatch({
          type: LOAD_MINI_APP_INVOICES,
          payload: response.data
        })
      })
  }
}

export function loadSuperAppInvoices() {
  return function (dispatch) {
    return axios.get(API_BILLING_SUPERAPP_INVOICES)
      .then(response => {
        return dispatch({
          type: LOAD_SUPER_APP_INVOICES,
          payload: response.data
        })
      })
  }
}

export function payMiniAppInvoiceWithAnotherCard(cardData, invoiceId) {
  return function (dispatch) {
    setPaymentStateToLoading()(dispatch)
    return axios.post(API_BILLING_CARDS, cardData)
      .then(response => {
        dispatch({
          type: ADD_PAYMENT_CARD,
          payload: response.data
        })
        return response
      })
      .then(response => payMiniAppInvoice({ card_id: response.data.id }, invoiceId)(dispatch))
      .catch(error => setPaymentStateToError(error.message)(dispatch))
  }
}

export function payMiniAppInvoice(cardData, invoiceId) {
  return function (dispatch) {
    setPaymentStateToLoading()(dispatch)
    const url = `${API_BILLING_MINIAPP_INVOICES}${invoiceId}/pay_manual/`
    axios.post(url, cardData)
      .then(() => setPaymentStateToSuccess()(dispatch))
      .catch(error => setPaymentStateToError(error.message)(dispatch))
  }
}

export function paySuperAppInvoiceWithAnotherCard(cardData, invoiceId) {
  return function (dispatch) {
    setPaymentStateToLoading()(dispatch)
    return axios.post(API_BILLING_CARDS, cardData)
      .then(response => {
        dispatch({
          type: ADD_PAYMENT_CARD,
          payload: response.data
        })
        return response
      })
      .then(response => paySuperAppInvoice({ card_id: response.data.id }, invoiceId)(dispatch))
      .catch(error => setPaymentStateToError(error.message)(dispatch))
  }
}

export function paySuperAppInvoice(cardData, invoiceId) {
  return function (dispatch) {
    setPaymentStateToLoading()(dispatch)
    const url = `${API_BILLING_SUPERAPP_INVOICES}${invoiceId}/pay_manual/`
    axios.post(url, cardData)
      .then(() => setPaymentStateToSuccess()(dispatch))
      .catch(error => setPaymentStateToError(error.message)(dispatch))
  }
}

export function downloadMiniappInvoice(miniappInvoice, format) {
  return function () {
    return axios.get(`${API_BILLING_MINIAPP_INVOICES}${miniappInvoice.id}/get_${format}/`, { responseType: 'blob' })
      .then((response) => {
        downloadFile(miniappInvoice.invoice_number, format, response.data)
      })
  }
}

export function downloadSuperappInvoice(superappInvoice, format) {
  return function () {
    return axios.get(`${API_BILLING_SUPERAPP_INVOICES}${superappInvoice.id}/get_${format}/`, { responseType: 'blob' })
      .then((response) => {
        downloadFile(superappInvoice.invoice_number, format, response.data)
      })
  }
}

export const setPaymentStateToError = (error) => dispatch => dispatch({ type: PAYMENT_ERROR, error })
export const setPaymentStateToLoading = () => dispatch => dispatch({ type: PAYMENT_IN_PROGRESS })
export const setPaymentStateToSuccess = () => dispatch => dispatch({ type: PAYMENT_SUCCESS })
export const setPaymentStateToInitial = () => dispatch => dispatch({ type: PAYMENT_INITIAL })

export const setAddNewCardStateToError = (error) => dispatch => dispatch({ type: ADD_CARD_ERROR, error })
export const setAddNewCardStateToLoading = () => dispatch => dispatch({ type: ADD_CARD_IN_PROGRESS })
export const setAddNewCardStateToInitial = () => dispatch => dispatch({ type: ADD_CARD_INITIAL })

