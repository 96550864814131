/*CORE*/
import React from 'react'
/*LIBS*/
import { Button, Typography } from 'antd'
/*COMPONENTS*/
import ScreenshotsCarousel from './ScreenshotsCarousel/ScreenshotsCarousel'
import CommercialModel from './CommercialModel/CommercialModel'
/*TYPES*/
import { IShowroomMiniApp, MiniAppOrientations } from 'types'
/*STYLES*/
import styles from './Overview.module.scss'

const { Title, Paragraph } = Typography

interface Props {
  app: IShowroomMiniApp
}

const Overview = ({ app }: Props) => {
  const displayCommercialModelSection = !!app.commercial_model?.length || (!app.is_my_miniapp && app.hide_commercial_model)

  return (
    <div className={styles['overview']}>
      {
        !!app.screens.length &&
        <ScreenshotsCarousel
          screens={app.screens}
          isLandscape={app.orientation === MiniAppOrientations.Landscape}
        />
      }
      <div className={styles['overview__section']}>
        <Title level={3}>Description</Title>
        <Paragraph>{app.long_description}</Paragraph>
      </div>
      {displayCommercialModelSection && (
        <div className={styles['overview__section']}>
          <CommercialModel
            miniappId={app.id}
            commercialModel={app.commercial_model}
            isHidden={app.hide_commercial_model}
            commercialModelRequested={app.commercial_model_requested}
            isMyMiniapp={app.is_my_miniapp}
          />
        </div>
      )}
      {!!app.website && (
        <div className={styles['overview__section']}>
          <Title level={3}>Company website</Title>
          <Button
            className={styles['website-url']}
            href={app.website}
            target='_blank'
            rel='noopener noreferrer'
            type='link'
          >
            {app.website}
          </Button>
        </div>
      )}
      {!!app.summary_of_terms && (
        <div className={styles['overview__section']}>
          <Title level={3}>Summary of terms</Title>
          <Paragraph>
            {app.summary_of_terms}
          </Paragraph>
        </div>
      )}
    </div>
  )
}

export default Overview
