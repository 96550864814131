/*CORE*/
import React from 'react'
/*LIBS*/
import { Typography, Form, Input, Button } from 'antd'
import { FieldData, Store } from 'rc-field-form/lib/interface'
import { Link } from 'react-router-dom'
/*UTILS*/
import { hasErrors } from 'utils/utils'
/*STYLES*/
import styles from './MiniappBasicInfo.module.scss'

const { Title } = Typography

export type IMiniAppBasic = {
  name: string
}

interface Props {
  onSubmit: (values: IMiniAppBasic) => void
}

const MiniAppBasicInfo = ({ onSubmit }: Props) => {
  const [form] = Form.useForm()
  const [submitDisabled, setSubmitDisabled] = React.useState(true)

  const detectErrors = (changedFields: FieldData[], allFields: FieldData[]) => {
    setSubmitDisabled(hasErrors(allFields))
  }

  const handleSubmit = (values: Store) => {
    onSubmit(values as IMiniAppBasic)
  }

  return (
    <Form
      form={form}
      className={styles['new-mini-app-form']}
      onFieldsChange={detectErrors}
      onFinish={handleSubmit}
    >
      <Title>Add new miniapp</Title>
      <div>
        <Form.Item
          name='name'
          rules={[
            { required: true, message: 'Please enter app name' }
          ]}
          hasFeedback
        >
          <Input placeholder='App name' size='large' />
        </Form.Item>
        <div className={styles['new-mini-app-form__terms']}>
          By creating a miniapp you agree to
          our <Link to='/terms' target='_blank' rel='noopener noreferrer'>Terms & Conditions</Link>
        </div>
      </div>
      <Button
        type='primary'
        htmlType='submit'
        size='large'
        block
        disabled={submitDisabled}
      >
        Create
      </Button>
    </Form>
  )
}

export default MiniAppBasicInfo
