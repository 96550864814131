import { createSelector } from 'reselect'
import { IShowroomMiniApp, RootState } from 'types'
import { IMiniAppByCategory } from '../../pages/Dashboard/Showroom/types'

const showroomStateSelector = (state: RootState) => state.showroom

export const appCategoriesSelector = createSelector(
  showroomStateSelector,
  showroomState => showroomState.categories
)

export const showroomMiniAppsSelector = createSelector(
  showroomStateSelector,
  showroomState => showroomState.miniApps
)

export const showroomMiniAppByIdSelector = (miniappId: number, state: RootState) => createSelector(
  showroomStateSelector,
  showroomState => showroomState.miniApps.data.find((miniapp: IShowroomMiniApp) => miniapp.id === miniappId)
)(state)

export const showroomMiniAppsByCategoriesSelector = createSelector(
  showroomStateSelector,
  showroomState => {
    const miniAppsByCategories: IMiniAppByCategory[] = []

    const loading = showroomState.miniApps.loading

    showroomState.miniApps.data
      .forEach((showroomMiniApp: IShowroomMiniApp) => {
        const categoryName = showroomMiniApp.category?.name
        const category = miniAppsByCategories.find(miniApp => miniApp.category.name === categoryName)
        category ?
          category.miniApps.push(showroomMiniApp) :
          miniAppsByCategories.push({ category: showroomMiniApp.category, miniApps: [ showroomMiniApp ] })
      }
    )
    return { miniApps: miniAppsByCategories, loading }
  }
)

export const showroomMiniAppByCategorySelector = (categoryName: string, state: RootState) => createSelector(
  showroomStateSelector,
  showroomState => {
    const miniAppsByCategory: IShowroomMiniApp[] = []
    showroomState.miniApps.data.forEach((showroomMiniApp: IShowroomMiniApp) => {
      showroomMiniApp.category.name === categoryName && miniAppsByCategory.push(showroomMiniApp)
    })

    return miniAppsByCategory
  }
)(state)
