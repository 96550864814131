import React from 'react'
import { Form, Input, List, Typography, Radio, Button } from 'antd'
import { hasErrors } from 'utils/utils'
import { IRole } from 'types/Team'
import './RoleForm.scss'

const { Text } = Typography

export interface FormData {
  [key: string]: any
}

interface Props {
  roles: IRole[]
  onCancel: (state: boolean) => void
  onSubmit: (values: FormData) => void
  submitText?: string[]
  isLoading?: boolean
  initialValues?: FormData
  errors?: string[]
}

const RoleForm = ({ roles, isLoading, onSubmit, onCancel, submitText, initialValues, errors }: Props) => {
  const [form] = Form.useForm()
  const [submitDisabled, setSubmitDisabled] = React.useState(true)

  const handleFieldsChange = (changedFields: FormData[], fields: FormData[]) => {
    setSubmitDisabled(hasErrors(fields))
  }

  return (
    <Form
      className="role-form"
      onFieldsChange={handleFieldsChange}
      onFinish={onSubmit}
      form={form}
      initialValues={initialValues && {
        role: initialValues.role.id
      }}
    >
      {
        (!initialValues || (initialValues && initialValues.email)) &&
        <div className="role-form__email">
          <Form.Item
            name="email"
            rules={[{
              required: true,
              message: "Field is required"
            }, {
              type: "email",
              message: "The input is not valid email"
            }]}
          >
            <Input type="email" placeholder="Email address" size="large" />
          </Form.Item>
        </div>
      }
      <div className="role-form__roles">
        <Form.Item
          name="role"
          rules={[{
            required: true,
            message: 'One of roles should be selected'
          }]}
        >
          <Radio.Group className='role-form__radio-group'>
            <List
              bordered
              dataSource={roles}
              renderItem={(item) => (
                <List.Item key={item.id}>
                  <Radio value={item.id} className="role-form__role-item">
                    <div className="role-form__role-text">
                      <Text className="role-form__role-title">{item.name}</Text>
                      <Text>{item.description}</Text>
                    </div>
                  </Radio>
                </List.Item>
              )}
            />
          </Radio.Group>
        </Form.Item>
      </div>
      {
        errors && errors.length > 0 &&
        <div className="role-form__errors">
          {errors.map((error, idx) => (
            <div className="role-form__error" key={idx}>{error}</div>
          ))}
        </div>
      }
      <div className="role-form__btns">
        <Button
          loading={isLoading}
          type="primary"
          htmlType="submit"
          size="middle"
          disabled={submitDisabled}
        >
          {isLoading
            ? (submitText && submitText[0]) || 'Sending'
            : (submitText && submitText[1]) || 'Send invitation'
          }
        </Button>
        <Button
          type="default"
          htmlType="button"
          size="middle"
          onClick={() => onCancel(false)}
        >
          Cancel
        </Button>
      </div>
    </Form>
  )
}

export default RoleForm
