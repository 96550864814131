/*CORE*/
import React from 'react'
/*LIBS*/
import { CloseCircleOutlined } from '@ant-design/icons'
import { bindActionCreators, Dispatch } from 'redux'
import { Elements } from '@stripe/react-stripe-js'
import { Typography, Button, Result } from 'antd'
import { loadStripe } from '@stripe/stripe-js'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
/*COMPONENTS*/
import SinglePageLayout from '../../components/SinglePageLayout/SinglePageLayout'
import CardSetupForm from './CardSetupForm/CardSetupForm'
import ResultPane from '../../components/Result/Result'
/*ACTIONS*/
import {
  addNewPaymentCard as addNewPaymentCardAction,
  setAddNewCardStateToError as setAddNewCardStateToErrorAction,
  setAddNewCardStateToInitial as setAddNewCardStateToInitialAction,
} from 'store/billing/actions'
/*SELECTORS*/
import { addNewCardStatusSelector } from 'store/billing/selectors'
/*ENUMS*/
import { STRIPE_STATUSES } from 'utils/enums'
/*TYPES*/
import { RootState } from 'types'
/*STYLES*/
import styles from './NewPaymentCard.module.scss'

const { Title, Paragraph, Text } = Typography


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_PUB_KEY || '')

interface IStateToProps {
  status: STRIPE_STATUSES
  errorMessage: string
}

interface IDispatchToProps {
  addNewPaymentCard: (cardData: { card_id: string }) => void
  setAddNewCardStateToError: (error: string) => void
  setAddNewCardStateToInitial: () => void
}

interface Props extends IStateToProps, IDispatchToProps {
}

const NewPaymentCard = ({ addNewPaymentCard, status, errorMessage, setAddNewCardStateToInitial, setAddNewCardStateToError }: Props) => {
  const history = useHistory()

  const handleClose = () => {
    history.goBack()
    setAddNewCardStateToInitial()
  }

  const handleAddNewPaymentCardSuccess = (cardData: { card_id: string }) => {
    addNewPaymentCard(cardData)
  }

  const handleAddNewPaymentCardError = (errorMessage: string) => {
    setAddNewCardStateToError(errorMessage)
  }

  const handleTryAgain = () => {
    setAddNewCardStateToInitial()
  }

  return (
    <SinglePageLayout
      onGoBack={handleClose}
      className={styles['payment-card-details']}
      isFullHeight
    >
      {status === STRIPE_STATUSES.Init && (
        <>
          <Title>New card</Title>
          <Elements stripe={stripePromise}>
            <CardSetupForm
              onAddNewPaymentCardSuccess={handleAddNewPaymentCardSuccess}
              onAddNewPaymentCardError={handleAddNewPaymentCardError}
            />
          </Elements>
        </>
      )}

      {status === STRIPE_STATUSES.Success && (
        <ResultPane
          title="You have added new payment method"
          text="Continue to dashboard to finish the setup process"
        >
          <Button
            type="primary"
            size="large"
            block
            style={{ width: '340px' }}
            onClick={handleClose}
          >
            Continue
          </Button>
        </ResultPane>
      )}

      {status === STRIPE_STATUSES.Error && (
        <Result
          className={styles['payment-card-details__error']}
          status="error"
          title="Operation Failed"
          subTitle="Please check and modify the following information before resubmitting."
          extra={[
            <Button key="buy" type="primary" onClick={handleTryAgain}>Try Again</Button>
          ]}
        >
          <div className="desc">
            <Paragraph>
              <Text
                strong
                style={{
                  fontSize: 16,
                }}
              >
                The card you submitted has the following error:
              </Text>
            </Paragraph>
            <Paragraph>
              <CloseCircleOutlined style={{ color: 'red' }} /> {errorMessage}
            </Paragraph>
          </div>
        </Result>
      )}
    </SinglePageLayout>
  )
}

const mapStateToProps = (state: RootState) => {

  const addCardStatus = addNewCardStatusSelector(state)

  return {
    status: addCardStatus.status,
    errorMessage: addCardStatus.error,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators({
      addNewPaymentCard: addNewPaymentCardAction,
      setAddNewCardStateToError: setAddNewCardStateToErrorAction,
      setAddNewCardStateToInitial: setAddNewCardStateToInitialAction,
    }, dispatch),
  }
}

export default connect<IStateToProps, IDispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(NewPaymentCard)
