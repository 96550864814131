/*CORE*/
import React from 'react'
/*LIBS*/
import { Button, Form, Input, Checkbox } from 'antd'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
/*UTILS*/
import { hasErrors } from 'utils/utils'
/*STYLES*/
import styles from './SingupForm.module.scss'

export interface FormData {
  first_name?: string,
  last_name?: string,
  email?: string
  password?: string
  agreement?: boolean,
  organization_name?: string
}

interface FieldData {
  [key: string]: any
}

interface Props {
  className?: string
  onSubmit?: (values: FormData) => void
  isLoading?: boolean
  errors?: string[]
}

const SignupForm = ({ onSubmit, className, isLoading, errors }: Props) => {
  const [form] = Form.useForm()
  const [submitDisabled, setSubmitDisabled] = React.useState(true)

  const handleFieldsChange = (changedFields: FieldData[], fields: FieldData[]) => {
    setSubmitDisabled(hasErrors(fields))
  }

  return (
    <Form
      form={form}
      className={classnames(styles['signup-form'], className)}
      onFinish={onSubmit}
      onFieldsChange={handleFieldsChange}
      layout='vertical'
    >
      <div className={styles['signup-form__content']}>
        <Form.Item
          name='first_name'
          rules={[
            {
              required: true,
              message: 'Please enter name'
            }
          ]}
          hasFeedback
        >
          <Input placeholder='Name' size='large'/>
        </Form.Item>
        <Form.Item
          name='last_name'
          rules={[
            {
              required: true,
              message: 'Please enter surname'
            }
          ]}
          hasFeedback
        >
          <Input placeholder='Surname' size='large'/>
        </Form.Item>
        <Form.Item
          name='organization_name'
          rules={[
            {
              required: true,
              message: 'Please enter company legal name'
            }
          ]}
        >
          <Input placeholder='Company legal name' size='large'/>
        </Form.Item>
        <Form.Item
          name='email'
          rules={[
            {
              type: 'email',
              message: 'The input is not a valid email',
            },
            {
              required: true,
              message: 'Please enter email'
            }
          ]}
        >
          <Input placeholder='Email' size='large'/>
        </Form.Item>
        <Form.Item
          name='password'
          rules={[
            {
              min: 8,
              message: 'Must be at least 8 characters long'
            },
            {
              required: true,
              message: 'Please enter password'
            }
          ]}
          hasFeedback
        >
          <Input.Password
            placeholder='Password'
            size='large'
          />
        </Form.Item>
        <Form.Item
          name='agreement'
          valuePropName='checked'
          rules={[
            {
              validator: (rule, value) => {
                if (value === true) {
                  return Promise.resolve()
                }
                return Promise.reject('Terms and conditions must be accepted')
              }
            }
          ]}
        >
          <Checkbox className={styles['signup-form__checkbox']}>
            I agree to Appboxo’s <Link to='/registration-terms' target='_blank' rel='noopener noreferrer'>Terms
            </Link> and <Link to='/privacy' target='_blank' rel='noopener noreferrer'>Privacy Policy</Link>
          </Checkbox>
        </Form.Item>
        {
          errors && errors.length > 0 &&
          <div className={styles['signup-form__errors']}>
            {errors.map((error, idx) => (
              <span key={idx} className={styles['signup-form__error']}>{error}</span>
            ))}
          </div>
        }
      </div>
      <div className={styles['signup-form__footer']}>
        <Button
          loading={isLoading}
          type='primary'
          htmlType='submit'
          size='large'
          block
          disabled={submitDisabled}
        >
          {isLoading ? 'Creating' : 'Create account'}
        </Button>
        <div className={styles['other-auth-option']}>
          <span>Have an account?</span>
          <Link to='/login'>Sign in</Link>
        </div>
      </div>
    </Form>
  )
}

export default SignupForm
