import {
  LOAD_ROLES,
  LOAD_ROLES_ERROR,
  LOAD_ROLES_REQUEST,
  LOAD_MEMBERS,
  LOAD_MEMBERS_ERROR,
  LOAD_MEMBERS_REQUEST,
  LOAD_INVITES,
  LOAD_INVITES_ERROR,
  LOAD_INVITES_REQUEST,
  CHANGE_ROLE,
  CHANGE_ROLE_ERROR,
  CHANGE_ROLE_REQUEST,
  DEACTIVATE_ACCOUNT,
  DEACTIVATE_ACCOUNT_ERROR,
  DEACTIVATE_ACCOUNT_REQUEST,
  REMOVE_INVITE,
  REMOVE_INVITE_ERROR,
  REMOVE_INVITE_REQUEST,
  RESEND_INVITE,
  RESEND_INVITE_ERROR,
  RESEND_INVITE_REQUEST,
  SEND_INVITE,
  SEND_INVITE_ERROR,
  SEND_INVITE_REQUEST,
  ACTIVATE_ACCOUNT_REQUEST,
  ACTIVATE_ACCOUNT,
  ACTIVATE_ACCOUNT_ERROR,
} from './actions'
import { ITeamMember, IRole, ITeamInvite } from 'types/Team'
import { ReducerAction, StoreEntityArray } from 'types/common'

interface State {
  members: StoreEntityArray<ITeamMember>
  invites: StoreEntityArray<ITeamInvite>
  roles: StoreEntityArray<IRole>
  newInviteSending: boolean
}

const defaultState: State = {
  members: {
    isLoading: false,
    loaded: false,
    entities: []
  },
  invites: {
    isLoading: false,
    loaded: false,
    entities: []
  },
  roles: {
    isLoading: false,
    loaded: false,
    entities: []
  },
  newInviteSending: false
}

export function team(state: State = defaultState, action: ReducerAction) {
  switch (action.type) {
    // ------------ roles ------------
    case LOAD_ROLES_REQUEST:
      return {
        ...state,
        roles: {
          ...state.members,
          isLoading: true
        }
      }
    case LOAD_ROLES:
      return {
        ...state,
        roles: {
          isLoading: false,
          loaded: true,
          entities: action.payload
        }
      }
    case LOAD_ROLES_ERROR:
      return {
        ...state,
        roles: {
          isLoading: false,
          loaded: false
        }
      }
    // ------------ roles end ------------
    // ------------ members ------------
    case LOAD_MEMBERS_REQUEST:
      return {
        ...state,
        members: {
          ...state.members,
          isLoading: true
        }
      }
    case LOAD_MEMBERS:
      return {
        ...state,
        members: {
          isLoading: false,
          loaded: true,
          entities: action.payload
        }
      }
    case LOAD_MEMBERS_ERROR:
      return {
        ...state,
        members: {
          isLoading: false,
          loaded: false,
          entities: []
        }
      }
    // ------------ members end ------------
    // ------------ invites ------------
    case LOAD_INVITES_REQUEST:
      return {
        ...state,
        invites: {
          ...state.invites,
          isLoading: true
        }
      }
    case LOAD_INVITES:
      return {
        ...state,
        invites: {
          isLoading: false,
          loaded: true,
          entities: action.payload
        }
      }
    case LOAD_INVITES_ERROR:
      return {
        ...state,
        invites: {
          isLoading: false,
          loaded: false,
          entities: []
        }
      }
    // ------------ invites end ------------
    // -------- send invite ---------
    case SEND_INVITE_REQUEST: {
      return {
        ...state,
        newInviteSending: true
      }
    }
    case SEND_INVITE:
    case SEND_INVITE_ERROR: {
      return {
        ...state,
        newInviteSending: false
      }
    }
    // -------- send invite end ---------
    // -------- change role ---------
    case CHANGE_ROLE_REQUEST: {
      return {
        ...state,
        members: {
          ...state.members,
          entities: state.members.entities.map(m => {
            if (m.user.email === action.payload) {
              m.isProcessing = true
            }
            return m
          })
        }
      }
    }
    case CHANGE_ROLE: {
      return {
        ...state,
        members: {
          ...state.members,
          entities: state.members.entities.map(m => {
            if (m.user.email === action.payload.email) {
              m.role = action.payload.role
              m.isProcessing = false
            }
            return m
          })
        }
      }
    }
    case CHANGE_ROLE_ERROR: {
      return {
        ...state,
        members: {
          ...state.members,
          entities: state.members.entities.map(m => {
            if (m.user.email === action.payload) {
              m.isProcessing = false
            }
            return m
          })
        }
      }
    }
    // -------- deactivate account ---------
    case DEACTIVATE_ACCOUNT_REQUEST: {
      return {
        ...state,
        members: {
          ...state.members,
          entities: state.members.entities.map(m => {
            if (m.user.email === action.payload) {
              m.isProcessing = true
            }
            return m
          })
        }
      }
    }
    case DEACTIVATE_ACCOUNT: {
      return {
        ...state,
        members: {
          ...state.members,
          entities: state.members.entities.map(m => {
            if (m.user.email === action.payload.email) {
              m.status = action.payload.status
              m.status_text = 'Inactive'
              m.isProcessing = false
            }
            return m
          })
        }
      }
    }
    case DEACTIVATE_ACCOUNT_ERROR: {
      return {
        ...state,
        members: {
          ...state.members,
          entities: state.members.entities.map(m => {
            if (m.user.email === action.payload) {
              m.isProcessing = false
            }
            return m
          })
        }
      }
    }
    // -------- deactivate account end ---------
    // -------- activate account ---------
    case ACTIVATE_ACCOUNT_REQUEST: {
      return {
        ...state,
        members: {
          ...state.members,
          entities: state.members.entities.map(m => {
            if (m.user.email === action.payload) {
              m.isProcessing = true
            }
            return m
          })
        }
      }
    }
    case ACTIVATE_ACCOUNT: {
      return {
        ...state,
        members: {
          ...state.members,
          entities: state.members.entities.map(m => {
            if (m.user.email === action.payload.email) {
              m.status = action.payload.status
              m.status_text = 'Active'
              m.isProcessing = false
            }
            return m
          })
        }
      }
    }
    case ACTIVATE_ACCOUNT_ERROR: {
      return {
        ...state,
        members: {
          ...state.members,
          entities: state.members.entities.map(m => {
            if (m.user.email === action.payload) {
              m.isProcessing = false
            }
            return m
          })
        }
      }
    }
    // -------- activate account end ---------
    // -------- remove invite ---------
    case REMOVE_INVITE_REQUEST: {
      return {
        ...state,
        invites: {
          ...state.invites,
          entities: state.invites.entities.map(m => {
            if (m.email === action.payload) {
              m.isProcessing = true
            }
            return m
          })
        }
      }
    }
    case REMOVE_INVITE: {
      return {
        ...state,
        invites: {
          ...state.invites,
          entities: state.invites.entities.filter(m => m.email !== action.payload)
        }
      }
    }
    case REMOVE_INVITE_ERROR: {
      return {
        ...state,
        invites: {
          ...state.members,
          entities: state.invites.entities.map(m => {
            if (m.email === action.payload) {
              m.isProcessing = false
            }
            return m
          })
        }
      }
    }
    // -------- remove invite end ---------
    // -------- resend invite ---------
    case RESEND_INVITE_REQUEST: {
      return {
        ...state,
        invites: {
          ...state.invites,
          entities: state.invites.entities.map(m => {
            if (m.email === action.payload) {
              m.isProcessing = true
            }
            return m
          })
        }
      }
    }
    case RESEND_INVITE:
    case RESEND_INVITE_ERROR: {
      return {
        ...state,
        invites: {
          ...state.members,
          entities: state.invites.entities.map(m => {
            if (m.email === action.payload) {
              m.isProcessing = false
            }
            return m
          })
        }
      }
    }
    // -------- resend invite end ---------

    default:
      return state
  }
}
