/*CORE*/
import React from 'react'
/*COMPONENTS*/
import MoneyWrapper from '../../../../common/MoneyWrapper'
import InfoTooltip from '../../../../common/InfoTooltip'
/*UTILS*/
import { formatAmount } from 'utils/common'
/*TYPES*/
import { IMetrics } from 'types'
/*STYLES*/
import styles from './CurrenciesTable.module.scss'

type Props = {
  metrics: IMetrics
}

const CurrenciesTable = ({ metrics }: Props) => {
  if (metrics.multi_currency_sales.length === 0) {
    return null
  }
  const totalAmount = metrics.multi_currency_sales.reduce(
    (acc, multiCurrency) => acc + Number(multiCurrency.exchanged_amount), 0
  )

  return (
    <div className={styles['currencies-table']}>
      <h2>Sales breakdown</h2>
      <table>
        <thead>
        <tr>
          <th>Currency</th>
          <th>Sales / GMV</th>
          <th>Amount {metrics.currency.code}</th>
        </tr>
        </thead>
        <tbody>
        {metrics.multi_currency_sales.map((multiCurrency, i) =>
          <tr key={i}>
            <td>{multiCurrency.currency_name}</td>
            <td>{formatAmount(+multiCurrency.sales)} {multiCurrency.currency_code}</td>
            <td><MoneyWrapper amount={+multiCurrency.exchanged_amount} currency={metrics.currency} /></td>
          </tr>
        )}
        <tr key='total'>
          <td/>
          <td>
            <div className={styles['estimation']}>
              <span>Estimated total</span>
              <InfoTooltip
                overlayClassName={styles['estimation__tooltip']}
                overlay={
                  <span>
                    Estimated amount in {metrics.currency.code} based on currency rate taken from <a
                    href='https://openexchangerates.org/' target='_blank' rel='noopener noreferrer'>
                    openexchangerates.org</a>
                  </span>
                }
              />
            </div>
          </td>
          <td><MoneyWrapper amount={totalAmount} currency={metrics.currency} /></td>
        </tr>
        </tbody>
      </table>
    </div>
  )
}

export default CurrenciesTable
