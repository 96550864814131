/*ENUMS*/
import { PartnershipStatuses } from './enums'
/*COMPONENTS*/
import { BadgeStatus } from '../components/Badge/Badge'

export const getPartnershipBadgeStatus = (status: PartnershipStatuses): { badgeStatus: BadgeStatus, tooltipStatus: string } => {

  const badgeStatusesStrategy:
    {
      [key in PartnershipStatuses]:
      { badgeStatus: BadgeStatus, tooltipStatus: string }
    } & { default: { badgeStatus: 'default', tooltipStatus: 'Pending' } } =
    {
      [PartnershipStatuses.Accepted]: {
        badgeStatus: 'success',
        tooltipStatus: 'Accepted',
      },
      [PartnershipStatuses.Pending]: {
        badgeStatus: 'warning',
        tooltipStatus: 'Pending',
      },
      [PartnershipStatuses.Rejected]: {
        badgeStatus: 'danger',
        tooltipStatus: 'Rejected',
      },
      [PartnershipStatuses.InTesting]: {
        badgeStatus: 'info',
        tooltipStatus: 'In Testing',
      },
      default: {
        badgeStatus: 'default',
        tooltipStatus: 'Pending',
      },
    }

  return badgeStatusesStrategy?.[status] || badgeStatusesStrategy['default']
}
