/*CORE*/
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
/*LIBS*/
import { message } from 'antd'
import { connect, ConnectedProps } from 'react-redux'
import { useQuery } from 'hooks/useQuery'
import { bindActionCreators, Dispatch } from 'redux'
/*COMPONENTS*/
import AuthLayout from './components/AuthLayout/AuthLayout'
import SetNewPasswordForm from './components/SetNewPasswordForm/SetNewPasswordForm'
/*ACTIONS*/
import { setNewPassword as setNewPasswordAction } from 'store/auth/actions'
/*UTILS*/
import { goBack } from 'utils/utils'

interface Props extends ConnectedProps<typeof connector> {
}

const SetNewPassword = ({ setNewPassword }: Props) => {
  const [ isLoading, setIsLoading ] = useState(false)
  const query: { token?: string } = useQuery()
  const history = useHistory()

  const handleGoBack = () => {
    goBack(history, '/login')
  }

  const onSubmit = (password: string) => {
    if (!!password && !!query?.token) {
      const dto = {
        password,
        token: query.token,
      }

      setIsLoading(true)
      setNewPassword(dto)
        .then(() => {
          setIsLoading(false)
          message.success('Password changed successfully')
          setTimeout(() => {
            history.push('/login')
          }, 1500)
        })
        .catch(() => {
          setIsLoading(false)
          message.error('Something went wrong. Please try again later.')
        })
    } else {
      message.error('Something went wrong. Please try again later.')
    }
  }

  return (
    <AuthLayout
      onGoBack={handleGoBack}
      title='Recover password'
      hideLogo={true}
    >
      <SetNewPasswordForm onSubmit={onSubmit} isLoading={isLoading} />
    </AuthLayout>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators<any, any>({
      setNewPassword: setNewPasswordAction,
    }, dispatch),
  }
}

const connector = connect(null, mapDispatchToProps)

export default connector(SetNewPassword)
