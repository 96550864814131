/*LIBS*/
import { combineReducers } from 'redux'
/*REDUCERS*/
import { auth } from './auth/reducer'
import { miniApps } from './miniApps/reducer'
import { superApps } from './superApps/reducer'
import { profile } from './profile/reducer'
import { showroom } from './showroom/reducer'
import { billing } from './billing/reducer'
import { payouts } from './payouts/reducers'
import { team } from './team/reducers'
import { integrations } from './integrations/reducer'
import { organization } from './organization/reducers'
import { partnerships } from './partnerships/reducer'
/*CONSTANTS*/
import { LOGOUT } from './auth/actions'

const appReducer = combineReducers({
  auth,
  miniApps,
  superApps,
  profile,
  showroom,
  billing,
  payouts,
  team,
  integrations,
  organization,
  partnerships,
})

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    state = undefined
  }
  return appReducer(state, action)
}

export default rootReducer
