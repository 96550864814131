/*CORE*/
import React from 'react'
/*LIBS*/
import classname from 'classnames'
/*STYLES*/
import styles from './Badge.module.scss'

export type BadgeStatus = 'success' | 'danger' | 'disabled' | 'default' | 'warning' | 'info'

interface Props {
  className?: string
  status?: BadgeStatus
  label?: React.ReactNode
}

const Badge = ({ status, label, className }: Props) => {
  return (
    <div className={classname(className, styles['badge'], styles[`badge--${status}`])}>
      <div className={classname(styles['badge__icon'], label ? styles['badge__icon--margin'] : '')} />
      {label && (
        <div className={styles['badge__label']}>
          {label}
        </div>
      )}
    </div>
  )
}

export default Badge
