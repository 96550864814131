import React from 'react'

import { Typography } from 'antd'
import { ReactComponent as Tick } from 'assets/img/tick.svg'

import './Result.scss'

const { Title } = Typography


const ResultPane = ({ title, text, children }) => {
  return (
    <div className="result-pane">
      <div className="result-pane__icon">
        <Tick />
      </div>
      <Title>{title}</Title>
      {text && <p>{text}</p>}
      <div className="result-pane__content">
        {children}
      </div>
    </div>
  )
}

ResultPane.defaultProps = {
  text: ''
}

export default ResultPane
