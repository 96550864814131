import { createSelector } from 'reselect'

const superAppsStateSelector = state => state.superApps

export const isSuperAppsInitialLoad = createSelector(
  superAppsStateSelector,
  superAppsState => superAppsState.isInitialLoad
)

export const isSuperAppsLoading = createSelector(
  superAppsStateSelector,
  superAppsState => superAppsState.isLoading
)

export const superAppsSelector = createSelector(
  superAppsStateSelector,
  superAppsState => superAppsState.superApps
)
