/*CORE*/
import React from 'react'
/*LIBS*/
import { Typography } from 'antd'
/*COMPONENTS*/
import { WithEmpty } from 'pages/Dashboard/components/WithEmpty/WithEmpty'
/*TYPES*/
import { IPartnershipAppDetails } from 'types/Partnerships'
/*STYLES*/
import styles from './PartnerDetails.module.scss'

const { Title } = Typography

interface Props {
  partnership: IPartnershipAppDetails
}

type ListItemContent = IPartnershipAppDetails['country'] & IPartnershipAppDetails[keyof Omit<IPartnershipAppDetails, 'country'>]

const listItemsStrategy: { [key in keyof Omit<IPartnershipAppDetails, 'id'>]: string } = {
  name: 'Organization legal name',
  street_address: 'Street address',
  city: 'City',
  country: 'Country',
  postal_code: 'Postal Code',
}

const getListItemLabel = (field: keyof Omit<IPartnershipAppDetails, 'id'>): string => listItemsStrategy[field] || ''

const ListItem = ({ label, content }: { label: string, content: ListItemContent }) => {

  const isCountry = label === listItemsStrategy.country
  const itemContent = isCountry ? content.name || '-' : content || '-'

  return (
    <div className={styles['list-item']}>
      <span>{label || ''}</span>
      <div>
        {itemContent}
      </div>
    </div>
  )
}

export const PartnerDetails = ({ partnership }: Props) => {

  return (
    <div className={styles['overview']}>
      <Title className={styles['title']} level={3}>Partner Details</Title>
      <WithEmpty>
        {
          Object.keys(listItemsStrategy).map(key =>
            <ListItem
              key={key}
              content={partnership[key as keyof Omit<IPartnershipAppDetails, 'id'>] as ListItemContent}
              label={getListItemLabel(key as keyof Omit<IPartnershipAppDetails, 'id'>)}
            />
          )
        }
      </WithEmpty>
    </div>
  )
}
