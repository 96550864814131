/*CORE*/
import React, { useEffect, useState } from 'react'
/*LIBS*/
import { Button, Checkbox, Collapse, Typography } from 'antd'
import { CloseOutlined } from '@ant-design/icons/lib'
import { connect, ConnectedProps } from 'react-redux'
import { Link } from 'react-router-dom'
/*SELECTORS*/
import { profileSelector } from 'store/profile/selectors'
/*CONSTANTS*/
import { COMPLETED_GUIDE_STEPS_KEY, GUIDE_STEPS, WELCOME_GUIDE_STEPS_KEY } from 'utils/constants'
/*TYPES*/
import { RootState } from 'types'
/*STYLES*/
import styles from './WelcomeGuide.module.scss'

const { Title } = Typography
const { Panel } = Collapse

type GuideLink = {
  label: string
  path: string
  isExternal: boolean
}

export type GuideStep = {
  id: number
  title: string
  text: string
  link: GuideLink
}

interface Props extends ConnectedProps<typeof connector> {
  isAppTypeNavModalOpen: boolean
}

const WelcomeGuide = ({ isAppTypeNavModalOpen, profile }: Props) => {
  const [guideSteps, setGuideSteps] = useState([] as number[])
  const [completedSteps, setCompletedSteps] = useState([] as number[])

  useEffect(() => {
    try {
      const stepIds = JSON.parse(localStorage.getItem(WELCOME_GUIDE_STEPS_KEY) || '[]')
      const completedStepIds = JSON.parse(localStorage.getItem(COMPLETED_GUIDE_STEPS_KEY) || '[]')
      if (Array.isArray(stepIds)) {
        setGuideSteps(stepIds as number[])
      }
      if (Array.isArray(completedStepIds)) {
        setCompletedSteps(completedStepIds as number[])
      }
    } catch {}
  }, [isAppTypeNavModalOpen])

  useEffect(() => {
    localStorage.setItem(WELCOME_GUIDE_STEPS_KEY, JSON.stringify(guideSteps))
    localStorage.setItem(COMPLETED_GUIDE_STEPS_KEY, JSON.stringify(completedSteps))
  }, [guideSteps, completedSteps])

  const checkAsComplete = (guideStepId: number, checked: boolean) => {
    if (checked) {
      setCompletedSteps([...completedSteps, guideStepId])
    } else {
      setCompletedSteps(completedSteps.filter((guideStep) => guideStep !== guideStepId))
    }
  }

  const closeStep = (guideStepId: number) => {
    setGuideSteps(guideSteps.filter((guideStep) => guideStep !== guideStepId))
  }

  if (guideSteps.length === 0) {
    return null
  }

  return (
    <div className={styles['welcome-guide']}>
      <Title className={styles['title']}>Welcome, {profile.first_name} — follow these steps to get started</Title>
      <Collapse
        className={styles['welcome-guide__collapse']}
        accordion={true}
      >
        {GUIDE_STEPS
        .filter((guideStep) => guideSteps.indexOf(guideStep.id) !== -1)
        .map((guideStep) => (
          <Panel
            key={guideStep.id}
            header={guideStep.title}
            extra={completedSteps.indexOf(guideStep.id) !== -1
              ? <CloseOutlined onClick={() => closeStep(guideStep.id)} />
              : null
            }
          >
            <p>{guideStep.text}</p>
            <div>
              {guideStep.link.isExternal ? (
                <a href={`${guideStep.link.path}${guideStep.link.path.startsWith('mailto') ? profile.first_name : ''}`} target='_blank' rel='noopener noreferrer'>
                  <Button type='primary'>{guideStep.link.label}</Button>
                </a>
              ) : (
                <Link to={guideStep.link.path}>
                  <Button type='primary'>{guideStep.link.label}</Button>
                </Link>
              )}
              <Checkbox
                checked={completedSteps.indexOf(guideStep.id) !== -1}
                onChange={(e) => checkAsComplete(guideStep.id, e.target.checked)}
              >
                Mark as completed
              </Checkbox>
            </div>
          </Panel>
        ))}
      </Collapse>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    profile: profileSelector(state)
  }
}

const connector = connect(mapStateToProps, {})

export default connector(WelcomeGuide)
