/*CORE*/
import React from 'react'
/*COMPONENTS*/
import MoneyWrapper from '../../../common/MoneyWrapper'
/*UTILS*/
import { formatAmount } from 'utils/common'
/*TYPES*/
import { IAppPlan } from 'types/Billing'
/*STYLES*/
import styles from './LaunchesPaymentTable.module.scss'

type Props = {
  appPlan: IAppPlan
}

const LaunchesPaymentTable = ({ appPlan }: Props) => {
  return (
    <table className={styles['launches-payment-table']}>
      <thead>
      <tr>
        <th>Currency</th>
        <th>Launches</th>
        <th>Rate</th>
        <th>Amount {appPlan.currency.code}</th>
      </tr>
      </thead>
      <tbody>
        <tr key='launch-details'>
          <td>
            {appPlan.currency.name}
          </td>
          <td>
            {formatAmount(appPlan.launches_count)}
          </td>
          <td>
            <MoneyWrapper currency={appPlan.currency} amount={appPlan.rate} />
          </td>
          <td>
            <MoneyWrapper currency={appPlan.currency} amount={appPlan.amount} />
          </td>
        </tr>
        <tr key='total'>
          <td />
          <td />
          <td>
            Total {appPlan.currency.code}:
          </td>
          <td>
            <MoneyWrapper amount={appPlan.amount} currency={appPlan.currency} />
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default LaunchesPaymentTable
