/*CORE*/
import React, { useState } from 'react'
/*LIBS*/
import { Input } from 'antd'
import { connect } from 'react-redux'
import { useRouteMatch, useHistory } from 'react-router-dom'
/*SELECTORS*/
import { isMiniAppsLoading, miniAppsSelector } from 'store/miniApps/selectors'
/*COMPONENTS*/
import InfoCard from '../components/InfoCard/InfoCard'
import MainPaneLayout from '../components/MainPaneLayout/MainPaneLayout'
import AppCard from '../components/AppCard/AppCard'
import AppSkeleton from '../components/Skeletons/AppSkeleton'
import AccessControl from '../../common/AccessControl'
/*CONSTANTS*/
import { DOCS_URL, Permissions } from 'utils/constants'
/*TYPES*/
import { IShowroomMiniApp, RootState } from 'types'
/*STYLES*/
import './MiniApps.scss'

interface IStateProps {
  isMiniAppsLoading: boolean
  miniApps: IShowroomMiniApp[]
}

type Props = IStateProps

const { Search } = Input

const MiniApps = ({ miniApps, isMiniAppsLoading }: Props) => {
  const history = useHistory()
  let { path } = useRouteMatch()
  const [ searchQuery, setSearchQuery ] = useState('')

  const handleOpenMiniapp = (app: IShowroomMiniApp, isClientMiniapp: boolean = true) => {
    const url = isClientMiniapp ? `${path}/${app.id}/overview` : `integrated-miniapp/${app.id}/overview`
    history.push(url)
  }

  const handleFilterByName = (app: IShowroomMiniApp): boolean => {
    if (searchQuery) {
      const appName = app.name.toLowerCase()
      const query = searchQuery.toLowerCase()
      return appName.indexOf(query) !== -1 || query.indexOf(appName) !== -1
    }
    return true
  }

  const filteredApps: IShowroomMiniApp[] = miniApps.filter(handleFilterByName)

  return (
    <MainPaneLayout
      title="My miniapps"
      description="Create miniapps for Boxo Platform"
    >
      {
        !!miniApps?.length &&
        <div className="mini-apps__search">
          <Search
            placeholder="Search"
            onChange={event => setSearchQuery(event.target.value)}
            onSearch={value => setSearchQuery(value)}
            allowClear
          />
        </div>
      }
      {isMiniAppsLoading ? <AppSkeleton /> : Boolean(filteredApps.length) && (
        <div className="cards-row">
          {filteredApps.map((app: IShowroomMiniApp) => (
            <AppCard
              key={app.id}
              imgUrl={app.logo}
              name={app.name}
              category={app.category?.name}
              onClick={() => handleOpenMiniapp(app)}
            />
          ))}
        </div>
      )}
      <div className='cards-row'>
        <InfoCard
          title='Integrate Boxo'
          content={<p>Install miniapp into your app using our SDK for iOS and Android.</p>}
          onClick={() => window.location.href = `${DOCS_URL}/miniapp/`}
        />
        <AccessControl permission={Permissions.CAN_EDIT}>
          <InfoCard
            title='Add miniapp'
            content={<p>Ready to add your miniapp? Follow the instructions.</p>}
            onClick={() => history.push(`${path}/new`)}
          />
        </AccessControl>
      </div>
    </MainPaneLayout>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    isMiniAppsLoading: isMiniAppsLoading(state),
    miniApps: miniAppsSelector(state),
  }
}

export default connect<IStateProps, {}>(mapStateToProps, {})(MiniApps)
