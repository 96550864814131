import { useEffect } from "react";
import { useQuery } from "./useQuery";

const dashboardLogoStorageKey = "dashboard-logo-storage-key";

export function useLogo() {
  const query = useQuery();

  useEffect(() => {
    if (query.logo) {
      localStorage.setItem(dashboardLogoStorageKey, query.logo || "");
    }
  }, [query.logo]);

  return query.logo || localStorage.getItem(dashboardLogoStorageKey);
}

