/*CORE*/
import React, { forwardRef, useImperativeHandle, useState } from 'react'
/*LIBS*/
import axios from 'axios'
import { Input } from 'antd'
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js'
/*CONSTANTS*/
import { API_BILLING_INTENT } from 'utils/constants'
/*STYLES*/
import styles from './PayManuallyCard.module.scss'

const BASE_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#E84060',
      iconColor: '#E84060',
    },
  },
}

interface Props {
  handleLoading: (state: boolean) => void
  handleConfirmCardSuccess: (cardId: string) => void
  handleConfirmCardError: (errorMessage: string) => void
}

const PaymentManuallyCard = forwardRef(({ handleConfirmCardSuccess, handleConfirmCardError, handleLoading }: Props, ref) => {
  const [name, setName] = useState('')
  const stripe = useStripe()
  const elements = useElements()

  useImperativeHandle(ref, () => ({
    submit() {
      handleSubmit()
    }
  }))

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return
    }

    handleLoading(true)

    try {
      const response = await axios.get(API_BILLING_INTENT)
      if (response.status === 200 && response.data.client_secret) {

        const result = await stripe.confirmCardSetup(response.data.client_secret, {
          payment_method: {
            card: elements.getElement(CardNumberElement)!,
            billing_details: {
              name,
            },
          }
        })

        if (result.error) {
          handleLoading(false)
          handleConfirmCardError(result?.error?.message || '')
        } else {
          handleLoading(false)
          !!result?.setupIntent?.payment_method ?
            handleConfirmCardSuccess(result.setupIntent.payment_method) :
            handleConfirmCardError('')
        }
      }
    } catch (error) {
      handleLoading(false)
      handleConfirmCardError(error?.message || '')
    }
  }

  const handleNameChange = (e: any) => {
    setName(e.target.value)
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles['new-payment-card-section']}>
        <div className={styles['new-payment-card-section__row']}>
          <div className={styles['new-payment-card-section__field']}>
            <CardNumberElement
              id="number"
              options={{ ...BASE_OPTIONS, placeholder: 'Card number' }}
            />
          </div>
        </div>
        <div className={styles['new-payment-card-section__row']}>
          <div className={styles['new-payment-card-section__field']}>
            <CardExpiryElement
              id="expiry"
              options={BASE_OPTIONS}
            />
          </div>
          <div className={styles['new-payment-card-section__field']}>
            <CardCvcElement
              id="cvc"
              options={BASE_OPTIONS}
            />
          </div>
        </div>
        <div className={styles['new-payment-card-section__row']}>
          <div className={styles['new-payment-card-section__field']}>
            <Input
              placeholder='Owner name'
              size="large"
              type="text"
              className={styles['owner-name']}
              onChange={handleNameChange}
            />
          </div>
        </div>
      </div>
    </form>
  )
})

export default PaymentManuallyCard
