/*CORE*/
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
/*LIBS*/
import Search from 'antd/lib/input/Search'
import { connect, ConnectedProps } from 'react-redux'
/*SELECTORS*/
import { showroomMiniAppsByCategoriesSelector, showroomMiniAppsSelector } from 'store/showroom/selectors'
import { miniappCountries as miniappCountriesSelector } from 'store/miniApps/selectors'
/*COMPONENTS*/
import AppDetails from './AppDetails/AppDetails'
import ShowroomCard from './ShowroomCard/ShowroomCard'
import { WithEmpty } from '../components/WithEmpty/WithEmpty'
import MainPaneLayoutRoute from '../components/MainPaneLayoutRoute'
import { ShowroomCategory } from './ShowroomCategory/ShowroomCategory'
import MainPaneLayout from '../components/MainPaneLayout/MainPaneLayout'
import ShowroomSkeletonLoader from './ShowroomSkeletonLoader/ShowroomSkeletonLoader'
import ShowroomCountriesFilter from './ShowroomCountriesFilter/ShowroomCountriesFilter'
import CustomSelect, { CustomSelectOption } from '../components/CustomSelect/CustomSelect'
/*TYPES*/
import { ICountry, IShowroomMiniApp, RootState } from 'types'
import { IMiniAppByCategory } from './types'
/*UTILS*/
import { sortBy } from 'utils/utils'
/*STYLES*/
import styles from './Showroom.module.scss'
import { config } from '../../common/Logo';
import { useLogo } from 'hooks/useLogo'

interface Props extends ConnectedProps<typeof connector> {
}

const DEFAULT_CATEGORY: Readonly<CustomSelectOption> = { label: 'All categories', value: 'All' } as const

const Showroom = ({ categories, miniApps, loading, miniAppsByCategories, miniappCountries }: Props) => {
  const [ activeCategoryFilter, setActiveCategoryFilter ] = useState(DEFAULT_CATEGORY)
  const [ checkedCountries, setCheckedCountries ] = useState<string[]>([])
  const [ searchQuery, setSearchQuery ] = useState('')
  const history = useHistory()
  const logo = useLogo();

  useEffect(() => {
    setCheckedCountries(getCountriesCodes(miniappCountries))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ miniappCountries ])

  const getCountriesCodes = (miniappCountries: ICountry[]) => miniappCountries.map(country => country.value)

  const handleFilterCategoryByCategory = (activeCategoryFilter: CustomSelectOption) => (miniApp: IMiniAppByCategory): boolean => {
    return activeCategoryFilter.value !== DEFAULT_CATEGORY.value ? activeCategoryFilter.value === miniApp.category.id : true
  }

  const isWorldwide = miniappCountries.length === checkedCountries.length

  const handleFilterMiniappsByCountries = (countriesSearchQuery: string[]) => (miniapp: IShowroomMiniApp): boolean =>
    isWorldwide ||
    !!miniapp.countries.find(country => countriesSearchQuery.find(searchQueryCountry => searchQueryCountry === country))

  const handleFilterMiniAppsByName = (searchQuery: string) => (miniApp: IShowroomMiniApp): boolean => {
    if (searchQuery) {
      const miniAppName = miniApp.name.toLowerCase()
      const query = searchQuery.toLowerCase()
      return miniAppName.indexOf(query) !== -1 || query.indexOf(miniAppName) !== -1
    }
    return true
  }

  const filteredMiniAppsByCategories: IMiniAppByCategory[] =
    miniAppsByCategories.filter(handleFilterCategoryByCategory(activeCategoryFilter))

  const filteredMiniApps: IShowroomMiniApp[] =
    miniApps
      .filter(handleFilterMiniappsByCountries(checkedCountries))
      .filter(handleFilterMiniAppsByName(searchQuery))
      .sort(sortBy('name'))

  const onCardClick = (id: number) => () => {
    history.push('/showroom/' + id)
  }

  const onChangeCountryFilter = (checkedCountries: string[]) => {
    setCheckedCountries(!!checkedCountries?.length ? checkedCountries : getCountriesCodes(miniappCountries))
  }

  const cards =
    <div className={styles['cards']}>
      {
        filteredMiniApps.map(miniApp =>
          <div className={styles['card']} key={miniApp.id} onClick={onCardClick(miniApp.id)}>
            <ShowroomCard miniApp={miniApp} />
          </div>
        )
      }
    </div>

  const cardsByCategory = filteredMiniAppsByCategories.map(app =>
    <ShowroomCategory
      isActiveCategoryFilter={activeCategoryFilter.value !== DEFAULT_CATEGORY.value}
      countriesSearchQuery={checkedCountries}
      isWorldwide={isWorldwide}
      searchQuery={searchQuery}
      key={app.category.id}
      app={app}
    />
  )

  const showCardsOutsideCategory = (!!searchQuery || !isWorldwide) && activeCategoryFilter.value === DEFAULT_CATEGORY.value

  return (
    <>
      <MainPaneLayoutRoute path={'/showroom/:appId'}>
        <AppDetails />
      </MainPaneLayoutRoute>
      <MainPaneLayout
        title={logo ? `${config[logo]?.title} Feature Library` : 'Showroom'}
        description="Browse available Boxo miniapps and integrate them within minutes"
      >
        <>
          <div className={styles['showroom__filter']}>
            <Search
              onChange={event => setSearchQuery(event.target.value)}
              onSearch={value => setSearchQuery(value)}
              className={styles['showroom__search']}
              placeholder="Search"
              allowClear
            />
            <div style={{ display: 'flex' }}>
              <ShowroomCountriesFilter checkedCountries={checkedCountries} onChange={onChangeCountryFilter} />
              <CustomSelect
                options={categories}
                activeOption={activeCategoryFilter}
                className={styles['showroom__select']}
                onClickOption={option => setActiveCategoryFilter(option)}
              />
            </div>
          </div>

          {
            loading ?
              <ShowroomSkeletonLoader className={styles['showroom__loader']} /> :
              <div className={styles['showroom__apps']}>
                {
                  !!searchQuery && activeCategoryFilter.value === DEFAULT_CATEGORY.value &&
                  <h2 className={styles['search-results-title']}>Results for "{searchQuery}"</h2>
                }
                <WithEmpty>
                  {
                    showCardsOutsideCategory ? !!filteredMiniApps?.length && cards : !!filteredMiniAppsByCategories?.length && cardsByCategory
                  }
                </WithEmpty>
              </div>
          }

        </>
      </MainPaneLayout>
    </>
  )
}

const mapStateToProps = (state: RootState) => {

  const showroomMiniAppsByCategories = showroomMiniAppsByCategoriesSelector(state)

  const categories = [
    DEFAULT_CATEGORY,
    ...showroomMiniAppsByCategories.miniApps
      .map((miniApp: IMiniAppByCategory) => ({ label: miniApp.category.name, value: miniApp.category.id }))
      .sort(sortBy('label'))
  ]

  return {
    categories,
    loading: showroomMiniAppsByCategories.loading,
    miniApps: showroomMiniAppsSelector(state)?.data,
    miniappCountries: miniappCountriesSelector(state),
    miniAppsByCategories: showroomMiniAppsByCategories.miniApps,
  }
}

const connector = connect(mapStateToProps)

export default connector(Showroom)
