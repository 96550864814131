/*CORE*/
import React from 'react'
/*COMPONENTS*/
import SinglePageLayout from '../Dashboard/components/SinglePageLayout/SinglePageLayout'

const RegistrationTerms = () => (
    <SinglePageLayout>
      <h1>Boxo user agreement</h1>
      <p>
        This User Agreement sets out the terms between you and Appboxo Pte. Ltd. (BOXO) under which you may
        access the intranet and software platform operated by BOXO (the “Interface”). This User Agreement applies
        to all users of the Interface (each a “User” or “You”, collectively “Users”) and constitutes a legally binding
        agreement between each User individually and BOXO. If you, the User do not accept the terms of this User
        Agreement, you cannot use the Interface.
      </p>
      <p>
        By clicking the ‘Step Two’ button during the registration process you accept the terms and conditions
        of this User Agreement.
      </p>
      <h2>Your user account</h2>
      <ul>
        <li>
          When you register on the Website you will be allocated a personal account on the Interface, which you can
          access by entering your username and password (“User Account”).
        </li>
        <li>
          The User Account is for a single User only. Boxo will not permit you to share your username and password
          with any other person nor with multiple Users on a network. Responsibility for the security and
          confidentiality of any passwords issued rests with you. Boxo will not be liable for any losses or
          damages suffered by you due to the disclosure of any User Account passwords.
        </li>
        <li>
          All User Accounts must be registered with your own name and a valid personal email address that you access
          regularly so that moderation emails can be sent to you. User Accounts registered with someone else's email
          address, or with temporary email addresses, may be closed without notice. Boxo may require Users to
          revalidate their User Account if Boxo believes they have been using an invalid email address.
        </li>
        <li>
          You will be responsible and liable for all activities occurring under your User Account. If you suspect that
          a third party has gained unauthorised access to access data, you must inform DWL immediately by sending an
          e-mail to support@boxo.io or such other e-mail address as may be notified to you from time to time.
        </li>
      </ul>
      <h2>Provision and use of services and the interface</h2>
      <ul>
        <li>
          For the duration of this User Agreement Boxo grants the User the revocable, non-exclusive,
          non-transferable and non-sub-licensable right to use the Interface for own business purposes in accordance
          with this User Agreement and the respective description of services.
        </li>
        <li>
          The Interface may from time to time provide certain services which enable Users to submit information,
          statements, content, documents, ideas, concepts, techniques or data (“Content”) to the Interface
          (“Interactive Services”).
        </li>
        <li>
          Boxo is under no obligation to oversee, monitor or moderate any Interactive Services Boxo provides on
          the Interface, and Boxo expressly excludes liability for any loss or damage arising from the use of any
          Interactive Services by a User in contravention of Boxo’s content standards, whether the service is
          moderated or not.
        </li>
        <li>
          BOXO has no obligation to verify the identity of any Users when they are connected to the Interface or
          to supervise the Content which has been provided by Users.
        </li>
        <li>
          Although some Interactive Services may allow you to choose to hide Content you submit from certain classes
          of other Users ("Privacy Settings"), BOXO cannot guarantee that any of these Privacy Settings will be
          effective or that Content subject to these Privacy Settings will not be made generally and publicly available
          by other Users or by third parties. Boxo will however, use commercially reasonable efforts to control the
          display of Content that is restricted by the Privacy Settings to other Users in accordance with the Privacy
          Settings you have chosen.
        </li>
        <li>
          If you submit Content Boxo cannot guarantee that other Users of the Interface will not use the Content you
          have submitted. Therefore, if you have Content that you would like to keep confidential and/or do not want
          others to use, do not submit it to an Interactive Service or to any other part of the Interface. Boxo is
          not responsible for the misuse or misappropriation by other Users of the Interface of any Content submitted
          by you to an Interactive Service.
        </li>
        <li>
          Please note that certain Content which you may choose to submit to Boxo’s Interactive Services or to any
          other part of this Interface may reveal personal information about you (“Personal Data”). Any Personal Data
          is provided voluntarily and with your consent for its use, storage, disclosure and possible transfer out of
          the Singapore.
        </li>
        <li>
          Prior to submitting Content to the Interface, you must consider and decide, yourself, the extent to which
          you wish to reveal Personal Data about yourself to the large community of other Users of this Interface,
          and to Boxo, and you must not communicate any Content which could be harmful to you if disclosed,
          processed or published in any way. Any Personal Data provided by you is given at your own discretion for
          all purposes and without expectation of protection or privacy.
        </li>
      </ul>
      <h2>Prohibited uses</h2>
      <p>This Interface is to be used for lawful purposes only. You may not use the Interface:</p>
      <ul>
        <li>
          if you are under the age of 18;
        </li>
        <li>
          in any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect or that
          breaches any applicable local, national or international law or regulation;
        </li>
        <li>
          to transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional material
          or any other form of solicitation, marketing, transaction, video, photo, sound, notice or advice;
        </li>
        <li>
          to knowingly transmit any data, send or upload any Content that contains viruses, Trojan horses, worms,
          time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code
          designed to adversely affect the operation of any computer software or hardware;
        </li>
        <li>
          to reproduce, duplicate, copy or re-sell any part of the Interface in contravention of the terms of this
          User Agreement; and
        </li>
        <li>
          to interfere with, cause damage or disrupt:
          <ul>
            <li>any part of the Interface;</li>
            <li>any equipment or network on which the Interface is stored;</li>
            <li>any software used in the provision of the Interface; or</li>
            <li>any equipment or network or software owned or used by any third party.</li>
          </ul>
        </li>
      </ul>
      <h2>Termination and further action</h2>
      <ul>
        <li>
          Either party may terminate the User Agreement at any time. Termination of the User Agreement shall
          automatically result in the withdrawal of all authorizations and rights of use of the User as well as
          the access to the Interface.
        </li>
        <li>
          In addition to termination Boxo reserves the right to take further legal action if Boxo determines,
          at its sole discretion whether, there has been a breach of this User Agreement. Boxo may take all or
          any of the following actions, with or without a warning to you:
          <ul>
            <li>immediate temporary or permanent withdrawal of your right to access and use the Interface;</li>
            <li>immediate temporary or permanent removal of any posting or material uploaded by you to the Interface;</li>
            <li>legal proceedings against you for reimbursement of all costs on an indemnity basis (including, but not limited to, reasonable administrative and legal costs) resulting from the breach; and</li>
            <li>disclosure of such information to law enforcement authorities or other Users, Advertisers or Publishers as BOXO reasonably feels is necessary.</li>
          </ul>
        </li>
      </ul>
      <h2>Reservation of rights</h2>
      <ul>
        <li>
          Boxo may add to, change, suspend or discontinue any aspect of the Interface or this User Agreement at any
          time by publishing such modifications on the Interface.
        </li>
        <li>
          It is the Users obligation and responsibility to periodically review the User Agreement on the Interface in
          order to remain aware of any changes to the User Agreement.
        </li>
      </ul>
      <h2>General</h2>
      <ul>
        <li>
          Boxo may assign or otherwise transfer any of its rights, benefits and duties under this User Agreement.
          Users may not transfer any of its rights, benefits or duties under this User Agreement without Boxo’s
          prior written consent.
        </li>
        <li>
          This User Agreement constitutes the entire agreement between the parties and supersedes all previous
          agreements between the parties relating to its subject matter. No other representation or statement, whether
          or not in writing shall form a term of the User Agreement.
        </li>
        <li>
          This User Agreement is governed by the law of Singapore and the courts of Singapore have exclusive
          jurisdiction.
        </li>
      </ul>
    </SinglePageLayout>
)

export default RegistrationTerms
