/*CORE*/
import React from 'react'
/*LIBS*/
import { Typography } from 'antd'
/*COMPONENTS*/
import LaunchesPaymentTable from '../LaunchesPaymentTable/LaunchesPaymentTable'
import TransactionCommissionTable from '../TransactionCommissionTable/TransactionCommissionTable'
/*UTILS*/
import { AppPlanType } from 'utils/constants'
/*TYPES*/
import { IAppPlan, PaymentType } from 'types/Billing'

const { Title } = Typography

type Props = {
  appPlans: IAppPlan[]
}

const PaymentInfoMultiCurrency = ({ appPlans }: Props) => {
  if (appPlans.length === 0) {
    return null
  }

  if (appPlans[0].plan_type === AppPlanType.Launches) {
    return (
      <>
        <Title level={3}>
          {appPlans[0].billing_type === PaymentType.Billing ? 'Charges' : 'Earnings'} for launches
        </Title>
        <LaunchesPaymentTable appPlan={appPlans[0]} />
      </>
    )
  } else if (appPlans[0].multi_currency_sales.length > 0) {
    return (
      <>
        <Title level={3}>Commission for transactions</Title>
        <TransactionCommissionTable appPlan={appPlans[0]} estimation={false} />
      </>
    )
  }

  return null
}

export default PaymentInfoMultiCurrency
