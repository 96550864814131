/*CORE*/
import React from 'react'
/*UTILS*/
import { formatAmount } from 'utils/common'
/*TYPES*/
import { ICurrency } from 'types/Payouts'


type Props = {
  amount: number | string
  currency: ICurrency
  className: string
}
const MoneyWrapper = ({ amount, currency, className, ...props }: Props) => (
  <span className={className} {...props}>{formatAmount(amount)} {currency.code}</span>
)

MoneyWrapper.defaultProps = {
  className: '',
  props: {}
}

export default MoneyWrapper
