/*CORE*/
import React from 'react'
import { Route } from 'react-router-dom'
/*COMPONENTS*/
import Portal from './Portal'
/*UTILS*/
import { NESTED_ROUTES_PORTAL_TARGET_ID } from 'utils/constants'

interface Props {
  path: string
  children: React.ReactNode
}

const MainPaneLayoutRoute = ({ path, children }: Props) =>
  <Route path={path} render={() => <Portal id={NESTED_ROUTES_PORTAL_TARGET_ID}>{children}</Portal>} />

export default MainPaneLayoutRoute
