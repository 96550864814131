/*CORE*/
import React, { useState } from 'react'
/*ASSETS*/
import { ReactComponent as CopyIcon } from 'assets/img/copy.svg'
/*LIBS*/
import Clipboard from 'react-clipboard.js'
import { Tooltip } from 'antd'
/*STYLES*/
import styles from './AppIdHeader.module.scss'

const TOOLTIP_DELAY = 2000 as const

interface Props {
  appId: string
  description: string
}

const AppIdHeader = ({ appId, description }: Props) => {
  const [ isShowTooltip, setIsShowTooltip ] = useState(false)

  function triggerTooltip() {
    setIsShowTooltip(true)
    setTimeout(() => setIsShowTooltip(false), TOOLTIP_DELAY)
  }

  return (
    <div className={styles['app-id-header']}>
      <div className={styles['app-id']}>
        <span className={styles['app-id__description']}>{description}</span>

        <Tooltip
          placement='top'
          title='Copied'
          visible={isShowTooltip}
        >
          <Clipboard data-clipboard-text={appId} component='div' onSuccess={triggerTooltip}>
            <div className={styles['app-id__content']}>
              <span>{appId}</span>
              <CopyIcon />
            </div>
          </Clipboard>
        </Tooltip>

      </div>
    </div>
  )
}

export default AppIdHeader
