/*CORE*/
import React from 'react'
/*LIBS*/
import { connect } from 'react-redux'
/*SELECTORS*/
import { appboxoBankDetailsSelector } from 'store/billing/selectors'
/*TYPES*/
import { RootState } from 'types'
import { IBankAccount } from 'types/Payouts'
/*STYLES*/
import styles from './WireTransferInfo.module.scss'

interface Props {
  refCode: string
  appboxoBankDetails: IBankAccount | null
}

const WireTransferInfo = ({ refCode, appboxoBankDetails }: Props) => (
  <div>
    <div className={styles['wire-transfer-desc']}>
      Use the following bank details to make a transfer. After you have completed the transfer, please,
      send us a copy of the payment slip for our reference.
    </div>
    <div className={styles['wire-transfer-grid']}>
      <div>Destination:</div>         <div>{appboxoBankDetails?.account_holder_name}</div>
      <div>Bank:</div>                <div>{appboxoBankDetails?.bank_name}</div>
      <div>Account No:</div>          <div>{appboxoBankDetails?.account_number}</div>
      <div>SWIFT/Address/Code:</div>  <div>{appboxoBankDetails?.swift_bic}</div>
      <div>Country:</div>             <div>Singapore</div>
      <div>Address:</div>             <div>{appboxoBankDetails?.bank_address}</div>
      <div>Ref code:</div>            <div>{ refCode }</div>
      <div>Email:</div>               <div>{appboxoBankDetails?.email}</div>
    </div>
  </div>
)

WireTransferInfo.defaultProps = {
  refCode: 'INV-xxxxx'
}

const mapStateToProps = (state: RootState) => {
  return {
    appboxoBankDetails: appboxoBankDetailsSelector(state)
  }
}

export default connect(mapStateToProps, {})(WireTransferInfo)
