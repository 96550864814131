/*CORE*/
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
/*LIBS*/
import { connect } from 'react-redux'
import Search from 'antd/lib/input/Search'
/*COMPONENTS*/
import AppSkeleton from '../components/Skeletons/AppSkeleton'
import PartnershipsCard from './PartnershipsCard/PartnershipsCard'
import MainPaneLayout from '../components/MainPaneLayout/MainPaneLayout'
import NoDataPlaceholder from '../components/NoDataPlaceholder/NoDataPlaceholder'
import CustomSelect, { CustomSelectOption } from '../components/CustomSelect/CustomSelect'
/*SELECTORS*/
import { partnershipsSelector, isPartnershipsLoading } from 'store/partnerships/selectors'
/*CONSTANTS*/
import { PartnershipStatuses } from './enums'
/*TYPES*/
import { IPartnership } from 'types/Partnerships'
import { RootState } from 'types'
/*ASSETS*/
import placeholder from 'assets/img/no-data-placeholers/placeholder_partnership.png'
/*STYLES*/
import styles from './Partnerships.module.scss'

const STATUSES: CustomSelectOption[] = [
  { label: 'All partnerships', value: 'All' },
  { label: 'Pending', value: PartnershipStatuses.Pending },
  { label: 'In Testing', value: PartnershipStatuses.InTesting },
  { label: 'Accepted', value: PartnershipStatuses.Accepted },
  { label: 'Rejected', value: PartnershipStatuses.Rejected },
]

interface IStateProps {
  partnerships: IPartnership[]
  isLoading: boolean
}

type Props = IStateProps

const Partnerships = ({ partnerships, isLoading }: Props) => {
  const history = useHistory()
  const [ searchQuery, setSearchQuery ] = useState('')
  const [ activeStatusFilter, setActiveStatusFilter ] = useState(STATUSES[0])

  const openDetails = (partnership: IPartnership) => () => {
    history.push('partnerships/details/' + partnership.id)
  }

  const handleFilterByStatus = (partnership: IPartnership): boolean => {
    return activeStatusFilter.value === STATUSES[0].value ? true : activeStatusFilter.value === partnership.status;
  }

  const handleFilterByName = (partnership: IPartnership): boolean => {
    if (searchQuery) {
      const hostAppName = partnership.hostapp.name.toLowerCase()
      const miniAppName = partnership.miniapp.name.toLowerCase()

      const query = searchQuery.toLowerCase()

      return filterByName(hostAppName, query) || filterByName(miniAppName, query)
    }

    return true
  }

  const filterByName = (name: string, query: string) => name.indexOf(query) !== -1 || query.indexOf(name) !== -1

  const filteredPartnerships: IPartnership[] = partnerships.filter(handleFilterByStatus).filter(handleFilterByName)

  return (
    <MainPaneLayout
      title="My partnerships"
      description="Browse all your miniapp and hostapp integrations"
    >
      <div className={styles['partnerships']}>

        <div className={styles['header']}>
          <div className={styles['search']}>
            <Search
              allowClear
              placeholder="Search for an app"
              onSearch={value => setSearchQuery(value)}
              onChange={event => setSearchQuery(event.target.value)}
            />
          </div>
          <CustomSelect options={STATUSES} activeOption={activeStatusFilter} onClickOption={status => setActiveStatusFilter(status)} />
        </div>
        {
          isLoading ?
            <AppSkeleton />
            :
            !!filteredPartnerships?.length ?
              <div className={styles['cards']}>
                {
                  filteredPartnerships.map(partnership =>
                    <div onClick={openDetails(partnership)} key={partnership.id}>
                      <PartnershipsCard
                        status={partnership.status}
                        hostAppName={partnership.hostapp.name}
                        hostAppLogo={partnership.hostapp.logo}
                        miniAppName={partnership.miniapp.name}
                        miniAppLogo={partnership.miniapp.logo}
                      />
                    </div>
                  )
                }
              </div>
              :
              <div className={styles['nodata-placeholder-wrapper']}>
                <NoDataPlaceholder
                  text='Miniapp and host app integrations and their statuses.'
                  img={placeholder}
                  height={'158px'}
                />
              </div>
        }
      </div>
    </MainPaneLayout>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    partnerships: partnershipsSelector(state),
    isLoading: isPartnershipsLoading(state),
  }
}

// @ts-expect-error
export default connect<IStateProps, {}>(mapStateToProps, {})(Partnerships)
