/*CORE*/
import React  from 'react'
/*LIBS*/
import { Button } from 'antd'
/*COMPONENTS*/
import EmergencyBackupCode from '../../../components/EmergencyBackupCode'


type Props = {
  handleContinue: () => void
  emergencyCode: string
}

const SetupSuccess = ({ handleContinue, emergencyCode }: Props) => {
  return (
    <>
      <header>
        <h1>Successfully enabled</h1>
        <p>
          Save this emergency backup code and store it somewhere safe. If you lose your mobile device,
          you can use this code to bypass two-step authentication and sign in.
        </p>
      </header>
      <EmergencyBackupCode emergencyCode={emergencyCode} />
      <div>
        <Button
          block
          type='primary'
          onClick={handleContinue}
        >
          Continue
        </Button>
      </div>
    </>
  )
}


export default SetupSuccess
