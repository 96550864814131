import { createSelector } from 'reselect'

const profileStateSelector = state => state.profile

export const isProfileInitialLoad = createSelector(
  profileStateSelector,
  profileState => profileState.isInitialLoad
)

export const profileSelector = createSelector(
  profileStateSelector,
  profileState => profileState.profile
)

export const profileRoleSelector = createSelector(
  profileStateSelector,
  profileState => profileState.profile.role
)