/*LIBS*/
import axios from 'axios'
import moment from 'moment'
/*UTILS*/
import { API_HOSTAPP_METRICS, API_MINIAPP_METRICS } from 'utils/constants'

export function getAppMetrics(appId: string, isMiniApp = true) {
  let typeKey = isMiniApp ? 'miniapp_id' : 'hostapp_id'
  const metricsUrl = isMiniApp ? API_MINIAPP_METRICS : API_HOSTAPP_METRICS
  return function () {
    return axios.get(metricsUrl, { params: { [`${typeKey}`]: appId } })
  }
}

export function getAppMetricsByRangeDate(
  appId: number | string,
  [ fromDate, toDate ]: [ moment.Moment, moment.Moment ],
  isMiniApp = true
) {
  let typeKey = isMiniApp ? 'miniapp_id' : 'hostapp_id'
  const metricsUrl = isMiniApp ? API_MINIAPP_METRICS : API_HOSTAPP_METRICS
  const from_date = moment(fromDate).format('YYYY-MM-DD')
  const to_date = moment(toDate).format('YYYY-MM-DD')
  return function () {
    return axios.get(metricsUrl, { params: { [`${typeKey}`]: appId, from_date, to_date } })
  }
}

export function getPartnershipMetricsByRangeDate(
  miniapp_id: number | string,
  hostapp_id: number | string,
  [ fromDate, toDate ]: [ moment.Moment, moment.Moment ],
  isMiniApp = true
) {
  const metricsUrl = isMiniApp ? API_MINIAPP_METRICS : API_HOSTAPP_METRICS
  const from_date = moment(fromDate).format('YYYY-MM-DD')
  const to_date = moment(toDate).format('YYYY-MM-DD')
  return function () {
    return axios.get(metricsUrl, { params: { miniapp_id, hostapp_id, from_date, to_date } })
  }
}
