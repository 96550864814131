/*CORE*/
import React from 'react'
import usePortal from 'hooks/usePortal'
import { createPortal } from 'react-dom'

/**
 * @example
 * <Portal>
 *   <p>Thinking with portals</p>
 * </Portal>
 */

interface Props {
  id: string
  children: React.ReactNode
}

const Portal = ({ id, children }: Props) => {
  React.useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'initial'
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const target = usePortal(id)
  target.classList.add('full-page-pane')
  return createPortal(
    children,
    target,
  )
}

export default Portal
