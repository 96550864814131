/*CORE*/
import React, { useCallback, useMemo } from 'react'
/*LIBS*/
import uuid from 'uuid/v4'
import { Route, Switch as RouterSwitch } from 'react-router-dom'
/*CONSTANTS*/
import { DOCS_URL } from './utils/constants'
/*GUARDS*/
import PrivateRoute from './guards/PrivateRoute'
import PublicNotAuthedRoute from './guards/PublicNotAuthedRoute'
import AdminRoute from './guards/AdminRoute'
/*PAGES*/
import Login from './pages/Auth/Login'
import Recover from './pages/Auth/Recover'
import SetNewPassword from './pages/Auth/SetNewPassword'
import DashboardLayout from './pages/Dashboard/components/DashboardLayout/DashboardLayout'
import Home from './pages/Dashboard/Home/Home'
import SuperApps from './pages/Dashboard/SuperApps/SuperApps'
import Showroom from './pages/Dashboard/Showroom/Showroom'
import CreateMiniApp from './pages/Dashboard/MiniApps/CreateMiniApp/CreateMiniApp'
import MiniAppDetails from './pages/Dashboard/MiniApps/MiniAppDetails/MiniAppDetails'
import CreateSuperApp from './pages/Dashboard/SuperApps/CreateSuperApp/CreateSuperApp'
import SuperAppDetails from './pages/Dashboard/SuperApps/SuperAppDetails'
import Terms from './pages/Legal/Terms'
import Privacy from './pages/Legal/Privacy'
import Profile from './pages/Dashboard/Profile/ProfileDetails/ProfileDetails'
import NotFound from './pages/NotFound/NotFound'
import MiniApps from './pages/Dashboard/MiniApps/MiniApps'
import PaymentCardDetails from './pages/Dashboard/Billing/PaymentCardDetails/PaymentCardDetails'
import NewPaymentCard from './pages/Dashboard/Billing/NewPaymentCard/NewPaymentCard'
import Join from './pages/Auth/Join'
import ChangePassword from './pages/Dashboard/Profile/ChangePassword/ChangePassword'
import Team from './pages/Dashboard/Team/Team'
import Billing from './pages/Dashboard/Billing/Billing/Billing'
import Payouts from './pages/Dashboard/Payouts/Payouts/Payouts'
import AddBankAccount from './pages/Dashboard/Payouts/BankAccount/AddBankAccount/AddBankAccount'
import AppIntegration from './pages/Dashboard/Showroom/AppIntegration/AppIntegration'
import OrganizationDetailsForm from './pages/Dashboard/Team/components/OrganizationDetailsForm'
import MiniAppBillingInfo from './pages/Dashboard/MiniApps/MiniAppBillingInfo'
import SuperAppBillingInfo from './pages/Dashboard/SuperApps/SuperAppBillingInfo'
import EditBankAccount from './pages/Dashboard/Payouts/BankAccount/EditBankAccount/EditBankAccount'
import MiniAppPayoutInfo from './pages/Dashboard/MiniApps/MiniAppPayoutInfo'
import SuperappPayoutInfo from './pages/Dashboard/SuperApps/SuperAppPayoutInfo'
import PartnershipDetails from './pages/Dashboard/Partnerships/PartnershipDetails/PartnershipDetails'
import Partnerships from './pages/Dashboard/Partnerships/Partnerships'
import CategoryDetails from './pages/Dashboard/Showroom/CategoryDetails/CategoryDetails'
import SetupTwoFactorAuth from './pages/Auth/SetupTwoFactorAuth/SetupTwoFactorAuth'
import DisableTwoFactorAuth from './pages/Auth/DisableTwoFactorAuth/DisableTwoFactorAuth'
import TurnOffTwoFactorAuth from './pages/Auth/TurnOffTwoFactorAuth/TurnOffTwoFactorAuth'
import RegenerateBackupCode from './pages/Auth/RegenerateBackupCode/RegenerateBackupCode'
import Signup from './pages/Auth/Signup'
import RegistrationTerms from './pages/Legal/RegistrationTerms'
import AppInternalAnalytics from './pages/Dashboard/Analytics/AppInternalAnalytics/AppInternalAnalytics'
import AppPlanDetails from './pages/Dashboard/Payment/AppPlanDetails/AppPlanDetails'
import { config } from './pages/common/Logo';
import { useLogo } from 'hooks/useLogo'

export interface RouteData {
  label?: string
  navKey?: string
  path: string
  exact?: boolean
  isExternalUrl?: boolean
  adminOnly?: boolean
  search?: string
  component: React.FC
}

const RootRouter = () => {
  const logo = useLogo();
  const DASHBOARD_MENU_ITEMS: RouteData[] = useMemo(() => {
    return [
      {
        label: 'Dashboard',
        navKey: uuid(),
        path: '/',
        exact: true,
        isExternalUrl: false,
        component: Home,
      },
      {
        label: 'My miniapps',
        navKey: uuid(),
        path: '/miniapps',
        exact: false,
        isExternalUrl: false,
        component: MiniApps,
      },
      {
        label: 'My host apps',
        navKey: uuid(),
        path: '/host-apps',
        exact: false,
        isExternalUrl: false,
        component: SuperApps,
      },
      {
        label: 'My partnerships',
        navKey: uuid(),
        path: '/partnerships',
        exact: false,
        isExternalUrl: false,
        component: Partnerships,
      },
      {
        label: logo ? `${config[logo]?.title} Feature Library` : 'Showroom',
        navKey: uuid(),
        path: '/showroom',
        search: logo ? `?logo=${logo}` : '',
        exact: false,
        isExternalUrl: false,
        component: Showroom,
      },
      {
        label: 'Documentation',
        navKey: uuid(),
        path: DOCS_URL as string,
        exact: true,
        isExternalUrl: true,
        component: () => null,
      },
    ]
  }, [logo])

  const getDashboardLayout = useCallback(
    (menuItem: RouteData) => {
      const DashboardRoute = menuItem.adminOnly ? AdminRoute : PrivateRoute

      return (
        <DashboardRoute
          key={menuItem.navKey}
          exact={menuItem.exact}
          path={menuItem.path}
        >
          <DashboardLayout
            menuItems={DASHBOARD_MENU_ITEMS}
            selectedKeys={[menuItem.navKey as string]}
          >
            <menuItem.component />
          </DashboardLayout>
        </DashboardRoute>
      )
    },
    [DASHBOARD_MENU_ITEMS]
  )

  return (
    <RouterSwitch>
      <PublicNotAuthedRoute path="/login">
        <Login />
      </PublicNotAuthedRoute>
      <PublicNotAuthedRoute path="/recover">
        <Recover />
      </PublicNotAuthedRoute>
      <PublicNotAuthedRoute path="/signup">
        <Join />
      </PublicNotAuthedRoute>
      <PublicNotAuthedRoute path="/join">
        <Signup />
      </PublicNotAuthedRoute>
      <PublicNotAuthedRoute path="/recover-password">
        <SetNewPassword />
      </PublicNotAuthedRoute>
      <PublicNotAuthedRoute path="/registration-terms">
        <RegistrationTerms />
      </PublicNotAuthedRoute>
      <PrivateRoute path="/miniapps/new">
        <CreateMiniApp />
      </PrivateRoute>
      <PrivateRoute path="/miniapps/:appId">
        <MiniAppDetails />
      </PrivateRoute>
      <PrivateRoute path="/host-apps/new">
        <CreateSuperApp />
      </PrivateRoute>
      <PrivateRoute path="/partnerships/details/:partnershipId">
        <PartnershipDetails />
      </PrivateRoute>
      <PrivateRoute path="/partnership-analytics/:partnershipId">
        <AppInternalAnalytics />
      </PrivateRoute>
      <PrivateRoute path="/app-plan-details">
        <AppPlanDetails />
      </PrivateRoute>
      <AdminRoute path="/payment-card/new">
        <NewPaymentCard />
      </AdminRoute>
      <AdminRoute path="/payment-card/:cardId">
        <PaymentCardDetails />
      </AdminRoute>
      <PrivateRoute path="/host-apps/:appId">
        <SuperAppDetails />
      </PrivateRoute>
      <PrivateRoute path="/showroom/category/:category">
        <CategoryDetails />
      </PrivateRoute>
      <PrivateRoute path="/showroom/integrate">
        <AppIntegration />
      </PrivateRoute>

      {DASHBOARD_MENU_ITEMS.map(getDashboardLayout)}

      <PrivateRoute path="/terms">
        <Terms />
      </PrivateRoute>
      <Route path="/privacy">
        <Privacy />
      </Route>
      <PrivateRoute path="/account">
        <Profile />
      </PrivateRoute>
      <PrivateRoute path="/setup-2fa">
        <SetupTwoFactorAuth />
      </PrivateRoute>
      <PrivateRoute path="/regenerate-2fa">
        <RegenerateBackupCode />
      </PrivateRoute>
      <PrivateRoute path="/turn-off-2fa">
        <TurnOffTwoFactorAuth />
      </PrivateRoute>
      <PublicNotAuthedRoute path="/disable-2fa">
        <DisableTwoFactorAuth />
      </PublicNotAuthedRoute>
      <PrivateRoute path="/change-password">
        <ChangePassword />
      </PrivateRoute>
      <AdminRoute path="/bank-account/new">
        <AddBankAccount />
      </AdminRoute>
      <AdminRoute path="/bank-account/:bankAccountId">
        <EditBankAccount />
      </AdminRoute>
      <PrivateRoute path="/organization">
        <OrganizationDetailsForm />
      </PrivateRoute>
      <AdminRoute path="/miniapp-billing-info/:invoiceId">
        <MiniAppBillingInfo />
      </AdminRoute>
      <AdminRoute path="/superapp-billing-info/:invoiceId">
        <SuperAppBillingInfo />
      </AdminRoute>
      <AdminRoute path="/miniapp-payout-info/:payoutId">
        <MiniAppPayoutInfo />
      </AdminRoute>
      <AdminRoute path="/superapp-payout-info/:payoutId">
        <SuperappPayoutInfo />
      </AdminRoute>
      {getDashboardLayout({
        path: '/team',
        component: Team,
      })}
      {getDashboardLayout({
        path: '/billing',
        component: Billing,
        adminOnly: true,
      })}
      {getDashboardLayout({
        path: '/payouts',
        component: Payouts,
        adminOnly: true,
      })}
      <Route path="*">
        <NotFound />
      </Route>
    </RouterSwitch>
  )
}

export default RootRouter
