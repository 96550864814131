/*CORE*/
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
/*LIBS*/
import { bindActionCreators, Dispatch } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
/*ACTIONS*/
import { recover as recoverAction } from 'store/auth/actions'
/*COMPONENTS*/
import AuthLayout from './components/AuthLayout/AuthLayout'
import RecoverForm from './components/RecoverForm/RecoverForm'
/*UTILS*/
import { goBack } from 'utils/utils'

interface Props extends ConnectedProps<typeof connector> {
}

const Recover = ({ recover }: Props) => {
  const [ isEmailSent, setIsEmailSent ] = useState(false)
  const [ isLoading, setIsLoading ] = useState(false)
  const [ errors, setErrors ] = useState<string[]>([])
  const [ email, setEmail ] = useState('')
  const history = useHistory()

  const handleGoBack = () => {
    goBack(history, '/login')
  }

  const handleSubmit = async ({ email }: { [Key: string]: any }) => {
    setIsLoading(true)

    try {
      await recover({
        email: email
      })
      setIsEmailSent(true)
      setEmail(email)
    } catch (error) {
      if (error.response.data && error.response.data.email) {
        setErrors(error.response.data.email)
      } else {
        console.error('Undefined error: ', error)
      }
    }

    setIsLoading(false)
  }

  return (
    <AuthLayout
      onGoBack={handleGoBack}
      title='Recover password'
      subtitle={isEmailSent
        ? `Password recovery instructions sent to ${email}.`
        : "Enter the email address associated with your account and we'll send you a link to reset your password."
      }
      hideLogo={true}
    >
      {
        !isEmailSent &&
          <RecoverForm
            onSubmit={handleSubmit}
            errors={errors}
            isLoading={isLoading}
          />
      }
    </AuthLayout>
  )
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators({
      recover: recoverAction
    }, dispatch),
  }
}

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
)
export default connector(Recover)
