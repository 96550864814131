/*CORE*/
import React from 'react'
/*COMPONENTS*/
import MoneyWrapper from '../../../common/MoneyWrapper'
import InfoTooltip from '../../../common/InfoTooltip'
/*UTILS*/
import { formatAmount } from 'utils/common'
/*TYPES*/
import { IAppPlan } from 'types/Billing'
/*STYLES*/
import styles from './TransactionCommissionTable.module.scss'

type Props = {
  appPlan: IAppPlan
  estimation: boolean
}

const TransactionCommissionTable = ({ appPlan, estimation }: Props) => {
  return (
    <table className={styles['transaction-commission-table']}>
      <thead>
      <tr>
        <th>Currency</th>
        <th>Sales / GMV</th>
        <th>Commission rate</th>
        <th>Commission</th>
        <th>Exchange rate</th>
        <th>Amount {appPlan.currency.code}</th>
      </tr>
      </thead>
      <tbody>
      {appPlan.multi_currency_sales.map((salesData, i) => (
        <tr key={i}>
          <td>
            {salesData.currency_name}
          </td>
          <td>
            {formatAmount(salesData.sales)} {salesData.currency_code}
          </td>
          <td>
            {formatAmount(salesData.commission_rate)}%
          </td>
          <td>
            {formatAmount(salesData.commission)} {salesData.currency_code}
          </td>
          <td>
            {formatAmount(salesData.exchange_rate)} {salesData.currency_code}
          </td>
          <td>
            {formatAmount(salesData.amount)} {appPlan.currency.code}
          </td>
        </tr>
      ))}
      <tr key='total'>
        <td />
        <td />
        <td />
        <td />
        <td>
          {estimation ? (
            <div className={styles['estimation']}>
              <span>Estimated total</span>
              <InfoTooltip
                overlayClassName={styles['estimation__tooltip']}
                overlay={
                  <span>
                    Estimated amount in {appPlan.currency.code} based on currency rate taken from <a
                    href='https://openexchangerates.org/' target='_blank' rel='noopener noreferrer'>
                    openexchangerates.org</a>
                  </span>
                }
              />
            </div>
          ) : (
            <span>Total {appPlan.currency.code}:</span>
          )}
        </td>
        <td>
          <MoneyWrapper currency={appPlan.currency} amount={appPlan.amount} />
        </td>
      </tr>
      </tbody>
    </table>
  )
}

export default TransactionCommissionTable
