import {
  LOAD_BANK_ACCOUNTS,
  UPDATE_BANK_ACCOUNT,
  DELETE_BANK_ACCOUNT,
  ADD_BANK_ACCOUNT,
  LOAD_MINIAPP_PAYOUTS,
  LOAD_SUPERAPP_PAYOUTS,
  LOAD_CURRENCIES,
} from './actions'


export function payouts(state = {
  bankAccounts: [],
  miniAppPayouts: [],
  hostAppPayouts: [],
  currencies: []
}, action) {
  switch (action.type) {
    case LOAD_BANK_ACCOUNTS:
      return {
        ...state,
        bankAccounts: action.payload
      }
    case UPDATE_BANK_ACCOUNT:
      return {
        ...state,
        bankAccounts: state.bankAccounts.map(item => {
          if (item.id === action.payload.id) {
            return action.payload
          } else if (action.payload.is_primary) {
            item.is_primary = false
          }
          return item
        })
      }
    case DELETE_BANK_ACCOUNT:
      return {
        ...state,
        bankAccounts: state.bankAccounts.filter(item => item.id !== action.payload.bankAccountId)
      }
    case ADD_BANK_ACCOUNT:
      const bankAccounts = action.payload.is_primary ? state.bankAccounts.map(item => {
        item.is_primary = false
        return item
      }) : state.bankAccounts

      return {
        ...state,
        bankAccounts: [
          action.payload,
          ...bankAccounts
        ]
      }
    case LOAD_MINIAPP_PAYOUTS: {
      return {
        ...state,
        miniAppPayouts: action.payload
      }
    }
    case LOAD_SUPERAPP_PAYOUTS: {
      return {
        ...state,
        hostAppPayouts: action.payload
      }
    }
    case LOAD_CURRENCIES: {
      return {
        ...state,
        currencies: action.payload
      }
    }
    default:
      return state
  }
}
