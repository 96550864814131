import { createSelector } from 'reselect'

const teamStoreSelector = (state: any) => state.team

export const membersSelector = createSelector(
  teamStoreSelector,
  team => team.members
)

export const rolesSelector = createSelector(
  teamStoreSelector,
  team => team.roles
)

export const invitesSelector = createSelector(
  teamStoreSelector,
  team => team.invites
)

export const isNewInviteSending = createSelector(
  teamStoreSelector,
  team => team.newInviteSending
)
