import React from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  useRouteMatch,
  Switch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";
import { Tabs } from "antd";

import MainPaneLayout from "../../components/MainPaneLayout/MainPaneLayout";
import PayoutTable from "../PayoutTable/PayoutTable";
import PayoutMethods from "../PayoutMethods/PayoutMethods";
import PayoutHistory from "../PayoutHistory/PayoutHistory";
import { allPayoutsSelector } from "store/payouts/selectors";
import { RootState } from "types";
import { profileSelector } from "store/profile/selectors";

const { TabPane } = Tabs;

interface Props extends ConnectedProps<typeof connector> {}

const Payouts = ({ allPayouts, profile }: Props) => {
  const history = useHistory();
  const match = useRouteMatch();

  const getActiveTab = () => {
    // TODO: Handle it with routing architecture
    const p = history.location.pathname;
    return p.split("/")[2];
  };

  const handleTabChange = (activeTab: string) => {
    history.replace(`${match.url}/${activeTab}`);
  };

  return (
    <MainPaneLayout
      title="Payouts"
      tooltip="Amount earned from commissions, paid out via stored bank details."
    >
      <Tabs activeKey={getActiveTab()} onChange={handleTabChange}>
        <TabPane tab="Month-to-date" key="month-to-date" />
        <TabPane tab="Payment Details" key="details" />
        <TabPane tab="Payment History" key="history" />
      </Tabs>
      <Switch>
        <Route path={`${match.url}/month-to-date`}>
          <PayoutTable />
        </Route>
        <Route path={`${match.url}/details`}>
          <PayoutMethods />
        </Route>
        <Route path={`${match.url}/history`}>
          <PayoutHistory payouts={allPayouts} />
        </Route>
        <Route path="*">
          <Redirect to={{ pathname: profile.is_billing_and_payouts_enabled ? `${match.url}/month-to-date` : '/' }} />
        </Route>
      </Switch>
    </MainPaneLayout>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    allPayouts: allPayoutsSelector(state),
    profile: profileSelector(state),
  };
};

const connector = connect(mapStateToProps);

export default connector(Payouts);
