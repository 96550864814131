/*LIBS*/
import { createSelector } from 'reselect'
/*CONSTANTS*/
import { AppType } from 'utils/constants'
/*TYPES*/
import { IAppInvoice, IAppPlan, IHostAppInvoice, IMiniAppInvoice, IPaymentCard, PaymentType } from 'types/Billing'
import { RootState } from 'types'

const billingStateSelector = (state: RootState) => state.billing

export const paymentCardSelector = createSelector(
  billingStateSelector,
  billingState => billingState.cards
)

export const appboxoBankDetailsSelector = createSelector(
  billingStateSelector,
  billingState => billingState.appboxoBankDetails
)

export const primaryPaymentCardSelector = createSelector(
  billingStateSelector,
  billingState => billingState.cards.find((card: IPaymentCard) => card.is_primary)
)

export const miniAppPlansSelector = createSelector(
  billingStateSelector,
  billingState => billingState.miniAppPlans
)

export const miniAppPlanSelector = (miniAppId: number, state: RootState) => {
  return createSelector(
    billingStateSelector,
    billingState => billingState.miniAppPlans.filter((item: IAppPlan) => item.miniapp.id === miniAppId)
  )(state)
}

export const miniAppsBillingPlansSelector = createSelector(
  billingStateSelector,
  billingState => billingState.miniAppPlans
    .filter((appPlan: IAppPlan) => appPlan.billing_type === PaymentType.Billing)
)

export const miniAppBillingPlansSelector = (miniAppId: number, state: RootState) => {
  return createSelector(
    billingStateSelector,
    billingState => billingState.miniAppPlans
      .filter((appPlan: IAppPlan) => appPlan.miniapp.id === miniAppId && appPlan.billing_type === PaymentType.Billing)
  )(state)
}

export const miniAppPayoutsPlansSelector = (miniAppId: number, state: RootState) => {
  return createSelector(
    billingStateSelector,
    billingState => billingState.miniAppPlans
      .filter((appPlan: IAppPlan) => appPlan.miniapp.id === miniAppId && appPlan.billing_type === PaymentType.Payout)
  )(state)
}

export const miniAppsPayoutsPlansSelector = createSelector(
  billingStateSelector,
  billingState => billingState.miniAppPlans
    .filter((appPlan: IAppPlan) => appPlan.billing_type === PaymentType.Payout)
)

export const miniAppPlansLoadedSelector = createSelector(
  billingStateSelector,
  billingState => billingState.miniAppPlansLoaded
)

export const miniAppInvoicesSelector = createSelector(
  billingStateSelector,
  billingState => billingState.miniAppInvoices
    .map(prepareInvoice)
)

export const miniAppInvoiceSelector = (miniAppId: number, state: RootState) => {
  return createSelector(
    billingStateSelector,
    billingState => billingState.miniAppInvoices
      .filter((invoice: IMiniAppInvoice) => invoice.miniapp_plan.miniapp.id === miniAppId)
      .map(prepareInvoice)
  )(state)
}

export const superAppInvoiceSelector = (superAppId: number, state: RootState) => {
  return createSelector(
    billingStateSelector,
    billingState => billingState.superAppInvoices
      .filter((invoice: IHostAppInvoice) => invoice.hostapp_plan.hostapp.id === superAppId)
      .map(prepareInvoice)
  )(state)
}

export const superAppInvoicesSelector = createSelector(
  billingStateSelector,
  billingState => billingState.superAppInvoices
    .map(prepareInvoice)
)

export const allInvoicesSelector =  createSelector(
  billingStateSelector,
  billingState => [...billingState.miniAppInvoices, ...billingState.superAppInvoices]
    .map(prepareInvoice)
)

export const superAppPlansSelector = createSelector(
  billingStateSelector,
  billingState => billingState.superAppPlans
)

export const superAppPlanSelector = (hostAppId: number, state: RootState) => {
  return createSelector(
    billingStateSelector,
    billingState => billingState.superAppPlans.filter((item: IAppPlan) => item.hostapp.id === hostAppId)
  )(state)
}

export const superAppBillingPlansSelector = (hostAppId: number, state: RootState) => {
  return createSelector(
    billingStateSelector,
    billingState => billingState.superAppPlans
      .filter((appPlan: IAppPlan) => appPlan.hostapp.id === hostAppId && appPlan.billing_type === PaymentType.Billing)
  )(state)
}

export const superAppsBillingPlansSelector = createSelector(
  billingStateSelector,
  billingState => billingState.superAppPlans
    .filter((appPlan: IAppPlan) => appPlan.billing_type === PaymentType.Billing)
)

export const superAppPayoutsPlansSelector = (hostAppId: number, state: RootState) => {
  return createSelector(
    billingStateSelector,
    billingState => billingState.superAppPlans
      .filter((appPlan: IAppPlan) => appPlan.hostapp.id === hostAppId && appPlan.billing_type === PaymentType.Payout)
  )(state)
}

export const superAppsPayoutsPlansSelector = createSelector(
  billingStateSelector,
  billingState => billingState.superAppPlans
    .filter((appPlan: IAppPlan) => appPlan.billing_type === PaymentType.Payout)
)

export const superAppPlansLoadedSelector = createSelector(
  billingStateSelector,
  billingState => billingState.superAppPlansLoaded
)

export const paymentStatusSelector = createSelector(
  billingStateSelector,
  billingState => ({
    status: billingState.paymentStatus,
    error: billingState.paymentError,
  })
)

export const addNewCardStatusSelector = createSelector(
  billingStateSelector,
  billingState => ({
    status: billingState.addCardStatus,
    error: billingState.addCardError,
  })
)

/*
* prepareInvoice function equates host app and miniapps invoices to a common interface for billing table
* */
export const prepareInvoice = (invoice: IMiniAppInvoice | IHostAppInvoice): IAppInvoice => {
  return {
    actual_currency_exchange_date: invoice.actual_currency_exchange_date,
    amount: invoice.amount,
    app_plan: isMiniappInvoice(invoice) ? { ...invoice.miniapp_plan } : { ...invoice.hostapp_plan },
    created_date: invoice.created_date,
    currency: invoice.currency,
    due_date: invoice.due_date,
    from_date: invoice.from_date,
    id: invoice.id,
    invoiced: isMiniappInvoice(invoice) ? AppType.Miniapp : AppType.Hostapp,
    invoice_number: invoice.invoice_number,
    launches_count: invoice.launches_count,
    multi_currency_sales: invoice.multi_currency_sales,
    paid_date: invoice.paid_date,
    payment_method: invoice.payment_method,
    payment_method_details: invoice.payment_method_details,
    status: invoice.status,
    to_date: invoice.to_date,
    transactions_amount: invoice.transactions_amount
  }
}

function isMiniappInvoice(invoice: IMiniAppInvoice | IHostAppInvoice): invoice is IMiniAppInvoice {
  return (invoice as IMiniAppInvoice).miniapp_plan !== undefined
}
