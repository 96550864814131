/*CORE*/
import React, { useEffect, useState } from 'react'
/*COMPONENTS*/
import AppTypeNavModal from './components/AppTypeNavModal/AppTypeNavModal'
import WelcomeGuide from './components/WelcomeGuide/WelcomeGuide'
import OldDashboard from './components/OldDashboard'
/*CONSTANTS*/
import { START_WELCOME_GUIDE_KEY } from 'utils/constants'

const Home = () => {
  const [isAppTypeNavModalOpen, setIsAppTypeNavModalOpen] = useState(false)

  useEffect(() => {
    try {
      const startWelcomeGuide = JSON.parse(localStorage.getItem(START_WELCOME_GUIDE_KEY) || 'false')
      setIsAppTypeNavModalOpen(startWelcomeGuide)
    } catch {}
}, [])

  return (
    <div className='main-pane-layout'>
      <WelcomeGuide isAppTypeNavModalOpen={isAppTypeNavModalOpen} />
      <AppTypeNavModal isOpen={isAppTypeNavModalOpen} setIsOpen={setIsAppTypeNavModalOpen} />
      <OldDashboard />
    </div>
  )
}

export default Home
