import { CurrencySales, IMetrics } from '../types'
import { ICurrency } from '../types/Payouts'

export const combineMetrics = (metrics: IMetrics[]) => {
  const accumulatedMetrics = metrics.filter((data: IMetrics) => data.app).reduce((acc: IMetrics, curr: IMetrics) => (
    {
      ...curr,
      total_conversion: acc.total_conversion + curr.total_conversion,
      total_launches: acc.total_launches + curr.total_launches,
      total_sales: acc.total_sales + curr.total_sales,
      average_session: acc.average_session + curr.average_session,
      unique_user_count: acc.unique_user_count + curr.unique_user_count,
      multi_currency_sales: [...acc.multi_currency_sales, ...curr.multi_currency_sales]
    }
  ), {
    total_conversion: 0,
    total_launches: 0,
    total_sales: 0,
    average_session: 0,
    unique_user_count: 0,
    currency: {} as ICurrency,
    actual_currency_exchange_date: '',
    multi_currency_sales: [] as CurrencySales[]
  } as IMetrics)

  if (metrics.length > 0) {
    accumulatedMetrics.average_session /= metrics.length
  }
  return accumulatedMetrics
}
