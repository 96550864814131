/*CORE*/
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
/*COMPONENTS*/
import Search from 'antd/lib/input/Search'
import ShowroomCard from '../ShowroomCard/ShowroomCard'
import ScrollToTopOnMount from '../../../common/ScrollToTopOnMount'
import SinglePageLayout from '../../components/SinglePageLayout/SinglePageLayout'
/*LIBS*/
import { connect, ConnectedProps } from 'react-redux'
import { Typography } from 'antd'
/*SELECTORS*/
import {
  showroomMiniAppByCategorySelector,
} from 'store/showroom/selectors'
/*UTILS*/
import { goBack } from 'utils/utils'
/*TYPES*/
import { IShowroomMiniApp, RootState } from 'types'
/*STYLES*/
import styles from './CategoryDetails.module.scss'

const { Title } = Typography

interface Props extends ConnectedProps<typeof connector> {
}

const CategoryDetails = ({ getMiniApps }: Props) => {

  const [ searchQuery, setSearchQuery ] = useState('')
  let { category } = useParams()
  const history = useHistory()

  const handleClose = () => {
    goBack(history, '/showroom')
  }

  const miniApps = getMiniApps(category || '')

  const onClickCard = (id: number) => () => {
    history.push('/showroom/' + id)
  }

  const handleFilterByName = (app: IShowroomMiniApp): boolean => {
    if (searchQuery) {
      const appName = app.name.toLowerCase()
      const query = searchQuery.toLowerCase()
      return appName.indexOf(query) !== -1 || query.indexOf(appName) !== -1
    }
    return true
  }

  const filteredApps: IShowroomMiniApp[] = miniApps.filter(handleFilterByName)

  return (
    <SinglePageLayout
      onGoBack={handleClose}
      className={styles['category-details']}
    >
      <ScrollToTopOnMount />
      <Title className={styles['title']}>{category}</Title>
      <div className={styles['search']}>
        <Search
          placeholder="Search"
          onChange={event => setSearchQuery(event.target.value)}
          onSearch={value => setSearchQuery(value)}
          allowClear
        />
      </div>
      <div className={styles['cards']}>
        {
          filteredApps.map(miniApp =>
            <div key={miniApp.id} onClick={onClickCard(miniApp.id)}>
              <ShowroomCard miniApp={miniApp} />
            </div>
          )
        }
      </div>
    </SinglePageLayout>
  )
}

const mapStateToProps = (state: RootState) => (
  {
    getMiniApps: (categoryName: string) => showroomMiniAppByCategorySelector(categoryName, state)
  }
)

const connector = connect(mapStateToProps)

export default connector(CategoryDetails)
