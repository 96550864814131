/*CORE*/
import React, { useEffect, useState } from 'react'
/*LIBS*/
import { RangeValue } from 'rc-picker/lib/interface'
import { DatePicker, message, Typography } from 'antd'
import { bindActionCreators, Dispatch } from 'redux'
import { useHistory, useParams } from 'react-router-dom'
import { AxiosResponse } from 'axios'
import queryString from 'query-string'
import { connect } from 'react-redux'
import moment from 'moment'
/*ASSETS*/
import { ReactComponent as ArrowLeft } from 'assets/img/arrow-right.svg'
/*ACTIONS*/
import { getPartnership as getPartnershipAction } from 'store/partnerships/actions'
import { getPartnershipMetricsByRangeDate as getPartnershipMetricsByRangeDateAction } from 'store/analytics/actions'
/*COMPONENTS*/
import AppSkeleton from '../../components/Skeletons/AppSkeleton'
import AnalyticsOverallTable from '../components/AnalyticsOverallTable/AnalyticsOverallTable'
import DoubleLogo from '../../components/DoubleLogo/DoubleLogo'
import SinglePageLayout from '../../components/SinglePageLayout/SinglePageLayout'
import CurrenciesTable from '../components/CurrenciesTable/CurrenciesTable'
import Badge from '../../components/Badge/Badge'
/*UTILS*/
import { getPartnershipBadgeStatus } from '../../Partnerships/get-partnership-badge-status'
import { goBack, normalizeMetrics } from 'utils/utils'
import { combineMetrics } from 'utils/analytics'
/*TYPES*/
import { IMetrics } from 'types'
import { IPartnershipDetails } from 'types/Partnerships'
/*STYLES*/
import styles from './AppInternalAnalytics.module.scss'

const { Title } = Typography
const { RangePicker } = DatePicker

interface IDispatchProps {
  getPartnershipMetricsByRangeDate: (
    miniappId: string | number,
    hostappID: string | number,
    range: [ moment.Moment, moment.Moment ],
    isMyIntegration: boolean
  ) => Promise<AxiosResponse<IMetrics[]>>
  getPartnershipDetails: (partnershipId: number) => Promise<AxiosResponse<IPartnershipDetails>>
}

interface Props extends IDispatchProps {
}

const AppInternalAnalytics = ({ getPartnershipMetricsByRangeDate, getPartnershipDetails }: Props) => {
  const [metrics, setMetrics] = useState<IMetrics | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [dateRange, setDateRange] =
    useState<RangeValue<moment.Moment>>([moment().startOf('month'), moment().endOf('month')])
  const [partnership, setPartnership] = useState<IPartnershipDetails | null>(null)
  const history = useHistory()
  const { partnershipId } = useParams()

  useEffect(() => {
    if (partnershipId) {
      const { startDate, endDate } = queryString.parse(history.location.search)

      if (startDate && endDate && moment(startDate).isValid() && moment(endDate).isValid()) {
        setDateRange([moment(startDate), moment(endDate)])
      }
      loadApp(partnershipId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnershipId])

  const loadApp = async (partnershipId: string) => {
    try {
      const appDetails = await getPartnershipDetails(+partnershipId)
      setPartnership(appDetails.data)
    } catch (e) {
      message.error('Something went wrong while loading partnership')
    }
  }

  useEffect(() => {
    if (partnership) {
      loadMetrics(partnership, dateRange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnership, dateRange?.[0], dateRange?.[1]])

  const loadMetrics = async (partnership: IPartnershipDetails, dateRange: RangeValue<moment.Moment>) => {
    if (dateRange?.[0] && dateRange?.[1]) {
      try {
        setIsLoading(true)
        const isMiniApp = !partnership.is_my_integration
        const range: [ moment.Moment, moment.Moment ] = [ dateRange[0], dateRange[1] ]

        const metricsResponse = await getPartnershipMetricsByRangeDate(partnership.miniapp.id, partnership.hostapp.id, range, isMiniApp)
        setMetrics(combineMetrics(normalizeMetrics(metricsResponse.data)))
        setIsLoading(false)

      } catch (e) {
        setIsLoading(false)
        message.error('Something went wrong while loading analytics')
      }
    }
  }

  const handleGoBack = () => {
    goBack(history, '/')
  }

  const onChangeDatePicker = (values: RangeValue<moment.Moment>) => {
    setDateRange(values)
  }

  if (!partnership) {
    return (
      <SinglePageLayout
        onGoBack={handleGoBack}
        className={styles['app-internal-analytics']}
      >
        <AppSkeleton />
      </SinglePageLayout>
    )
  }

  return (
    <SinglePageLayout
      onGoBack={handleGoBack}
      className={styles['app-internal-analytics']}
    >
      <header>
        <div>
          <DoubleLogo backLogo={partnership.hostapp.logo} frontLogo={partnership.miniapp.logo} />
          <Title className={styles['title']}>{partnership.hostapp.name} <i>/</i> {partnership.miniapp.name}</Title>
          <Badge
            status={getPartnershipBadgeStatus(partnership.status).badgeStatus}
            label={getPartnershipBadgeStatus(partnership.status).tooltipStatus}
          />
        </div>
        <div className={styles['date-picker-wrapper']}>
          <RangePicker
            bordered={false}
            suffixIcon={null}
            value={dateRange}
            allowClear={false}
            format={'DD/MM/YYYY'}
            onChange={onChangeDatePicker}
            className={styles['date-picker']}
            separator={<ArrowLeft className={styles['separator']} />}
          />
        </div>
      </header>
      {isLoading || !metrics ? (
        <AppSkeleton />
      ) : (
        <>
          <AnalyticsOverallTable metrics={metrics} />
          <CurrenciesTable metrics={metrics} />
        </>
      )}
    </SinglePageLayout>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => (
  {
    ...bindActionCreators<any, any>({
      getPartnershipMetricsByRangeDate: getPartnershipMetricsByRangeDateAction,
      getPartnershipDetails: getPartnershipAction,
    }, dispatch),
  }
)

export default connect<null, IDispatchProps>(null, mapDispatchToProps)(AppInternalAnalytics)
