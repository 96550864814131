/*CORE*/
import React  from 'react'
/*STYLES*/
import styles from './ParagraphItem.module.scss'

type Props = {
  prefix: string
  children: React.ReactNode
}

const ParagraphItem = ({ prefix, children }: Props) => (
  <div className={styles['wrapper']}>
    <div>{prefix}</div>
    <div className={styles['wrapper__content']}>
      {children}
    </div>
  </div>
)

export default ParagraphItem
