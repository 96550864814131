import axios from 'axios'

import {
  API_BILLING_BANK_ACCOUNTS, API_BILLING_MINIAPP_PAYOUTS, API_BILLING_SUPERAPP_PAYOUTS, API_BILLING_CURRENCY
} from 'utils/constants'
import { preparePayout } from './selectors'
import { downloadFile } from 'utils/utils'

export const LOAD_BANK_ACCOUNTS = 'LOAD_BANK_ACCOUNTS'
export const UPDATE_BANK_ACCOUNT = 'UPDATE_BANK_ACCOUNT'
export const DELETE_BANK_ACCOUNT = 'DELETE_BANK_ACCOUNT'
export const ADD_BANK_ACCOUNT = 'ADD_BANK_ACCOUNT'
export const LOAD_MINIAPP_PAYOUTS = 'LOAD_MINIAPP_PAYOUTS'
export const LOAD_SUPERAPP_PAYOUTS = 'LOAD_SUPERAPP_PAYOUTS'
export const LOAD_CURRENCIES = 'LOAD_CURRENCIES'


export function loadBankAccounts() {
  return function (dispatch, getState) {
    return axios.get(API_BILLING_BANK_ACCOUNTS)
    .then(response => {
      return dispatch({
        type: LOAD_BANK_ACCOUNTS,
        payload: response.data
      })
    })
  }
}

export function updateBankAccount(bankAccountId, formData) {
  return function (dispatch, getState) {
    return axios.patch(API_BILLING_BANK_ACCOUNTS + bankAccountId + '/', formData).then(response => {
      return dispatch({
        type: UPDATE_BANK_ACCOUNT,
        payload: response.data
      })
    })
  }
}

export function deleteBankAccount(bankAccountId) {
  return function (dispatch, getState) {
    return axios.delete(API_BILLING_BANK_ACCOUNTS + bankAccountId + '/').then(() => {
      return dispatch({
        type: DELETE_BANK_ACCOUNT,
        payload: {
          bankAccountId
        }
      })
    })
  }
}

export function addNewBankAccount(bankAccountData) {
  return function (dispatch, getState) {
    return axios.post(API_BILLING_BANK_ACCOUNTS, bankAccountData).then(response => {
      return dispatch({
        type: ADD_BANK_ACCOUNT,
        payload: response.data
      })
    })
  }
}

export function loadMiniAppPayouts() {
  return function (dispatch) {
    return axios.get(API_BILLING_MINIAPP_PAYOUTS)
      .then(response => {
        return dispatch({
          type: LOAD_MINIAPP_PAYOUTS,
          payload: response.data
        })
      })
  }
}

export function loadSuperAppPayouts() {
  return function (dispatch) {
    return axios.get(API_BILLING_SUPERAPP_PAYOUTS)
      .then(response => {
        return dispatch({
          type: LOAD_SUPERAPP_PAYOUTS,
          payload: response.data
        })
      }).catch(() => {})
  }
}

export function getMiniAppPayout(payoutId) {
  return function () {
    return axios.get(API_BILLING_MINIAPP_PAYOUTS, { params: { id: payoutId } })
      .then(response => preparePayout(response.data[0]))
  }
}

export function getSuperAppPayout(payoutId) {
  return function () {
    return axios.get(API_BILLING_SUPERAPP_PAYOUTS, { params: { id: payoutId } })
      .then(response => preparePayout(response.data[0]))
  }
}

export function loadCurrencies() {
  return function (dispatch, getState) {
    return axios.get(API_BILLING_CURRENCY)
      .then(response => {
        return dispatch({
          type: LOAD_CURRENCIES,
          payload: response.data
        })
      })
  }
}

export function downloadMiniappPayout(miniappPayout, format) {
  return function() {
    return axios.get(`${API_BILLING_MINIAPP_PAYOUTS}${miniappPayout.id}/get_${format}/`, { responseType: 'blob' })
      .then((response) => {
        downloadFile(miniappPayout.payout_number, format, response.data)
      })
  }
}

export function downloadSuperappPayout(superappPayout, format) {
  return function() {
    return axios.get(`${API_BILLING_SUPERAPP_PAYOUTS}${superappPayout.id}/get_${format}/`, { responseType: 'blob' })
      .then((response) => {
        downloadFile(superappPayout.payout_number, format, response.data)
      })
  }
}
