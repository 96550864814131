/*CORE*/
import React, { useState } from 'react'
/*LIBS*/
import { useHistory } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AxiosError, AxiosResponse } from 'axios'
import { message } from 'antd'
/*ACTIONS*/
import { switch2FA as switch2FAAction } from 'store/profile/actions'
import { verifyTwoFactorAuthCode as verifyTwoFactorAuthCodeAction } from 'store/auth/actions'
/*COMPONENTS*/
import SinglePageLayout from '../../Dashboard/components/SinglePageLayout/SinglePageLayout'
import InitialSetup from './components/InitialSetup/InitialSetup'
import AuthCode, { AuthCodeData } from './components/AuthCode/AuthCode'
import SetupSuccess from './components/SetupSuccess/SetupSuccess'
/*UTILS*/
import { goBack } from 'utils/utils'
/*STYLES*/
import styles from './SetupTwoFactorAuth.module.scss'


enum STEPS {
  initialSetup,
  authCode,
  success
}

type Props = {
  verifyTwoFactorAuthCode: (formData: AuthCodeData) => Promise<AxiosResponse<{ status: string, emergency_code: string }>>
  switch2FA: (is_2fa_enabled: boolean) => void
}

const SetupTwoFactorAuth = ({ verifyTwoFactorAuthCode, switch2FA }: Props) => {
  const [currentStep, setCurrentStep] = useState(STEPS.initialSetup)
  const [emergencyCode, setEmergencyCode] = useState('')
  const history = useHistory()

  const handleGoBack = () => {
    if (currentStep === STEPS.authCode) {
      setCurrentStep(STEPS.initialSetup)
    } else {
      goBack(history, '/account')
    }
  }

  const handleContinueOnInitialSetup = () => {
    setCurrentStep(STEPS.authCode)
  }

  const handleAuthCodeSubmit = (formData: AuthCodeData) => {
    verifyTwoFactorAuthCode(formData).then((resp) => {
      setEmergencyCode(resp.data.emergency_code)
      switch2FA(true)
      setCurrentStep(STEPS.success)
    }).catch((error: AxiosError) => {
      message.error(error.response?.data || 'Could not verify two factor authorization code')
    })
  }

  const handleContinueOnSuccess = () => {
    history.push('/account')
  }

  const renderStep = () => {
    switch (currentStep) {
      case STEPS.initialSetup:
        return <InitialSetup handleContinue={handleContinueOnInitialSetup} />
      case STEPS.authCode:
        return <AuthCode handleContinue={handleAuthCodeSubmit} />
      case STEPS.success:
        return <SetupSuccess handleContinue={handleContinueOnSuccess} emergencyCode={emergencyCode} />
    }
  }

  return (
    <SinglePageLayout
      onGoBack={currentStep !== STEPS.success ? handleGoBack : null}
      onClose={currentStep === STEPS.success ? handleGoBack : undefined}
      isFullHeight
    >
      <div className={styles['two-factor-auth-step']}>
        {renderStep()}
      </div>
    </SinglePageLayout>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators({
      verifyTwoFactorAuthCode: verifyTwoFactorAuthCodeAction,
      switch2FA: switch2FAAction,
    }, dispatch),
  }
}

// @ts-ignore
export default connect(null, mapDispatchToProps)(SetupTwoFactorAuth)
