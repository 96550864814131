/*CORE*/
import React from "react";
/*COMPONENTS*/
import SinglePageLayout from "../Dashboard/components/SinglePageLayout/SinglePageLayout";
import ParagraphItem from "./components/ParagraphItem";

const Terms = () => (
  <SinglePageLayout>
    <div style={{ maxWidth: 720, margin: '0 auto' }}>

    <h1>Publisher Terms and Conditions</h1>
    <p>
      This Agreement details the Terms and Conditions under which you are
      accepted as a Publisher of Appboxo Pte. Ltd. incorporated in Singapore
      (Company Number 201542169R) (“Boxo”) and governs all activities on
      Advertiser Programmes through Boxo.
    </p>

    <h2>Recitals</h2>
    <ul>
      <li>
        Once you click to confirm your acceptance of the terms of this
        Agreement, Boxo will consider your application. On receipt by you of
        confirmation from Boxo that you have been accepted as a Publisher,
        the Agreement shall come into force and shall be binding on you
        automatically
      </li>
      <li>
        Boxo operates a range of services allowing Publishers to transfer
        visitors from their own Publisher App to the Advertiser Miniapp and
        thereby earn commission according to the terms of the relevant service.
      </li>
      <li>
        In order to benefit from one or more of the services, you must read and
        accept the terms and conditions of this Agreement, which shall apply to
        any and all services joined by you as a Publisher, and receive
        confirmation of your acceptance by Boxo as a Publisher. Please read
        this Agreement carefully.
      </li>
      <li>
        Please check the box to confirm that you have read, understood and
        accept this Agreement.
      </li>
    </ul>

    <h2>1. Definitions and Interpretation</h2>

    <ParagraphItem prefix="1.1">
      In this Agreement unless the context otherwise requires the following
      words and expressions shall bear the following meanings:
    </ParagraphItem>

    <p>
      <b>&quot;Advertiser&quot;</b> means a company, partnership or other
      commercial entity participating in the Services for the purposes of
      selling or promoting its goods and services. For avoidance of doubt,
      Advertiser is synonymous with Merchant
    </p>

    <p>
      <b>&quot;Advertiser Account&quot;</b> means the secure area of the Boxo
      Website to which an Advertiser has access.
    </p>

    <p>
      <b>&quot;Advertiser Commission&quot;</b> means any payment due to Boxo
      from an Advertiser arising from any Referral.
    </p>

    <p>
      <b>&quot;Advertiser Links&quot;</b> means any link between a Publisher App
      and an Advertiser Miniapp and including any Intellectual Property, logos
      or banners incorporated in or connected with such link.
    </p>

    <p>
      <b>&quot;Advertiser Miniapp&quot;</b> means the miniapp of the Advertiser
      through which the individual product or service is advertised or sold.
    </p>

    <p>
      <b>&quot;Boxo Website&quot;</b> means the website maintained by Boxo
      at www.boxo.io or such other domain name as may be notified to the
      Publisher from time to time.
    </p>

    <p>
      <b>&quot;Chargeback&quot;</b> means Commission already paid to an
      Publisher in respect of a Referral from which no sale has resulted
    </p>

    <p>
      <b>&quot;Commission&quot;</b> means sums payable by Boxo to the
      Publisher as specified by Boxo in relation to each Service as the
      Publisher may join from time to time and in accordance with clause 6.1
    </p>

    <p>
      <b>&quot;Commission Rates&quot;</b> means the rate of Commission payable
      by Boxo to the Publisher in respect of any Service taken by the
      Publisher and as notified by Boxo to the Publisher on application by
      the Publisher for that Service (and as may be varied and notified to the
      Publisher by Boxo from time to time).
    </p>

    <p>
      <b>&quot;Confidential Information&quot;</b> means in relation to either
      party any and all confidential information that it may acquire in relation
      to the business or affairs, know how or trade secrets of the other party
      as more particularly referred to in clause 8.4 and shall include any such
      information relating to the business or affairs of any Advertiser.
    </p>

    <p>
      <b>&quot;Data Protection Laws&quot;</b> means all laws, statutes,
      enactments, orders or regulations or other similar instruments of general
      application and any other rules, instruments or provisions in force from
      time to time relating to the processing of personal data and privacy
      applicable to the performance of this Agreement. Data Processing Schedule
      means Schedule 2 included in this Agreement which specifies the personal
      data processing arrangements and particulars between the Parties where the
      processing of personal data is subject to EU Data Protection Laws. Email
      Promotion means the promotion of a Programme on emails sent to Users.
    </p>

    <p>
      <b>&quot;Documentation&quot;</b> means the document made available to the
      Publisher by Boxo via docs.boxo.io or such other web address
      notified by Boxo to the Publisher from time to time which sets out a
      description of the Services and the user instructions for the Services.
    </p>

    <p>
      <b>&quot;Intellectual Property&quot;</b> means patents, design rights,
      trade marks, trading business or domain names and e-mail addresses,
      copyrights (including any such rights in typographical arrangements of
      websites or software) whether registered or not and any application to
      register or rights to apply for registration of any of the foregoing,
      rights in inventions, know- how, trade secrets and other confidential
      information, rights in databases and all other intellectual property
      rights of a similar or corresponding character that subsist now or in the
      future in any part of the world.
    </p>

    <p>
      <b>&quot;Payment Terms&quot;</b> means the additional terms and conditions
      (if any) relating to the payment of Commission in relation to any Service
      as displayed via the Publisher Account and as may be varied by Boxo
      from time to time and which shall be deemed incorporated into this
      Agreement.
    </p>

    <p>
      <b>&quot;Programme&quot;</b> means the promotion of an individual product
      or service of an Advertiser that a Publisher may apply to join.
    </p>

    <p>
      <b>&quot;Programme Rules&quot;</b> means the specific terms and conditions
      of a Programme that the Publisher agrees to comply with when joining that
      Programme. The Programme Rules incorporate the Programme specific pages
      within the Publisher Account. In addition to the written document Boxo
      also presents a simplified view of the key points of the Programme Rules
      within the Publisher Account, in the event that there is any perceived
      conflict between the simplified view and the Programme Rules, the
      Programme Rules shall take precedence. In the event that a matter is only
      addressed within the Simplified view and not the Programme Rules then it
      shall still have legal meaning, based on Boxo’s reasonable
      interpretation.
    </p>

    <p>
      <b>&quot;Prohibited Site&quot;</b> means a website containing any
      information or materials which are in breach of any third party
      intellectual property rights are or may be objectively considered to be
      defamatory, obscene, pornographic, offensive, threatening, blasphemous, or
      liable to incite racial hatred or which promote any illegal activity
      including but not limited to ware, cracking or hacking and/or such
      websites as may from time to time be specified as Prohibited Websites on
      the Boxo Website.
    </p>

    <p>
      <b>&quot;Publisher&quot;</b> means the individual person partnership
      company or other entity entering into this Agreement with Boxo. For
      avoidance of doubt, Publisher is synonymous with Affiliate
    </p>

    <p>
      <b>&quot;Publisher Account&quot;</b> means the secure area of the Boxo
      Website to which the Publisher shall be granted access in accordance with
      clause 2
    </p>

    <p>
      <b>&quot;Publisher Hosted Content&quot;</b> means the Service provided to
      the Publisher by Boxo and displayed by the Publisher on the Publisher
      App or otherwise
    </p>

    <p>
      <b>&quot;Publisher App&quot;</b> means the app and/or website edited, run,
      managed, administered or displayed by the Publisher
    </p>

    <p>
      <b>&quot;Query Period&quot;</b> means the timeframe after a Transaction
      occurs, within which a query on a Transaction may be raised by the
      Publisher. The query period expires at the earlier of 30 days after a
      Transaction is marked as Validated Transaction or is rejected within the
      Publisher Account and 30 days after the Transaction would normally have
      been marked as a Validated Transaction or rejected within the Publisher
      Account based on the Programme Rules.
    </p>

    <p>
      <b>&quot;Referral&quot;</b> means an introduction or lead made or obtained
      as a result of a visitor to the Publisher App or other link by the
      Publisher to an Advertiser Miniapp.
    </p>

    <p>
      <b>&quot;Service&quot;</b> means such services as Boxo may make
      available to Publishers from time to time by way of the demonstrations and
      application procedures made available through the Boxo Website, as more
      particularly described in the Documentation.
    </p>

    <p>
      <b>&quot;Territory&quot;</b> means those countries or regions communicated
      in the programme terms and conditions. If no region is specified then it
      should be assumed that the Territory is restricted to the country in which
      the programme is offered.
    </p>

    <p>
      <b>&quot;Transaction&quot;</b> means the purchase of a product or service,
      or other action, by a User which results from a Referral by the Publisher.
    </p>

    <p>
      <b>&quot;User&quot;</b> means an internet user who accesses the
      Publisher’s App
    </p>

    <p>
      <b>&quot;Validated Transaction&quot;</b> is a status given to a
      Transaction within the Publisher Account based on it meeting the criteria
      within the Programme Rule.
    </p>

    <ParagraphItem prefix="1.2">
      In this Agreement, unless the context otherwise requires:
    </ParagraphItem>

    <ParagraphItem prefix="(a)">
      References to persons shall include the firm&#39;s corporations and other
      associations or bodies of persons whether or not incorporated and any
      government state or agency of a state whether or not any of the foregoing
      has any separate legal personality;
    </ParagraphItem>

    <ParagraphItem prefix="(b)">
      Any reference to a statute or statutory provision shall be construed as
      including a reference to any statutory modification consolidation or
      re-enactment (whether before or in the case of consolidation or
      re-enactment only after the date hereof) from time to time and shall
      include reference to any provision of which it is a re-enactment (whether
      with or without modification) and any bye-laws, statutory instruments,
      rules, regulations, orders, directions, consents and permissions made or
      given thereunder and any conditions attaching thereto;
    </ParagraphItem>

    <ParagraphItem prefix="(c)">
      Clause headings are for ease of reference only and shall not affect the
      construction or interpretation of this Agreement;
    </ParagraphItem>

    <ParagraphItem prefix="(d)">
      Any reference to Clauses, sub-Clauses and paragraphs is to Clauses,
      sub-clauses and paragraphs of this Agreement.
    </ParagraphItem>

    <h2>2. Becoming a Publisher</h2>

    <ParagraphItem prefix="2.1">
      In consideration of the Publisher entering into the obligations and
      restrictions contained herein, Boxo has issued to the Publisher a
      username and password which enables the Publisher to access the Publisher
      Account.
    </ParagraphItem>

    <ParagraphItem prefix="2.2">
      The Publisher shall keep such password secure at all times and shall not
      disclose the same to any third party.
    </ParagraphItem>

    <ParagraphItem prefix="2.3">
      Publisher agrees to undertake technical integration to install Boxo’s
      software development kit into the Publisher App to access the Services.
    </ParagraphItem>

    <h2>3. Taking a Service</h2>

    <ParagraphItem prefix="3.1">
      Boxo shall post on the Boxo Website details of all Services managed
      by Boxo and relevant or appropriate to the Publisher with applicable
      Commission Rates and Payment Terms, together with details of all
      Advertisers taking part in such Services.
    </ParagraphItem>

    <ParagraphItem prefix="3.2">
      At any time during the continuance of this Agreement, the Publisher may
      apply to take up any Service or Services using the online procedure
      provided within the Publisher Account or at such URL or hypertext link as
      may be posted on the Boxo Website.
    </ParagraphItem>

    <ParagraphItem prefix="3.3">
      Boxo reserves the right at its sole discretion to reject a
      Publisher&#39;s application to join a Service or to exclude from any
      Service provided to the Publisher, any Advertiser or Advertisers.
    </ParagraphItem>

    <h2>4. Obligations of the Publisher</h2>

    <ParagraphItem prefix="4.1">The Publisher shall at all times</ParagraphItem>

    <ParagraphItem prefix="(a)">
      provide such information and complete such forms as may reasonably be
      required by Boxo;
    </ParagraphItem>

    <ParagraphItem prefix="(b)">
      ensure that all information provided to Boxo is complete and accurate
      and not misleading.
    </ParagraphItem>

    <ParagraphItem prefix="4.2">
      The Publisher is responsible for providing accurate and up to date details
      within the Publisher Account, for the purposes of receiving payment or
      being contacted by Boxo. Boxo shall not be liable for any
      communication or payment that does not reach the Boxo due to the
      inclusion of incorrect details within the Publishers
    </ParagraphItem>

    <ParagraphItem prefix="4.3">
      Boxo shall be entitled to act upon any information or requests that it
      reasonably believes comes from the Publisher and Boxo shall not be
      liable for any loss, damage costs or expenses howsoever arising directly
      or indirectly from its reliance on such requests.
    </ParagraphItem>

    <ParagraphItem prefix="4.5">
      The Publisher shall immediately notify Boxo by email at
      support@boxo.io or such other e-mail address as Boxo shall notify
      to the Publisher for this purpose if:
    </ParagraphItem>

    <ParagraphItem prefix="(a)">
      it believes that any unauthorised use has been or may be made of the
      password referred to in clause 2; or
    </ParagraphItem>

    <ParagraphItem prefix="(b)">
      it suspects or is aware of any other actual or possible abuse of the
      Publisher Programme by agents or employees of the Publisher or by any
      third party.
    </ParagraphItem>

    <ParagraphItem prefix="(4.6)">
      The Publisher shall not at any time use a Prohibited Site for any purpose
      directly or indirectly connected with this Agreement.
    </ParagraphItem>

    <ParagraphItem prefix="(4.7)">The Publisher warrants that:</ParagraphItem>

    <ParagraphItem prefix="(a)">
      it owns or has the right to use all intellectual property rights in and
      relating to the Publisher App;
    </ParagraphItem>

    <ParagraphItem prefix="(b)">
      the Publisher App and all interactions with Users comply with all
      applicable laws, government rules and regulations and industry codes.
    </ParagraphItem>

    <ParagraphItem prefix="(c)">
      it has taken such steps as are required to ensure that the tracking of a
      User, after they have clicked on a hypertext link provided by Boxo,
      complies with any relevant privacy legislation.
    </ParagraphItem>

    <ParagraphItem prefix="(d)">
      that the Publisher has such licences and meets such industry requirements
      as may be applicable to any Service which it takes or activities that it
      undertakes;
    </ParagraphItem>

    <ParagraphItem prefix="(e)">
      the Publisher App does not and shall not display or contain any
      information or materials or hypertext links to information or materials
      which are in breach of the Intellectual Property Rights of any Advertiser,
      Boxo or any third party;
    </ParagraphItem>

    <ParagraphItem prefix="(f)">
      the Publisher App does not and shall not display or contain any
      information or materials or hypertext links to information or materials
      which are or may be objectively considered to be defamatory, obscene,
      pornographic, offensive, threatening, blasphemous or liable to incite
      racial hatred or which promote any illegal activity including (but not
      limited to) cracking or hacking;
    </ParagraphItem>

    <ParagraphItem prefix="(g)">
      the Publisher shall not generate transactions by means which in the
      opinion of Boxo, are either fraudulent, unethical or that carry an
      unacceptable brand risk to the Advertiser. No Commission will be payable
      where in Boxo’s reasonable opinion traffic has been generated by such
      means.
    </ParagraphItem>

    <ParagraphItem prefix="(h)">
      the Publisher shall ensure that it, at all times, complies with the
      specific Programme Rules in respect of its activity on each Programme.
    </ParagraphItem>

    <ParagraphItem prefix="4.8">
      The Publisher shall at all times respect the Intellectual Property and
      other rights of Boxo, Advertisers and third parties. The Publisher
      shall indemnify and keep indemnified Boxo against any and all damages
      costs losses and expenses arising directly or indirectly from any breach
      or infringement by the Publisher of such Intellectual Property
    </ParagraphItem>

    <ParagraphItem prefix="4.9">
      The Publisher shall not alter, edit or amend and shall not procure or
      attempt to procure the alteration, editing or amendment of any material
      provided to it by Boxo or any Advertiser for inclusion on the Publisher
      App including but not limited to Advertiser Links or Intellectual Property
      of Advertisers or Boxo, editorial or creative without the prior consent
      in writing of Boxo or the Advertiser as the case may be.
    </ParagraphItem>

    <ParagraphItem prefix="4.10">
      the Publisher shall not either directly or indirectly alter edit amend or
      vary Publisher Hosted Content (if any).
    </ParagraphItem>

    <ParagraphItem prefix="4.11">
      Whilst accepting no responsibility of any kind for the contents of any
      Publisher App, Boxo reserves the right to monitor the Publisher App and
      any links from or to it, including but not limited to Advertiser Links for
      the purpose of overseeing and administering the Services. If, in Boxo’s
      reasonable opinion, it considers that the Publisher App is unsuitable for
      the Services or any of them it may in its sole discretion require
      amendments or alterations to the Publisher App.
    </ParagraphItem>

    <ParagraphItem prefix="4.12">
      The Publisher shall at all times ensure that Boxo is made fully aware
      of all methods and apps and/or websites that are being used to generate
      traffic. Including ensuring that all apps and/or websites that are active
      on an Advertiser Programme are included within the Publisher Account and
      signed up to the Programme.
    </ParagraphItem>

    <ParagraphItem prefix="4.13">
      The Publisher shall not promote Programmes through a network of their own
      Publishers without the written approval of Boxo for each individual
      Programme. Where this approval is given the Publisher shall assume
      responsibility for all activity within their account and ensure that it is
      performed in accordance with this Agreement. In the event of a breach of
      this Agreement all activities and Publisher Commission generated within
      the account of the Publisher may be withheld.
    </ParagraphItem>

    <ParagraphItem prefix="4.14">
      The Publisher accepts that the Advertiser Programmes are operated on the
      basis that the Publisher promotes marketing material to a User, on the
      Publisher App, who then clicks through to the Advertiser Miniapp to
      complete a transaction.
    </ParagraphItem>

    <ParagraphItem prefix="4.15">
      In the event that Boxo receives a complaint from any Advertiser or any
      third party relating to the Publisher App or any methods employed by the
      Publisher to direct internet traffic to or through it, the Publisher shall
      co-operate with Boxo to facilitate the investigation of such complaint.
      Boxo may in its sole discretion require the Publisher to amend or
      remove the subject matter or methods complained of.
    </ParagraphItem>

    <ParagraphItem prefix="4.16">
      During this Agreement and for the period of six months following
      termination of this Agreement, the Publisher shall not:
    </ParagraphItem>

    <ParagraphItem prefix="(a)">
      without Boxo’s prior written consent communicate directly with an
      Advertiser in relation to any matters involving the provision of Services
      covered by this Agreement. The Publisher shall promptly notify Boxo if
      it is approached by any Advertiser directly in relation to the provision
      of introductory services or services of a nature similar to or competing
      with the Services;
    </ParagraphItem>

    <ParagraphItem prefix="(b)">
      be concerned or interested directly or indirectly in the setting up,
      administration or management of any programme which, in the reasonable
      opinion of Boxo, competes with the Services.
    </ParagraphItem>

    <ParagraphItem prefix="4.17">
      Boxo reserves the right to vary this Agreement and any such variation
      which is likely to materially affect the Publisher shall be notified to
      the Publisher by e-mail or within their Publisher Account giving not less
      than 14 days notice of such variation or change. For the avoidance of
      doubt, the Agreement shall be deemed effective as varied at the expiry of
      such notice.
    </ParagraphItem>

    <ParagraphItem prefix="4.18">
      The Publisher shall acquire no rights following the termination of this
      Agreement, or after ceasing to work on a Programme, to use any
      Intellectual Property rights of the Advertiser or Boxo including but
      not limited to registered or unregistered trade or domain names or any
      text, or images, banners or any other works created by or for Boxo or
      any Advertiser and shall act promptly following removal from a Programme
      to remove any such Publisher Hosted Content.
    </ParagraphItem>

    <ParagraphItem prefix="4.19">
      If at any time the Publisher is unable to access the Publisher Account or
      Services, it shall immediately notify Boxo. Boxo will use reasonable
      endeavours to resolve any such problem as soon as is practicable.
    </ParagraphItem>

    <h2>5. Obligations of Boxo</h2>

    <ParagraphItem prefix="5.1">
      Boxo undertakes that the Services will be performed substantially in
      accordance with the Documentation and with reasonable skill and care.
    </ParagraphItem>

    <ParagraphItem prefix="5.2">
      The undertaking at clause 5.1 shall not apply to the extent of any
      non-conformance which is caused by use of the Services contrary to the
      Boxo’s instructions, or modification or alteration of the Services by
      any party other than the Publisher or the Publisher’s duly authorised
      contractors or agents. If the Services do not conform with the foregoing
      undertaking, Boxo will, at its expense, use all reasonable commercial
      endeavours to correct any such non-conformance promptly, or provide the
      Publisher with an alternative means of accomplishing the desired
      performance. Such correction or substitution constitutes the Publisher’s
      sole and exclusive remedy for any breach of the undertaking set out in
      clause 5.1
    </ParagraphItem>

    <h2>6. Commission</h2>

    <ParagraphItem prefix="6.1">
      Subject to clause 6.3, Boxo shall pay to the Publisher Commission at
      the Commission Rates and on the Payment Terms.
    </ParagraphItem>

    <ParagraphItem prefix="6.2">
      Boxo’s primary method of payment is electronic transfer to a bank
      account. Boxo reserves the right to set a minimum payment limit on any
      payment method and to deduct any fees incurred in processing payments by
      any other method or to Publishers with bank accounts outside of Singapore.
    </ParagraphItem>

    <ParagraphItem prefix="6.3">
      Where Chargeback is specifically applied to a Service by virtue of the
      Payment Terms, and in the event that Boxo receives notification from an
      Advertiser that a purported Referral within that Service has failed to
      produce a sale for that Advertiser, Boxo shall be entitled forthwith to
      recover the Chargeback from the Publisher. For the avoidance of doubt,
      Chargeback shall not apply to a Service unless specified in the Payment
      Terms
    </ParagraphItem>

    <ParagraphItem prefix="6.4">
      Commission in relation to any Service and/or any Advertiser shall not be
      payable by Boxo to the Publisher until such time as Boxo has
      received the corresponding Advertiser Commission.
    </ParagraphItem>

    <ParagraphItem prefix="6.5">
      Commission shall only be paid for genuine Referrals which meet the
      criteria agreed by each Advertiser and communicated from time to time
      within the Payment Terms. Referrals that are believed to meet these
      criteria will be shown as validated (“Validated Transactions”) within the
      Publisher Account. In the event that Transactions are subsequently found
      to not meet the criteria or are believed to have not been generated in
      accordance with this Agreement, Boxo reserves the right to reverse this
      status. The Publisher recognises that Transactions that are marked as
      pending may never meet the criteria and that the likely rate of validation
      will vary between Programmes based on the specific technical
      implementation and the nature of the product or service being promoted to
      the User.
    </ParagraphItem>

    <ParagraphItem prefix="6.6">
      In the event that the Publisher wishes to raise a query against the status
      of a transaction any query must be raised within the Query Period. Any
      query raised after the Query Period shall be rejected by Boxo. For the
      avoidance of doubt such queries would include the status and rate of
      Commission of a Transaction.
    </ParagraphItem>

    <ParagraphItem prefix="6.7">
      The Publisher acknowledges that Boxo operates a Self Billing process.
    </ParagraphItem>

    <ParagraphItem prefix="6.8">
      Boxo shall have no obligation to pay the Publisher and accepts no
      liability whatsoever arising following a failure or omission on the part
      of the Publisher to obtain any necessary consent for the processing of
      personal data and use of online identifiers, including cookies, as deemed
      necessary under any regulations, including Data Protection Laws (including
      EU Data Protection Laws).
    </ParagraphItem>

    <ParagraphItem prefix="6.9">
      Boxo shall have no obligation to pay Commission to the Publisher and
      accepts no liability whatsoever where programme activity is not tracked or
      cannot be attributed due to an absence of unambiguous consent (from
      users/data subjects) as and where required by Data Protection Laws
      (including EU Data Laws) for the use of personal data or online
      identifiers, including cookies.
    </ParagraphItem>

    <h2>7. Limitation of Liability and Indemnity</h2>

    <ParagraphItem prefix="7.1">
      Subject to clause 7.4 below save in respect of death or personal injury,
      Boxo’s total aggregate liability to the Publisher under or in
      connection with this Agreement, including contract and tort (including the
      tort of negligence) shall not exceed USD 10,000.00
    </ParagraphItem>

    <ParagraphItem prefix="7.2">
      Boxo cannot warrant usual functionality of the Publisher Account or the
      Services at all times, but will use reasonable endeavours to ensure that
      downtime is kept to a minimum.
    </ParagraphItem>

    <ParagraphItem prefix="7.3">
      Boxo shall not be deemed to be in breach of this Agreement, or
      otherwise be liable to the Publisher by reason of any delay in
      performance, or non-performance, of any of its obligations or any other
      matters under this Agreement to the extent that the same is caused by any
      circumstances beyond its reasonable control
    </ParagraphItem>

    <ParagraphItem prefix="7.4">
      The Publisher shall immediately indemnify and keep indemnified Boxo
      against all proceedings, fees, losses, expenses, payments, liabilities,
      injury, costs and damages arising out of:
    </ParagraphItem>

    <ParagraphItem prefix="(a)">
      the breach by the Publisher, its agents or employees of any of its
      obligations under this Agreement;
    </ParagraphItem>

    <ParagraphItem prefix="(b)">
      any express or implied warranties, representations, confirmations or
      acknowledgements which are given by the Publisher and prove to be untrue
      or are otherwise breached by the Publisher.
    </ParagraphItem>

    <ParagraphItem prefix="7.5">
      The Publisher undertakes to immediately indemnify and keep indemnified
      Advertisers against all proceedings, fees, losses, expenses, payments,
      liabilities, injury, costs and damages arising out of:
    </ParagraphItem>

    <ParagraphItem prefix="(a)">
      the breach by the Publisher, its agents or employees of any of its
      obligations under this Agreement;
    </ParagraphItem>

    <ParagraphItem prefix="(b)">
      any express or implied warranties, representations, confirmations or
      acknowledgements which are given by the Publisher and prove to be untrue
      or are otherwise breached by the Publisher.
    </ParagraphItem>

    <ParagraphItem prefix="7.6">
      In the event that in the opinion of Boxo the Publisher is delivering or
      has delivered traffic in breach of either this Agreement or the Programme
      Rules then Boxo shall be entitled to remove the Publisher from the
      Programme without notice, withhold outstanding Commission and may recoup
      any previously paid Commission which Boxo reasonably deems to have been
      generated using the same methods.
    </ParagraphItem>

    <h2>8. Confidentiality</h2>

    <ParagraphItem prefix="8.1">
      The Publisher grants to Boxo the right to post the following
      information within Advertiser Accounts or to publish the following
      information within literature supplied to Advertisers:
    </ParagraphItem>

    <ParagraphItem prefix="(a)">the Publisher&#39;s identity as a Publisher;</ParagraphItem>

    <ParagraphItem prefix="(b)">
      any information (other than Confidential Information) that is submitted by
      the Publisher to Boxo.
    </ParagraphItem>

    <ParagraphItem prefix="8.2">
      The Publisher confirms that in order to enable Boxo to monitor,
      administer, improve, promote or market the Services:
    </ParagraphItem>

    <ParagraphItem prefix="(a)">
      Boxo may publish statistics or summaries relating to the Services, save
      that any such statistics or summaries will not be identifiable as relating
      to the Publisher unless previously agreed and approved by the Publisher;
      and
    </ParagraphItem>

    <ParagraphItem prefix="(b)">
      Boxo may contact the Publisher by email, telephone or post to request
      feedback regarding the Services it offers including any ways in which it
      might be improved
    </ParagraphItem>

    <ParagraphItem prefix="8.3">
      Subject to the provisions of clauses 8.1 and 8.2 each party confirms that
      it shall keep confidential all Confidential Information obtained as a
      result of this Agreement and Boxo confirms that it shall not use any
      such Confidential Information for any purpose other than the performance
      of its obligations under this Agreement. For the avoidance of doubt,
      Confidential Information shall include information acquired from the
      Publisher about the methods of acquiring traffic used by the Publisher.
    </ParagraphItem>

    <ParagraphItem prefix="8.4">
      Confidential Information shall not include information which:
    </ParagraphItem>

    <ParagraphItem prefix="(a)">
      is or becomes generally available to the public or enters the public
      domain other than as a result of the unauthorised disclosure by the
      receiving party;
    </ParagraphItem>

    <ParagraphItem prefix="(b)">
      is available to or in the possession of the receiving party free of any
      restriction as to its use or disclosure prior to its being furnished by
      the disclosing party provided that the source of such information is not
      subject to any agreement or other duties relating to confidentiality in
      respect of that information;
    </ParagraphItem>

    <ParagraphItem prefix="(c)">
      otherwise becomes lawfully available to the receiving party otherwise than
      from the disclosing party pursuant to this agreement provided that the
      source of such information is not subject to any obligation relating to
      confidentiality in respect of such information.
    </ParagraphItem>

    <h2>9. Withdrawal of Services or Advertisers</h2>

    <ParagraphItem prefix="9.1">
      Boxo reserves the right to withdraw from the Publisher with immediate
      effect the provision of any Service or Services in the event of:
    </ParagraphItem>

    <ParagraphItem prefix="(a)">
      Any problem with the functionality of the Service in question which
      Boxo is unable to resolve within 48 hours of becoming aware of the same
    </ParagraphItem>

    <ParagraphItem prefix="(b)">
      on receipt of any complaint received by Boxo from any Advertiser or
      third party regarding the Publisher&#39;s conduct in relation to any
      Service or Services
    </ParagraphItem>

    <ParagraphItem prefix="(c)">any breach of this Agreement by the Publisher</ParagraphItem>

    <ParagraphItem prefix="(d)">
      any failure by the Publisher to comply with any reasonable instruction
      given by Boxo under clauses 4.10 and 4.11
    </ParagraphItem>

    <ParagraphItem prefix="9.2">
      Boxo may remove or exclude from any Service or Services any Advertiser
      or Advertisers with immediate effect in the event of:
    </ParagraphItem>

    <ParagraphItem prefix="(a)">
      Any problem with the functionality of the Service in question which
      Boxo is unable to resolve within 48 hours of becoming aware of the same
    </ParagraphItem>

    <ParagraphItem prefix="(b)">
      the receipt by Boxo of a request from a Advertiser that the Advertiser
      be removed from any Services provided to that Publisher
    </ParagraphItem>

    <ParagraphItem prefix="(c)">any breach of this Agreement by the Publisher</ParagraphItem>

    <ParagraphItem prefix="(d)">
      any failure by the Publisher to comply with any reasonable instruction
      given by Boxo under clauses 4.10 and 4.11
    </ParagraphItem>

    <ParagraphItem prefix="9.3">
      The Publisher may withdraw from any Service or Services provided to it or
      may request the removal of any Advertiser from any Service or Services
      provided to it on 28 days notice in writing to Boxo.
    </ParagraphItem>

    <ParagraphItem prefix="9.4">
      Upon the exercise by Boxo or by the Publisher of their respective
      rights under clauses 9.1 or 9.2 or 9.3 above:
    </ParagraphItem>

    <ParagraphItem prefix="(a)">
      any licence or consent granted hereby for the use of any intellectual
      property of Boxo or any Advertiser in respect of that Service or
      Advertiser shall be considered to be revoked forthwith;
    </ParagraphItem>

    <ParagraphItem prefix="(b)">
      the Publisher shall immediately remove or co-operate with Boxo to allow
      it to remove from the Publisher App all relevant material issued by
      Boxo including but not limited to all or any references to the Services
      or Advertisers in question, copy, banners, logos or editorial; and
    </ParagraphItem>

    <ParagraphItem prefix="(c)">
      the Publisher shall cease to be entitled to Commission in relation to such
      Service or Advertiser.
    </ParagraphItem>

    <h2>10. Term and Termination</h2>

    <ParagraphItem prefix="10.1">
      This Agreement shall come into force upon confirmation by the Publisher of
      its acceptance of the terms of this Agreement and shall continue in effect
      until terminated by either party at any time on thirty days notice in
      writing.
    </ParagraphItem>

    <ParagraphItem prefix="10.2">
      Boxo shall be entitled to terminate this Agreement forthwith if:
    </ParagraphItem>

    <ParagraphItem prefix="(a)">
      the other party commits any repudiatory breach of any of the provisions of
      this Agreement;
    </ParagraphItem>

    <ParagraphItem prefix="(b)">
      the Publisher breaches any of clauses 4.7 (d) or 4.7 (e)
    </ParagraphItem>

    <ParagraphItem prefix="(c)">
      in the case of a breach capable of remedy, fails to remedy the same within
      30 days after receipt of a written notice giving full particulars of the
      breach and requiring it to be remedied;
    </ParagraphItem>

    <ParagraphItem prefix="(d)">
      an encumbrancer takes possession, or a receiver or trustee is appointed
      over any of the property or assets of that other party;
    </ParagraphItem>

    <ParagraphItem prefix="(e)">
      that other party makes any voluntary arrangement with its creditors or
      becomes subject to an administration order;
    </ParagraphItem>

    <ParagraphItem prefix="(f)">
      that other party goes into liquidation (except for the purposes of
      amalgamation or reconstruction and in such manner that the company
      resulting therefrom effectively agreed to be bound by or assume the
      obligations imposed on that other party under this Agreement);
    </ParagraphItem>

    <ParagraphItem prefix="(g)">
      anything analogous to any of the foregoing under the law of any
      jurisdiction occurs in relation to that other party; or
    </ParagraphItem>

    <ParagraphItem prefix="(h)">
      that other party ceases, or threatens to cease, to carry on business
    </ParagraphItem>

    <ParagraphItem prefix="(i)">
      the number of Referrals produced or obtained through the Publisher App
      falls below a level which is acceptable in the reasonable opinion of
      Boxo.
    </ParagraphItem>

    <ParagraphItem prefix="10.3">
      For the purposes of clause 10.2 (a) but without limitation, the following
      may be considered by Boxo to be a repudiatory breach of this Agreement:
    </ParagraphItem>

    <ParagraphItem prefix="(a)">
      any breach by the Publisher of clauses 4.5, 4.6, 4.8, 4.9 and 8;
    </ParagraphItem>

    <ParagraphItem prefix="(b)">
      any failure by the Publisher to comply with a reasonable instruction from
      Boxo under clauses 4.10 and 4.11
    </ParagraphItem>

    <ParagraphItem prefix="10.4">
      For the purposes of clause 10.2 (b), a breach shall be considered capable
      of remedy if the party in breach can comply with the provision in question
      in all respects other than as to the time of performance (provided that
      time of performance is not of the essence).
    </ParagraphItem>

    <ParagraphItem prefix="10.5">
      Upon termination of this Agreement for any reason:
    </ParagraphItem>

    <ParagraphItem prefix="(a)">
      any licence or consent granted hereby for the use of the any Intellectual
      Property of Boxo or any Advertiser shall be considered to be revoked
      forthwith;
    </ParagraphItem>

    <ParagraphItem prefix="(b)">
      the Publisher shall immediately remove or co-operate with Boxo to allow
      it to remove from the Publisher App all relevant material issued by
      Boxo or Advertisers including but not limited to all or any references
      to the Services or Advertisers, and all copy, banners, logos or editorial.
    </ParagraphItem>

    <ParagraphItem prefix="(c)">
      the Publisher shall indemnify Boxo and Advertisers against all losses
      damage costs and expenses arising directly or indirectly from any failure
      to comply with clauses 10.5 (a) or 10.5 (b)
    </ParagraphItem>

    <ParagraphItem prefix="10.6">
      The Publisher shall not be entitled to receive Commission on any Referrals
      made after the date of termination of this Agreement.
    </ParagraphItem>

    <ParagraphItem prefix="10.7">
      Clauses 4.7, 4.8, 4.13, 4.16, 4.18, 6, 7, 8, 10.5 and 10.6 shall survive
      the termination of this Agreement.
    </ParagraphItem>

    <h2>11. General</h2>

    <ParagraphItem prefix="11.1">
      This Agreement is personal to the Publisher and the Publisher may not
      without the written consent of Boxo, assign, transfer sub-contract or
      otherwise dispose of any of its rights or obligations under it
    </ParagraphItem>

    <ParagraphItem prefix="11.2">
      Except as provided elsewhere in this Agreement all notices proceedings or
      other formal documents to be served by either party on the other shall be
      delivered:
    </ParagraphItem>

    <ParagraphItem prefix="(a)">
      by e-mail to the e-mail address of the Publisher as provided by it to
      Boxo through the Publisher Account,
    </ParagraphItem>

    <ParagraphItem prefix="(b)">by posting on the Publisher Account</ParagraphItem>

    <ParagraphItem prefix="(c)">
      by hand to the other&#39;s address as set out at the head of this
      Agreement (or as subsequently notified in writing); or
    </ParagraphItem>

    <ParagraphItem prefix="(d)">
      by recorded delivery to the other&#39;s address as set out at the head of
      this Agreement (or as subsequently notified in writing)and shall be deemed
      received where sent by post, on the 2nd day after posting if within
      Singapore or 7 days if elsewhere, or where sent by e-mail, by 9am
      Singapore Time on the date after transmission.
    </ParagraphItem>

    <ParagraphItem prefix="11.3">
      This Agreement contains the entire agreement between the parties with
      respect to the subject matter, supersedes all previous agreements and
      understandings between the parties. and may not be modified otherwise than
      in accordance with clause 4.16.
    </ParagraphItem>

    <ParagraphItem prefix="11.4">
      Nothing in this Agreement shall be deemed to constitute a partnership
      between the parties nor the relationship of employer and employee under a
      contract of service nor the relationship of principal and agent.
    </ParagraphItem>

    <ParagraphItem prefix="11.5">
      If any provision of this Agreement is held by any court or other competent
      authority to be void or unenforceable in whole or in part, this Agreement
      shall continue to be valid as to the other provisions thereof and the
      remainder of the affected provision.
    </ParagraphItem>

    <ParagraphItem prefix="11.6">
      This Agreement shall be governed by and construed in all respects in
      accordance with the laws of Singapore and any dispute shall be subject to
      the exclusive jurisdiction of the Singapore courts.
    </ParagraphItem>

    <ParagraphItem prefix="11.7">
      Boxo may use the Publisher’s name and logo on Boxo’s website and
      marketing materials to identify Publisher’s relationship with Boxo, and
      Boxo may publicize that Publisher is a customer of Boxo and utilizes
      the Boxo Software.
    </ParagraphItem>

    <h2>12. Data Protection</h2>

    <ParagraphItem prefix="12.1">
      Both Parties agree that they shall comply with the Data Protection Laws.
    </ParagraphItem>

    <ParagraphItem prefix="12.2">
      To the extent that the activities covered by this Agreement fall within
      the scope of the EU Data Protection Laws then Schedule 2 (Data Processing
      Schedule) shall apply.
    </ParagraphItem>

    <h2>Schedule 1: Self Billing</h2>
    <p>
      The parties agree that Boxo shall raise Self Billing Invoices on behalf
      of the Publisher.
    </p>

    <h3>Publisher Obligations</h3>
    <p>
      The Publisher confirms that they will not issue GST invoices in respect of
      any Services included within this Self Billing Agreement.
    </p>
    <p>
      The Publisher commits to ensure that they shall notify Boxo of any
      changes to the status of their GST registration and the GST status of the
      services that they are supplying to Boxo to the extent that it would
      affect this Agreement or the preparation of accurate Self Billing Invoices
      by Boxo.
    </p>
    <p>
      In addition, the Publisher shall be responsible for ensuring that all
      other information within the Publisher Account which is required to allow
      Boxo to prepare an accurate and valid GST invoice is maintained at all
      times.
    </p>

    <h3>Boxo Obligations</h3>
    <p>
      Boxo shall generate Self Billing Invoices and provide them to the
      Publisher through the Publisher Account, within 48 hours of generating the
      payment to the Publisher at the beginning of each calendar month.
    </p>
    <p>
      Boxo shall ensure that the Self Billing Invoices comply with the
      relevant GST requirements for a valid GST invoice and shall accurately
      incorporate the information provided by the Publisher within the Publisher
      Account.
    </p>

    <h3>Term and Termination</h3>
    <p>This Self Billing Agreement shall remain in force for the Term of the Publisher Agreement
    and will automatically terminate following the final payment of Publisher Commission by
    Boxo to the Publisher under the Publisher Agreement.</p>
    <p>Notwithstanding the above, the Parties may renew this Agreement after a period of not less
    than 12 months.</p>

    <h2>Schedule 2 - Data Processing Schedule</h2>
    <h3>1.1 Definitions</h3>
    <p>“Publisher Agreement” means the Terms and Conditions under which the Publisher is
    accepted as a Publisher of Boxo and governs all activities on Advertiser Programmes
    through Boxo.</p>
    <p>Personal Data (personal data) means &quot;Personal Data&quot; and &quot;Special Categories of Personal
    Data&quot; as defined under the EU Data Protection Laws;</p>
    <p>“Data Controller”, “Data Processor”, “Data Subject, “Personal Data”, “Processed”,
    “Processing” and “Sensitive Personal Data”&#39; shall have the definitions given to these terms
    respectively given under the EU Data Protection Laws;</p>
    <p>“Data Processing Particulars”; means the processing specifics in relation to any personal
    data processing including the purpose of processing, the types of data and the types of data
    subjects</p>
    <p>“Data Subject Request”: means a request from a Data Subject in relation to the exercise of
    his/her rights under EU Data Protection Laws, such as for access to, correction, amendment,
    transfer or deletion of that Data Subject&#39;s personal data;</p>
    <p>“EU Data Protection Laws”: means the EU Directive 95/46/EC, as replaced or superseded
    from time to time, including by the GDPR and any other laws and/or regulations which give
    effect to, implement, replace, supplement or supersede EU Data Protection Laws including
    the GDPR and the UK Data Protection Act 2018; the E-Privacy Directive (2002/58/EC) and
    all other applicable laws and/or regulations that give effect to, implement, replace,
    supplement or supersede the foregoing);</p>
    <p>“Standard Contractual Clauses” means the standard contractual clauses for the transfer of
    Personal Data from the EEA to Data Processors established in third countries as set out in
    the Annex to European Commission Decision 2010/87/EU, (or any subsequent clauses that
    may amend or supersede such standard contractual clauses);</p>

    <h3>2. Arrangement between the parties</h3>

    <ParagraphItem prefix='2.1'>
      This Schedule 2 only applies to the extent that the services and processing of personal
      data falls within the scope of the EU Data Protection Laws.
    </ParagraphItem>

    <ParagraphItem prefix='2.2'>
      This Schedule 2 defines the scope and associated arrangements for personal data
      processing, as defined under GDPR Article 4, between Boxo and the Publisher (the
      Parties).
    </ParagraphItem>

    <ParagraphItem prefix='2.3'>
      Both Parties warrant that they will comply with applicable EU Data Protection Laws.
    </ParagraphItem>

    <ParagraphItem prefix='2.4'>
      For the purpose of the Publisher Agreement
    </ParagraphItem>

    <ParagraphItem prefix='2.4.1'>
      The Publisher is the Data Controller where any of the following apply
    </ParagraphItem>

    <ParagraphItem prefix='(a)'>
      It promotes Boxo advertising campaigns to its site visitors (and/or other audiences)
      whereby, as a consequence of such promotion, personal data is processed by Boxo in
      order to attribute online activity and/or commission to the Publisher and/or to provide
      performance reporting to the Publisher.
    </ParagraphItem>

    <ParagraphItem prefix='(b)'>
      It configures Boxo technology (e.g. tracking links) in order to pass personal data to
      Boxo for processing.
    </ParagraphItem>

    <ParagraphItem prefix='(c)'>
      It makes available to Boxo personal data in order that Boxo can carry out agreed
      processing activities for the Publisher.
    </ParagraphItem>

    <ParagraphItem prefix='2.4.2'>
      Boxo is the Data Processor where 2.4.1 applies and, in these circumstances, is
      operating according to the instructions of the Data Controller.
    </ParagraphItem>

    <ParagraphItem prefix='2.4.3'>
      When processing personal data each party shall fulfil its legal obligation reflecting its
      role as Data Controller or Data Processor and should raise any disagreements with the
      arrangement outlined within 2.4 prior to commencing or continuing processing of personal
      data.
    </ParagraphItem>

    <ParagraphItem prefix='2.5'>
      For the purpose of fulfilling the services covered by the Publisher Agreement the
      following Data Processing Particulars shall apply:
    </ParagraphItem>

    <ParagraphItem prefix='2.5.1'>
      Processing purpose - measurement, billing and validation of advertising campaign
      activity in connection with the services provided under the Publisher Agreement
    </ParagraphItem>

    <ParagraphItem prefix='2.5.2'>
      Data types - web traffic data including cookies, device identifiers, IP addresses and
      other unique online identifiers as may be provided by the Data Controller and required from
      time to time, email addresses, customer names and address as well as any other details
      required to deliver the services under the Agreement as agreed with the Data Controller from
      time to time.
    </ParagraphItem>

    <ParagraphItem prefix='2.5.3'>
      Data subjects categories - shall include the Data Controller&#39;s customers and
      subscribers or potential customers and subscribers and its other online users/visitors.
    </ParagraphItem>

    <ParagraphItem prefix='2.5.4'>
      Location of processing - the personal data processed with the scope of the Publisher
      Agreement shall be processed in Singapore and the EEA.
    </ParagraphItem>

    <ParagraphItem prefix='2.5.5'>
      Data retention - default data retention policies (which may vary on request) apply as
      stated in the Boxo privacy policy (www.boxo.io )
    </ParagraphItem>

    <h3>3. Controller Instructions</h3>

    <ParagraphItem prefix='3.1'>
      The Data Controller will notify Boxo of any variations to the data processing
      requirements outlined in this Schedule 2. Processing outside the scope of this Schedule 2
      will require prior written agreement between both Parties.
    </ParagraphItem>

    <h3>4. Processor Obligations</h3>

    <ParagraphItem prefix='4.1'>
      Boxo warrants to process personal data only to perform its obligations under the
      Publisher Agreement or other documented instructions and for no other purpose save to the
      limited extent required by law.
    </ParagraphItem>

    <ParagraphItem prefix='4.2'>
      Boxo will ensure that persons employed to process personal data have received
      appropriate training and instructions and have a contractual agreement in place to ensure
      appropriate obligation to confidentiality.
    </ParagraphItem>

    <ParagraphItem prefix='4.3'>
      In the event that the Boxo is unable to fulfil the instructions of the Data Controller or
      believes such instructions to infringe EU Data Protection Laws the it shall promptly notify the
      Data Controller.
    </ParagraphItem>

    <ParagraphItem prefix='4.4'>
      Taking into account the state of the art, the costs of implementation and the nature,
      scope, context and purpose of processing, as well as the risk of varying likelihood and
      severity for the rights and freedoms of natural persons, Boxo shall implement and
      maintain appropriate technical and organisational measures to ensure a level of security
      appropriate to the risk, including the risk of unauthorised or unlawful processing of personal
      data, and of accidental or unlawful loss, alteration, unauthorised disclosure or destruction of,
      or damage to, relevant personal data; such measures to include (without limitation):
    </ParagraphItem>

    <ParagraphItem prefix='4.4.1'>
      Physical security and access controls
    </ParagraphItem>

    <ParagraphItem prefix='4.4.2'>
      Controls to maintain confidentiality
    </ParagraphItem>

    <ParagraphItem prefix='4.4.3'>
      Controls for appropriate and authorised data access and availability;
    </ParagraphItem>

    <ParagraphItem prefix='4.4.4'>
      Password and authentication controls;
    </ParagraphItem>

    <ParagraphItem prefix='4.4.6'>
      Data minimisation, pseudonymisation and encryption
    </ParagraphItem>

    <ParagraphItem prefix='4.5'>
      Boxo will ensure appropriate technical and organisation measures are in place in
      such a manner as is designed to ensure the protection of the rights of the Data Subject and
      to ensure a level of security appropriate to the risk so as to enable the Data Subject&#39;s rights,
      as defined under GDPR Chapter III, to be fulfilled.
    </ParagraphItem>

    <ParagraphItem prefix='4.6'>
      Boxo shall, taking into account the nature of the processing, provide assistance to
      the Data Controller, insofar as possible, in connection with the fulfilment of the Data
      Controller&#39;s obligation to respond to requests for the exercise of Data Subject&#39;s rights
      pursuant to Chapter III of the GDPR to the extent applicable.
    </ParagraphItem>

    <ParagraphItem prefix='4.7'>
      Boxo shall assist the Data Controller in respect of demonstrating its compliance with
      the EU Data Protection Laws, including submitting to reasonable requests to audits and
      inspections.
    </ParagraphItem>

    <ParagraphItem prefix='4.8'>
      Boxo will delete, return or apply the agreed retention policies to all of the Data
      Controller&#39;s personal data in its possession at the end of the contract.
    </ParagraphItem>

    <h3>5. Subcontracting</h3>

    <ParagraphItem prefix='5.1'>
      Both parties accept any use of hosting or infrastructure sub processors (e.g. Amazon
      Web Services) will be restricted to computation, storage and content delivery and will not
      include any other form or processing unless agreed in writing in advance.
    </ParagraphItem>

    <ParagraphItem prefix='5.2'>
      Save clause 5.1 Boxo warrants that it will not employ sub processors for processing
      personal data without the prior specific authorisation of the Data Controller.
    </ParagraphItem>

    <ParagraphItem prefix='5.3'>
      Where sub processors are engaged Boxo warrant that appropriate organisational
      and security measures will be in place equivalent to those set out in this Schedule 2 and
      such that they will ensure the safe, secure and legal processing of personal data.
    </ParagraphItem>

    <ParagraphItem prefix='5.4'>
      Where sub processors are engaged Boxo warrant that appropriate contracts and/or a
      Data Processing Agreement will be in place including terms which are no weaker than those
      contained in this Schedule 2. Boxo shall ensure any sub processor appointed fulfils its
      obligations as stated in this Schedule 2 and within the EU Data Protection Laws.
    </ParagraphItem>

    <h2>6. Record Keeping</h2>

    <ParagraphItem prefix='6.1'>
      Boxo will maintain appropriate records of its personal data processing activities in
      accordance with Article 30 (1) if the GDPR including:
    </ParagraphItem>

    <ParagraphItem prefix='6.1.1'>
      Details of any Sub-processors employed
    </ParagraphItem>

    <ParagraphItem prefix='6.1.2'>
      Categories of processing activities performed
    </ParagraphItem>

    <ParagraphItem prefix='6.1.3'>
      Categories of data processed
    </ParagraphItem>

    <ParagraphItem prefix='6.1.4'>
      Descriptions of technical and organisational measures taken in respect of the data
      processing.
    </ParagraphItem>

    <h3>7. Breach Notification</h3>

    <ParagraphItem prefix='7.1'>
      Boxo will notify the Data Controller of any data breach within 24 hours after it
      becomes aware of the breach. Such notification will be provided in writing and contain
      details as to the categories and extent of the breach and what measures have been taken to
      contain or resolve the incident.
    </ParagraphItem>

    <h3>8. Non-EEA processing</h3>

    <ParagraphItem prefix='8.1'>
      The Parties agree that for the provision of the services under the Publisher Agreement
      that personal data may be transferred outside of the EEA. Where this is the case the parties
      agree that Standard Contractual Clauses shall be incorporated into this Agreement by
      reference with the same effect and force as though fully stated in this Agreement.
    </ParagraphItem>

    <ParagraphItem prefix='8.2'>
      The Data Processing Particulars described in clause 2.5 of this Schedule 2 replaces
      Appendix 1 of the Standard Contractual Clauses.
    </ParagraphItem>

    <ParagraphItem prefix='8.3'>
      Appendix 2 of the Standard Contractual Clauses shall be replaced by 4.4 which
      describes the technical and organisational measures which shall be applied to safeguard the
      processing of personal data:
    </ParagraphItem>

    <h3>9. Data Protection Officer</h3>

    <ParagraphItem prefix='9.1'>
      Boxo will appoint named individuals, including Data Protection Officers where
      required, responsible for data protection.
    </ParagraphItem>

    <h3>10. Indemnities and liabilities</h3>

    <ParagraphItem prefix='10.1'>
      Each Party shall indemnify and keep indemnified the other Party against all claims,
      proceedings, liability, losses, costs and fines arising from or in connection with the defaulting
      Party&#39;s (including its employees, agents and sub-processors) proven personal data breach
      or unauthorised or unlawful processing of personal data. Boxo will indemnify the
      Publisher as per the Publisher Agreement clause 6.1.
    </ParagraphItem>

    <ParagraphItem prefix='10.2'>
      The provisions of this agreement survive any termination of the Publisher Agreement
      (and any other Agreement made between the Parties), howsoever arising.
    </ParagraphItem>

    </div>
  </SinglePageLayout>
);

export default Terms;
