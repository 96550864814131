/*CORE*/
import React, { useEffect, useRef, useState } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
/*LIBS*/
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { CloseCircleOutlined } from '@ant-design/icons'
import { RadioChangeEvent } from 'antd/lib/radio/interface'
import { Button, List, Modal, Radio, Result, Tabs, Typography } from 'antd'
/*COMPONENTS*/
import ResultPane from '../../components/Result/Result'
import WireTransferInfo from '../WireTransferModal/WireTransferInfo'
import PaymentManuallyCard from './PayManuallyCard/PaymentManuallyCard'
import MoneyWrapper from '../../../common/MoneyWrapper'
/*ACTIONS*/
import { setPaymentStateToInitial as setPaymentStateToInitialAction } from 'store/billing/actions'
/*SELECTORS*/
import { paymentCardSelector } from 'store/billing/selectors'
/*UTILS*/
import { STRIPE_STATUSES } from 'utils/enums'
/*TYPES*/
import { IPaymentCard } from 'types/Billing'
import { ICurrency } from 'types/Payouts'
import { RootState } from 'types'
/*STYLES*/
import styles from './PayManually.module.scss'

const { TabPane } = Tabs

interface IStateProps {
  paymentCards: IPaymentCard[]
}

interface IDispatchProps {
  setPaymentStateToInitial: () => void,
}

interface Props extends IStateProps, IDispatchProps {
  amount: string
  currency: ICurrency
  onOk: () => void
  isOpenModal: boolean
  onCancel: () => void
  status: STRIPE_STATUSES
  refCode: string
  handleTryAgain: () => void
  errorMessage: string | null
  handlePayAnotherCard: (cardId: string) => void
  handlePayCollectedCard: (cardId: number) => void
  handlePayAnotherCardError: (errorMessage: string) => void
}

const ANOTHER_CARD_KEY = 'another' as const
const SELECT_CARD_TAB = 'cardsTab' as const
const WIRE_TRANSFER_TAB = 'wireTransferTab' as const

const { Paragraph, Text } = Typography

export const PayManually = (
  {
    onOk,
    amount,
    status,
    refCode,
    currency,
    onCancel,
    isOpenModal,
    paymentCards,
    errorMessage,
    handleTryAgain,
    handlePayAnotherCard,
    handlePayCollectedCard,
    setPaymentStateToInitial,
    handlePayAnotherCardError,
  }: Props) => {

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_PUB_KEY || '')
  const initialSelectedCard = paymentCards?.find(card => card.is_primary)?.id || paymentCards?.[0]?.id || ANOTHER_CARD_KEY

  const [ card, setCard ] = useState<number | null | typeof ANOTHER_CARD_KEY>(initialSelectedCard)
  const [ isLoadingConfirmCard, setIsLoadingConfirmCard ] = useState(false)
  const [ activeTab, setActiveTab ] = useState<string>(SELECT_CARD_TAB)
  const newPaymentCardRef = useRef()

  useEffect(() => {
    isOpenModal && setDefaultState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isOpenModal ])

  const handleChangeCard = (e: RadioChangeEvent) => {
    if (!isLoadingConfirmCard && status !== STRIPE_STATUSES.Loading) {
      setCard(e.target.value)
    }
  }

  const onPayCollectedCard = () => {
    if (card && typeof card === 'number') {
      handlePayCollectedCard(card)
    }
  }

  const setDefaultState = () => {
    setPaymentStateToInitial()
    setActiveTab(SELECT_CARD_TAB)
    setCard(paymentCards?.find(card => card.is_primary)?.id || ANOTHER_CARD_KEY)
  }

  const handleSubmit = () => {
    if (card === ANOTHER_CARD_KEY) {
      // @ts-ignore
      newPaymentCardRef.current && newPaymentCardRef.current.submit()
    } else {
      onPayCollectedCard()
    }
  }

  const renderFooterBtns = () => {
    if (status !== STRIPE_STATUSES.Init && status !== STRIPE_STATUSES.Loading) {
      return null
    }
    if (activeTab === SELECT_CARD_TAB) {
      return (
        <>
          <Button
            size='large'
            type='primary'
            htmlType='submit'
            onClick={handleSubmit}
            loading={isLoadingConfirmCard || status === STRIPE_STATUSES.Loading}
          >
            Pay {amount} {currency.code}
          </Button>
          <Button key='back' size='large' className='btn-outline' onClick={onCancel}>
            Cancel
          </Button>
        </>
      )
    }
    return (
      <Button size='large' type='primary' onClick={onCancel}>Done</Button>
    )
  }

  return (
    <Modal
      centered
      onOk={onOk}
      title={(status !== STRIPE_STATUSES.Success && status !== STRIPE_STATUSES.Error) && (
        <span>Pay manually <MoneyWrapper currency={currency} amount={amount} className={styles['amount-wrapper']} /></span>
      )}
      footer={renderFooterBtns()}
      closable={false}
      onCancel={onCancel}
      visible={isOpenModal}
      wrapClassName={styles['pay-manually-modal']}
    >
      {
        (status === STRIPE_STATUSES.Init || status === STRIPE_STATUSES.Loading) && (
          <Tabs activeKey={activeTab} onChange={(tab: string) => setActiveTab(tab)}>
            <TabPane tab='Card payment' key={SELECT_CARD_TAB}>
              <Radio.Group className={styles['radio-group']} value={card} onChange={handleChangeCard}>
                <List
                  bordered
                  dataSource={paymentCards}
                  renderItem={(card: IPaymentCard) => (
                    <List.Item>
                      <div className={styles['card-item']}>
                        <Radio value={card.id} />
                        <div>
                          <Text className={styles['card-name']}>
                            {card.name}{card.is_primary && <span className={styles['default']}>default</span>}
                          </Text>
                          <Text style={{ textTransform: 'capitalize' }}>{card.brand} **** {card.last4}</Text>
                        </div>
                      </div>
                    </List.Item>
                  )}
                  footer={(
                    <List.Item>
                      <div className={styles['card-item']}>
                        <Radio value={ANOTHER_CARD_KEY} />
                        <div>
                          <Text className={styles['card-name']}>Add new</Text>
                          <Text>Credit or debit cards</Text>
                        </div>
                      </div>
                      <div>
                        <Elements stripe={stripePromise}>
                          {card === ANOTHER_CARD_KEY && (
                            <PaymentManuallyCard
                              handleConfirmCardSuccess={handlePayAnotherCard}
                              handleConfirmCardError={handlePayAnotherCardError}
                              handleLoading={state => setIsLoadingConfirmCard(state)}
                              ref={newPaymentCardRef}
                            />
                          )}
                        </Elements>
                      </div>
                    </List.Item>
                  )}
                >
                </List>
              </Radio.Group>
            </TabPane>
            <TabPane tab='Wire transfer' key={WIRE_TRANSFER_TAB}>
              <WireTransferInfo refCode={refCode} />
            </TabPane>
          </Tabs>
        )}

      {
        status === STRIPE_STATUSES.Success && (
          <ResultPane
            title='Your payment was successful'
          >
            <Button
              type='primary'
              size='large'
              block
              style={{ width: '340px' }}
              onClick={onOk}
            >
              Continue
            </Button>
          </ResultPane>
        )}

      {
        status === STRIPE_STATUSES.Error && (
          <Result
            status='error'
            title='Operation Failed'
            subTitle='Please check and modify the following information before resubmitting.'
            extra={[
              <Button key='buy' type='primary' onClick={handleTryAgain}>Try Again</Button>
            ]}
          >
            <div>
              <Paragraph>
                <Text
                  strong
                  style={{
                    fontSize: 16,
                  }}
                >
                  The card you submitted has the following error:
                </Text>
              </Paragraph>
              <Paragraph>
                <CloseCircleOutlined style={{ color: 'red' }} /> {errorMessage}
              </Paragraph>
            </div>
          </Result>
        )}
    </Modal>
  )
}

const mapStateToProps = (state: RootState) => {

  let paymentCards: IPaymentCard[] = [ ...paymentCardSelector(state) ]

  const primaryCardIdx = paymentCards.findIndex(card => card.is_primary)

  if (primaryCardIdx) {
    paymentCards = [ ...paymentCards.splice(primaryCardIdx), ...paymentCards ]
  }

  return {
    paymentCards,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators({
      setPaymentStateToInitial: setPaymentStateToInitialAction,
    }, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PayManually)
