import React from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  useHistory,
  useRouteMatch,
  Switch as RouterSwitch,
  Route,
  Redirect,
} from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { Tabs } from "antd";

import MainPaneLayout from "../../components/MainPaneLayout/MainPaneLayout";
import BillingTable from "../BillingTable/BillingTable";
import PaymentMethods from "../PaymentMethods/PaymentMethods";
import BillingHistory from "../BillingHistory/BillingHistory";
import { allInvoicesSelector } from "store/billing/selectors";
import {
  loadMiniAppInvoices as loadMiniAppInvoicesAction,
  loadSuperAppInvoices as loadSuperAppInvoicesAction,
} from "store/billing/actions";
import { RootState } from "types";
import { profileSelector } from "store/profile/selectors";

const { TabPane } = Tabs;

interface Props extends ConnectedProps<typeof connector> {}

const Billing = ({
  allInvoices,
  profile,
  updateMiniAppInvoices,
  updateSuperAppInvoices,
}: Props) => {
  const history = useHistory();
  const match = useRouteMatch();

  const handleTabChange = (activeTab: string) => {
    history.replace(`${match.url}/${activeTab}`);
  };

  const getActiveTab = () => {
    // TODO: Handle it with routing architecture
    const p = history.location.pathname;
    return p.split("/")[2];
  };

  const updateInvoices = () => {
    updateMiniAppInvoices();
    updateSuperAppInvoices();
  };

  return (
    <MainPaneLayout
      title="Billing"
      tooltip="Amount billed for transaction or launch commissions, automatically deducted from the stored credit card."
    >
      <Tabs activeKey={getActiveTab()} onChange={handleTabChange}>
        <TabPane tab="Month-to-date" key="month-to-date" />
        <TabPane tab="Payment Methods" key="methods" />
        <TabPane tab="Billing History" key="history" />
      </Tabs>
      <RouterSwitch>
        <Route path={`${match.url}/month-to-date`}>
          <BillingTable />
        </Route>
        <Route path={`${match.url}/methods`}>
          <PaymentMethods />
        </Route>
        <Route path={`${match.url}/history`}>
          <BillingHistory
            invoices={allInvoices}
            updateInvoices={updateInvoices}
          />
        </Route>
        <Route path="*">
          <Redirect to={{ pathname: profile.is_billing_and_payouts_enabled ? `${match.url}/month-to-date` : '/'  }} />
        </Route>
      </RouterSwitch>
    </MainPaneLayout>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    allInvoices: allInvoicesSelector(state),
    profile: profileSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators(
      {
        updateMiniAppInvoices: loadMiniAppInvoicesAction,
        updateSuperAppInvoices: loadSuperAppInvoicesAction,
      },
      dispatch
    ),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Billing);
