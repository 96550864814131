/*CORE*/
import React from 'react'
/*LIBS*/
import { connect, ConnectedProps } from 'react-redux'
/*ASSETS*/
import placeholder from 'assets/img/no-data-placeholers/placeholder_month-to-date.png'
/*COMPONENTS*/
import TotalPayouts from '../TotalPayouts/TotalPayouts'
import AppsPaymentTable from '../../Payment/AppsPaymentTable/AppsPaymentTable'
import NoDataPlaceholder from '../../components/NoDataPlaceholder/NoDataPlaceholder'
/*SELECTORS*/
import { miniAppsPayoutsPlansSelector, superAppsPayoutsPlansSelector } from 'store/billing/selectors'
/*TYPES*/
import { RootState } from 'types'


interface Props extends ConnectedProps<typeof connector> {
}

const PayoutTable = ({ superAppPlans, miniAppPlans }: Props) => {
  return (
    <>
      <TotalPayouts appPlans={[ ...superAppPlans, ...miniAppPlans ]} />
      {
        !!superAppPlans.length || !!miniAppPlans.length ?
          <>
            <AppsPaymentTable appPlans={superAppPlans} title='My hostapps' />
            <AppsPaymentTable appPlans={miniAppPlans} title='My miniapps' />
          </>
          :
          <NoDataPlaceholder
            height={'265px'}
            img={placeholder}
            text='Month-to-date totals from all of your partnerships will be collected here.'
          />
      }
    </>
  )
}


const mapStateToProps = (state: RootState) => {
  return {
    miniAppPlans: miniAppsPayoutsPlansSelector(state),
    superAppPlans: superAppsPayoutsPlansSelector(state)
  }
}

const connector = connect(mapStateToProps)

export default connector(PayoutTable)
