/*CORE*/
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react'
/*LIBS*/
import classnames from 'classnames'
/*STYLES*/
import styles from './ImageWithPlaceholder.module.scss'

const SPINNER_DELAY = 500 as const
const RELOAD_IMG_COUNT = 1 as const
const RELOAD_IMG_DELAY = 2000 as const

export interface IImgWithPlaceholderRefAttrs {
  onRefresh(): void
}

interface Props {
  src: string
  className?: string
  onError?: () => void
  loading?: (isLoading: boolean) => void
  errorPlaceholder?: JSX.Element
  loadingPlaceholder?: JSX.Element
}

const ImageWithPlaceholder = React.forwardRef<IImgWithPlaceholderRefAttrs, Props>((
  {
    src,
    loading,
    onError,
    className,
    errorPlaceholder,
    loadingPlaceholder,
  }, ref
  ) => {

    const [ reloadImgCount, setImageReloadCounter ] = useState<number>(0)
    const [ isLoaded, setIsLoaded ] = useState(false)
    const [ isError, setIsError ] = useState(false)

    const imgRef = useRef<HTMLImageElement | null>(null)

    useEffect(() => {
      loading && loading(true)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ src ])

    useImperativeHandle(ref, () => ({
      onRefresh() {
        forceReload()
      }
    }))

    function setInitState() {
      setIsLoaded(false)
      setIsError(false)
    }

    function reloadImage() {
      setTimeout(() => {
        forceReload()
        setImageReloadCounter(reloadImgCount + 1)
      }, RELOAD_IMG_DELAY)
    }

    function forceReload() {
      setInitState()
      imgRef && imgRef.current && (imgRef.current.src = src)
    }

    function onLoad() {
      setTimeout(() => {
        setIsLoaded(true)
        loading && loading(false)
      }, SPINNER_DELAY)
    }

    function onErrorHandler() {
      if (reloadImgCount < RELOAD_IMG_COUNT) {
        reloadImage()
      } else {
        onError && onError()
        setIsError(true)
      }
    }

    return (
      <div className={classnames(styles['wrapper'], className)}>
        <div className={styles['placeholder']}>
          {
            isError && errorPlaceholder ? errorPlaceholder : !isLoaded && loadingPlaceholder && loadingPlaceholder
          }
        </div>
        <img
          ref={imgRef}
          onLoad={onLoad}
          src={src} alt="alt"
          onError={onErrorHandler}
          className={isLoaded && !isError ? styles['loaded'] : ''}
        />
      </div>
    )
  }
)

export default ImageWithPlaceholder
