import React from 'react'

import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import './SpinnerOverlay.scss'

const SpinnerOverlay = () => {
  const icon = <LoadingOutlined style={{ fontSize: 24 }} spin />
  return (
    <div className="spinner-overlay">
      <Spin indicator={icon} />
    </div>
  )
}

export default SpinnerOverlay
