/*CORE*/
import React from 'react'
/*LIBS*/
import { Tooltip } from 'antd'
/*TYPES*/
import { ICurrency } from 'types/Payouts'
/*STYLES*/
import styles from './CurrencyDataTooltip.module.scss'

interface CurrencyDataTooltipProps {
  currency: ICurrency
  exchangeRateDate: string
  children: React.ReactElement
  hidden?: boolean
}

const CurrencyDataTooltip = ({ currency, exchangeRateDate, children, hidden }: CurrencyDataTooltipProps) => {
  return hidden ? children : (
    <Tooltip
      placement='top'
      overlayClassName={styles['currency-data-tooltip']}
      overlay={
        <div>
          <span className={styles['currency-data-tooltip__header']}>{children}</span>
          <hr />
          <p>
            Estimated amount in {currency.code} on {exchangeRateDate}<br />
            Source: <a href='https://openexchangerates.org/' target='_blank' rel='noopener noreferrer'>
            openexchangerates.org</a>
          </p>
        </div>
      }
    >
      <div style={{ display: 'inline-block', cursor: 'pointer' }}>
        {children}
      </div>
    </Tooltip>
  )
}

export default CurrencyDataTooltip
