import React from 'react'
import { Button, Modal } from 'antd'

import WireTransferInfo from './WireTransferInfo'

import styles from './WireTransferModal.module.scss'


interface Props {
  isOpen: boolean
  onClose: () => void
}

const WireTransferModal = ({ isOpen, onClose }: Props) => {
  return (
    <Modal
      className={styles['wire-transfer-modal']}
      visible={isOpen}
      title='Wire Transfer'
      closable={false}
      width={630}
      footer={false}
    >
      <WireTransferInfo />
      <div className={styles['footer']}>
        <Button onClick={onClose} className='btn-outline'>Close</Button>
      </div>
    </Modal>
  )
}

export default WireTransferModal
