/*CORE*/
import React, { useState } from 'react'
/*LIBS*/
import { EllipsisOutlined } from '@ant-design/icons/lib'
import { Button, Dropdown, Menu, Typography } from 'antd'
import { bindActionCreators, Dispatch } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
/*ACTIONS*/
import {
  downloadMiniappInvoice as downloadMiniappInvoiceAction,
  downloadSuperappInvoice as downloadSuperappInvoiceAction,
  payMiniAppInvoice as payMiniAppInvoiceAction,
  payMiniAppInvoiceWithAnotherCard as payMiniAppInvoiceWithAnotherCardAction,
  paySuperAppInvoice as paySuperAppInvoiceAction,
  paySuperAppInvoiceWithAnotherCard as paySuperAppInvoiceWithAnotherCardAction,
  setPaymentStateToError as setPaymentStateToErrorAction,
  setPaymentStateToInitial as setPaymentStateToInitialAction
} from 'store/billing/actions'
/*SELECTORS*/
import { paymentStatusSelector } from 'store/billing/selectors'
/*COMPONENTS*/
import Badge from '../../components/Badge/Badge'
import PayManually from '../PayManually/PayManually'
import DoubleLogo from '../../components/DoubleLogo/DoubleLogo'
import NoDataPlaceholder from '../../components/NoDataPlaceholder/NoDataPlaceholder'
import CustomSelect, { CustomSelectOption } from '../../components/CustomSelect/CustomSelect'
import MoneyWrapper from '../../../common/MoneyWrapper'
import PaymentMethod from './PaymentMethod'
/*CONSTANTS*/
import { PAYMENT_STATUS } from 'utils/billing'
import { DEFAULT_CURRENCY, FULL_DATE_FORMAT, AppType } from 'utils/constants'
/*TYPES*/
import { IAppInvoice, InvoiceStatus } from 'types/Billing'
import { RootState } from 'types'
/*ASSETS*/
import placeholder from 'assets/img/no-data-placeholers/placeholder_billing_history.png'
/*STYLES*/
import styles from '../../Payment/PaymentHistory.module.scss'


const STATUSES: CustomSelectOption[] = [
  { label: 'All invoices', value: 'All' },
  { label: PAYMENT_STATUS[InvoiceStatus.Paid].label, value: InvoiceStatus.Paid },
  { label: PAYMENT_STATUS[InvoiceStatus.DueToPay].label, value: InvoiceStatus.DueToPay },
  { label: PAYMENT_STATUS[InvoiceStatus.Failed].label, value: InvoiceStatus.Failed },
]

const { Title } = Typography

interface Props extends ConnectedProps<typeof connector> {
  invoices: IAppInvoice[]
  updateInvoices: () => void
}

const BillingHistory = ({
                          paymentStatus, downloadMiniappInvoice, setPaymentStateToError, setPaymentStateToInitial,
                          invoices, paySuperAppInvoice, paySuperAppInvoiceWithAnotherCard, payMiniAppInvoice,
                          payMiniAppInvoiceWithAnotherCard, updateInvoices, downloadSuperappInvoice
                        }: Props) => {
  const [ invoiceForManualPay, setInvoiceForManualPayment ] = useState<IAppInvoice>({} as IAppInvoice)
  const [ isOpenModal, setIsOpenModal ] = useState(false)
  const [ statusFilter, setStatusFilter ] = React.useState(STATUSES[0])
  const history = useHistory()

  const onOpenPaymentManually = (invoice: IAppInvoice, event: React.MouseEvent) => {
    event.stopPropagation()
    setInvoiceForManualPayment(invoice)
    setIsOpenModal(true)
  }

  const handlePayAnotherCardError = (error: string) => {
    setPaymentStateToError(error)
  }

  const handlePayAnotherCard = (card_id: string) => {
    if (invoiceForManualPay.invoiced === AppType.Miniapp) {
      invoiceForManualPay.id && payMiniAppInvoiceWithAnotherCard({ card_id }, invoiceForManualPay.id)
    } else {
      invoiceForManualPay.id && paySuperAppInvoiceWithAnotherCard({ card_id }, invoiceForManualPay.id)
    }
  }

  const goToBillingInfo = (invoice: IAppInvoice) => {
    if (invoice.invoiced === AppType.Miniapp) {
      history.push(`/miniapp-billing-info/${invoice.id}`)
    } else {
      history.push(`/superapp-billing-info/${invoice.id}`)
    }
  }

  const handlePayCollectedCard = (card_id: number) => {
    if (invoiceForManualPay.invoiced === AppType.Miniapp) {
      invoiceForManualPay?.id && payMiniAppInvoice({ card_id }, invoiceForManualPay.id)
    } else {
      invoiceForManualPay?.id && paySuperAppInvoice({ card_id }, invoiceForManualPay.id)
    }
  }

  const handleDownloadInvoice = (invoice: IAppInvoice, format: string) => {
    if (invoice.invoiced === AppType.Miniapp) {
      downloadMiniappInvoice(invoice, format)
    } else {
      downloadSuperappInvoice(invoice, format)
    }
  }

  const handleTryAgain = () => {
    setPaymentStateToInitial()
  }

  const onOKPayManually = () => {
    setIsOpenModal(false)
    updateInvoices()
  }

  const onCancelPayManually = () => {
    setIsOpenModal(false)
  }

  const filteredInvoices = invoices.filter((invoice) => statusFilter === STATUSES[0] || invoice.status === statusFilter.value)

  return (
    <div className={styles['billing-history']}>
      <div className={styles['header']}>
        <Title level={3}>Billing History</Title>
        <CustomSelect options={STATUSES} activeOption={statusFilter} onClickOption={status => setStatusFilter(status)} />
      </div>
      <table>
        <thead>
        <tr>
          <th>Amount</th>
          <th>Payment Method</th>
          <th>Payment Date</th>
          <th>ID</th>
          <th>Status</th>
          <th />
        </tr>
        </thead>
        <tbody>
        {
          !!filteredInvoices.length ?
            filteredInvoices.map((invoice, index) => (
              <tr key={index} onClick={() => goToBillingInfo(invoice)}>
                <td>
                  <div className={styles['amount']}>
                    <DoubleLogo backLogo={invoice.app_plan.hostapp.logo} frontLogo={invoice.app_plan.miniapp.logo} />
                    <MoneyWrapper currency={invoice.currency} amount={invoice.amount} />
                  </div>
                </td>
                <td>
                  {(invoice.payment_method && invoice.payment_method_details)
                    ? <PaymentMethod
                      paymentMethod={invoice.payment_method}
                      paymentMethodDetails={invoice.payment_method_details}
                      className={styles['payer']}
                    />
                    : <>-</>
                  }
                </td>
                <td>
                  {moment(invoice.paid_date ? invoice.paid_date : invoice.due_date).format(FULL_DATE_FORMAT)}
                </td>
                <td>
                  {invoice.invoice_number}
                </td>
                <td>
                  <div className={styles['status-block']}>
                    <Badge
                      status={PAYMENT_STATUS[invoice.status]?.status}
                      label={PAYMENT_STATUS[invoice.status]?.label}
                    />
                    {
                      invoice.status === InvoiceStatus.Failed &&
                      <Button type='link' onClick={e => onOpenPaymentManually(invoice, e)}>
                        Pay manually
                      </Button>
                    }
                  </div>
                </td>
                <td>
                  <div onClick={event => event.stopPropagation()}>
                    <Dropdown
                      placement='bottomRight'
                      overlay={(
                        <Menu>
                          <Menu.Item key={0} onClick={() => handleDownloadInvoice(invoice, 'csv')}>
                            Download as CSV
                          </Menu.Item>
                          <Menu.Item key={1} onClick={() => handleDownloadInvoice(invoice, 'pdf')}>
                            Download as PDF
                          </Menu.Item>
                        </Menu>
                      )}
                      trigger={[ 'click' ]}
                    >
                      <EllipsisOutlined />
                    </Dropdown>
                  </div>
                </td>
              </tr>
            ))
            :
            <tr style={{backgroundColor: '#fff', cursor: 'auto'}}>
              <td style={{padding: '0px'}} colSpan={5}>
                <div style={{ marginTop: '50px' }}>
                  <NoDataPlaceholder
                    text='Invoices will be collected here.'
                    img={placeholder}
                    height={'265px'}
                  />
                </div>
              </td>
            </tr>
        }
        </tbody>
      </table>
      <PayManually
        onOk={onOKPayManually}
        isOpenModal={isOpenModal}
        status={paymentStatus.status}
        onCancel={onCancelPayManually}
        handleTryAgain={handleTryAgain}
        errorMessage={paymentStatus?.error || ''}
        handlePayAnotherCard={handlePayAnotherCard}
        refCode={invoiceForManualPay.invoice_number}
        handlePayCollectedCard={handlePayCollectedCard}
        handlePayAnotherCardError={handlePayAnotherCardError}
        currency={invoiceForManualPay.currency || DEFAULT_CURRENCY}
        amount={parseFloat(invoiceForManualPay.amount || '0').toFixed(2)}
      />
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    paymentStatus: paymentStatusSelector(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators({
      downloadMiniappInvoice: downloadMiniappInvoiceAction,
      downloadSuperappInvoice: downloadSuperappInvoiceAction,
      setPaymentStateToError: setPaymentStateToErrorAction,
      setPaymentStateToInitial: setPaymentStateToInitialAction,

      paySuperAppInvoice: paySuperAppInvoiceAction,
      paySuperAppInvoiceWithAnotherCard: paySuperAppInvoiceWithAnotherCardAction,
      payMiniAppInvoice: payMiniAppInvoiceAction,
      payMiniAppInvoiceWithAnotherCard: payMiniAppInvoiceWithAnotherCardAction,
    }, dispatch),
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(BillingHistory)
