/*CORE*/
import React from 'react'
/*LIBS*/
import { Button, Form, Input, Checkbox } from 'antd'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
/*UTILS*/
import { hasErrors } from 'utils/utils'
/*STYLES*/
import styles from './InvitationForm.module.scss'

export interface FormData {
  first_name?: string
  last_name?: string
  email?: string
  password?: string
  agreement?: boolean
}

interface FieldData {
  [key: string]: any
}

interface Props {
  className?: string
  onSubmit?: (values: FormData) => void
  isLoading?: boolean
  initialValues?: FormData
  errors?: string[]
}

const InvitationForm = ({ onSubmit, className, isLoading, errors, initialValues }: Props) => {
  const [form] = Form.useForm()
  const [submitDisabled, setSubmitDisabled] = React.useState(true)

  const handleFieldsChange = (changedFields: FieldData[], fields: FieldData[]) => {
    setSubmitDisabled(hasErrors(fields))
  }

  return (
    <Form
      form={form}
      className={classnames(styles['invitation-form'], className)}
      onFinish={onSubmit}
      onFieldsChange={handleFieldsChange}
      initialValues={initialValues}
      layout="vertical"
    >
      <div className={styles['invitation-form__content']}>
        <Form.Item
          name='email'
        >
          <Input readOnly placeholder='Work email' size='large'/>
        </Form.Item>
        <Form.Item
          name='first_name'
          rules={[
            {
              required: true,
              message: 'Please enter first name'
            }
          ]}
          hasFeedback
        >
          <Input placeholder='Name' size='large'/>
        </Form.Item>
        <Form.Item
          name='last_name'
          rules={[
            {
              required: true,
              message: 'Please enter surname'
            }
          ]}
          hasFeedback
        >
          <Input placeholder='Surname' size='large'/>
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              min: 8,
              message: "Must be at least 8 characters long"
            },
            {
              required: true,
              message: "Please enter password"
            }
          ]}
          hasFeedback
        >
          <Input.Password
            placeholder="Password"
            size="large"
          />
        </Form.Item>
        <Form.Item
          name="agreement"
          valuePropName="checked"
          rules={[
            {
              validator: (rule, value) => {
                if (value === true) {
                  return Promise.resolve()
                }
                return Promise.reject('Terms and conditions must be accepted')
              }
            }
          ]}
        >
          <Checkbox className={styles['invitation-form__checkbox']}>
            I agree to Appboxo’s <Link to='/registration-terms' target='_blank' rel='noopener noreferrer'>Terms
            </Link> and <Link to='/privacy' target='_blank' rel='noopener noreferrer'>Privacy Policy</Link>
          </Checkbox>
        </Form.Item>
        {
          errors && errors.length > 0 &&
          <div className={styles['invitation-form__errors']}>
            {errors.map((error, idx) => (
              <span key={idx} className={styles['invitation-form__error']}>{error}</span>
            ))}
          </div>
        }
      </div>
      <div className={styles['invitation-form__footer']}>
        <Button
          loading={isLoading}
          type="primary"
          htmlType="submit"
          size="large"
          block
          disabled={submitDisabled}
        >
          {isLoading ? 'Creating' : 'Create account'}
        </Button>
      </div>
    </Form>
  )
}

export default InvitationForm
