import React from 'react'
import { message } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { updateBankAccount as updateBankAccountAction } from 'store/payouts/actions'
import { bankAccountsSelector, currenciesSelector } from 'store/payouts/selectors'
import BankAccountForm from '../BankAccountForm/BankAccountForm'
import { IBankAccount } from 'types/Payouts'
import { RootState } from 'types'


interface Props extends ConnectedProps<typeof connector> {}

const EditBankAccount = ({ updateBankAccount, bankAccounts, currencies }: Props) => {
  const history = useHistory()
  const { bankAccountId } = useParams()

  const bankAccount = bankAccounts.find((account: IBankAccount) => account.id === +bankAccountId!)
  if (!bankAccount) {
    message.error('Bank account not found')
    history.push('/payouts/details')
  }

  const handleSave = (values: FormData) => {
    updateBankAccount(bankAccount.id, values)
    message.success('Updated successfully')
    history.push('/payouts/details')
  }

  return (
    <BankAccountForm onSave={handleSave} bankAccount={bankAccount} currencies={currencies} />
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    currencies: currenciesSelector(state),
    bankAccounts: bankAccountsSelector(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators({
      updateBankAccount: updateBankAccountAction
    }, dispatch),
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(EditBankAccount)

