export const formatAmount = (amount: number | string) => {
  if (typeof amount === 'string') {
    amount = Number(amount)
  }
  if (!Number.isInteger(amount)) {
    amount = roundToDecimals(amount)
  }
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const roundToDecimals = (num: number, dec: number = 2) => {
  const calcDec = Math.pow(10, dec)
  return Math.round(num * calcDec) / calcDec
}
