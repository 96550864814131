/*CORE*/
import React, { ReactNode } from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
/*LIBS*/
import { connect, ConnectedProps } from 'react-redux'
/*SELECTORS*/
import { isAuthenticated } from '../store/auth/selectors'
/*TYPES*/
import { RootState } from '../types'

// A wrapper for <Route> that redirects to the home
// screen if you're authenticated.

interface Props extends ConnectedProps<typeof connector>, RouteProps {
  children: ReactNode
}

const PublicNotAuthedRoute = ({ children, isAuthenticated, ...rest }: Props) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ?
          <Redirect
            to={{
              pathname: '/',
              state: { from: location }
            }}
          />
          :
          children
      }
    />
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    isAuthenticated: isAuthenticated(state)
  }
}

const connector = connect(mapStateToProps)

export default connector(PublicNotAuthedRoute)
