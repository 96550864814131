import React, { useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Typography, Form, Input, Button, message } from 'antd'
import { LockOutlined } from '@ant-design/icons'

import SinglePageLayout from '../../components/SinglePageLayout/SinglePageLayout'
import { changePassword as changePasswordAction } from 'store/profile/actions'
import { goBack, hasErrors, normalizeFields } from 'utils/utils'

const { Title } = Typography

interface FormData {
  [key: string]: any
}

interface Props extends ConnectedProps<typeof connector> {}

const ChangePassword = ({ changePassword }: Props) => {
  const [form] = Form.useForm()
  const [submitDisabled, setSubmitDisabled] = React.useState(true)
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const handleClose = () => {
    goBack(history, '/account')
  }

  const handleSubmit = ({ current_password, new_password }: FormData) => {
    updatePassword({
      current_password,
      new_password
    })
  }

  const handleFieldsChange = (changedFields: FormData[], fields: FormData[]) => {
    const values = normalizeFields(fields)
    setSubmitDisabled(
      !(values.current_password.value && values.new_password.value &&
        values.new_password.value === values.confirm_new_password.value) || hasErrors(fields)
    )
  }

  const updatePassword = async (data: any) => {
    setLoading(true)
    try {
      await changePassword(data)
      message.success('Updated successfully')

      form.resetFields()
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const errors = Object.keys(error.response.data).map(errorKey => ({
          name: errorKey,
          errors: error.response.data[errorKey]
        }))

        form.setFields(errors)
      } else {
        message.error('Something went wrong. Please try again.')
      }
    }
    setLoading(false)
  }

  return (
    <SinglePageLayout
      onGoBack={handleClose}
      isFullHeight
    >
      <div className="form-layout">
        <Form
          form={form}
          className="form-layout__form-wrapper"
          onFinish={handleSubmit}
          onFieldsChange={handleFieldsChange}
        >
          <Title>Change password</Title>
          <div className="profile__form">
            <Form.Item
              name='current_password'
              rules={[{ required: true, message: 'Please input your current password' }]}
              hasFeedback
            >
              <Input.Password
                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="password"
                placeholder="Current password"
                size="large"
              />
            </Form.Item>
            <Form.Item
              name='new_password'
              rules={[{ required: true, message: 'Please input your new password' }]}
              hasFeedback
            >
              <Input.Password
                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="password"
                placeholder="New password"
                size="large"
              />
            </Form.Item>
            <Form.Item
              name='confirm_new_password'
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('new_password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject('The two passwords that you entered do not match');
                  },
                }),
              ]}
              hasFeedback
            >
              <Input.Password
                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="password"
                placeholder="Confirm new password"
                size="large"
              />
            </Form.Item>
          </div>
          <div className="profile__submit">
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              block
              loading={loading}
              disabled={submitDisabled}
            >
              {loading ? 'Updating' : 'Update'}
            </Button>
          </div>
        </Form>
      </div>
    </SinglePageLayout>
  )
}

const connector = connect(null, {
  changePassword: changePasswordAction
})

export default connector(ChangePassword)
