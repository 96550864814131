/*CORE*/
import React from 'react'
/*ASSETS*/
import logoPlaceholder from 'assets/img/no-data-placeholers/logo_placeholder.png'
/*STYLES*/
import styles from './DoubleLogo.module.scss'

const DoubleLogo = ({ backLogo, frontLogo }: { backLogo: string, frontLogo: string }) => (
  <div className={styles['double-logo']}>
    <div>
      <img src={backLogo || logoPlaceholder} alt='back-logo' />
    </div>
    <div>
      <img src={frontLogo || logoPlaceholder} alt='front-logo' />
    </div>
  </div>
)

export default DoubleLogo
