/*CORE*/
import React, {  useRef, useState } from 'react'
/*LIBS*/
import { Tooltip } from 'antd'
import classnames from 'classnames'
import { DeleteOutlined, EyeOutlined, ReloadOutlined } from '@ant-design/icons/lib'
/*COMPONENTS*/
import ImageWithPlaceholder, { IImgWithPlaceholderRefAttrs } from '../../../../../components/ImageWithPlaceholder/ImageWithPlaceholder'
import LoadingPlaceholder from '../ImgPlaceholders/LoadingPlaceholder/LoadingPlaceholder'
import ErrorPlaceholder from '../ImgPlaceholders/ErrorPlaceholder/ErrorPlaceholder'
import Spinner from '../../../../../components/Spinner/Spinner'
/*CONSTANTS*/
import { BASE_URL } from 'utils/constants'
/*STYLES*/
import styles from './ScreenshotPreview.module.scss'

interface Props {
  imgUrl: string
  className?: string
  onDelete: () => void
  onPreview: () => void
  isDeleteInProgress?: boolean
}

const ActionTooltip = ({ children, title }: { children: JSX.Element, title: string }) =>
  <Tooltip
    title={title}
    trigger={'hover'}
    placement="bottom"
    align={{ offset: [ 0, 17 ] }}
    overlayClassName={'action-tooltip'}
  >
    {
      children
    }
  </Tooltip>

const Actions = ({ onPreview, onDelete }: { onPreview: () => void, onDelete: () => void }) =>
  <div className={styles['actions']}>
    <ActionTooltip title={'Preview'}>
      <div className={styles['action']} onClick={onPreview}><EyeOutlined className={styles['icon']} /></div>
    </ActionTooltip>

    <ActionTooltip title={'Delete'}>
      <div className={styles['action']} onClick={onDelete}><DeleteOutlined className={styles['icon']} /></div>
    </ActionTooltip>
  </div>

const ErrorActions = ({ onReloadImg, onDelete }: { onReloadImg: () => void, onDelete: () => void }) =>
  <div className={styles['actions']}>
    <ActionTooltip title={'Reload'}>
      <div className={styles['action']} onClick={onReloadImg}><ReloadOutlined className={styles['icon']} /></div>
    </ActionTooltip>
    <ActionTooltip title={'Delete'}>
      <div className={styles['action']} onClick={onDelete}><DeleteOutlined className={styles['icon']} /></div>
    </ActionTooltip>
  </div>

const ScreenshotPreview = ({ imgUrl, className, onDelete, onPreview, isDeleteInProgress }: Props) => {
  const [ showActionIcons, setShowActionIcons ] = useState(false)
  const [ showErrorActionIcons, setShowErrorActionIcons ] = useState(false)
  const imgWithPlaceholderRef = useRef<IImgWithPlaceholderRefAttrs | null>(null)

  const onReloadImg = () => {
    imgWithPlaceholderRef?.current?.onRefresh()
  }

  function onErrorPlaceholder() {
    setShowActionIcons(false)
    setShowErrorActionIcons(true)
  }

  function loadingPlaceholder(isLoading: boolean) {
    setShowActionIcons(!isLoading)
    setShowErrorActionIcons(!isLoading)
  }

  return (
    <div
      className={
        classnames(
          styles['screenshot-preview'],
          !isDeleteInProgress && (showActionIcons || showErrorActionIcons) && styles['screenshot-preview__allowed'],
          isDeleteInProgress && styles['blackout'],
          className
        )
      }
    >
      {
        isDeleteInProgress ?
          <div className={styles['loader']}>
            <Spinner color={'#fff'} />
          </div>
          :
          showActionIcons ?
            <Actions onDelete={onDelete} onPreview={onPreview} />
            :
            showErrorActionIcons &&
            <ErrorActions onDelete={onDelete} onReloadImg={onReloadImg} />
      }
      {/*
        TODO (Vlad): Fix src when backend is ready
      */}
      <ImageWithPlaceholder
        src={BASE_URL + imgUrl}
        className={styles['img']}
        ref={imgWithPlaceholderRef}
        loading={loadingPlaceholder}
        onError={onErrorPlaceholder}
        errorPlaceholder={<ErrorPlaceholder />}
        loadingPlaceholder={<LoadingPlaceholder />}
      />
    </div>
  )
}

export default ScreenshotPreview
