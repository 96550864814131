// A custom hook that builds on useLocation to parse
// the query string
import { useLocation } from 'react-router-dom'

export const useQuery = (): Record<string, string> => {
  const next = useLocation().search
  if (next) {
    return next.replace('?', '')
      .split('&')
      .reduce(
        (memo, param) => ({
          ...memo,
          [param.split("=")[0]]: param.split("=")[1]
        }),
        {}
      )
  } else {
    return {}
  }
}
