import { LOAD_COUNTRIES, UPDATE_ORGANIZATION } from './actions'
import { Country, OrganizationData, ReducerAction } from '../../types'
import { UPDATE_PAYMENT_CARD } from '../billing/actions'
import { PaymentMethodType } from '../../utils/constants'

interface State {
  organization: OrganizationData
  countries: Country[]
}

const defaultState: State = {
  organization: {},
  countries: []
}

export function organization(state: State = defaultState, action: ReducerAction) {
  switch (action.type) {
    case UPDATE_ORGANIZATION:
      return {
        ...state,
        organization: {
          ...state.organization,
          ...action.payload
        }
      }
    case LOAD_COUNTRIES:
      return {
        ...state,
        countries: action.payload
      }
    case UPDATE_PAYMENT_CARD:
      return {
        ...state,
        organization: {
          ...state.organization,
          payment_method: action.payload.is_primary ? PaymentMethodType.CreditCard : PaymentMethodType.WireTransfer
        }
      }
    default:
      return state
  }
}
