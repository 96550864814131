import React from 'react'
import App from './App'
import axios from 'axios'
import Cookies from 'js-cookie'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/browser'
import { DEBUG, PRODUCTION, STAGING } from './utils/constants'
import './index.scss'

import configureStore from './store/configureStore'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

if (PRODUCTION) {
  Sentry.init({ dsn: "https://af6c592d1fde482faaf5360ac145e47b@sentry.appboxo.com/3" });
} else if (STAGING && !DEBUG) {
  Sentry.init({ dsn: "https://010b7daeb3264fd9b00d221d33be0d8c@sentry.appboxo.com/7" });
}

// Handle axios interceptors
// Add a request interceptor
axios.interceptors.request.use(
  config => {
    const token = Cookies.get('token')
    if (token) {
      config.headers['Authorization'] = 'Token ' + token
    }
    config.headers['Content-Type'] = 'application/json'
    return config
  },
  error => {
    Promise.reject(error)
  })

//Add a response interceptor
axios.interceptors.response.use((response) => {
  return response
}, function (error) {
  if (error.response.status === 401) {
    // Clear token and redirect to login page
    Cookies.remove('token')
    window.location.href = '/login'
  }
  return Promise.reject(error)
})

const store = configureStore()
const queryClient = new QueryClient()

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
