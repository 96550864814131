/*CORE*/
import React, {useEffect, useState} from "react";
/*LIBS*/
import classnames from "classnames";
import {Button, Form, Input, Modal, message} from "antd";
import {useClipboard} from "use-clipboard-copy";
/*SELECTORS*/
/*ASSETS*/
import {ReactComponent as CopyIcon} from "assets/img/copy.svg";
/*TYPES*/
import {IConsentLink} from "types";
/*STYLES*/
import styles from "./UserConsentLinksModal.module.scss";

interface Props {
  closeModal: () => void;
  visible: boolean;
  initialData?: IConsentLink;
  onAdd: (data: { title: string; url: string }) => void;
  onEdit: (data: IConsentLink) => void;
  onDelete: (id: number) => void;
}

const UserConsentLinksModal = ({
                                 visible,
                                 onAdd,
                                 onEdit,
                                 onDelete,
                                 closeModal,
                                 initialData,
                               }: Props) => {
  const [form] = Form.useForm();
  const clipboard = useClipboard({
    onSuccess() {
      message.success("Copied successfully");
    },
    onError() {
      message.error("Failed to copy text");
    },
  });
  const {validateFields} = form;
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const checkFormValues = () => {
    const values = form.getFieldsValue();
    setIsButtonDisabled(!values.title || !values.url);
  };

  const handleClickSave = () => {
    validateFields().then(({title, url}) => {
      if (initialData) {
        onEdit({...initialData, title, url});
      } else {
        onAdd({title, url});
      }
      closeModal();
    });
  };

  const handleClickDelete = () => {
    if (initialData) {
      onDelete(initialData.id);
    }
    closeModal();
  };

  useEffect(() => {
    if (visible) {
      initialData ? form.setFieldsValue({title: initialData.title, url: initialData.url}) : form.resetFields()
    }
  }, [form, initialData, visible])

  return (
    <Modal
      className={styles["consent-modal"]}
      onCancel={closeModal}
      closeIcon={<div/>}
      visible={visible}
      width={"750px"}
      destroyOnClose
      footer={false}
    >
      <div className={styles["consent-modal__content-wrapper"]}>
        <header className={styles["header"]}>
          <h2>User Consent Links</h2>
          <p>
            Agreements will be displayed in the single sign-on modal in the
            following sample format: 'By continuing, you agree to our{" "}
            <span>Terms & Conditions</span>, <span>Privacy Policy</span>, and{" "}
            <span>FAQ</span>.'
          </p>
        </header>

        <main>
          <Form
            form={form}
            onFieldsChange={checkFormValues}
          >
            <Form.Item
              label="Title"
              name="title"
              colon={false}
              rules={[
                {
                  required: true,
                  message: "Please enter title",
                },
              ]}
            >
              <Input placeholder="e.g. Privacy Policy" size="large"/>
            </Form.Item>
            <Form.Item
              label="URL"
              name="url"
              colon={false}
              rules={[
                {
                  required: true,
                  message: "Please enter url",
                },
              ]}
            >
              <Input
                placeholder="Enter URL"
                size="large"
                suffix={
                  <CopyIcon
                    className="copy-icon"
                    onClick={() => clipboard.copy(form.getFieldValue("url"))}
                  />
                }
              />
            </Form.Item>
          </Form>
        </main>

        <footer>
          <Button
            size="middle"
            type="primary"
            onClick={handleClickSave}
            disabled={isButtonDisabled}
          >
            Save
          </Button>
          <Button
            size="middle"
            type="default"
            htmlType="button"
            onClick={closeModal}
            className={"btn-outline"}
          >
            Cancel
          </Button>
          {initialData && (
            <Button
              size="middle"
              type="default"
              htmlType="button"
              onClick={handleClickDelete}
              className={classnames("btn-outline", styles["delete-btn"])}
            >
              Delete
            </Button>
          )}
        </footer>
      </div>
    </Modal>
  );
};

export default UserConsentLinksModal;
