import {
  LOAD_PAYMENT_CARDS,
  LOAD_MINIAPP_SUB_PLANS,
  UPDATE_PAYMENT_CARD,
  DELETE_PAYMENT_CARD,
  ADD_PAYMENT_CARD,
  LOAD_MINI_APP_INVOICES,
  LOAD_SUPERAPP_SUB_PLANS,
  LOAD_SUPER_APP_INVOICES,
  PAYMENT_IN_PROGRESS,
  PAYMENT_ERROR,
  PAYMENT_SUCCESS,
  PAYMENT_INITIAL,
  ADD_CARD_ERROR,
  ADD_CARD_IN_PROGRESS,
  ADD_CARD_INITIAL,
  LOAD_APPBOXO_BANK_DETAILS,
} from './actions'
import { STRIPE_STATUSES } from '../../utils/enums'

// TODO(Chyngyz): Add hostapp subscriptions and invoices
// TODO(Chyngyz): Refactor this after joining miniapp and hostapp data
export function billing(state = {
  cards: [],
  miniAppPlans: [],
  miniAppPlansLoaded: false,
  superAppPlans: [],
  superAppPlansLoaded: false,
  miniAppInvoices: [],
  superAppInvoices: [],
  paymentError: '',
  paymentStatus: STRIPE_STATUSES.Init,
  addCardError: '',
  addCardStatus: STRIPE_STATUSES.Init,
  appboxoBankDetails: null
}, action) {
  switch (action.type) {
    case LOAD_PAYMENT_CARDS:
      return {
        ...state,
        cards: action.payload
      }
    case LOAD_MINIAPP_SUB_PLANS:
      return {
        ...state,
        miniAppPlans: action.payload,
        miniAppPlansLoaded: true
      }
    case LOAD_SUPERAPP_SUB_PLANS:
      return {
        ...state,
        superAppPlans: action.payload,
        superAppPlansLoaded: true
      }
    case UPDATE_PAYMENT_CARD:
      return {
        ...state,
        cards: state.cards.map(item => {
          if (item.id === action.payload.id) {
            return action.payload
          } else if (action.payload.is_primary) {
            item.is_primary = false
          }
          return item
        })
      }
    case DELETE_PAYMENT_CARD:
      return {
        ...state,
        cards: state.cards.filter(item => item.id !== action.payload.cardId)
      }
    case ADD_PAYMENT_CARD:
      return {
        ...state,
        addCardStatus: STRIPE_STATUSES.Success,
        cards: [
          ...state.cards,
          action.payload
        ]
      }
    case ADD_CARD_INITIAL: {
      return {
        ...state,
        addCardStatus: STRIPE_STATUSES.Init,
      }
    }
    case ADD_CARD_IN_PROGRESS: {
      return {
        ...state,
        addCardStatus: STRIPE_STATUSES.Loading
      }
    }
    case ADD_CARD_ERROR: {
      return {
        ...state,
        addCardStatus: STRIPE_STATUSES.Error,
        addCardError: action.error,
      }
    }
    case LOAD_MINI_APP_INVOICES: {
      return {
        ...state,
        miniAppInvoices: action.payload
      }
    }
    case LOAD_SUPER_APP_INVOICES: {
      return {
        ...state,
        superAppInvoices: action.payload
      }
    }
    case PAYMENT_IN_PROGRESS: {
      return {
        ...state,
        paymentStatus: STRIPE_STATUSES.Loading
      }
    }
    case PAYMENT_ERROR: {
      return {
        ...state,
        paymentStatus: STRIPE_STATUSES.Error,
        paymentError: action.error,
      }
    }
    case PAYMENT_SUCCESS: {
      return {
        ...state,
        paymentStatus: STRIPE_STATUSES.Success,
      }
    }
    case PAYMENT_INITIAL: {
      return {
        ...state,
        paymentStatus: STRIPE_STATUSES.Init,
      }
    }
    case LOAD_APPBOXO_BANK_DETAILS: {
      return {
        ...state,
        appboxoBankDetails: action.payload
      }
    }
    default:
      return state
  }
}
