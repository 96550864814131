/*CORE*/
import React, { useState } from 'react'
/*LIBS*/
import { Button, message, Typography } from 'antd'
import { connect, ConnectedProps } from 'react-redux'
/*ACTIONS*/
import { requestCommercialModel as requestCommercialModelAction } from 'store/miniApps/actions'
/*STYLES*/
import styles from './CommercialModel.module.scss'

const { Title } = Typography

interface Props extends ConnectedProps<typeof connector> {
  miniappId: number
  commercialModel: string[]
  commercialModelRequested: boolean
  isHidden: boolean
  isMyMiniapp: boolean
}

const CommercialModel = ({ miniappId, commercialModel, commercialModelRequested, isHidden, requestCommercialModel, isMyMiniapp }: Props) => {
  const [requestSent, setRequestSent] = useState(commercialModelRequested)
  const [requestIsLoading, setRequestIsLoading] = useState(false)

  const handleRequestCommercialModel = () => {
    setRequestIsLoading(true)
    requestCommercialModel(miniappId).then(() => {
      setRequestSent(true)
    }).catch(() => {
      message.error('Something went wrong while requesting commercial model. Please try again later.')
    }).finally(() => {
      setRequestIsLoading(false)
    })
  }

  return (
    <>
      <Title level={3}>Commercial model</Title>
      {isMyMiniapp || !isHidden ? (
        <>
          {commercialModel.map((commercialModelItem, i) => (
            <div key={i} className={styles['commercial-model__item']}>{commercialModelItem}</div>
          ))}
        </>
      ) : (
        <div className={styles['commercial-model__hidden']}>
          {requestSent ? (
            <>
              <div>Commercial model will be sent to your e-mail soon.</div>
              <div className={styles['request-sent']}>Request sent</div>
            </>
          ) : (
            <>
              <div>Commercial model will be available upon request.</div>
              <Button
                type='link'
                className='btn-link'
                onClick={handleRequestCommercialModel}
                loading={requestIsLoading}
              >
                {requestIsLoading ? 'Sending' : 'Send'} request
              </Button>
            </>
          )}
        </div>
      )}
    </>
  )
}

const connector = connect(null, {
  requestCommercialModel: requestCommercialModelAction
})

export default connector(CommercialModel)
