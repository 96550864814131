/*CORE*/
import React from 'react'
/*LIBS*/
import { connect } from 'react-redux'
import { useRouteMatch, useHistory } from 'react-router-dom'
/*SELECTORS*/
import { isSuperAppsLoading, superAppsSelector } from 'store/superApps/selectors'
/*COMPONENTS*/
import InfoCard from '../components/InfoCard/InfoCard'
import MainPaneLayout from '../components/MainPaneLayout/MainPaneLayout'
import AppCard from '../components/AppCard/AppCard'
import AppSkeleton from '../components/Skeletons/AppSkeleton'
import AccessControl from '../../common/AccessControl'
/*CONSTANTS*/
import { DOCS_URL, Permissions } from 'utils/constants'
/*TYPES*/
import { ISuperApp, RootState } from 'types'
/*STYLES*/
import './SuperApps.scss'

interface IStateProps {
}

interface IDispatchProps {
  superApps: ISuperApp[]
  isSuperAppsLoading: boolean
}

type Props = IStateProps & IDispatchProps

const SuperApps = ({superApps, isSuperAppsLoading}: Props) => {
  const history = useHistory()
  let {path} = useRouteMatch()

  const handleOpenApp = (appId: number) => () => {
    history.push(`${path}/${appId}/overview`)
  }

  return (
    <MainPaneLayout
      title='My host apps'
      description="Add your host apps by integrating Boxo SDK"
    >
      {isSuperAppsLoading ? <AppSkeleton/> : Boolean(superApps?.length) && (
        <div className="cards-row">
          {superApps.map(app => (
            <AppCard
              key={app.id}
              imgUrl={app.logo}
              name={app.name}
              onClick={handleOpenApp(app.id)}
            />
          ))}
        </div>
      )}
      <div className="cards-row">
        <InfoCard
          title="Documentation"
          content={(
            <p>Explore documentation on how to integrate Boxo SDK to your app.</p>
          )}
          onClick={() => window.open(`${DOCS_URL}/hostapp/`)}
        />
        <AccessControl permission={Permissions.CAN_EDIT}>
          <InfoCard
            title='Add host app'
            content={(
              <p>Ready to add your host app? Follow the instructions.</p>
            )}
            onClick={() => history.push(`${path}/new`)}
          />
        </AccessControl>
      </div>
    </MainPaneLayout>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    isSuperAppsLoading: isSuperAppsLoading(state),
    superApps: superAppsSelector(state)
  }
}

const mapDispatchToProps = () => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuperApps)
