/*CORE*/
import React from 'react'
/*LIBS*/
import classnames from 'classnames'
import { Dropdown, Menu } from 'antd'
import { DropDownProps } from 'antd/lib/dropdown'
/*ASSETS*/
import { ReactComponent as ArrowDown } from 'assets/img/arrow-down.svg'
/*STYLES*/
import styles from './CustomSelect.module.scss'

export interface CustomSelectOption {
  label: string
  value: number | string
}

interface Props {
  className?: string
  options: CustomSelectOption[]
  activeOption: CustomSelectOption
  placement?: DropDownProps['placement']
  onClickOption: (option: CustomSelectOption) => void
}

const CustomSelect = ({ className, options, activeOption, onClickOption, placement = 'bottomRight' }: Props) => {

  const onClickOptionItem = (option: CustomSelectOption) => () => {
    onClickOption(option)
  }

  const menu =
    <Menu>
      {
        options.map(option =>
          <Menu.Item
            key={option.label}
            onClick={onClickOptionItem(option)}
            className={option.value === activeOption.value ? styles['filter-item-active'] : ''}
          >
            {option.label}
          </Menu.Item>
        )
      }
    </Menu>

  return (
    <Dropdown
      overlay={menu}
      trigger={[ 'click' ]}
      className={className}
      placement={placement}
      align={{ offset: [ 0, 12 ] }}
      overlayClassName={'filter-menu'}
      overlayStyle={{ minWidth: '210px' }}
    >
      <div className={classnames(styles['filter-current-indicator'], 'ant-dropdown-link')}>
        {activeOption.label} <ArrowDown className={styles['arrow-down']} />
      </div>
    </Dropdown>
  )
}

export default CustomSelect
