/*CORE*/
import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
/*PAGES*/
import TotalPayouts from 'pages/Dashboard/Payouts/TotalPayouts/TotalPayouts'
import PayoutHistory from 'pages/Dashboard/Payouts/PayoutHistory/PayoutHistory'
import AppsPaymentTable from 'pages/Dashboard/Payment/AppsPaymentTable/AppsPaymentTable'
/*SELECTORS*/
import { miniAppPlanSelector, superAppPlanSelector } from 'store/billing/selectors'
import { miniAppPayoutsSelector, superAppPayoutsSelector } from 'store/payouts/selectors'
/*TYPES*/
import { RootState } from 'types'
import { IAppPlan } from 'types/Billing'
import { IAppPayout } from 'types/Payouts'
import { IPartnershipDetails } from 'types/Partnerships'

interface Props extends ConnectedProps<typeof connector> {
  partnership: IPartnershipDetails
}

const Payouts = ({ payouts, plans }: Props) => {
  return (
    <>
      <TotalPayouts appPlans={plans} />
      <AppsPaymentTable appPlans={plans} title='Month-to-date' />
      <PayoutHistory payouts={payouts} />
    </>
  )
}

const mapStateToProps = (state: RootState, { partnership }: { partnership: IPartnershipDetails }) => {

  const payouts =
    partnership.is_my_integration ?
      miniAppPayoutsSelector(partnership.miniapp.id, state)
        .filter((invoice: IAppPayout) => invoice.app_plan.hostapp.id === partnership.hostapp.id)
      :
      superAppPayoutsSelector(partnership.hostapp.id, state)
        .filter((invoice: IAppPayout) => invoice.app_plan.miniapp.id === partnership.miniapp.id)

  const plans = partnership.is_my_integration ?
    miniAppPlanSelector(partnership.miniapp.id, state).filter((plan: IAppPlan) => plan.hostapp.id === partnership.hostapp.id)
    :
    superAppPlanSelector(partnership.hostapp.id, state).filter((plan: IAppPlan) => plan.miniapp.id === partnership.miniapp.id)

  return {
    payouts,
    plans,
  }
}

const connector = connect(mapStateToProps)

export default connector(Payouts)
