/*CORE*/
import React, { useEffect, useState } from 'react'
import { Switch as RouterSwitch } from 'react-router'
import { Redirect, Route, useHistory, useParams, useRouteMatch } from 'react-router-dom'
/*LIBS*/
import { bindActionCreators, Dispatch } from 'redux'
import { Button, message, Tabs, Typography } from 'antd'
import { connect } from 'react-redux'
/*ACTIONS*/
import { getPartnership as getPartnershipAction, updateUI as updateUIAction } from 'store/partnerships/actions'
/*COMPONENTS*/
import SinglePageLayout from '../../components/SinglePageLayout/SinglePageLayout'
import AppSkeleton from '../../components/Skeletons/AppSkeleton'
import Badge from 'pages/Dashboard/components/Badge/Badge'
import DoubleLogo from '../../components/DoubleLogo/DoubleLogo'
import SendEmailComplete from 'pages/Dashboard/Analytics/components/SendEmailComplete/SendEmailComplete'
/*SELECTORS*/
import {
  miniAppPlansLoadedSelector, miniAppPlansSelector, superAppPlansLoadedSelector, superAppPlansSelector
} from 'store/billing/selectors'
import { isProfileInitialLoad, profileRoleSelector } from 'store/profile/selectors'
import { uiSelector } from 'store/partnerships/selectors'
/*FUNCTIONS*/
import { PartnershipTabs, tabPanesStrategy } from './tabPanesStrategy'
/*TYPES*/
import { IPartnershipDetails } from 'types/Partnerships'
import { IAppPlan, PaymentType } from 'types/Billing'
import { RootState } from 'types'
/*UTILS*/
import { getPartnershipBadgeStatus } from '../get-partnership-badge-status'
import { goBack } from 'utils/utils'
/*CONSTANTS*/
import { ROLE_NAMES } from 'utils/constants'
/*STYLES*/
import styles from './PartnershipDetials.module.scss'
import { Flex } from 'pages/Dashboard/components/Flex'
import { useMutatePartnership } from 'queries/partnership'
import { PartnershipStatuses } from '../enums'
import { useConfirmModal } from 'pages/common/ConfirmModal/ConfirmModal'
import useUserPermission from 'hooks/useUserPermission'
import { loadPartnerships as loadPartnershipsAction } from 'store/partnerships/actions'
import { AxiosResponse } from 'axios'
import { UpdateUiAction } from 'store/partnerships/types'

const { Title } = Typography

type IStateProps = ReturnType<typeof mapStateToProps>

type IDispatchProps = {
  getPartnershipDetails: (appId: string) => Promise<AxiosResponse<IPartnershipDetails>>
  updateUI: (payload: UpdateUiAction['payload']) => void
  loadPartnerships: () => void
}

type Props = IStateProps & IDispatchProps

const PartnershipDetails = (
  {
    getPartnershipDetails,
    updateUI,
    ui,
    isAdmin,
    isProfileLoaded,
    miniAppPlans,
    miniAppPlansLoaded,
    superAppPlans,
    superAppPlansLoaded,
    loadPartnerships,
  }: Props
) => {
  const [partnership, setPartnership] = useState<IPartnershipDetails | null>(null)
  const history = useHistory()
  const match = useRouteMatch()
  const { partnershipId } = useParams<{ partnershipId: string }>()
  const { CAN_EDIT } = useUserPermission()

  useEffect(() => {
    loadPartnership()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadPartnership = async () => {
    try {
      if (partnershipId) {
        const appDetails = await getPartnershipDetails(partnershipId)
        setPartnership(appDetails.data)
      }
    } catch (e) {
      message.error('Something went wrong while loading partnership')
    }
  }

  const handleTabChange = (activeTab: string) => {
    history.replace(`${match.url}/${activeTab}`)
  }

  const getActiveTab = () => history.location.pathname.split('/')[4]

  const handleClose = () => {
    goBack(history, '/partnerships')
  }

  let appPlansLoaded = false
  let hasBillingPlans = false
  let hasPayoutPlans = false
  if (partnership) {
    (partnership.is_my_integration ? miniAppPlans : superAppPlans).forEach((plan: IAppPlan) => {
      if (plan.miniapp.id === partnership.miniapp.id && plan.hostapp.id === partnership.hostapp.id) {
        hasBillingPlans = hasBillingPlans || plan.billing_type === PaymentType.Billing
        hasPayoutPlans = hasPayoutPlans || plan.billing_type === PaymentType.Payout
      }
    })
    appPlansLoaded = partnership.is_my_integration ? miniAppPlansLoaded : superAppPlansLoaded
  }

  const hiddenTabs: string[] = []
  if (!isAdmin || !hasBillingPlans) {
    hiddenTabs.push(PartnershipTabs.Billing)
  }
  if (!isAdmin || !hasPayoutPlans) {
    hiddenTabs.push(PartnershipTabs.Payouts)
  }

  const tabsPanes = tabPanesStrategy.filter(item => hiddenTabs.indexOf(item.key) === -1)

  const { mutateAsync, isLoading } = useMutatePartnership()
  const { close, modal, open } = useConfirmModal({
    title: 'Reject partner',
    description: 'Are you sure you want to reject this partnership? Once you reject, you can’t accept it anymore.',
    okText: 'Yes, reject',
    cancelText: 'Close',
    onOk: async () => {
      if (partnership) {
        await mutateAsync({
          id: partnership.id,
          status: PartnershipStatuses.Rejected
        })
        loadPartnership()
        loadPartnerships()
        close()
      }
    },
    okButtonProps: { loading: isLoading }
  })

  async function approve() {
    if (partnership) {
      await mutateAsync({
        id: partnership.id,
        status: PartnershipStatuses.Accepted
      })
      loadPartnership()
      loadPartnerships()
    }
  }

  return !ui.sentEmail ? <SinglePageLayout
    onGoBack={handleClose}
    className={styles['partnership-details']}
  >
    {
      (partnership && isProfileLoaded && appPlansLoaded) ?
        <>
          <Flex vertical={false} justify="space-between">
            <Flex vertical={false} align="center">
              <DoubleLogo backLogo={partnership.hostapp.logo} frontLogo={partnership.miniapp.logo} />
              <Title className={styles['title']}>{partnership.hostapp.name} <i>/</i> {partnership.miniapp.name}</Title>
              <Badge
                status={getPartnershipBadgeStatus(partnership.status).badgeStatus}
                label={getPartnershipBadgeStatus(partnership.status).tooltipStatus}
              />
            </Flex>
            {
              CAN_EDIT && partnership.status === PartnershipStatuses.Pending &&
              <Flex vertical={false} align="center" gap={20}>
                <Button type="primary" loading={isLoading} onClick={approve}>Accept</Button>
                <Button type="default" onClick={open}>Reject</Button>
              </Flex>
            }
          </Flex>
          <Tabs
            className={styles['tabs']}
            defaultActiveKey={getActiveTab()}
            onChange={handleTabChange}
          >
            {
              tabsPanes.map(item => item.pane(partnership.status))
            }
          </Tabs>
          <RouterSwitch>
            {
              tabsPanes.map(item => item.route(partnership, match.url))
            }
            <Route path="*">
              <Redirect to={{ pathname: `${match.url}/${PartnershipTabs.Overview}` }} />
            </Route>
          </RouterSwitch>
        </>
        :
        <AppSkeleton />
    }
    {modal}
  </SinglePageLayout >
    : <SendEmailComplete text='The exported orders will be sent to your inbox within a few minutes.' handleClose={() => { updateUI({ sentEmail: false }) }} />
}

const mapStateToProps = (state: RootState) => {
  return {
    isAdmin: profileRoleSelector(state) === ROLE_NAMES.ADMIN,
    isProfileLoaded: !isProfileInitialLoad(state),
    miniAppPlans: miniAppPlansSelector(state),
    miniAppPlansLoaded: miniAppPlansLoadedSelector(state),
    superAppPlans: superAppPlansSelector(state),
    superAppPlansLoaded: superAppPlansLoadedSelector(state),
    ui: uiSelector(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators({
      getPartnershipDetails: getPartnershipAction,
      updateUI: updateUIAction,
      loadPartnerships: loadPartnershipsAction,
    }, dispatch),
  }
}

// @ts-ignore
export default connect<IStateProps, IDispatchProps, {}>(mapStateToProps, mapDispatchToProps)(PartnershipDetails)
