/*CORE*/
import React, { useState } from 'react'
/*LIBS*/
import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { AxiosError, AxiosResponse } from 'axios'
import { useHistory } from 'react-router-dom'
import { flatten } from 'lodash'
import Cookies from 'js-cookie'
/*ACTIONS*/
import {
  login as loginAction,
  setAuth as setAuthAction
} from 'store/auth/actions'
/*COMPONENTS*/
import LoginForm from './components/LoginForm/LoginForm'
import AuthLayout from './components/AuthLayout/AuthLayout'
import VerificationCodeForm from './components/VerificationCodeForm/VerificationCodeForm'
/*HOOKS*/
import { useQuery } from 'hooks/useQuery'
/*UTILS*/
import { storeToken } from 'utils/utils'

interface Props extends ConnectedProps<typeof connector> {
}

const Login = ({ login, setAuth }: Props) => {
  const [showVerificationCodeForm, setShowVerificationCodeForm] = useState(false)
  const [lastCredentials, setLastCredentials] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState<string[]>([])
  const query: { next?: string } = useQuery()
  const history = useHistory()

const handleSubmit = (formData: { email?: string, password?: string, code?: string }) => {
    setIsLoading(true)

    const { email, password, code } = {
      ...lastCredentials,
      ...formData
    }
    login({ email, password, code }).then((resp: AxiosResponse<{ token: string, first_login: boolean }>) => {
      storeToken(resp.data.token, resp.data.first_login)

      if (query.next) {
        window.location.assign(query.next)
      } else {
        setAuth(true)
        history.push('/')
      }
    }).catch((error: AxiosError) => {
      let errors
      if (error?.response?.data && error.response.status !== 404) {
        const errorLabels = Object.keys(error.response.data).map(errorName => error?.response?.data[errorName])
        errors = flatten(errorLabels)
      } else {
        errors = [ error?.response?.statusText || '' ]
      }
      setErrors(errors)

      if (error?.response?.status === 423) {
        setLastCredentials({ email, password })
        setShowVerificationCodeForm(true)
        setErrors([])
      }

      setIsLoading(false)

      Cookies.remove('token')
      setAuth(false)
    })
  }

  return (
    <AuthLayout
      hideLogo={showVerificationCodeForm}
      title={showVerificationCodeForm ? 'Verification code required' : 'Welcome back'}
      subtitle={showVerificationCodeForm
        ? 'To continue, please enter the 6-digit verification code generated by your authenticator app.'
        : 'Login to your existing Boxo account.'
      }
    >
      {showVerificationCodeForm ? (
        <VerificationCodeForm
          onSubmit={handleSubmit}
          errors={errors}
          isLoading={isLoading}
        />
      ) : (
        <LoginForm
          onSubmit={handleSubmit}
          errors={errors}
          isLoading={isLoading}
        />
      )}
    </AuthLayout>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators<any, any>({
      login: loginAction,
      setAuth: setAuthAction
    }, dispatch),
  }
}

const connector = connect(
  null,
  mapDispatchToProps
)

export default connector(Login)
