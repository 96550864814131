import React from 'react'
import { Table, Dropdown, Menu } from 'antd'
import { EllipsisOutlined } from '@ant-design/icons'
import Badge from 'pages/Dashboard/components/Badge/Badge'
import { ActiveMember } from '../Team'
import { ITeamInvite, ITeamMember } from 'types/Team'
import AccessControl from '../../../common/AccessControl'
import { Permissions } from 'utils/constants'

import './TeamTable.scss'

type ITeamItem = (ITeamMember | ITeamInvite)

interface Column {
  [key: string]: any
}

interface Props {
  currentUserEmail: string,
  setActiveMember: (values: ActiveMember) => void
  members: Array<ITeamItem>
  loading?: boolean
}

function isMember(item: ITeamItem): item is ITeamMember {
  return (item as ITeamMember).user !== undefined
}

const InvitedByBadge = (status: boolean, item: ITeamItem) => (
  <div className='team-table__status'>
    <Badge
      status={isMember(item) && status ? 'success' : 'disabled'}
      label={isMember(item) ? item.status_text
        : <>Invited {item.inviter
          ? <>by <b>{item.inviter.name}</b></>
          : <>from <b>admin page</b></>
        }</>
      }
    />
  </div>
)

const TeamTable = ({ currentUserEmail, setActiveMember, members, loading }: Props) => {
  const columns: Column[] = [
    {
      title: 'Name',
      dataIndex: 'role.id',
      key: 'role.id',
      width: '20%',
      render: (_: any, item: ITeamItem) => (
        <div>
          <div className="team-table__title">{isMember(item) ? item.user.name : '-'}</div>
          <span>{isMember(item) ? item.user.email : item.email}</span>
        </div>
      )
    },
    {
      title: 'Roles and permissions',
      dataIndex: 'role.name',
      key: 'role.name',
      width: '40%',
      render: (_: any, item: ITeamItem) => (
        <div>
          <div className="team-table__title">{item.role.name}</div>
          <span>{item.role.description}</span>
        </div>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '35%',
      render: InvitedByBadge
    },
    {
      dataIndex: 'id',
      key: 'id',
      width: '5%',
      render: (value: string, item: ITeamMember) => item?.user?.email !== currentUserEmail && (
        <AccessControl permission={Permissions.CAN_MANAGE}>
          <Dropdown
            trigger={['click']}
            placement='bottomRight'
            overlay={getOverlay(item)}
          >
            <EllipsisOutlined />
          </Dropdown>
        </AccessControl>
      )
    }
  ]

  const getOverlay = (item: ITeamItem) => {

    if (isMember(item)) {
      return item.status_text === 'Active' ? (
        <Menu>
          <Menu.Item key={0} onClick={() => setActiveMember({
            modal: 'ChangeRoleModal',
            member: item
          })}>
            Change role
          </Menu.Item>
          <Menu.Item key={1} onClick={() => setActiveMember({
            modal: 'DeactivationModal',
            member: item
          })}>
            Deactivate account
          </Menu.Item>
        </Menu>
      ) : (
        <Menu>
          <Menu.Item key={0} onClick={() => setActiveMember({
            modal: 'ActivationModal',
            member: item
          })}>
            Activate account
          </Menu.Item>
        </Menu>
      )
    } else {
      return (
        <Menu>
          <Menu.Item key={0} onClick={() => setActiveMember({
            modal: 'DeleteInvitationModal',
            member: item
          })}>
            Cancel invitation
          </Menu.Item>
          <Menu.Item key={1} onClick={() => setActiveMember({
            modal: 'ResendInvitationModal',
            member: item
          })}>
            Resend invitation
          </Menu.Item>
        </Menu>
      )
    }
  }

  return (
    <Table
      className="team-table"
      columns={columns}
      dataSource={members}
      pagination={false}
      loading={loading}
    />
  )
}

export default TeamTable
