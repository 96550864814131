/*CORE*/
import React from 'react'
/*LIBS*/
import { Typography, Form, Input, Button, Select, Checkbox } from 'antd'
import { Link } from 'react-router-dom'
/*UTILS*/
import { hasErrors } from 'utils/utils'
/*TYPES*/
import { ISuperApp, IFormData } from 'types'
/*STYLES*/
import styles from './IntegrationForm.module.scss'

const { Title } = Typography
const { Option } = Select
const { TextArea } = Input

interface Props {
  miniAppName: string;
  loading: boolean;
  superapps: ISuperApp[];
  onSubmit: (data: IFormData) => void;
}

const IntegrationForm = ({ miniAppName, superapps, loading, onSubmit }: Props) => {
  const [form] = Form.useForm()
  const [submitDisabled, setSubmitDisabled] = React.useState(true)

  const detectErrors = (changedFields: IFormData[], allFields: IFormData[]) => {
    setSubmitDisabled(hasErrors(allFields))
  }

  const handleSubmit = (values: IFormData) => {
    onSubmit(values)
  }

  return (
    <Form
      form={form}
      className={styles['integration-form']}
      onFieldsChange={detectErrors}
      onFinish={handleSubmit}
    >
      <div className={styles['integration-form__header']}>
        <Title>Apply to integrate {miniAppName}</Title>
      </div>
      <div className={styles['integration-form__content']}>
        <Form.Item
          name='hostapp'
          rules={[
            { required: true, message: 'Please select host app' }
          ]}
        >
          <Select
            placeholder='Host app to integrate with'
            className={styles['integration-form__superapp-select']}
            size='large'
          >
            {superapps.map(superapp => (
              <Option
                key={superapp.id}
                value={superapp.id}
              >{superapp.name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name='note'
        >
          <TextArea
            placeholder='Short description of your host app'
            className='ant-input-lg'
            autoSize
          />
        </Form.Item>
        <Form.Item
          name='agreement'
          valuePropName='checked'
          rules={[
            () => ({
              validator(rule, value) {
                if (value && value === true) {
                  return Promise.resolve()
                }
                return Promise.reject('Terms and conditions must be accepted')
              }
            })
          ]}
        >
          <Checkbox className={styles['integration-form__checkbox']}>
            By checking this box, you agree to Boxo <Link to='/terms' target='_blank' rel='noopener noreferrer'>Terms
            </Link> and <Link to='/privacy' target='_blank' rel='noopener noreferrer'>Privacy Policy</Link>.
          </Checkbox>
        </Form.Item>
      </div>
      <div className={styles['integration-form__footer']}>
        <Button
          type='primary'
          htmlType='submit'
          size='large'
          loading={loading}
          block
          disabled={submitDisabled}
        >
          {loading ? 'Applying' : 'Apply'}
        </Button>
      </div>
    </Form>
  )
}

export default IntegrationForm 
