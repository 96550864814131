/*CORE*/
import React, { useEffect } from 'react'
/*LIBS*/
import { Link } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
/*ACTIONS*/
import { loadOrganization as loadOrganizationAction } from 'store/organization/actions'
/*SELECTORS*/
import { appboxoBankDetailsSelector, primaryPaymentCardSelector } from 'store/billing/selectors'
import { organizationSelector } from 'store/organization/selectors'
/*COMPONENTS*/
import CurrencyDataTooltip from '../../Payment/CurrencyDataTooltip/CurrencyDataTooltip'
import PaymentMethod from '../BillingHistory/PaymentMethod'
import MoneyWrapper from '../../../common/MoneyWrapper'
/*CONSTANTS*/
import { DEFAULT_CURRENCY, PaymentMethodType } from 'utils/constants'
/*TYPES*/
import { IAppInvoice, IAppPlan, IPaymentCard } from 'types/Billing'
import { IOrganization, RootState } from 'types'
import { IBankAccount } from 'types/Payouts'
/*STYLES*/
import styles from './TotalBilling.module.scss'

interface Props {
  appPlans: IAppPlan[]
  invoice?: IAppInvoice
  organization: IOrganization | null
  appboxoBankDetails: IBankAccount
  primaryCard: IPaymentCard
  loadOrganization: () => Promise<void>
}

const TotalBilling = ({ appPlans, primaryCard, invoice, loadOrganization, organization, appboxoBankDetails }: Props) => {
  const total = appPlans.reduce((total, plan) => total + Number(plan.team_currency_amount), 0)
  const exchangeRateDate = appPlans.length > 0 ? appPlans[0].actual_currency_exchange_date : ''
  const dueToPay = appPlans.some((plan) => !plan.paid_date)
  const currency = invoice ? invoice.currency : (organization?.default_currency || DEFAULT_CURRENCY)
  const paymentMethod = invoice ? invoice.payment_method : organization?.payment_method
  const defaultPaymentMethodDetails = paymentMethod === PaymentMethodType.WireTransfer ? appboxoBankDetails : primaryCard

  useEffect(() => {
    loadOrganization()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles['total-payment']}>
      <div>
        <CurrencyDataTooltip
          currency={currency}
          exchangeRateDate={exchangeRateDate}
          hidden={!exchangeRateDate}
        >
          <MoneyWrapper amount={total} currency={currency} className={styles['highlight']} />
        </CurrencyDataTooltip>
        <div>Estimated total for {dueToPay ? 'this' : ''} billing period</div>
      </div>
      <div className={styles['payment-method']}>
        <div>
          <PaymentMethod
            paymentMethod={paymentMethod}
            paymentMethodDetails={invoice ? invoice.payment_method_details : defaultPaymentMethodDetails}
            className={styles['highlight']}
          />
        </div>
        {!invoice && <Link to='/billing/methods'>Change</Link>}
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    primaryCard: primaryPaymentCardSelector(state),
    organization: organizationSelector(state),
    appboxoBankDetails: appboxoBankDetailsSelector(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators({
      loadOrganization: loadOrganizationAction,
    }, dispatch),
  }
}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(TotalBilling)
