import { BadgeStatus } from '../pages/Dashboard/components/Badge/Badge'
import { InvoiceStatus } from '../types/Billing'

export const PAYMENT_STATUS: {[key in InvoiceStatus]: {status: BadgeStatus, label: string}} = {
  [InvoiceStatus.DueToPay]: {
    status: 'warning',
    label: 'Due to pay'
  },
  [InvoiceStatus.Paid]: {
    status: 'success',
    label: 'Paid'
  },
  [InvoiceStatus.Failed]: {
    status: 'danger',
    label: 'Failed'
  }
} as const
