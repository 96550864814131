/*CORE*/
import React from 'react'
/*UTILS*/
import { hasErrors } from 'utils/utils'
/*TYPES*/
import { IFormData } from 'types/common'
/*LIBS*/
import { Button, Form, Input } from 'antd'
import { LockOutlined } from '@ant-design/icons/lib'
/*CONSTANTS*/
import { REGEX_PASSWORD } from 'utils/constants'
/*STYLES*/
import styles from './SetNewPassword.module.scss'

interface Props {
  onSubmit: (password: any) => void
  isLoading: boolean
}

interface FieldData {
  [key: string]: any
}

const SetNewPasswordForm = ({onSubmit, isLoading}: Props) => {

  const [form] = Form.useForm()
  const [submitDisabled, setSubmitDisabled] = React.useState(false)

  const detectErrors = (changedFields: FieldData[], allFields: FieldData[]) => {
    setSubmitDisabled(hasErrors(allFields))
  }

  const onFinish = ({password}: IFormData) => {
    onSubmit(password)
  }

  return (
    <Form
      form={form}
      onFinish={onFinish}
      onFieldsChange={detectErrors}
      className={styles['set-new-password-form']}
    >
      <div className={styles['set-new-password-form__content']}>
        <Form.Item
          name='password'
          rules={[
            {required: true, message: 'Please input your password'},
            {pattern: REGEX_PASSWORD, message: 'Must be at least one number and one letter'},
            {min: 6, message: 'Must be at least 6 characters long'},
          ]}
        >
          <Input.Password
            prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
            type="password"
            placeholder="Password"
            size="large"
          />
        </Form.Item>
        <Form.Item
          name='confirmPassword'
          rules={[
            {required: true, message: 'Please confirm your password'},
            () => ({
              validator(rule, value) {
                const password = form.getFieldValue('password')
                return password === value ? Promise.resolve() : Promise.reject('Passwords mismatch')
              },
            })
          ]}
        >
          <Input.Password
            prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
            type="password"
            placeholder="Confirm Password"
            size="large"
          />
        </Form.Item>
      </div>
      <div className={styles['set-new-password-form__footer']}>
        <Button
          block
          size="large"
          type="primary"
          htmlType="submit"
          loading={isLoading}
          disabled={submitDisabled}
        >
          {isLoading ? 'Saving' : 'Save'}
        </Button>
      </div>
    </Form>
  )
}

export default SetNewPasswordForm
