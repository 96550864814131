import React from 'react'
import { Button } from 'antd'
import { connect, ConnectedProps } from 'react-redux'

import {
  changeRole as changeRoleAction,
  deactivateAccount as deactivateAccountAction,
  activateAccount as activateAccountAction,
  sendInvite as sendInviteAction,
  resendInvite as resendInviteAction,
  removeInvite as removeInviteAction,
  loadInvites as loadInvitesAction
} from 'store/team/actions'
import { membersSelector, rolesSelector, invitesSelector } from 'store/team/selectors'
import { profileSelector } from 'store/profile/selectors'
import MainPaneLayout from '../components/MainPaneLayout/MainPaneLayout'
import TeamTable from './components/TeamTable'
import InvitationModal from './components/InvitationModal'
import ChangeRoleModal from './components/ChangeRoleModal'
import DeactivationModal from './components/DeactivationModal'
import ActivationModal from './components/ActivationModal'
import DeleteInvitationModal from './components/DeleteInvitationModal'
import ResendInvitationModal from './components/ResendInvitationModal'
import AccessControl from '../../common/AccessControl'
import CustomSelect, { CustomSelectOption } from '../components/CustomSelect/CustomSelect'
import { IRole, ITeamInvite, ITeamMember } from 'types/Team'
import { Permissions } from 'utils/constants'

import './Team.scss'


export const ALL_MEMBERS: CustomSelectOption = {
  label: 'All members',
  value: 'all'
}

export interface ActiveMember {
  modal: ''
    | 'ChangeRoleModal'
    | 'DeactivationModal'
    | 'ActivationModal'
    | 'DeleteInvitationModal'
    | 'ResendInvitationModal'
  member: ITeamMember | ITeamInvite | null
}

interface Props extends ConnectedProps<typeof connector> {}

const Team = ({
  profile,
  roles,
  members,
  invites,
  loadInvites,
  changeRole,
  deactivateAccount,
  activateAccount,
  removeInvite,
  sendInvite,
  resendInvite
}: Props) => {
  const [invitationModalOpened, setInvitationModalOpened] = React.useState(false)
  const [activeMember, setActiveMember] =
    React.useState<ActiveMember>({modal: '', member: null})
  const [roleFilter, setRoleFilter] = React.useState(ALL_MEMBERS)

  const getFilteredMembers = (): Array<ITeamMember | ITeamInvite> => {
    // get rid of key duplication in antdtable
    const filteredMembers = [...members.entities, ...invites.entities].map((item, key) => {
      item.key = key
      return item
    })

    return filteredMembers.filter((member) => roleFilter === ALL_MEMBERS || member.role.name === roleFilter.value)
  }

  const getRoles = (): CustomSelectOption[] => {
    return [ALL_MEMBERS, ...roles.entities.map((role: IRole) => ({ label: role.name, value: role.name }))]
  }

  return (
    <MainPaneLayout
      title="Team"
    >
      <div className="team">
        <div className="team__header">
          <CustomSelect options={getRoles()} activeOption={roleFilter} onClickOption={status => setRoleFilter(status)} />
          <AccessControl permission={Permissions.CAN_MANAGE}>
            <Button
              className="team__btn-title"
              type="link"
              onClick={() => setInvitationModalOpened(true)}
            >
              + Invite team member
            </Button>
          </AccessControl>
        </div>
        <div className="team__table">
          <TeamTable
            currentUserEmail={profile.email}
            members={getFilteredMembers()}
            setActiveMember={setActiveMember}
            loading={members.isLoading || invites.isLoading}
          />
        </div>
      </div>

      {
        invitationModalOpened &&
        <InvitationModal
          roles={roles.entities}
          closeModal={setInvitationModalOpened}
          opened={invitationModalOpened}
          handleSubmit={sendInvite}
          loadInvites={loadInvites}
        />
      }

      {
        !!activeMember.member && activeMember.modal === 'ChangeRoleModal' &&
        <ChangeRoleModal
          roles={roles.entities}
          member={activeMember.member as ITeamMember}
          closeModal={() => setActiveMember({modal: '', member: null})}
          handleSubmit={changeRole}
        />
      }
      {
        !!activeMember.member && activeMember.modal === 'DeactivationModal' &&
        <DeactivationModal
          member={activeMember.member as ITeamMember}
          closeModal={() => setActiveMember({modal: '', member: null})}
          handleSubmit={deactivateAccount}
        />
      }
      {
        !!activeMember.member && activeMember.modal === 'ActivationModal' &&
        <ActivationModal
          member={activeMember.member as ITeamMember}
          closeModal={() => setActiveMember({modal: '', member: null})}
          handleSubmit={activateAccount}
        />
      }
      {
        !!activeMember.member && activeMember.modal === 'DeleteInvitationModal' &&
        <DeleteInvitationModal
          member={activeMember.member as ITeamInvite}
          closeModal={() => setActiveMember({modal: '', member: null})}
          handleSubmit={removeInvite}
        />
      }
      {
        !!activeMember.member && activeMember.modal === 'ResendInvitationModal' &&
        <ResendInvitationModal
          member={activeMember.member as ITeamInvite}
          closeModal={() => setActiveMember({modal: '', member: null})}
          handleSubmit={resendInvite}
        />
      }
    </MainPaneLayout>
  )
}

const connector = connect(state => ({
  roles: rolesSelector(state),
  members: membersSelector(state),
  invites: invitesSelector(state),
  profile: profileSelector(state)
}), {
  loadInvites: loadInvitesAction,
  changeRole: changeRoleAction,
  deactivateAccount: deactivateAccountAction,
  activateAccount: activateAccountAction,
  sendInvite: sendInviteAction,
  resendInvite: resendInviteAction,
  removeInvite: removeInviteAction
})

export default connector(Team)
