/*CORE*/
import React from 'react'
/*LIBS*/
import { Tooltip } from 'antd'
/*ASSETS*/
import logoPlaceholder from 'assets/img/no-data-placeholers/logo_placeholder.png'
/*TYPES*/
import { PartnershipStatuses } from '../enums'
/*COMPONENTS*/
import Badge from '../../components/Badge/Badge'
/*STYLES*/
import styles from './PartnershipsCard.module.scss'
/*UTILS*/
import { getPartnershipBadgeStatus } from '../get-partnership-badge-status'

interface Props {
  hostAppName: string
  miniAppName: string
  miniAppLogo: string
  hostAppLogo: string
  status: PartnershipStatuses
}

const PartnershipsCard = ({ hostAppName, miniAppName, hostAppLogo, miniAppLogo, status }: Props) => {

  return (
    <div className={styles['partnerships-card']}>

      <div className={styles['partnerships-card__part']}>
        <div className={styles['logo']}>
          <img src={hostAppLogo || logoPlaceholder} alt='hostapp-logo' />
        </div>
        <Tooltip
          trigger='hover'
          placement='bottom'
          align={{ offset: [ 0, 17 ] }}
          overlayClassName='partnership-name-card-tooltip'
          title={hostAppName}
        >
          <h2>{hostAppName}</h2>
        </Tooltip>
        <p>Hostapp</p>
      </div>

      <div className={styles['divider']}>
        <Tooltip
          trigger='hover'
          placement='bottom'
          align={{ offset: [ 0, 17 ] }}
          overlayClassName='partnership-card-tooltip'
          title={getPartnershipBadgeStatus(status).tooltipStatus}
        >
          <div>
            <Badge
              status={getPartnershipBadgeStatus(status).badgeStatus}
            />
          </div>
        </Tooltip>
      </div>

      <div className={styles['partnerships-card__part']}>
        <div className={styles['logo']}>
          <img src={miniAppLogo || logoPlaceholder} alt='miniapp-logo' />
        </div>
        <Tooltip
          trigger='hover'
          placement='bottom'
          align={{ offset: [ 0, 17 ] }}
          overlayClassName='partnership-name-card-tooltip'
          title={miniAppName}
        >
          <h2>{miniAppName}</h2>
        </Tooltip>
        <p>Miniapp</p>
      </div>

    </div>
  )
}

export default PartnershipsCard
