import {
  LOAD_CATEGORIES,
  LOADING_SHOWROOM_MINI_APPS,
  LOAD_SHOWROOM_MINI_APPS,
  LOAD_SHOWROOM_MINI_APPS_ERROR
} from './actions'

import { ICategory, IShowroomMiniApp, ReducerAction } from 'types'

interface State {
  categories: ICategory[],
  miniApps: {
    loading: boolean,
    data: IShowroomMiniApp[],
    error: boolean
  }
}

const initialState = {
  categories: [],
  miniApps: {
    loading: false,
    data: [],
    error: false
  }
}

export function showroom(state: State = initialState, action: ReducerAction) {
  switch (action.type) {
    case LOAD_CATEGORIES:
      return {
        ...state,
        categories: action.payload
      }
    case LOADING_SHOWROOM_MINI_APPS:
      return {
        ...state,
        miniApps: {
          ...state.miniApps,
          loading: true
        }
      }
    case LOAD_SHOWROOM_MINI_APPS:
      return {
        ...state,
        miniApps: {
          loading: false,
          error: false,
          data: action.payload
            // TODO: remove this filter once we are sure that backend will not return apps without category
            .filter((showroomMiniApp: IShowroomMiniApp) => !!showroomMiniApp.category)
        }
      }
    case LOAD_SHOWROOM_MINI_APPS_ERROR:
      return {
        ...state,
        miniApps: {
          ...state.miniApps,
          error: true
        }
      }
    default:
      return state
  }
}
