/*CORE*/
import React  from 'react'
/*LIBS*/
import { Button } from 'antd'
/*COMPONENTS*/
import EmergencyBackupCode from '../../components/EmergencyBackupCode'
import { useHistory } from 'react-router-dom'


type Props = {
  emergencyCode: string
  className?: string
}

const RegenerateSuccess = ({ emergencyCode, className }: Props) => {
  const history = useHistory()

  const handleFinish = () => {
    history.push('/account')
  }

  return (
    <div className={className}>
      <header>
        <h1>Here's your new code</h1>
        <p>
          Save your new emergency backup code and store it somewhere safe.
          If you lose your mobile device, you can use this code to bypass two-step authentication.
        </p>
      </header>
      <EmergencyBackupCode emergencyCode={emergencyCode} />
      <div>
        <Button
          block
          type='primary'
          onClick={handleFinish}
        >
          Finish
        </Button>
      </div>
    </div>
  )
}

RegenerateSuccess.defaultProps = {
  className: ''
}


export default RegenerateSuccess
