/*CORE*/
import React, { useEffect, useState } from 'react'
/*LIBS*/
import classnames from 'classnames'
/*COMPONENTS*/
import ShowroomCardLoader from '../ShowroomCardLoader/ShowroomCardLoader'
/*CONSTANTS*/
import { SCREEN_LARGE_RESOLUTION } from 'utils/constants'
/*STYLES*/
import styles from './ShowroomSkeletonLoader.module.scss'

export default function ShowroomSkeletonLoader({ className }: { className?: string }) {
  const [ isLargeResolution, setIsLargeResolution ] = useState(false)

  useEffect(() => {
    setIsLargeResolution(window.innerWidth >= SCREEN_LARGE_RESOLUTION)
    window.addEventListener('resize', handleResizeScreen)
    return () => window.removeEventListener('resize', handleResizeScreen)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleResizeScreen() {
    setIsLargeResolution(window.innerWidth >= SCREEN_LARGE_RESOLUTION)
  }

  return (
    <div className={classnames(styles['wrapper'], className)}>
      <ShowroomCardLoader />
      <ShowroomCardLoader />
      {
        isLargeResolution &&
        <ShowroomCardLoader />
      }
    </div>
  )
}
