import axios from 'axios'
import {
  API_CATEGORIES_URL,
  API_APPS
} from '../../utils/constants'

import { Dispatch, GetState } from 'types'

export const LOAD_CATEGORIES = 'LOAD_CATEGORIES'
export const LOADING_SHOWROOM_MINI_APPS = 'LOADING_SHOWROOM_MINI_APPS'
export const LOAD_SHOWROOM_MINI_APPS = 'LOAD_SHOWROOM_MINI_APPS'
export const LOAD_SHOWROOM_MINI_APPS_ERROR = 'LOAD_SHOWROOM_MINI_APPS_ERROR'

export function loadShowroomMiniApps () {
  return function(dispatch: Dispatch, getState: GetState) {
    dispatch({type: LOADING_SHOWROOM_MINI_APPS})
    return axios.get(API_APPS)
      .then(response => {
        return dispatch({
          type: LOAD_SHOWROOM_MINI_APPS,
          payload: response.data
        })
      })
      .catch(e => {
        return dispatch({
          type: LOAD_SHOWROOM_MINI_APPS_ERROR,
          error: e
        })
      })
  }
}

export function loadAppCategories () {
  return function (dispatch: Dispatch, getState: GetState) {
    return axios.get(API_CATEGORIES_URL)
      .then(response => {
        return dispatch({
          type: LOAD_CATEGORIES,
          payload: response.data
        })
      })
  }
}

export function getApp (appId: number) {
  return function(dispatch: Dispatch, getState: GetState) {
    const url = API_APPS + appId + '/'
    return axios.get(url)
  }
}
