/*CORE*/
import React, { useEffect } from 'react'
/*LIB*/
import { Button, Form, Input, message, Select, Typography } from 'antd'
import { FieldData } from 'rc-field-form/lib/interface'
import { connect, ConnectedProps } from 'react-redux'
import { useHistory } from 'react-router-dom'
/*ACTIONS*/
import {
  loadOrganization as loadOrganizationAction,
  updateOrganization as updateOrganizationAction,
  loadCountries as loadCountriesAction
} from 'store/organization/actions'
/*SELECTORS*/
import { countriesSelector, organizationSelector } from 'store/organization/selectors'
/*HOOKS*/
import useUserPermission from 'hooks/useUserPermission'
/*COMPONENTS*/
import SinglePageLayout from '../../components/SinglePageLayout/SinglePageLayout'
import AccessControl from '../../../common/AccessControl'
/*UTILS*/
import { goBack, hasErrors, normalizeFields } from 'utils/utils'
/*CONSTANTS*/
import { Permissions } from 'utils/constants'
/*TYPES*/
import { Country, IFormData } from 'types'
/*STYLES*/
import styles from './OrganizationDetailsForm.module.scss'

const { Title } = Typography
const { Option } = Select

interface Props extends ConnectedProps<typeof connector> {}

const OrganizationDetailsForm = ({ organization, countries, loadOrganization, updateOrganization, loadCountries }: Props) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = React.useState(false)
  const [submitDisabled, setSubmitDisabled] = React.useState(true)
  const history = useHistory()
  const user = useUserPermission()

  useEffect(() => {
    loadOrganization()
    loadCountries()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    form.setFieldsValue({
      name: organization.name,
      street_address: organization.street_address,
      city: organization.city,
      country: organization.country?.code,
      postal_code: organization.postal_code,
      default_currency: organization.default_currency?.name
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization])

  const updateOrganizationDetails = async (values: IFormData) => {
    setLoading(true)
    try {
      await updateOrganization({
        name: values.name,
        street_address: values.street_address,
        city: values.city,
        country: values.country,
        postal_code: values.postal_code
      })
      message.success('Updated successfully')
    } catch (error) {
      message.error('Something went wrong. Please try again.')
    }
    setLoading(false)
  }

  const handleSubmit = (values: IFormData) => {
    updateOrganizationDetails(values)
  }

  const handleFieldsChange = (changedFields: FieldData[], fields: FieldData[]) => {
    const values = normalizeFields(fields)
    setSubmitDisabled(
      (
        values.name.value === organization.name &&
        values.country.value === organization.country &&
        values.city.value === organization.city &&
        values.street_address.value === organization.street_address &&
        values.postal_code.value === organization.postal_code
      ) || hasErrors(fields)
    )
  }

  const handleGoBack = () => {
    goBack(history, '/')
  }

  return (
    <SinglePageLayout onGoBack={handleGoBack}>
      <div className='form-layout'>
        <Form
          form={form}
          className='form-layout__form-wrapper'
          layout='vertical'
          onFinish={handleSubmit}
          onFieldsChange={handleFieldsChange}
        >
          <Title>Organization Details</Title>
          <div>
            <Form.Item
              name='name'
              rules={[{
                required: true,
                message: 'Please enter company legal name',
              }]}
              hasFeedback
              label='Company legal name'
            >
              <Input placeholder='Company legal name' size='large' readOnly={!user.CAN_MANAGE} />
            </Form.Item>
            <Form.Item
              name='street_address'
              rules={[{
                required: true,
                message: 'Please enter company street address',
              }]}
              label='Street address'
              hasFeedback
            >
              <Input placeholder='Company street address' size='large' readOnly={!user.CAN_MANAGE} />
            </Form.Item>
            <Form.Item
              name='city'
              rules={[{
                required: true,
                message: 'Please enter city your company located in',
              }]}
              label='City'
              hasFeedback
            >
              <Input placeholder='City' size='large' readOnly={!user.CAN_MANAGE} />
            </Form.Item>
            <Form.Item
              name='country'
              rules={[{
                required: true,
                message: 'Please enter country your company located in',
              }]}
              label='Country'
              hasFeedback
            >
              <Select
                showSearch
                style={{ textAlign: 'left' }}
                placeholder='Select a country'
                size='large'
                disabled={!user.CAN_MANAGE}
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {countries.map((country: Country) =>
                  <Option key={country.value} value={country.value}>{country.display_name}</Option>
                )}
              </Select>
            </Form.Item>
            <Form.Item
              name='postal_code'
              rules={[{
                required: true,
                message: 'Please enter company postal code',
              }]}
              label='Postal code'
              hasFeedback
            >
              <Input placeholder='Postal code' size='large' readOnly={!user.CAN_MANAGE} />
            </Form.Item>
            <Form.Item
              name='default_currency'
              label='Default currency'
            >
              <Input size='large' readOnly />
            </Form.Item>
            <div className={styles['transaction-currency-details']}>
              Transactions in other currencies will be converted to your default currency. Default currency will be
              used for analytics, billing, payouts, etc.
            </div>
          </div>
          <div>
            <AccessControl permission={Permissions.CAN_MANAGE}>
              <Button
                type='primary'
                htmlType='submit'
                size='large'
                block
                loading={loading}
                disabled={submitDisabled}
              >
                {loading ? 'Updating' : 'Update'}
              </Button>
            </AccessControl>
          </div>
        </Form>
      </div>
    </SinglePageLayout>
  )
}

const connector = connect(state => ({
    organization: organizationSelector(state),
    countries: countriesSelector(state)
  }),
  {
    loadOrganization: loadOrganizationAction,
    updateOrganization: updateOrganizationAction,
    loadCountries: loadCountriesAction
  }
)

export default connector(OrganizationDetailsForm)
