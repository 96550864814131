export interface IMiniApp {
  id: number
  name: string
  short_description: string
  long_description: string
  logo: string | null
  category: ICategory | null
  app_url: string
  get_token_url: string
  app_id: string
  url: string | null
  countries: string[]
  publish: boolean
  status: string
  website: string | null
  required_fields: string[]
  commercial_model: string[] | null
  hide_commercial_model: boolean
  summary_of_terms: string | null
  inherit_colors: boolean
  orientation: MiniAppOrientations
  action_button_theme: MiniAppActionBtnTheme
  action_button_position: MiniAppActionBtnPosition
  primary_color: string
  secondary_color: string
  tertiary_color: string
  status_bar_background: string
  webview_frame_size: MiniAppWebviewFrameSize
  white_listed_urls: string[]
  screens: IMiniAppScreenshot[]
  single_sign_on_integrated: boolean;
  get_auth_token_url: string;
  payments_integrated: boolean;
  whitelisted_ips: string[]
  webhook_url: string;
}

export enum MiniAppOrientations {
  Portrait,
  Landscape,
  Auto,
}

export enum MiniAppActionBtnTheme {
  Light = 'light',
  Dark = 'dark',
}

export enum MiniAppActionBtnPosition {
  Fixed = 'fixed',
  Floating = 'floating',
}

export enum MiniAppWebviewFrameSize {
  SafeArea,
  Fullscreen,
}

export interface IMiniAppScreenshot {
  id: number
  url: string
}

export interface IUploadMiniAppScreenShotResponse {
  id: number
  image: string
  miniapp: number
}

export interface ICategory {
  id: number;
  name: string;
  description: string;
  logo: string | null;
}

export interface IFile {
  id: number;
  name: string;
  document: string;
}

export interface IShowroomMiniApp {
  id: number
  name: string
  short_description: string
  long_description: string
  logo: string
  category: ICategory
  countries: string[]
  app_url: string
  website: string | null
  required_fields: string[]
  commercial_model: string[]
  commercial_model_requested: boolean
  hide_commercial_model: boolean
  summary_of_terms: string | null
  documents: IFile[]
  orientation: MiniAppOrientations
  screens: IMiniAppScreenshot[]
  is_my_miniapp: boolean
}

export interface IIntegratedMiniApp {
  id: number
  name: string
  long_description: string
  logo: string
  category: ICategory
  app_id: string
  required_fields: string[]
  summary_of_terms: string
  documents: any[]
  commercial_model?: string | null;
  website: string
}
