/*CORE*/
import React from 'react'
/*LIBS*/
import { Dropdown, Menu, Typography } from 'antd'
import { EllipsisOutlined } from '@ant-design/icons/lib'
import { useHistory } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import moment from 'moment'
/*ACTIONS*/
import {
  downloadMiniappPayout as downloadMiniappPayoutAction,
  downloadSuperappPayout as downloadSuperappPayoutAction
} from 'store/payouts/actions'
/*COMPONENTS*/
import Badge from '../../components/Badge/Badge'
import DoubleLogo from '../../components/DoubleLogo/DoubleLogo'
import CustomSelect, { CustomSelectOption } from '../../components/CustomSelect/CustomSelect'
import NoDataPlaceholder from '../../components/NoDataPlaceholder/NoDataPlaceholder'
import MoneyWrapper from '../../../common/MoneyWrapper'
/*CONSTANTS*/
import { FULL_DATE_FORMAT, AppType } from 'utils/constants'
import { PAYMENT_STATUS } from 'utils/billing'
/*TYPES*/
import { InvoiceStatus } from 'types/Billing'
import { IAppPayout } from 'types/Payouts'
/*ASSETS*/
import placeholder from '../../../../assets/img/no-data-placeholers/placeholder_billing_history.png'
/*STYLES*/
import styles from '../../Payment/PaymentHistory.module.scss'


const STATUSES: CustomSelectOption[] = [
  { label: 'All payouts', value: 'All' },
  { label: PAYMENT_STATUS[InvoiceStatus.Paid].label, value: InvoiceStatus.Paid },
  { label: PAYMENT_STATUS[InvoiceStatus.DueToPay].label, value: InvoiceStatus.DueToPay },
  { label: PAYMENT_STATUS[InvoiceStatus.Failed].label, value: InvoiceStatus.Failed },
]

const { Title } = Typography

interface Props extends ConnectedProps<typeof connector> {
  payouts: IAppPayout[]
}

const PayoutHistory = ({ payouts, downloadMiniappPayout, downloadSuperappPayout }: Props) => {
  const [ statusFilter, setStatusFilter ] = React.useState(STATUSES[0])
  const history = useHistory()

  const goToPayoutInfo = (payout: IAppPayout) => {
    if (payout.paidTo === AppType.Miniapp) {
      history.push(`/miniapp-payout-info/${payout.id}`)
    } else {
      history.push(`/superapp-payout-info/${payout.id}`)
    }
  }

  const handleDownloadPayout = (payout: IAppPayout, format: string) => {
    if (payout.paidTo === AppType.Miniapp) {
      downloadMiniappPayout(payout, format)
    } else {
      downloadSuperappPayout(payout, format)
    }
  }

  const filteredPayouts = payouts.filter((payout) => statusFilter === STATUSES[0] || payout.status === statusFilter.value)

  return (
    <div className={styles['payout-history']}>
      <div className={styles['header']}>
        <Title level={3}>Payout History</Title>
        <CustomSelect options={STATUSES} activeOption={statusFilter} onClickOption={status => setStatusFilter(status)} />
      </div>
      <table>
        <thead>
        <tr>
          <th>Amount</th>
          <th>Payment Details</th>
          <th>Payout Date</th>
          <th>ID</th>
          <th>Status</th>
          <th />
        </tr>
        </thead>
        <tbody>
        {
          !!filteredPayouts.length ?
            filteredPayouts.map((payout, index) => (
              <tr key={index} onClick={() => goToPayoutInfo(payout)}>
                <td>
                  <div className={styles['amount']}>
                    <DoubleLogo backLogo={payout.app_plan.hostapp.logo} frontLogo={payout.app_plan.miniapp.logo} />
                    <MoneyWrapper currency={payout.currency} amount={payout.amount} />
                  </div>
                </td>
                <td>
                  {
                    payout.destination ? (
                      <>
                        <div className={styles['payer']}>{payout.destination.bank_name}</div>
                        <div>Account Number: {payout.destination.account_number}</div>
                      </>
                    ) : '-'
                  }
                </td>
                <td>
                  {moment(payout.paid_date ? payout.paid_date : payout.due_date).format(FULL_DATE_FORMAT)}
                </td>
                <td>
                  {payout.payout_number}
                </td>
                <td>
                  <Badge
                    status={PAYMENT_STATUS[payout.status]?.status}
                    label={PAYMENT_STATUS[payout.status]?.label}
                  />
                </td>
                <td>
                  <div onClick={event => event.stopPropagation()}>
                    <Dropdown
                      placement='bottomRight'
                      overlay={(
                        <Menu>
                          <Menu.Item key={0} onClick={() => handleDownloadPayout(payout, 'csv')}>
                            Download as CSV
                          </Menu.Item>
                          <Menu.Item key={1} onClick={() => handleDownloadPayout(payout, 'pdf')}>
                            Download as PDF
                          </Menu.Item>
                        </Menu>
                      )}
                      trigger={[ 'click' ]}
                    >
                      <EllipsisOutlined />
                    </Dropdown>
                  </div>
                </td>
              </tr>
            ))
            :
            <tr style={{ backgroundColor: '#fff', cursor: 'auto' }}>
              <td style={{ padding: '0px' }} colSpan={5}>
                <div style={{ marginTop: '50px' }}>
                  <NoDataPlaceholder
                    text='Payout reports will be collected here.'
                    img={placeholder}
                    height={'265px'}
                  />
                </div>
              </td>
            </tr>
        }
        </tbody>
      </table>
    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators({
      downloadMiniappPayout: downloadMiniappPayoutAction,
      downloadSuperappPayout: downloadSuperappPayoutAction,
    }, dispatch),
  }
}


const connector = connect(null, mapDispatchToProps)

export default connector(PayoutHistory)

