/*CORE*/
import React, { useEffect, useState } from 'react'
/*LIBS*/
import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { Link, useHistory } from 'react-router-dom'
import { Typography, Form, Input, Button, message } from 'antd'
import { FieldData, Store } from 'rc-field-form/lib/interface'
/*ASSETS*/
import { ReactComponent as Cross } from 'assets/img/cross.svg'
/*ACTIONS*/
import { updateProfile as updateProfileAction } from 'store/profile/actions'
/*SELECTORS*/
import { profileSelector } from 'store/profile/selectors'
/*COMPONENTS*/
import SinglePageLayout from '../../components/SinglePageLayout/SinglePageLayout'
/*UTILS*/
import { hasErrors, normalizeFields } from 'utils/utils'
/*TYPES*/
import { RootState } from 'types'
/*STYLES*/
import styles from './ProfileDetails.module.scss'


const { Title } = Typography

interface Props extends ConnectedProps<typeof connector> {}

const ProfileDetails = ({ profile, updateProfile }: Props) => {
  const [form] = Form.useForm()
  const [submitDisabled, setSubmitDisabled] = React.useState(true)
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const handleClose = () => {
    history.push('/')
  }

  const handleSubmit = (values: Store) => {
    updateProfileDetails({
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
    })
  }

  const handleFieldsChange = (changedFields: FieldData[], fields: FieldData[]) => {
    const values = normalizeFields(fields)
    setSubmitDisabled(
      (
        values.first_name.value === profile.first_name &&
        values.last_name.value === profile.last_name &&
        values.email.value === profile.email
      ) || hasErrors(fields)
    )
  }

  const updateProfileDetails = async (data: Store) => {
    setLoading(true)
    try {
      await updateProfile(data)
      setSubmitDisabled(true)
      message.success('Updated successfully')
    } catch (error) {
      if (error.response?.data) {
        Object.keys(error.response.data).forEach(key => {
          form.setFields([
            { name: key, errors: error.response.data[key] }
          ]);
        })
      } else {
        message.error('Something went wrong. Please try again.')
      }
    }
    setLoading(false)
  }

  const handleTurnOff2FA = () => {
    history.push('/turn-off-2fa')
  }

  useEffect(() => {
    form.setFieldsValue({
      first_name: profile.first_name,
      last_name: profile.last_name,
      email: profile.email
    })
  }, [form, profile])

  return (
    <SinglePageLayout
      onGoBack={handleClose}
      className={styles['profile-wrapper']}
    >
      <Form
        form={form}
        className={styles['profile-form']}
        layout='vertical'
        onFinish={handleSubmit}
        onFieldsChange={handleFieldsChange}
        initialValues={{
          first_name: profile.first_name,
          last_name: profile.last_name,
          email: profile.email
        }}
      >
        <Title>Account details</Title>
        <div>
          <Form.Item
            name='first_name'
            rules={[{
              required: true,
              message: 'Please enter first name',
            }]}
            hasFeedback
            label='Name'
          >
            <Input placeholder='Name' size='large'/>
          </Form.Item>
          <Form.Item
            name='last_name'
            rules={[{
              message: 'Please enter last name',
            }]}
            hasFeedback
            label='Surname'
          >
            <Input placeholder='Surname' size='large'/>
          </Form.Item>
          <Form.Item
            name='email'
            rules={[{
              required: true,
              message: 'Please enter email',
            }, {
              type: 'email',
              message: 'The input is not valid email',
            }]}
            hasFeedback
            label='Email'
          >
            <Input placeholder='Email' size='large'/>
          </Form.Item>
          <div className={styles['actions']}>
            <Link to='/change-password'>Change Password</Link>
            <hr />
            {profile.is_enabled_2fa
              ? <div className={styles['info']}>Two-step authentication</div>
              : <Link to='/setup-2fa'>Two-step authentication</Link>
            }
            <span>Keep your account extra secure with a second authentication step.</span>
            {profile.is_enabled_2fa && (
              <>
                <div>
                  <div className={styles['enabled']}>Authenticator app is enabled
                    <Cross onClick={handleTurnOff2FA} />
                  </div>
                </div>
                <span>
                  If you lose your mobile device or security key, you
                  can <Link to='/regenerate-2fa'>generate a backup code</Link> to sign in to your account.
                </span>
              </>
            )}
          </div>
        </div>
        <div>
          <Button
            type='primary'
            htmlType='submit'
            size='large'
            block
            loading={loading}
            disabled={submitDisabled}
          >
            {loading ? 'Updating' : 'Update'}
          </Button>
        </div>
      </Form>
    </SinglePageLayout>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    profile: profileSelector(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators({
      updateProfile: updateProfileAction
    }, dispatch),
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(ProfileDetails)
