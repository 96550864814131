/*CORE*/
import React from 'react'
/*ASSETS*/
import { ReactComponent as BrokenFileIcon } from 'assets/img/broken-file.svg'
/*STYLES*/
import styles from './ErrorPlaceholder.module.scss'

const ErrorPlaceholder = () =>
  <div className={styles['error-placeholder']}>
    <div className={styles['content']}>
      <BrokenFileIcon className={styles['icon']} />
      <p className={styles['description']}>The file is corrupted</p>
    </div>
  </div>

export default ErrorPlaceholder
