/*CORE*/
import React from 'react'
/*LIBS*/
import { useHistory } from 'react-router-dom'
import { Typography, Button, Form, Row, Col, Input, Select } from 'antd'
/*COMPONENTS*/
import SinglePageLayout from '../../../components/SinglePageLayout/SinglePageLayout'
/*TYPES*/
import { IBankAccount, ICurrency } from 'types/Payouts'
import { IFormData } from 'types'
/*CONSTANTS*/
import { DEFAULT_CURRENCY } from 'utils/constants'
/*STYLES*/
import styles from './BankAccountForm.module.scss'


const { Title } = Typography

const FIELD_IS_REQUIRED = 'Field is required'

interface Props {
  onSave: (values: FormData) => void
  bankAccount?: IBankAccount,
  currencies: ICurrency[]
}

const BankAccountForm = ({ onSave, bankAccount, currencies }: Props) => {
  const history = useHistory()
  const [form] = Form.useForm()

  const handleClose = () => {
    history.goBack()
  }

  const handleSubmit = (values: IFormData) => {
    if (values.bank_branch_code === '') {
      delete values.bank_branch_code
    }
    if (values.iban === '') {
      delete values.iban
    }
    if (values.ifsc === '') {
      delete values.ifsc
    }
    if (values.transfer_reason === '') {
      delete values.transfer_reason
    }
    onSave(values as FormData)
  }

  return (
    <SinglePageLayout
      onGoBack={handleClose}
      contentPadding='large'
      isFullHeight
      className={styles['payouts-settings']}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        layout='vertical'
        initialValues={{
          preferred_currency: 1,
          ...bankAccount
        }}
      >
        <div className={styles['payouts-settings__title']}>
          <Title>Payment Details</Title>
          <Button type='primary' htmlType='submit'>Save</Button>
        </div>
        <div className={styles['payouts-settings__content']}>
          <Row gutter={[144, 8]}>
            <Col span={12}>
              <Title
                className={styles['payouts-settings__sub']}
                level={3}
              >
                Beneficiary Bank Details
              </Title>
              <Form.Item
                name='bank_name'
                label='Bank name'
                rules={[{ required: true, message: FIELD_IS_REQUIRED }]}
              >
                <Input placeholder='Bank name'/>
              </Form.Item>
              <Form.Item
                name='account_holder_name'
                label='Account holder name'
                rules={[{ required: true, message: FIELD_IS_REQUIRED }]}
              >
                <Input placeholder='Account holder name'/>
              </Form.Item>
              <Form.Item
                name='account_number'
                label='Account number'
                rules={[{ required: true, message: FIELD_IS_REQUIRED }]}
              >
                <Input placeholder='Account number'/>
              </Form.Item>
              <Form.Item
                name='bank_address'
                label='Bank address'
                rules={[{ required: true, message: FIELD_IS_REQUIRED }]}
              >
                <Input placeholder='Bank address*'/>
              </Form.Item>
              <Form.Item
                name='swift_bic'
                label='Swift Code / BIC'
                rules={[{ required: true, message: FIELD_IS_REQUIRED }]}
              >
                <Input placeholder='Swift Code / BIC'/>
              </Form.Item>
              <Form.Item
                name='bank_branch_code'
                label='Bank branch code'
              >
                <Input placeholder='Bank branch code'/>
              </Form.Item>
              <Form.Item
                name='iban'
                label='IBAN'
              >
                <Input placeholder='IBAN'/>
              </Form.Item>
              <Form.Item
                name='ifsc'
                label='IFSC'
              >
                <Input placeholder='IFSC'/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Title
                className={styles['payouts-settings__sub']}
                level={3}
              >
                Payment Details
              </Title>
              <Form.Item
                name='preferred_currency'
                label='Preferred currency'
                rules={[{ required: true, message: FIELD_IS_REQUIRED }]}
              >
                <Select placeholder='Preferred currency'>
                  {currencies.filter((currency) => currency.code === DEFAULT_CURRENCY.code).map((currency) => (
                    <Select.Option value={currency.id}>{currency.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name='transfer_reason'
                label='Reference'
              >
                <Input placeholder='Reference'/>
              </Form.Item>
              <Form.Item
                name='email'
                label='Email'
                rules={[
                  { type: 'email', message: 'The input is not valid email' },
                ]}
              >
                <Input placeholder='Email address for transfer statement'/>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </SinglePageLayout>
  )
}

export default BankAccountForm
