/*CORE*/
import React from 'react'
/*LIBS*/
import { Button, Typography } from 'antd'
import { useHistory } from 'react-router-dom'
/*COMPONENTS*/
import PaymentInfoMultiCurrency from '../../Payment/PaymentInfoMultiCurrency/PaymentInfoMultiCurrency'
import SinglePageLayout from '../../components/SinglePageLayout/SinglePageLayout'
import AppsPaymentTable from '../../Payment/AppsPaymentTable/AppsPaymentTable'
import AppSkeleton from '../../components/Skeletons/AppSkeleton'
import TotalPayouts from '../TotalPayouts/TotalPayouts'
import Badge from '../../components/Badge/Badge'
/*UTILS*/
import { PAYMENT_STATUS } from 'utils/billing'
import { goBack } from 'utils/utils'
/*TYPES*/
import { IAppPayout } from 'types/Payouts'
import { IAppPlan } from 'types/Billing'
/*STYLES*/
import styles from '../../Payment/PaymentInfo.module.scss'

const { Title } = Typography

interface Props {
  payout?: IAppPayout
  downloadPayout: (invoice: IAppPayout, format: string) => void,
  appPlan?: IAppPlan
}

const PayoutInfo = ({ payout, downloadPayout, appPlan }: Props) => {
  const history = useHistory()

  const handleGoBack = () => {
    goBack(history, '/payout/payout-history')
  }

  if (!payout) {
    return (
      <SinglePageLayout onGoBack={handleGoBack}>
        <AppSkeleton />
      </SinglePageLayout>
    )
  }

  return (
    <SinglePageLayout className={styles['billing-info']} onGoBack={handleGoBack}>
      <div className={styles['header']}>
        <div className={styles['details']}>
          <div>
            <Title>Payout information</Title>
            <span>{payout.payout_number}</span>
          </div>
          <Badge
            status={PAYMENT_STATUS[payout.status]?.status}
            label={PAYMENT_STATUS[payout.status]?.label}
            className={styles['badge']}
          />
        </div>
        <div className={styles['actions']}>
          <Button type='link' onClick={() => downloadPayout(payout, 'pdf')}>Export PDF</Button>
          <span>{' | '}</span>
          <Button type='link' onClick={() => downloadPayout(payout, 'csv')}>Export CSV</Button>
        </div>
      </div>
      <TotalPayouts appPlans={appPlan ? [appPlan] : []} payout={payout} />
      <AppsPaymentTable appPlans={appPlan ? [appPlan] : []} unClickable />
      <PaymentInfoMultiCurrency appPlans={appPlan ? [appPlan] : []} />
    </SinglePageLayout>
  )
}

export default PayoutInfo
