import React from 'react'
import { Link } from 'react-router-dom'

import { Layout, Menu } from 'antd'
import ProfileNav from '../ProfileNav/ProfileNav'
import { RouteData } from 'RootRouter'
import Footer from '../Footer/Footer'

import './DashboardLayout.scss'
import { DashboardLogo } from 'pages/common/Logo'

interface Props {
  menuItems: RouteData[],
  selectedKeys: string[],
  children?: React.ReactNode | React.ReactNodeArray
}

const { Content, Sider } = Layout

const DashboardLayout = ({ menuItems, selectedKeys, children }: Props) => {
  return (
    <Layout className="dashboard">
      <Sider
        width={256}
        className="dashboard__aside"
      >
        <div className="dashboard__logo">
          <DashboardLogo />
        </div>
        <Menu
          theme="light"
          mode="inline"
          selectedKeys={selectedKeys}
          defaultSelectedKeys={['1']}
          inlineIndent={36}
        >
          {menuItems.map(menuItem => (
            <Menu.Item key={menuItem.navKey}>
              {menuItem.isExternalUrl ? (
                <a href={menuItem.path} target="_blank" rel="noopener noreferrer">{menuItem.label}</a>
              ) : (
                <Link to={{ pathname: menuItem.path, search: menuItem.search }}>{menuItem.label}</Link>
              )}
            </Menu.Item>
          ))}
        </Menu>
        <div className="dashboard__bottom">
          <ProfileNav />
        </div>
      </Sider>
      <Layout className="dashboard__main">
        <Content className="dashboard__content">
          {children}
        </Content>
        <Footer />
      </Layout>
    </Layout>
  )
}

export default DashboardLayout
