import {
  LOAD_MINI_APPS,
  LOAD_MINI_APPS_ERROR,
  UPDATE_MINI_APP,
  LOAD_MINI_APPS_REQUEST,
  LOAD_COUNTRIES,
} from './actions'

export function miniApps(state = {
  isInitialLoad: true,
  isLoading: false,
  miniApps: [],
  countries: [],
}, action) {
  switch (action.type) {
    case LOAD_MINI_APPS_REQUEST: {
      return {
        ...state,
        isLoading: true
      }
    }
    case LOAD_MINI_APPS_ERROR: {
      return {
        ...state,
        isLoading: false
      }
    }
    case LOAD_COUNTRIES: {
      return {
        ...state,
        countries: action.payload,
      }
    }
    case LOAD_MINI_APPS: {
      return {
        ...state,
        isInitialLoad: false,
        isLoading: false,
        miniApps: action.miniApps
      }
    }
    case UPDATE_MINI_APP: {
      const miniApps = state.miniApps.map(app => {
        if (app.id === action.miniApp.id) {
          return {
            ...app,
            ...action.miniApp,
          }
        }
        return app
      })
      return {
        ...state,
        miniApps
      }
    }
    default:
      return state
  }
}
