/*CORE*/
import React from 'react'
/*LIBS*/
import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
/*COMPONENTS*/
import AppsPaymentTable from '../Payment/AppsPaymentTable/AppsPaymentTable'
import TotalBilling from '../Billing/TotalBilling/TotalBilling'
import BillingHistory from '../Billing/BillingHistory/BillingHistory'
/*SELECTORS*/
import { miniAppBillingPlansSelector, miniAppInvoiceSelector } from 'store/billing/selectors'
/*ACTIONS*/
import { loadMiniAppInvoices as loadMiniAppInvoicesAction } from 'store/billing/actions'
/*TYPES*/
import { RootState } from 'types'


interface Props extends ConnectedProps<typeof connector> {
  appId: string
}

const MiniAppBilling = ({ miniAppInvoices, miniAppPlans, updateInvoices }: Props) => {
  return (
    <>
      <TotalBilling appPlans={miniAppPlans} />
      <AppsPaymentTable appPlans={miniAppPlans} title='Month-to-date' />
      <BillingHistory invoices={miniAppInvoices} updateInvoices={updateInvoices} />
    </>
  )
}


const mapStateToProps = (state: RootState, { appId }: { appId?: string }) => {
  return {
    miniAppInvoices: miniAppInvoiceSelector(+appId!, state),
    miniAppPlans: miniAppBillingPlansSelector(+appId!, state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators({
      updateInvoices: loadMiniAppInvoicesAction
    }, dispatch)
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(MiniAppBilling)
