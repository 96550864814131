/*CORE*/
import React, { ChangeEvent, useRef, useState } from 'react'
/*LIBS*/
import { PlusOutlined } from '@ant-design/icons/lib/icons'
import { IMiniApp, IMiniAppScreenshot, MiniAppOrientations } from 'types'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AxiosResponse } from 'axios'
import classnames from 'classnames'
import { message } from 'antd'
/*ACTIONS*/
import {
  deleteMiniAppScreenshot as deleteMiniAppScreenshotAction,
  uploadMiniAppScreenshot as uploadMiniAppScreenshotsAction
} from 'store/miniApps/actions'
/*COMPONENTS*/
import ScreenshotPreview from './ScreenshotPreview/ScreenshotPreview'
import Spinner from 'pages/Dashboard/components/Spinner/Spinner'
import ScreenshotPreviewModal from './ScreenshotPreviewModal/ScreenshotPreviewModal'
/*CONSTANTS*/
import { ALLOWED_SCREENSHOT_SIZES_TO_UPLOAD } from 'utils/constants'
/*STYLES*/
import styles from './ScreenshotsUploader.module.scss'

const INCORRECT_SCREENSHOT_SIZE_MSG = 'Incorrect image size' as const
const MAX_SCREENS_COUNT = 5 as const
const SPINNER_DELAY = 500 as const

interface IDispatchProps {
  uploadMiniAppScreenshots: (miniAppId: number | string, screenshots: any) => void
  deleteMiniAppScreenshot: (screenshotId: number) => Promise<AxiosResponse>
}

interface Props extends IDispatchProps {
  miniApp: IMiniApp
  updateApp: () => void
}

function ScreenshotsUploader({ uploadMiniAppScreenshots, miniApp, updateApp, deleteMiniAppScreenshot }: Props) {

  const [ screenshotIdToDelete, setScreenshotIdToDelete ] = useState<number | null>(null)
  const [ screenshotIdToOpen, setScreenshotIdToOpen ] = useState<number | null>(null)
  const [ isUploading, setIsUploading ] = useState(false)
  const filesInput = useRef<HTMLInputElement>(null)

  const isLandscape = !!miniApp.orientation
  const isUploadAllowed = miniApp?.screens?.length < MAX_SCREENS_COUNT

  const UploadButton = () => (
    <div className={styles['upload-btn']}>
      {
        isUploading ?
          <Spinner />
          :
          <>
            <PlusOutlined className={styles['icon']} />
            <div className="ant-upload-text">Upload</div>
          </>
      }
    </div>
  )

  function screenshotsSizesStrategy(isLandscape: boolean): { WIDTH: number, HEIGHT: number } {
    const orientation = isLandscape ? MiniAppOrientations.Landscape : MiniAppOrientations.Portrait
    return ALLOWED_SCREENSHOT_SIZES_TO_UPLOAD[orientation]
  }

  function detectUploadImgSize(file: File): Promise<{ error: string } | null> {
    return new Promise(resolve => {
      const img = new Image()

      img.onload = () => {
        const { width, height } = img
        const allowedSizes = screenshotsSizesStrategy(isLandscape)
        const isCorrectSize = width === allowedSizes.WIDTH && height === allowedSizes.HEIGHT
        const resolveMessage = isCorrectSize ? null : { error: INCORRECT_SCREENSHOT_SIZE_MSG }
        resolve(resolveMessage)
      }

      img.src = URL.createObjectURL(file)
    })
  }

  async function onChange(e: ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0]
    if (!file) {
      return
    }

    const imageSizeResult = await detectUploadImgSize(file)
    const imageSizeErrorMsg = imageSizeResult?.error

    if (imageSizeErrorMsg) {
      message.error(imageSizeErrorMsg)
      return
    }

    try {
      setIsUploading(true)
      await uploadMiniAppScreenshots(miniApp!.id, file)
      clearFileInput()
      updateApp()
      hideUploadBtnSpinner()
    } catch (e) {
      setIsUploading(false)
      message.error('Something went wrong while uploading screenshot')
    }
  }

  function clearFileInput() {
    filesInput && filesInput.current && (filesInput.current.value = '')
  }

  function hideUploadBtnSpinner() {
    setTimeout(() => {
      const hideUploadSpinner = miniApp?.screens?.length < MAX_SCREENS_COUNT - 1
      hideUploadSpinner && setIsUploading(false)
    }, SPINNER_DELAY)
  }

  function openPreviewModal(screenArrayIdx: number) {
    setScreenshotIdToOpen(screenArrayIdx)
  }

  function closePreviewModal() {
    setScreenshotIdToOpen(null)
  }

  const onDeleteScreenshot = (screenshotId: number) => async () => {
    try {
      setScreenshotIdToDelete(screenshotId)
      await deleteMiniAppScreenshot(screenshotId)
      setIsUploading(false)
      updateApp()
    } catch (e) {
      setScreenshotIdToDelete(null)
      message.error('Something went wrong while deleting screenshot')
    }
  }

  const onPreviewScreenshot = (id: number) => () => {
    const candidateIdx = miniApp?.screens.findIndex(screen => screen.id === id)
    candidateIdx > -1 && openPreviewModal(candidateIdx)
  }

  return (
    <section className={classnames(styles['screenshots-uploader'], isLandscape && styles['screenshots-uploader__landscape'])}>
      <div className={styles['preview-area']}>
        {
          !!miniApp?.screens?.length &&
          miniApp.screens
            .sort((a, b) => a.id - b.id)
            .map(({ id, url }: IMiniAppScreenshot) =>
              <ScreenshotPreview
                key={id}
                imgUrl={url}
                onDelete={onDeleteScreenshot(id)}
                onPreview={onPreviewScreenshot(id)}
                className={styles['common-wrapper']}
                isDeleteInProgress={screenshotIdToDelete === id}
              />
            )
        }
        {
          isUploadAllowed &&
          <div
            className={classnames(styles['upload-area'], styles['common-wrapper'], isUploading && styles['upload-area__loading'])}
            onClick={() => !isUploading && filesInput?.current?.click()}
          >
            <UploadButton />
            <input
              hidden
              type="file"
              ref={filesInput}
              onChange={onChange}
              className={styles['input']}
              accept='image/jpg, image/jpeg, image/x-png, image/png, .png, .jpg, .jpeg'
            />
          </div>
        }
        <ScreenshotPreviewModal
          screenshotToOpenId={screenshotIdToOpen}
          onCancel={closePreviewModal}
          isLandscape={isLandscape}
          screens={miniApp.screens}
        />
      </div>
    </section>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators<any, any>({
      uploadMiniAppScreenshots: uploadMiniAppScreenshotsAction,
      deleteMiniAppScreenshot: deleteMiniAppScreenshotAction,
    }, dispatch),
  }
}

export default connect<null, IDispatchProps>(
  null,
  mapDispatchToProps
)(ScreenshotsUploader)
