/*CORE*/
import React, { useState, useEffect, useRef } from 'react'
/*LIBS*/
import Cropper from 'cropperjs'
import { Modal, Button } from 'antd'
/*HOOKS*/
import usePrevious from 'hooks/usePrevious'
/*STYLES*/
import './LogoCropper.scss'

type Props = {
  isModalVisible: boolean
  imgUrl: string | null
  onImageCrop: (data: { dataUrl: string; dataBlob: Blob }) => void
  onCancelClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
}

const LogoCropper = ({
  isModalVisible,
  imgUrl,
  onImageCrop,
  onCancelClick,
}: Props) => {
  const [visible, setVisible] = useState(isModalVisible)
  const prevImgUrl = usePrevious(imgUrl)
  const imgRef = useRef<HTMLImageElement | null>(null)
  const cropper = useRef<Cropper | null>(null)

  useEffect(() => {
    setVisible(isModalVisible)

    if (imgUrl && imgRef.current && prevImgUrl !== imgUrl) {
      cropper.current = new Cropper(imgRef.current, {
        aspectRatio: 1,
        scalable: false,
        rotatable: false,
        cropBoxResizable: true,
        zoomable: false,
        dragMode: 'none',
      })
    }

    return () => {
      if (cropper?.current) {
        cropper.current.destroy()
        cropper.current = null
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalVisible, imgUrl])

  const handleCrop = () => {
    const canvas = cropper.current?.getCroppedCanvas()
    canvas?.toBlob(
      function (blob) {
        onImageCrop({
          // fix https://github.com/fengyuanchen/cropperjs#known-issues
          dataUrl: canvas.toDataURL('image/png'),
          dataBlob: blob as Blob,
        })
      },
      'image/png',
      // the image size increased after crop, we can't fix this issue 100%, refer https://github.com/fengyuanchen/cropperjs/issues/538
      0.1
    )
  }

  return (
    <Modal
      visible={visible}
      title="Crop image"
      className="logo-cropper"
      maskClosable={false}
      closable={false}
      footer={[
        <Button key="cropBtn" onClick={handleCrop} type="primary">
          Crop
        </Button>,
        <Button key="cancelBtn" onClick={onCancelClick} className="btn-outline">
          Cancel
        </Button>,
      ]}
    >
      <img
        ref={imgRef}
        src={imgUrl as string}
        alt=""
        style={{
          display: 'block',
          maxWidth: '100%',
        }}
      />
    </Modal>
  )
}

export default LogoCropper
