/*CORE*/
import React from 'react'
/*LIBS*/
import { Skeleton } from 'antd'
/*STYLES*/
import styles from './DetailsSkeletonLoader.module.scss'

function DetailsSkeletonLoader() {

  return (
    <div className={styles['loader']}>
      <div className={styles['loader__screenshots']}>
        <Skeleton.Input active style={{ width: 180, height: 320 }} />
        <Skeleton.Input active style={{ width: 180, height: 320 }} />
        <Skeleton.Input active style={{ width: 180, height: 320 }} />
      </div>
      <Skeleton title={false} active paragraph={{rows: 7, width: '100%', style: {height: 24}}} />
    </div>
  )
}

export default DetailsSkeletonLoader
