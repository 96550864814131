import { createSelector } from 'reselect'
import { RootState, Integration } from 'types'
import { IntegrationStatuses } from 'utils/constants'

const integrationStateSelector = (state: RootState) => state.integrations

export const integrationsSelector = createSelector(
  integrationStateSelector,
  integrationState => integrationState.integrations
)

export const integratedMiniappIdsSelector = createSelector(
  integrationStateSelector,
  integrationState => {
    const integrations = integrationState.integrations.filter((item: Integration) => item.status === IntegrationStatuses.Accepted)
    return integrations.map((item: Integration) => item.miniapp)
  }
)
