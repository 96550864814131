import axios from 'axios'
import {
  API_LOGIN_URL,
  API_SIGNUP_URL,
  API_ACCEPT_INVITE,
  API_RESET_PASSWORD,
  API_RESET_PASSWORD_CONFIRM,
  API_GET_2FA_TOKEN,
  API_VERIFY_2FA_CODE,
  API_RESEND_CONFIRMATION,
  API_ACCEPT_ORGANIZATION_INVITE,
  API_ACTIVATE_URL,
  API_DISABLE_2FA,
  API_NEW_2FA_EMERGENCY_CODE,
} from '../../utils/constants'

export const SET_AUTH = 'SET_AUTH'
export const LOGOUT = 'LOGOUT'

export const setAuth = (isAuthenticated) => {
  return dispatch => {
    dispatch({ type: SET_AUTH, isAuthenticated })
    !isAuthenticated && dispatch({ type: LOGOUT })
  }
}

export function login(credentials) {
  return function (dispatch, getState) {
    return axios.post(API_LOGIN_URL, credentials)
  }
}

export function recover(email) {
  return function (dispatch, getState) {
    return axios.post(API_RESET_PASSWORD, email)
  }
}

export function resendConfirmation(email) {
  return function (dispatch, getState) {
    return axios.post(API_RESEND_CONFIRMATION, { email })
  }
}

export function setNewPassword(recoverData) {
  return function (dispatch, getState) {
    return axios.post(API_RESET_PASSWORD_CONFIRM, recoverData)
  }
}

export function validateInvitationToken(key) {
  return function (dispatch, getState) {
    return axios.post(API_ACCEPT_INVITE, { key })
  }
}

export function validateOrganizationInvitationToken(key) {
  return function (dispatch, getState) {
    return axios.post(API_ACCEPT_ORGANIZATION_INVITE, { key })
  }
}

export function validateUserActivation(uidb64, token) {
  return function (dispatch, getState) {
    return axios.post(API_ACTIVATE_URL, { uidb64, token })
  }
}

export function signup(formData) {
  return function (dispatch, getState) {
    return axios.post(API_SIGNUP_URL, formData)
  }
}

export function getTwoFactorAuthToken() {
  return function (dispatch, getState) {
    return axios.get(API_GET_2FA_TOKEN)
  }
}

export function verifyTwoFactorAuthCode(formData) {
  return function (dispatch, getState) {
    return axios.post(API_VERIFY_2FA_CODE, formData)
  }
}

export function disableTwoFactorAuth(formData) {
  return function (dispatch, getState) {
    return axios.post(API_DISABLE_2FA, formData)
  }
}

export function regenerateTwoFactorBackupCode(formData) {
  return function (dispatch, getState) {
    return axios.post(API_NEW_2FA_EMERGENCY_CODE, formData)
  }
}
