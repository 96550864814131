/*CORE*/
import React, { useEffect, useState } from 'react'
/*LIBS*/
import { bindActionCreators, Dispatch } from 'redux'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { message } from 'antd'
/*COMPONENT*/
import PayoutInfo from '../Payouts/PayoutHistory/PayoutInfo'
/*ACTIONS*/
import {
  getMiniAppPayout as getMiniAppPayoutAction,
  downloadMiniappPayout as downloadMiniappPayoutAction
} from 'store/payouts/actions'
/*TYPES*/
import { convertToAppPlan, IAppPlan } from 'types/Billing'
import { IAppPayout } from 'types/Payouts'


interface Props {
  getMiniAppPayout: (payoutId: number) => Promise<IAppPayout>
  downloadMiniappPayout: (payout: IAppPayout, format: string) => void
}

const MiniAppPayoutInfo = ({ getMiniAppPayout, downloadMiniappPayout }: Props) => {
  const [payout, setPayout] = useState(undefined as IAppPayout | undefined)
  const [miniappPlan, setMiniappPlan] = useState(undefined as IAppPlan | undefined)
  const { payoutId } = useParams()

  useEffect(() => {
    loadAppPlan()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payoutId])

  const loadAppPlan = async () => {
    try {
      const miniappPayout: IAppPayout = await getMiniAppPayout(+payoutId!)
      setPayout(miniappPayout)

      const appPlan: IAppPlan = convertToAppPlan(miniappPayout)
      setMiniappPlan(appPlan)
    } catch (error) {
      message.error('Something went wrong while loading miniapp plan')
    }
  }

  return <PayoutInfo payout={payout} downloadPayout={downloadMiniappPayout} appPlan={miniappPlan} />
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators({
      getMiniAppPayout: getMiniAppPayoutAction,
      downloadMiniappPayout: downloadMiniappPayoutAction
    }, dispatch)
  }
}

// @ts-ignore
export default connect(null, mapDispatchToProps)(MiniAppPayoutInfo)
