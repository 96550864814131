/*CORE*/
import React, { useEffect, useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
/*LIBS*/
import { Carousel } from 'antd'
import classnames from 'classnames'
/*UTILS*/
import { sortBy } from 'utils/utils'
/*ASSETS*/
import { ReactComponent as ArrowLeft } from 'assets/img/arrow-left.svg'
import { ReactComponent as ArrowRight } from 'assets/img/arrow-right.svg'
/*TYPES*/
import { IShowroomMiniApp } from 'types'
import { IMiniAppByCategory } from '../types'
/*COMPONENTS*/
import ShowroomCard from '../ShowroomCard/ShowroomCard'
import { WithEmpty } from '../../components/WithEmpty/WithEmpty'
/*STYLES*/
import styles from './ShowroomCategory.module.scss'

const LARGE_RESOLUTION = 1400
const VISIBLE_CARDS_COUNT = 3
const VISIBLE_CARDS_COUNT_SMALL = 2

const NextArrow = ({ className, onClick }: { className?: string, onClick: () => void }) => (
  <div onClick={onClick} className={classnames(styles['arrow'], className)}>
    <ArrowRight />
  </div>
)

const PrevArrow = ({ className, onClick }: { className?: string, onClick: () => void }) => (
  <div onClick={onClick} className={classnames(styles['arrow'], className)}>
    <ArrowLeft />
  </div>
)

interface Props {
  isActiveCategoryFilter: boolean
  countriesSearchQuery: string []
  app: IMiniAppByCategory
  isWorldwide: boolean
  searchQuery: string
}

export const ShowroomCategory = ({ app, searchQuery, countriesSearchQuery, isActiveCategoryFilter, isWorldwide }: Props) => {
  const [ visibleSliderItemsCount, setVisibleSliderItemsCount ] = useState(VISIBLE_CARDS_COUNT)
  const [ prevIsAllowed, setPrevIsAllowed ] = useState(false)
  const [ nextIsAllowed, setNextIsAllowed ] = useState(true)
  const carouselRef = useRef<Carousel | null>(null)

  const history = useHistory()

  useEffect(() => {
    setVisibleCardsCount()
    window.addEventListener('resize', setVisibleCardsCount)
    return () => {
      window.removeEventListener('resize', setVisibleCardsCount)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function getVisibleCardsCountByScreen() {
    return window.innerWidth > LARGE_RESOLUTION ? VISIBLE_CARDS_COUNT : VISIBLE_CARDS_COUNT_SMALL
  }

  const haveMoreApps = app.miniApps.length > getVisibleCardsCountByScreen()

  const setVisibleCardsCount = () => {
    const count = haveMoreApps ? getVisibleCardsCountByScreen() : app.miniApps.length
    setVisibleSliderItemsCount(count)
  }

  const onClickCard = (id: number) => () => {
    history.push('/showroom/' + id)
  }

  const onNext = () => carouselRef?.current?.next()

  const onPrev = () => carouselRef?.current?.prev()

  const beforeChangeCarousel = (from: number, to: number) => {
    to === app.miniApps.length - visibleSliderItemsCount ?
      nextIsAllowed && setNextIsAllowed(false) : !nextIsAllowed && setNextIsAllowed(true)
    to === 0 ? prevIsAllowed && setPrevIsAllowed(false) : !prevIsAllowed && setPrevIsAllowed(true)
  }

  const handleFilterMiniappsByCountries = (countriesSearchQuery: string[]) => (miniapp: IShowroomMiniApp): boolean =>
    isWorldwide ||
    !!miniapp.countries.find(country => countriesSearchQuery.find(searchQueryCountry => searchQueryCountry === country))

  const handleFilterMiniappsByName = (searchQuery: string) => (miniapp: IShowroomMiniApp): boolean => {
    if (searchQuery) {
      const miniAppName = miniapp.name.toLowerCase()
      const query = searchQuery.toLowerCase()
      return miniAppName.indexOf(query) !== -1 || query.indexOf(miniAppName) !== -1
    }
    return true
  }

  const filteredMiniApps: IShowroomMiniApp[] =
    app.miniApps
      .filter(handleFilterMiniappsByCountries(countriesSearchQuery))
      .filter(handleFilterMiniappsByName(searchQuery))
      .sort(sortBy('name'))

  return (
    <section className={styles['showroom-category']}>
      <div className={styles['title']}>
        <h2>{app.category.name}</h2>
        {
          haveMoreApps && !isActiveCategoryFilter &&
          <Link to={'showroom/category/' + app.category.name} className={styles['link']}>See more</Link>
        }
      </div>

      {
        isActiveCategoryFilter ?
          !!filteredMiniApps?.length ?
            <div className={styles['cards']}>
              {
                filteredMiniApps.map(miniapp =>
                  <div className={styles['card']} key={miniapp.id} onClick={onClickCard(miniapp.id)}>
                    <ShowroomCard miniApp={miniapp} />
                  </div>
                )
              }
            </div>
            :
            <WithEmpty className={styles['empty']} />
          :
          <div className={styles['carousel-wrapper']}>
            {
              <NextArrow
                onClick={onNext}
                className={
                  classnames(
                    styles['next-arrow'],
                    nextIsAllowed && haveMoreApps && styles['next-arrow__visible'],
                  )
                }
              />
            }
            {
              <PrevArrow
                onClick={onPrev}
                className={
                  classnames(
                    styles['prev-arrow'],
                    prevIsAllowed && haveMoreApps && styles['prev-arrow__visible'],
                  )
                }
              />
            }
            <Carousel
              slidesToShow={visibleSliderItemsCount}
              beforeChange={beforeChangeCarousel}
              className={styles['carousel']}
              slidesToScroll={1}
              ref={carouselRef}
              infinite={false}
              dots={false}
            >
              {
                app.miniApps.sort(sortBy('name')).map(miniApp =>
                  <div className={styles['card']} key={miniApp.id} onClick={onClickCard(miniApp.id)}>
                    <ShowroomCard miniApp={miniApp} />
                  </div>,
                )
              }
            </Carousel>
          </div>
      }

    </section>
  )
}
