/*CORE*/
import React from 'react'
/*LIBS*/
import { Modal, Typography } from 'antd'
/*ASSETS*/
import { ReactComponent as MiniappIcon } from 'assets/img/miniapp-icon.svg'
import { ReactComponent as HostappIcon } from 'assets/img/hostapp-icon.svg'
/*CONSTANTS*/
import {
  COMPLETED_GUIDE_STEPS_KEY,
  MINIAPP_GUIDE_STEP_IDS,
  START_WELCOME_GUIDE_KEY,
  SUPERAPP_GUIDE_STEP_IDS,
  WELCOME_GUIDE_STEPS_KEY
} from 'utils/constants'
/*STYLES*/
import styles from './AppTypeNavModal.module.scss'

const { Title } = Typography

type Props = {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
}

const AppTypeNavModal = ({ isOpen, setIsOpen }: Props) => {
  const cleanUp = () => {
    localStorage.setItem(COMPLETED_GUIDE_STEPS_KEY, '[]')
    localStorage.setItem(START_WELCOME_GUIDE_KEY, 'false')
    setIsOpen(false)
  }

  const setMiniappWelcomeGuide = () => {
    localStorage.setItem(WELCOME_GUIDE_STEPS_KEY, JSON.stringify(MINIAPP_GUIDE_STEP_IDS))
    cleanUp()
  }

  const setSuperappWelcomeGuide = () => {
    localStorage.setItem(WELCOME_GUIDE_STEPS_KEY, JSON.stringify(SUPERAPP_GUIDE_STEP_IDS))
    cleanUp()
  }

  return (
    <Modal
      centered={true}
      visible={isOpen}
      closable={false}
      title='What do you want to add first?'
      className={styles['app-type-nav']}
      width={900}
      footer={null}
    >
      <div className={styles['app-types']}>
        <div onClick={setSuperappWelcomeGuide}>
          <HostappIcon />
          <Title level={4}>Host app</Title>
          <span>Native app that launches miniapps</span>
        </div>
        <div onClick={setMiniappWelcomeGuide}>
          <MiniappIcon />
          <Title level={4}>Miniapp</Title>
          <span>Native-like miniapp that is integrated in other native apps</span>
        </div>
      </div>
    </Modal>
  )
}

export default AppTypeNavModal
