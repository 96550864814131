import React from "react"
import SinglePageLayout from "pages/Dashboard/components/SinglePageLayout/SinglePageLayout"
import ResultPane from "pages/Dashboard/components/Result/Result"
import { Button } from "antd"

interface Props {
  handleClose: () => void
  text: string
}

const SendEmailComplete = ({ handleClose, text }: Props) => {
  return (
    <SinglePageLayout contentPadding='large' onClose={handleClose} isFullHeight>
      <ResultPane
        title='Check your email'
        text={text}
      >
        <Button
          type='primary'
          size='large'
          block
          style={{ width: "340px" }}
          onClick={handleClose}
        >
          Go Back
        </Button>
      </ResultPane>
    </SinglePageLayout>
  )
}

export default SendEmailComplete
