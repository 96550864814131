/*CORE*/
import React from 'react'
/*LIBS*/
import { Button, Typography } from 'antd'
import { useHistory } from 'react-router-dom'
/*ASSETS*/
import { ReactComponent as NotFoundIcon } from 'assets/img/not-found.svg'
import { ReactComponent as Logo } from 'assets/img/logo-oval.svg'
/*STYLES*/
import styles from './NotFound.module.scss'

const { Title, Text } = Typography

const NotFound = () => {
  const history = useHistory()

  const goHome = () => {
    history.push('/')
  }

  return (
    <div className={styles['not-found']}>
      <div className={styles['not-found__header']}>
        <div className={styles['not-found__logo']}>
          <Logo />
        </div>
        <div className={styles['not-found__help-text']}>
          <Title>Oops! Got lost?</Title>
          <Text>The page you’re looking for could not be found.</Text>
        </div>
        <div className={styles['not-found__home-btn']}>
          <Button
            block
            type='primary'
            onClick={goHome}
          >
            Back to home
          </Button>
        </div>
      </div>
      <div className={styles['not-found__icon']}>
        <NotFoundIcon />
      </div>
    </div>
  )
}

export default NotFound
