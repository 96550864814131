/*CORE*/
import React from 'react'
/*LIBS*/
import { Link } from 'react-router-dom'
import { Layout } from 'antd'
/*STYLES*/
import styles from './Footer.module.scss'

const { Footer } = Layout

const PageFooter = () => (
  <Footer className={styles['page-footer']}>
    <ul>
      <li>&copy; Appboxo</li>
      <li><Link to='/terms' target='_blank' rel='noopener noreferrer'>Terms</Link></li>
      <li><Link to='/privacy' target='_blank' rel='noopener noreferrer'>Privacy Policy</Link></li>
    </ul>
  </Footer>
)

export default PageFooter
