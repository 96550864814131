/*CORE*/
import React, { useEffect, useState } from 'react'
/*LIBS*/
import { bindActionCreators, Dispatch } from 'redux'
import { useHistory } from 'react-router-dom'
import { message, Typography } from 'antd'
import queryString from 'query-string'
import { connect } from 'react-redux'
import { AxiosResponse } from 'axios'
/*ACTIONS*/
import { getAppSubPlan as getAppSubPlanAction } from 'store/billing/actions'
/*COMPONENTS*/
import TransactionCommissionTable from '../TransactionCommissionTable/TransactionCommissionTable'
import SinglePageLayout from '../../components/SinglePageLayout/SinglePageLayout'
import LaunchesPaymentTable from '../LaunchesPaymentTable/LaunchesPaymentTable'
import AppsPaymentTable from '../AppsPaymentTable/AppsPaymentTable'
import AppSkeleton from '../../components/Skeletons/AppSkeleton'
/*TYPES*/
import { IAppPlan, PaymentType } from 'types/Billing'
import { AppPlanType, AppType } from 'utils/constants'
/*UTILS*/
import { goBack } from 'utils/utils'

const { Title } = Typography

type IDispatchProps = {
  getAppSubPlan: (id: string, isMiniappPlan: boolean) => Promise<AxiosResponse<IAppPlan>>
}

const AppPlanDetails = ({ getAppSubPlan }: IDispatchProps) => {
  const [appPlan, setAppPlan] = useState<IAppPlan | null>(null)
  const history = useHistory()

  const handleGoBack = () => {
    goBack(history, '/billing/month-to-date')
  }

  useEffect(() => {
    const { appPlanId, appType } = queryString.parse(history.location.search)

    if (appPlanId && appType) {
      getAppSubPlan(appPlanId as string, appType === AppType.Miniapp).then((resp) => {
        setAppPlan(resp.data)
      }).catch(() => {
        message.error('Something went wrong while retrieving app plan')
      })
    } else {
      history.push('/billing/month-to-date')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, history.location.search])

  const LaunchesDetails = ({ appPlan }: { appPlan: IAppPlan }) => (
    <div>
      <Title style={{ marginBottom: 40 }}>
        {appPlan.billing_type === PaymentType.Billing ? 'Charges' : 'Earnings'} for launches
      </Title>
      <AppsPaymentTable appPlans={[appPlan]} unClickable />
      <LaunchesPaymentTable appPlan={appPlan} />
    </div>
  )

  const TransactionDetails = ({ appPlan }: { appPlan: IAppPlan }) => (
    <div>
      <Title style={{ marginBottom: 40 }}>Commission for transactions</Title>
      <AppsPaymentTable appPlans={[appPlan]} unClickable />
      {appPlan.multi_currency_sales.length > 0 && (
        <TransactionCommissionTable appPlan={appPlan} estimation={true} />
      )}
    </div>
  )

  return (
    <SinglePageLayout onGoBack={handleGoBack}>
      {appPlan ?
        appPlan.plan_type === AppPlanType.Launches ? (
          <LaunchesDetails appPlan={appPlan} />
        ) : (
          <TransactionDetails appPlan={appPlan} />
        )
        :
        <AppSkeleton />
      }
    </SinglePageLayout>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators<any, any>({
      getAppSubPlan: getAppSubPlanAction,
    }, dispatch),
  }
}

export default connect<null, IDispatchProps>(null, mapDispatchToProps)(AppPlanDetails)
