/*TYPES*/
import { IPartnership } from 'types/Partnerships'
/*ACTIONS*/
import { LOAD_PARTNERSHIPS_ERROR, LOAD_PARTNERSHIPS_REQUEST, UPDATE_UI } from './actions'

export const LOAD_PARTNERSHIPS = 'LOAD_PARTNERSHIPS'

interface LoadPartnershipsAction {
  type: typeof LOAD_PARTNERSHIPS
  partnerships: IPartnership[]
}

interface LoadPartnershipsRequestAction {
  type: typeof LOAD_PARTNERSHIPS_REQUEST
}

interface LoadPartnershipsRequestErrorAction {
  type: typeof LOAD_PARTNERSHIPS_ERROR,
  error: any
}

export interface UpdateUiAction {
  type: typeof UPDATE_UI,
  payload: {
    csvModal?: boolean
    sentEmail?: boolean 
  }
}

export interface ExportOrderEmailPayload {
  email: string;
  from_date: string;
  to_date: string;
  hostapp_id: number;
  miniapp_id: number;
}

export type PartnershipsActionTypes =
  | LoadPartnershipsAction
  | LoadPartnershipsRequestAction
  | LoadPartnershipsRequestErrorAction
  | UpdateUiAction;
