/*CORE*/
import React, { ReactNode } from 'react'
/*ASSETS*/
import { ReactComponent as Logo } from 'assets/img/logo-oval.svg'
import { ReactComponent as Arrow } from 'assets/img/arrow.svg'
/*LIBS*/
import { Layout, Typography } from 'antd'
/*STYLES*/
import styles from './AuthLayout.module.scss'

const { Content } = Layout
const { Title, Text } = Typography

interface Props {
  title: string
  subtitle?: string
  children: ReactNode
  onGoBack?: (() => void) | null
  hideLogo?: boolean
}

const AuthLayout = ({ title, subtitle, children, onGoBack, hideLogo }: Props) => {
  return (
    <Layout>
      <Content>
        {onGoBack && (
          <div className={styles['auth-layout__back']} onClick={onGoBack}>
            <Arrow />
          </div>
        )}
        <div className={styles['auth-layout']}>
          <div className={styles['auth-layout__header']}>
            {!hideLogo && (
              <div className={styles['auth-layout__logo']}>
                <Logo />
              </div>
            )}
            <Title>{title}</Title>
            {subtitle && <Text className={styles['auth-layout__subtitle']}>{subtitle}</Text>}
          </div>
          {children}
        </div>
      </Content>
    </Layout>
  )
}

export default AuthLayout
