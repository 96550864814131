import { Button, Modal, Typography } from 'antd'
import { ModalFuncProps, ModalProps } from 'antd/lib/modal'
import { Flex } from 'pages/Dashboard/components/Flex'
import React, { useState } from 'react'
import styles from './ConfirmModal.module.scss'

type ConfirmModalProps = ModalProps & {
  description: string
} & Pick<
    ModalFuncProps,
    'okText' | 'cancelText' | 'onOk' | 'onCancel' | 'okButtonProps'
  >

export function useConfirmModal({
  title,
  description,
  okText,
  cancelText,
  onOk,
  onCancel,
  okButtonProps,
}: ConfirmModalProps) {
  const [visible, setVisible] = useState(false)

  const modal = (
    <Modal visible={visible} footer={null} closable={false} centered>
      <Flex className={styles.content} gap={40}>
        <Flex gap={8}>
          <Typography.Title>{title}</Typography.Title>
          <Typography.Text className={styles.description}>
            {description}
          </Typography.Text>
        </Flex>
        <Flex vertical={false} gap={20}>
          <Button type="primary" onClick={handleOK} danger {...okButtonProps}>
            {okText}
          </Button>
          <Button type="default" onClick={handleCancel}>
            {cancelText}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )

  function handleOK() {
    onOk?.()
  }
  function handleCancel() {
    onCancel?.()
    setVisible(false)
  }

  return {
    modal,
    open: () => setVisible(true),
    close: () => setVisible(false),
  }
}
