/*CORE*/
import React, { useState } from 'react'
/*LIBS*/
import { useHistory } from 'react-router-dom'
import { Button, Form, Input, message, Typography } from 'antd'
import { FieldData } from 'rc-field-form/lib/interface'
import { bindActionCreators, Dispatch } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
/*ACTIONS*/
import { disableTwoFactorAuth as disableTwoFactorAuthAction, setAuth as setAuthAction } from 'store/auth/actions'
/*COMPONENTS*/
import SinglePageLayout from '../../Dashboard/components/SinglePageLayout/SinglePageLayout'
/*UTILS*/
import { goBack, hasErrors, storeToken } from 'utils/utils'
/*TYPES*/
import { IFormData } from 'types'
/*STYLES*/
import styles from './DisableTwoFactorAuth.module.scss'
import { AxiosError, AxiosResponse } from 'axios'

const { Title } = Typography

interface Props extends ConnectedProps<typeof connector> {}

const DisableTwoFactorAuth = ({ disableTwoFactorAuth, setAuth }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [submitDisabled, setSubmitDisabled] = React.useState(false)
  const [form] = Form.useForm()
  const history = useHistory()

  const handleGoBack = () => {
    goBack(history, '/login')
  }

  const detectErrors = (changedFields: FieldData[], allFields: FieldData[]) => {
    setSubmitDisabled(hasErrors(allFields))
  }

  const handleSubmit = (formData: IFormData) => {
    setIsLoading(true)
    disableTwoFactorAuth(formData as { email: string, password: string, emergency_code: string })
    .then((resp: AxiosResponse<{ token: string, status: string }>) => {
      storeToken(resp.data.token, false)
      setAuth(true)
      message.success('Google Authenticator was successfully disabled')
      history.push('/')
    }).catch((error: AxiosError) => {
      message.error(error.response?.data?.status || 'Something went wrong while trying to disable two factor authorization')
    }).finally(() => {
      setIsLoading(false)
    })
  }

  return (
    <SinglePageLayout
      onGoBack={handleGoBack}
      className={styles['disable-2fa-wrapper']}
    >
      <Form
        form={form}
        layout='vertical'
        onFieldsChange={detectErrors}
        onFinish={handleSubmit}
        className={styles['two-factor-auth-disable']}
      >
        <header>
          <Title>Disable two-step authentication</Title>
          <p>
            Enter your backup code to disable two-step authentication.
            Your backup code is the 24 character code you received when you activated two-step authentication.
          </p>
        </header>
        <div>
          <Form.Item
            name='email'
            label='Email'
            rules={[
              {
                type: 'email',
                message: 'The input is not valid email',
              },
              {
                required: true,
                message: 'Please input your email'
              }
            ]}
          >
            <Input
              placeholder='Email'
              size='large'
              type='text'
            />
          </Form.Item>
          <Form.Item
            name='password'
            rules={[ { required: true, message: 'Please input your password' } ]}
          >
            <Input.Password
              type='password'
              placeholder='Password'
              size='large'
            />
          </Form.Item>
          <Form.Item
            name='emergency_code'
            rules={[ { required: true, message: 'Please input your emergency backup code' } ]}
          >
            <Input
              placeholder='Emergency backup code'
              size='large'
            />
          </Form.Item>
          <div className={styles['helper-text']}>
            In case you lost your emergency backup code, write us an email to <a
            href='mailto:support@boxo.io?subject=Lost my emergency backup code'>support@boxo.io</a>
          </div>
        </div>
        <div>
          <Button
            loading={isLoading}
            type='primary'
            htmlType='submit'
            size='large'
            block
            disabled={submitDisabled}
          >
            {isLoading ? 'Logging' : 'Continue'}
          </Button>
        </div>
      </Form>
    </SinglePageLayout>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators<any, any>({
      disableTwoFactorAuth: disableTwoFactorAuthAction,
      setAuth: setAuthAction
    }, dispatch)
  }
}

const connector = connect(null, mapDispatchToProps)

export default connector(DisableTwoFactorAuth)
