/*CORE*/
import React from 'react'
import { NavLink } from 'react-router-dom'
/*ASSETS*/
import { ReactComponent as DotsIcon } from 'assets/img/three-dots.svg'
/*LIBS*/
import Cookies from 'js-cookie'
import { Avatar, Menu, Dropdown } from 'antd'
import { bindActionCreators, Dispatch } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
/*SELECTORS*/
import { profileSelector } from 'store/profile/selectors'
/*ACTIONS*/
import { setAuth as setAuthAction } from 'store/auth/actions'
/*CONSTANTS*/
import { DEBUG, COOKIE_DOMAIN, BOXO_COOKIE_DOMAIN, Permissions } from 'utils/constants'
/*COMPONENTS*/
import AccessControl from '../../../common/AccessControl'
/*TYPES*/
import { RootState } from 'types'
/*STYLES*/
import styles from './ProfileNav.module.scss'

interface Props extends ConnectedProps<typeof connector> {
}

const ProfileNav = ({ profile, setAuth }: Props) => {
  const handleLogout = () => {
    Cookies.remove('token', {
      domain: DEBUG ? '' : COOKIE_DOMAIN
    })
    Cookies.remove('token', {
      domain: DEBUG ? '' : BOXO_COOKIE_DOMAIN
    })
    setAuth(false)
  }

  const menu = (
    <Menu>
      <Menu.Item>
        <NavLink to='/account' activeClassName={styles['active-link']}>Account</NavLink>
      </Menu.Item>
      <Menu.Item>
        <NavLink to='/organization' activeClassName={styles['active-link']}>Organization</NavLink>
      </Menu.Item>
      <Menu.Item>
        <NavLink to='/team' activeClassName={styles['active-link']}>Team</NavLink>
      </Menu.Item>
      {profile.is_billing_and_payouts_enabled && 
        <AccessControl permission={Permissions.CAN_MANAGE}>
          <Menu.Item>
            <NavLink to='/billing' activeClassName={styles['active-link']}>Billing</NavLink>
          </Menu.Item>
        </AccessControl>
      }
      {profile.is_billing_and_payouts_enabled && 
        <AccessControl permission={Permissions.CAN_MANAGE}>
          <Menu.Item>
            <NavLink to='/payouts' activeClassName={styles['active-link']}>Payouts</NavLink>
          </Menu.Item>
        </AccessControl>
      }
      <Menu.Item onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  )

  const name = profile.first_name || 'Anonymous user'

  return (
    <Dropdown
      overlay={menu}
      placement='topRight'
      trigger={[ 'click' ]}
      overlayClassName={styles['profile-nav']}
    >
      <div className={styles['profile-nav__trigger']}>
        <div className={styles['left']}>
          <Avatar size='large'>{name.slice(0, 1)}</Avatar>
          <div className={styles['name']}>{name}</div>
        </div>
        <DotsIcon className={styles['profile-nav__more-icon']} />
      </div>
    </Dropdown>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    profile: profileSelector(state)
  }
}


const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    ...bindActionCreators({
      setAuth: setAuthAction
    }, dispatch),
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(ProfileNav)
