/*CORE*/
import React from 'react'
/*LIBS*/
import classNames from 'classnames'
import { Tooltip } from 'antd'
/*ASSETS*/
import logoPlaceholder from 'assets/img/no-data-placeholers/logo_placeholder.png'
/*STYLES*/
import styles from './AppCard.module.scss'

interface Props {
  name: string
  imgUrl: string | null
  category?: string
  onClick?: () => void
}

const AppCard = ({ imgUrl, category, name, onClick }: Props) => {
  return (
    <div className={classNames(styles['app-card'], onClick && styles['allow-click'])} onClick={() => onClick && onClick()}>
      <div className={styles['logo']}>
        <img src={imgUrl || logoPlaceholder} alt='app logo' />
      </div>
      <Tooltip
        trigger={'hover'}
        placement="bottom"
        align={{ offset: [ 0, 17 ] }}
        overlayClassName={'partnership-name-card-tooltip'}
        title={name}
      >
        <h2>{name}</h2>
      </Tooltip>
      <p>{category}</p>
    </div>
  )
}

export default AppCard
