import React, { useState } from 'react'
import { FieldData, Store } from 'rc-field-form/lib/interface'
import { Button, Form, Input } from 'antd'
import { hasErrors } from 'utils/utils'

export type AuthCodeData = {
  code: string
}

type Props = {
  handleContinue: (formData: AuthCodeData) => void
}

const AuthCode = ({ handleContinue }: Props) => {
  const [submitDisabled, setSubmitDisabled] = useState(true)

  const hasChangesOrInvalid = (changedValues: FieldData[], allFields: FieldData[]) => {
    setSubmitDisabled(hasErrors(allFields))
  }

  const handleSubmit = (formData: Store) => {
    handleContinue(formData as AuthCodeData)
  }

  return (
    <>
      <header>
        <h1>Two-step authentication</h1>
        <p>
          Please enter your 6-digit authentication code from the <a href='https://support.google.com/accounts/answer/1066447?hl=en'
                                                                    target='_blank' rel='noopener noreferrer'>Google Authenticator</a>.
        </p>
      </header>
      <Form layout='vertical' id='authenticator-code' onFieldsChange={hasChangesOrInvalid} onFinish={handleSubmit}>
        <Form.Item
          label='Authenticator code'
          name='code'
          colon={false}
          required={false}
          rules={[
            { required: true, message: 'Please enter your authenticator code' },
            { min: 6, message: 'Please enter full authenticator code' }
          ]}
        >
          <Input
            maxLength={6}
            placeholder='******'
          />
        </Form.Item>
      </Form>
      <div>
        <Button
          block
          type='primary'
          htmlType='submit'
          form='authenticator-code'
          disabled={submitDisabled}
        >
          Continue
        </Button>
      </div>
    </>
  )
}

export default AuthCode
